<template>
  <div>
    <div
      style="
        text-align: center;
        background: #efefef;
        width: 1400px;
        margin: 0 auto;
        min-height: 668px;
        border: 1px solid #ddd;
        margin-bottom: 20px;
        position: relative;
      "
    >
      <div
        style="
          width: 1395px;
          position: fixed;
          background-color: #fff;
          top: 140px;
          left: calc(50% - 700px);
          margin: 0 auto;
          z-index: 999;
          padding-left: 5px;
        "
      >
        <el-row
          style="
            width: 1370px;
            height: auto;
            border-radius: 6px;
            margin: 0 auto;
            background: #fff;

            height: 3.75rem;
            line-height: 3.75rem;
            font-size: 18px;
            font-weight: 600;
            padding-left: 1rem;
            border: 1px solid #eee;
          "
          ><el-col :span="20"> 领导日程 </el-col>
          <el-col :span="4" style="text-align: right">
            <el-button type="text" @click="resetData">重置</el-button>
            <el-button type="text" @click="dialogVisible = true"
              >历史日程</el-button
            >
          </el-col>
        </el-row>
        <div id="LeaderDateBar" v-show="showLS">
          <el-row
            style="
              width: 1370px;
              height: auto;
              border-radius: 6px;
              margin: 8px auto;
              background: #fff;

              height: 3.875rem;
              line-height: 3.875rem;
              font-size: 16px;
              padding-left: 240px;
              border: 1px solid #eee;
            "
          >
            <el-col
              :span="2"
              @click.native="yearclick"
              style="
                text-align: center;
                padding-left: 1rem;
                cursor: pointe;
                border-bottom: 1px solid #ddd;
                width: 150px;
              "
              >{{ nowyear }}年</el-col
            >
            <el-col
              :span="2"
              @click.native="nowmonthclick"
              style="
                text-align: center;
                cursor: pointer;
                width: 114px;
                border-bottom: 1px solid #ddd;
              "
              id="row-nowmonth"
              >{{ nowmonth }}月</el-col
            >
            <el-col
              :span="2"
              @click.native="nextmonthclick"
              style="
                text-align: center;
                padding-right: 1rem;
                cursor: pointer;
                width: 114px;
                border-bottom: 1px solid #ddd;
              "
              id="row-nextmonth"
              >{{ nextmonth }}</el-col
            >
          </el-row>
          <el-row
            style="
              width: 1370px;
              height: auto;
              border-radius: 6px;
              margin: 8px auto;
              background: #fff;
              border: 1px solid #eee;
            "
          >
            <el-col :span="14" style="width: 150px; height: 10px"></el-col>
            <el-col
              class="el-col-leader"
              v-for="(name, index) in leaders"
              style="
                height: 3.875rem;
                line-height: 3.875rem;
                text-align: center;
                cursor: pointer;
                font-size: 16px;
              "
              :span="2"
              :key="index"
              @click.native="chooseLeader(name, $event)"
              >{{ name }}</el-col
            >
          </el-row>
        </div>
      </div>
      <div
        id="showListItem"
        style="
          margin-top: 240px;
          height: auto;
          min-height: 1000px;
          overflow: hidden;
        "
      >
        <div v-for="(row, i) in rows" :key="'info-' + i">
          <el-row
            style="
              width: 1366px;
              height: auto;
              border-radius: 6px;
              margin: 0 auto;
              background: #fff;
              margin-bottom: 8px;
            "
          >
            <el-col
              :span="3"
              style="
                width: 120px;
                height: 120px;
                line-height: 80px;
                border-radius: 20px;
                opacity: 1;
                text-align: center;
                font-size: 20px;
                color: #fff;
                margin: 17px 33px;
              "
              class="leader-date"
            >
              <div style="height: 60px; line-height: 80px">{{ row.ndate }}</div>
              <div style="height: 60px; line-height: 16px">{{ row.week }}</div>
            </el-col>
            <el-col :span="20" style="padding: 17px 0px">
              <el-row
                style="
                  font-size: 16px;
                  border-bottom: 1px solid #d8d8d8;
                  height: 3.75rem;
                  line-height: 3.75rem;
                  cursor: pointer;
                "
                v-for="(item, index) in row.data"
                :key="'key-' + index"
              >
                {{ item.leader }}， {{ item.ntime }}，{{ item.location }}，{{
                  item.content
                }}
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 查询历史日程 -->
    <el-dialog
      title="查询领导历史日程"
      :visible.sync="dialogVisible"
      width="30%"
      top="300px"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="12">
          领导：
          <el-select auto-complete filterable clearable v-model="sleader">
            <el-option
              v-for="leader in leaders"
              :key="leader"
              :value="leader"
              :label="leader"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12"
          >年月：
          <el-date-picker
            v-model="syearmonth"
            type="month"
            style="width: 80%"
          ></el-date-picker>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="searchByDate">确 定</el-button>
      </span>
    </el-dialog>
    <MFooter></MFooter>
  </div>
</template>

<script>
import MFooter from "../../components/mFooter";
import mFooter from "@/components/mFooter.vue";

import { ListInfoByMonth } from "@/api/content/db/xtbg_leader_trip.js";
import { ListLeaders } from "@/api/system/sys_dict_data.js";

import CryptoJS from "crypto-js";

import {
  getNowDateYearMonth,
  getNextDateYearMonth,
  getYearMonthDay,
  weekDay,
  geHHMM,
  JsonClassification,
  uniqueJsonArrByField,
  getalldays,
  transMD,
  goToTop,
  dateCompare2,
} from "@/utils/common.js";
var _this = null;
export default {
  components: {
    MFooter,
    mFooter,
    "full-calendar": require("vue-fullcalendar"),
  },
  data() {
    return {
      showLS: true,
      sleader: "",
      syearmonth: "",

      dialogVisible: false,
      loading: false,

      nowyear: "",
      nowmonth: "",
      nextmonth: "下月",
      leaders: [],
      chooseDate: "",
      rows: [],
      table_name: "xtbg_leader_trip",
    };
  },
  created() {
    _this = this;
    this.listLeaders();
    //this.getListTripDynamic();
  },
  watch: {},
  methods: {
    encrypt(text) {
      const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
      const IV_LENGTH = 16; // 初始化向量的长度

      // 生成随机的初始化向量（IV）
      const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

      // 使用 AES 加密
      const encrypted = CryptoJS.AES.encrypt(
        text,
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // 返回加密后的字符串和 IV
      return (
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    listLeaders() {
      ListLeaders().then((res) => {
        this.leaders = [];

        let rows = res.data.rows.sort((s, n) => {
          return s.dictSort - n.dictSort;
        });

        rows.map((row) => {
          this.leaders.push(row.dictValue);
        });

        let nowM = new Date().getMonth() + 1;
        this.nowmonth = nowM > 12 ? 1 : nowM;
        this.nowyear = new Date().getFullYear();
        //获取当前月份的的领导日程
        this.nowmonthclick();
        goToTop();
      });
    },
    //查询历史日程
    searchByDate() {
      this.showLS = false;
      this.showTrueOrFalse();
      this.dialogVisible = false;
      this.syearmonth = getNowDateYearMonth(this.syearmonth);
      //开始去后台查询对应月份的领导日程
      this.getListTripDynamicForHistory(this.sleader, this.syearmonth);
      goToTop();
    },
    resetData() {
      this.showLS = true;
      this.showTrueOrFalse();
    },
    showTrueOrFalse() {
      let leaderBar = document.getElementById("LeaderDateBar");
      let showListItem = document.getElementById("showListItem");
      if (this.showLS) {
        leaderBar.style.height = localStorage.getItem("lheight");
        showListItem.style.marginTop = 240 + "px";
      } else {
        leaderBar.style.height = 0;
        showListItem.style.marginTop = 80 + "px";
      }
    },
    //历史日程查询
    getListTripDynamicForHistory(leader, mdate) {
      //console.log(mdate);
      let queryStr =
        "SELECT * FROM xtbg_leader_trip WHERE leader like '%" +
        leader +
        "%' and (mdate like '%" +
        mdate +
        "%' or edate LIKE '%" +
        mdate +
        "%') and del_flag='正常显示'";

      this.rows = [];
      let query = {
        query: this.encrypt(queryStr),
        params: [],
      };
      this.rows = [];

      let mdateArr = [];

      this.$http
        .post(this.$db_dynamic_url, query, {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
          },
        })
        .then((res) => {
          //第一先筛选出有结束日期和没结束日期的数据

          //console.log(res);

          let hasEndData = res.data.filter((row) => {
            return row.edate != null;
          });

          //获取两个时间之间的所有日期
          let hasNewData = [];
          let daysData = [];
          hasEndData.map((row) => {
            let allDays = getalldays(row.mdate, row.edate);
            allDays = allDays.filter((item) => {
              return item.indexOf(mdate) > -1;
            });
            daysData.push({
              id: row.id,
              days: allDays,
              data: row,
            });
          });

          // console.log(daysData);

          var newDatas = [];
          daysData.map((row) => {
            row.days.map((one) => {
              let newRow = {
                ndate: one,
              };

              let lrow = Object.assign({}, newRow, row.data);
              newDatas.push(lrow);
            });
          });

          let noHasEndData = res.data.filter((row) => {
            return row.edate == null;
          });

          newDatas.map((row) => {
            //et mdates = getYearMonthDay(row.mdate).split("-");
            row.week = weekDay(row.ndate);
            row.start_time = geHHMM(row.start_time);
            row.end_time = geHHMM(row.end_time);
            //let mdate = mdates[1] + "月" + mdates[2] + "日";
            row.ndate = getYearMonthDay(row.ndate);

            mdateArr.push({
              ndate: row.ndate,
              week: row.week,
            });

            this.rows.push(row);
          });

          //没有结束日期的再次处理
          noHasEndData.map((row) => {
            //et mdates = getYearMonthDay(row.mdate).split("-");
            row.week = weekDay(row.mdate);
            row.start_time = geHHMM(row.start_time);
            row.end_time = geHHMM(row.end_time);
            //let mdate = mdates[1] + "月" + mdates[2] + "日";
            row.ndate = getYearMonthDay(row.mdate);

            mdateArr.push({
              ndate: getYearMonthDay(row.ndate),
              week: row.week,
            });

            this.rows.push(row);
          });

          mdateArr = uniqueJsonArrByField(mdateArr, "ndate");
          //按时间排序
          mdateArr.sort((s, n) => {
            return Date.parse(s.ndate) - Date.parse(n.ndate);
          });
          //筛选时间
          // mdateArr = mdateArr.filter((row) => {
          //   // console.log(row.ndate);
          //   // console.log(getYearMonthDay(new Date()));
          //   return dateCompare2(getYearMonthDay(new Date()), row.ndate);
          // });
          let lmdateArr = [];
          mdateArr.map((row) => {
            lmdateArr.push({
              ndate: transMD(row.ndate),
              week: row.week,
            });
          });

          let mrows = [];
          this.rows.map((row) => {
            row.ndate = transMD(row.ndate);
            mrows.push(row);
          });

          let last_arr = [];
          lmdateArr.map((mydateRow) => {
            let data = mrows.filter((item) => {
              return item.ndate == mydateRow.ndate;
            });

            let ndata = [];
            for (let i = 0; i < this.leaders.length; i++) {
              let lls = data.filter((rr) => {
                return rr.leader == this.leaders[i];
              });
              let mymd =
                new Date().getFullYear() +
                "-" +
                mydateRow.ndate.replace("月", "-").replace("日", " ");

              lls.sort((s, n) => {
                return (
                  Date.parse(mymd + " " + s.start_time + ":00") -
                  Date.parse(mymd + " " + n.start_time + ":00")
                );
              });
              lls.map((ll) => {
                if (typeof ll != "undefined") {
                  let ntime = ll.start_time + "-" + ll.end_time;
                  ntime = ntime == "08:30-18:00" ? "全天" : ntime;
                  ll.ntime = ntime;
                  ndata.push(ll);
                }
              });
            }
            //对ndata进行排序先按 根据时间

            last_arr.push({
              ndate: mydateRow.ndate,
              week: mydateRow.week,
              data: ndata,
            });
          });

          this.rows = last_arr;

          goToTop();

          //第一先把所有日期读出来

          //默认是当前月份
          // let divNoewmonth = document.getElementById("row-nowmonth");
          // divNoewmonth.style.background = "#519f6d";
          // divNoewmonth.style.color = "#FFFFFF";
        });
    },
    getNextListTripDynamic(leader, mdate) {
      let month_first = mdate.split("-")[0] + "-" + mdate.split("-")[1] + "-01";
      let month_end =
        getNextDateYearMonth(new Date()) + "-" + new Date().getDate();
      console.log(month_end);

      //console.log(mdate);
      let query = {
        query: this.encrypt(
          "SELECT * FROM xtbg_leader_trip WHERE leader like '%" +
            leader +
            "%' AND (mdate >= '" +
            month_first +
            "' or edate <= '" +
            month_end +
            "') AND (mdate like '%" +
            mdate +
            "%' or edate like '" +
            mdate +
            "%') and del_flag='正常显示'"
        ),
        params: [],
      };
      // console.log(query);

      this.rows = [];

      let mdateArr = [];

      this.$http.post(this.$db_dynamic_url, query,{
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
        },
      }).then((res) => {
        //第一先筛选出有结束日期和没结束日期的数据

        let hasEndData = res.data.filter((row) => {
          return row.edate != null;
        });

        //获取两个时间之间的所有日期
        let hasNewData = [];
        let daysData = [];
        hasEndData.map((row) => {
          let allDays = getalldays(row.mdate, row.edate);
          allDays = allDays.filter((item) => {
            return item.indexOf(mdate) > -1;
          });
          daysData.push({
            id: row.id,
            days: allDays,
            data: row,
          });
        });

        //console.log(daysData);

        var newDatas = [];
        daysData.map((row) => {
          row.days.map((one) => {
            let newRow = {
              ndate: one,
            };

            let lrow = Object.assign({}, newRow, row.data);
            newDatas.push(lrow);
          });
        });

        let noHasEndData = res.data.filter((row) => {
          return row.edate == null;
        });

        newDatas.map((row) => {
          //et mdates = getYearMonthDay(row.mdate).split("-");
          row.week = weekDay(row.ndate);
          row.start_time = geHHMM(row.start_time);
          row.end_time = geHHMM(row.end_time);
          //let mdate = mdates[1] + "月" + mdates[2] + "日";
          row.ndate = getYearMonthDay(row.ndate);

          mdateArr.push({
            ndate: row.ndate,
            week: row.week,
          });

          this.rows.push(row);
        });

        //没有结束日期的再次处理
        noHasEndData.map((row) => {
          //et mdates = getYearMonthDay(row.mdate).split("-");
          row.week = weekDay(row.mdate);
          row.start_time = geHHMM(row.start_time);
          row.end_time = geHHMM(row.end_time);
          //let mdate = mdates[1] + "月" + mdates[2] + "日";
          row.ndate = getYearMonthDay(row.mdate);

          mdateArr.push({
            ndate: getYearMonthDay(row.ndate),
            week: row.week,
          });

          this.rows.push(row);
        });

        mdateArr = uniqueJsonArrByField(mdateArr, "ndate");
        //按时间排序
        mdateArr.sort((s, n) => {
          return Date.parse(s.ndate) - Date.parse(n.ndate);
        });
        //筛选时间
        mdateArr = mdateArr.filter((row) => {
          // console.log(row.ndate);
          // console.log(getYearMonthDay(new Date()));
          return dateCompare2(getYearMonthDay(new Date()), row.ndate);
        });
        let lmdateArr = [];
        mdateArr.map((row) => {
          lmdateArr.push({
            ndate: transMD(row.ndate),
            week: row.week,
          });
        });

        let mrows = [];
        this.rows.map((row) => {
          row.ndate = transMD(row.ndate);
          mrows.push(row);
        });

        let last_arr = [];
        lmdateArr.map((mydateRow) => {
          let data = mrows.filter((item) => {
            return item.ndate == mydateRow.ndate;
          });

          let ndata = [];
          for (let i = 0; i < this.leaders.length; i++) {
            let lls = data.filter((rr) => {
              return rr.leader == this.leaders[i];
            });
            let mymd =
              new Date().getFullYear() +
              "-" +
              mydateRow.ndate.replace("月", "-").replace("日", " ");

            lls.sort((s, n) => {
              return (
                Date.parse(mymd + " " + s.start_time + ":00") -
                Date.parse(mymd + " " + n.start_time + ":00")
              );
            });
            lls.map((ll) => {
              if (typeof ll != "undefined") {
                let ntime = ll.start_time + "-" + ll.end_time;
                ntime = ntime == "08:30-18:00" ? "全天" : ntime;
                ll.ntime = ntime;
                ndata.push(ll);
              }
            });
          }
          //对ndata进行排序先按 根据时间

          last_arr.push({
            ndate: mydateRow.ndate,
            week: mydateRow.week,
            data: ndata,
          });
        });

        this.rows = last_arr;

        goToTop();

        //第一先把所有日期读出来

        //默认是当前月份
        // let divNoewmonth = document.getElementById("row-nowmonth");
        // divNoewmonth.style.background = "#519f6d";
        // divNoewmonth.style.color = "#FFFFFF";
      });
    },
    // 根据
    getListTripDynamic(leader, mdate) {
      let month_first = mdate.split("-")[0] + "-" + mdate.split("-")[1] + "-01";
      let month_end = getNextDateYearMonth(new Date());

      let query = {
        query: this.encrypt(
          "SELECT * FROM xtbg_leader_trip WHERE leader like '%" +
            leader +
            "%' AND (start_time like '%" +
            mdate +
            "%' or end_time like '" +
            mdate +
            "%') and del_flag='正常显示'"
        ),
        params: [],
      };

      this.rows = [];

      let mdateArr = [];

      this.$http.post(this.$db_dynamic_url, query,{
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
        },
      }).then((res) => {
        //第一先筛选出有结束日期和没结束日期的数据

        let hasEndData = res.data.filter((row) => {
          return row.edate != null;
        });

        //获取两个时间之间的所有日期
        let hasNewData = [];
        let daysData = [];
        hasEndData.map((row) => {
          let allDays = getalldays(row.mdate, row.edate);
          allDays = allDays.filter((item) => {
            return item.indexOf(mdate) > -1;
          });
          daysData.push({
            id: row.id,
            days: allDays,
            data: row,
          });
        });

        //console.log(daysData);

        var newDatas = [];
        daysData.map((row) => {
          row.days.map((one) => {
            let newRow = {
              ndate: one,
            };

            let lrow = Object.assign({}, newRow, row.data);
            newDatas.push(lrow);
          });
        });

        let noHasEndData = res.data.filter((row) => {
          return row.edate == null;
        });

        newDatas.map((row) => {
          //et mdates = getYearMonthDay(row.mdate).split("-");
          row.week = weekDay(row.ndate);
          row.start_time = geHHMM(row.start_time);
          row.end_time = geHHMM(row.end_time);
          //let mdate = mdates[1] + "月" + mdates[2] + "日";
          row.ndate = getYearMonthDay(row.ndate);

          mdateArr.push({
            ndate: row.ndate,
            week: row.week,
          });

          this.rows.push(row);
        });

        //没有结束日期的再次处理
        noHasEndData.map((row) => {
          //et mdates = getYearMonthDay(row.mdate).split("-");
          row.week = weekDay(row.mdate);
          row.start_time = geHHMM(row.start_time);
          row.end_time = geHHMM(row.end_time);
          //let mdate = mdates[1] + "月" + mdates[2] + "日";
          row.ndate = getYearMonthDay(row.mdate);

          mdateArr.push({
            ndate: getYearMonthDay(row.ndate),
            week: row.week,
          });

          this.rows.push(row);
        });

        mdateArr = uniqueJsonArrByField(mdateArr, "ndate");
        //按时间排序
        mdateArr.sort((s, n) => {
          return Date.parse(s.ndate) - Date.parse(n.ndate);
        });
        //筛选时间
        mdateArr = mdateArr.filter((row) => {
          // console.log(row.ndate);
          // console.log(getYearMonthDay(new Date()));
          return dateCompare2(getYearMonthDay(new Date()), row.ndate);
        });
        let lmdateArr = [];
        mdateArr.map((row) => {
          lmdateArr.push({
            ndate: transMD(row.ndate),
            week: row.week,
          });
        });

        let mrows = [];
        this.rows.map((row) => {
          row.ndate = transMD(row.ndate);
          mrows.push(row);
        });

        let last_arr = [];
        lmdateArr.map((mydateRow) => {
          let data = mrows.filter((item) => {
            return item.ndate == mydateRow.ndate;
          });

          let ndata = [];
          for (let i = 0; i < this.leaders.length; i++) {
            let lls = data.filter((rr) => {
              return rr.leader == this.leaders[i];
            });
            let mymd =
              new Date().getFullYear() +
              "-" +
              mydateRow.ndate.replace("月", "-").replace("日", " ");

            lls.sort((s, n) => {
              return (
                Date.parse(mymd + " " + s.start_time + ":00") -
                Date.parse(mymd + " " + n.start_time + ":00")
              );
            });
            lls.map((ll) => {
              if (typeof ll != "undefined") {
                let ntime = ll.start_time + "-" + ll.end_time;
                ntime = ntime == "08:30-18:00" ? "全天" : ntime;
                ll.ntime = ntime;
                ndata.push(ll);
              }
            });
          }
          //对ndata进行排序先按 根据时间

          last_arr.push({
            ndate: mydateRow.ndate,
            week: mydateRow.week,
            data: ndata,
          });
        });

        this.rows = last_arr;

        goToTop();

        //第一先把所有日期读出来

        //默认是当前月份
        // let divNoewmonth = document.getElementById("row-nowmonth");
        // divNoewmonth.style.background = "#519f6d";
        // divNoewmonth.style.color = "#FFFFFF";
      });
    },

    clearBg() {
      let elLeaders = document.getElementsByClassName("el-col-leader");
      for (let i = 0; i < elLeaders.length; i++) {
        elLeaders[i].style.background = "rgb(255,255,255)";
        elLeaders[i].style.color = "rgb(0,0,0)";
      }
    },
    chooseLeader(name, e) {
      this.clearBg();
      //e.target.style.background = "#519f6d";
      e.target.style.color = "#519f6d";
      this.getListTripDynamic(name, this.chooseDate);
    },
    //当月
    nowmonthclick() {
      let divNextwmonth = document.getElementById("row-nextmonth");
      //divNextwmonth.style.background = "#FFFFFF";
      divNextwmonth.style.color = "#000000";

      let divNoewmonth = document.getElementById("row-nowmonth");
      //divNoewmonth.style.background = "#519f6d";
      divNoewmonth.style.color = "#519f6d";

      this.clearBg();
      let date = getNowDateYearMonth(new Date());
      this.chooseDate = date;
      let leader = "";
      //this.getLeaderTripList(leader, date);
      this.getListTripDynamic(leader, date);
      goToTop();
    },
    //下一个月
    nextmonthclick() {
      let divNextwmonth = document.getElementById("row-nextmonth");
      //divNextwmonth.style.background = "#519f6d";
      divNextwmonth.style.color = "#519f6d";

      let divNoewmonth = document.getElementById("row-nowmonth");
      //divNoewmonth.style.background = "#FFFFFF";
      divNoewmonth.style.color = "#000000";
      this.clearBg();
      let date = getNextDateYearMonth(new Date());
      this.chooseDate = date;
      let leader = "";
      //this.getLeaderTripList(leader, date);
      this.getNextListTripDynamic(leader, date);
      goToTop();
    },

    //默认显示当月份的所有数据
  },
  mounted() {
    // let divNoewmonth = document.console.log(query)("row-nowmonth");
    // divNoewmonth.style.background = "#519f6d";
    // divNoewmonth.style.color = "#FFFFFF";

    let leaderBar = document.getElementById("LeaderDateBar");
    let showListItem = document.getElementById("showListItem");
    console.log(showListItem.style.height);
    localStorage.setItem("lheight", leaderBar.style.height);
    localStorage.setItem("bmargic", showListItem.style.marginTop);
  },
};
</script>
  
  <style lang="scss" scoped>
.el-col-leader {
  /* &:first-child {
    border: 1px solid #dd7a10;
  }
  &:nth-child(2) {
    border: 1px solid #3a9cc2;
  }
  &:nth-child(3) {
    border: 1px solid #6b50bc;
  }
  &:nth-child(4) {
    border: 1px solid #902d06;
  }
  &:nth-child(5) {
    border: 1px solid #12766f;
  } */
}
.row-style {
  height: 2rem;
  line-height: 2rem;
}
::v-deep .el-row {
  text-align: left;
  font-size: 0.875rem;
}
.leader-showrc {
  &:last-child {
    border: none !important;
  }
}
.leader-date {
  background: url("../../assets/imgs/leadertrip/leader-rq.png");
}
::v-deep .el-dialog {
  text-align: left;
}
</style>