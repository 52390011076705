<template>
  <div>
    <div class="personal-page">
      <el-row style="padding: 10px">
        <el-col :span="4" style="background: #ffff; min-height: 700px">
          <div style="padding: 10px 0; position: relative">
            <img
              src="../../assets/imgs/avator/avator.jpg"
              style="width:200px,height:210px ;"
              alt=""
            />
            <div
              style="
                color: #ffff;
                width: 210px;
                height: 30px;
                line-height: 30px;
                cursor: pointer;
                font-size: 14px;
                background: rgba(0, 0, 0, 0.5);
                position: absolute;
                bottom: 14px;
                left: 10px;
              "
            >
              修改头像
            </div>
          </div>
          <div>
            <div class="personal-page-info">用户：{{ userinfo.name }}</div>
            <div class="personal-page-info">电话：{{ userinfo.phone }}</div>
            <div class="personal-page-info">邮箱：{{ userinfo.email }}</div>
            <div class="personal-page-info">部门：{{ userinfo.deptName }}</div>
          </div>
        </el-col>
        <el-col :span="20" style="padding: 0 10px 10px 10px">
          <div style="background: #ffff; min-height: 680px; padding: 10px">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="资料修改" name="first">
                <el-card class="box-card">
                  <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="电话" style="text-align: left">
                      <el-input
                        v-model="form.phone"
                        style="width: 50%"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="邮箱"
                      style="text-align: left"
                      prop="email"
                    >
                      <el-input
                        v-model="form.email"
                        style="width: 50%"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="部门"
                      style="text-align: left"
                      prop="deptName"
                    >
                      <el-select
                        auto-complete=""
                        filterable
                        v-model="form.deptName"
                        placeholder="部门"
                        style="width: 50%"
                      >
                        <el-option
                          v-for="(item, index) in depts"
                          :key="'dept-' + index"
                          :label="item.deptName"
                          :value="item.deptName"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item style="text-align: left">
                      <el-button type="primary" @click="onSubmitForInfo"
                        >修改信息</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-card>
              </el-tab-pane>
              <el-tab-pane label="密码重置" name="second">
                <el-card class="box-card">
                  <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="旧密码" style="text-align: left">
                      <el-input
                        type="password"
                        v-model="form2.password"
                        style="width: 50%"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" style="text-align: left">
                      <el-input
                        type="password"
                        v-model="form2.newpassword"
                        style="width: 50%"
                        show-password
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="确认新密码" style="text-align: left">
                      <el-input
                        type="password"
                        v-model="form2.againpassword"
                        style="width: 50%"
                        show-password
                      ></el-input>
                    </el-form-item>

                    <el-form-item style="text-align: left">
                      <el-button type="primary" @click="onSubmitForPwd"
                        >修改密码</el-button
                      >
                      <el-button>取消</el-button>
                    </el-form-item>
                  </el-form>
                </el-card>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
    </div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mFooter from "@/components/mFooter.vue";
import { ListUser2, UpdateUser } from "@/api/content/xtbg_user";
import { ListAllDepts } from "@/api/system/sys_dept";
import sha1 from 'js-sha1';

export default {
  components: {
    mFooter,
  },
  data() {
    return {
      activeName: "first",
      userinfo: {},
      form: {},
      form2: {},
      id: 0,
      depts: [],
    };
  },
  created() {
    this.getUserInfo();
    this.getListAllDepts();
  },
  methods: {
    getListAllDepts() {
      ListAllDepts().then((res) => {
        //console.log(res);
        this.depts = res.data.data;
        //console.log(this.depts);
      });
    },
    onSubmitForInfo() {
      this.form.id = this.id;
      UpdateUser(this.form).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            offset: 200,
            message: "修改成功",
          });
          this.getUserInfo();
          //this.userinfo = res.data.
        } else {
          this.$message({
            type: "error",
            offset: 200,
            message: "修改失败",
          });
        }
      });
    },
    onSubmitForPwd() {
      //判断密码强度
      var reg =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@ #$%^&*()_+`\-={}:";'<>?,.\/]).{6,64}$/;

      if (reg.test(this.form2.newpassword)) {
        // 验证通过
        //修改初始密码
        if (this.form2.againpassword == this.form2.newpassword) {
          UpdateUser({
            id: this.id,
            email: localStorage.getItem("email"),
            password: sha1(onSubmitForPwd),
          }).then((res) => {
            //console.log(res);
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                offset: 200,
                message: "修改成功",
              });
              localStorage.clear();
              this.$router.push({
                path: "/login",
              });
            } else {
              this.$message({
                type: "error",
                offset: 200,
                message: "修改失败",
              });
            }
          });
        } else {
          this.$message({
            type: "error",
            offset: 200,
            message: "两次输入密码不一样",
          });
        }
      } else {
        this.$message({
          type: "error",
          message: "密码必须由 6-64位字母、数字、特殊符号组成",
          offset: 300,
        });
        return false;
      }
    },
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    getUserInfo() {
      ListUser2({
        pageSize: 10,
        pageNum: 1,
        email: localStorage.getItem("email"),
      }).then((res) => {
        if (typeof res != undefined) {
          if (res.data.rows.length > 0) {
            let user = res.data.rows[0];
            this.userinfo = user;
            this.form = user;
            this.form2 = user;
            this.id = user.id;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-page {
  background: #efefef;
  min-height: 700px;
  width: 1400px;
  margin: 0 auto;
  .personal-page-info {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    text-align: left;
    padding: 0 10px;
  }
}
</style>