<template>
  <div>
    <div style="display: flex" class="top-container">
      <div class="search-container">
        <input
          type="text"
          v-model="search_text"
          placeholder="仪器名称/仪器型号/功能/存放地点/仪器责任人"
        />
        <button type="submit" style="width: 40px" @click="searchDevice">
          <i class="el-icon-search" style="color: #fff"></i>
        </button>
        <button
          type="default"
          style="width: 40px; margin-left: 10px; width: 90px; color: #fff"
          @click="reloadData"
        >
          <i class="el-icon-refresh" style="color: #fff"></i>重载数据
        </button>
      </div>
      <div style="display: flex; justify-content: center; align-items: center">
        <button
          @click="showAdd"
          type="submit"
          style="
            width: 80px;
            height: 40px;
            line-height: 40px;
            border: 0px;
            background-color: #4caf50;
            color: #fff;
          "
        >
          <i class="el-icon-plus"></i>新增
        </button>
      </div>
    </div>
    <div class="grid-container">
      <!-- <masonry :cols="{ default: 5, 798: 2, 700: 1 }" gutter="5px">
     
    </masonry> -->

      <GridItem
        @click.native="showDetail(item.id)"
        v-for="(item, index) in items"
        :key="index"
        :photo_urls="item.photo_urls"
        :name="item.name"
        :maker="item.maker"
        :id="item.id"
        :num="item.num"
        :location="item.location"
        :administrator="item.administrator"
        @deleteDevice="deleteDevice"
        @editDevice="editorDevice"
      />
    </div>
    <!-- 添加 -->
    <addDevice :visible="showDeviceAdd" @close="closeDialog"></addDevice>
    <!-- 修改 -->
    <EditDevice
      :visible="showDeviceEdit"
      :id="editId"
      @close="closeDialog"
    ></EditDevice>
  </div>
</template>

<script>
import GridItem from "./components/GridItemGLY.vue";
import { Masonry } from "vue-masonry-css";
import addDevice from "./components/device_add.vue";
import EditDevice from "./components/device_edit.vue";

export default {
  name: "App",
  components: {
    GridItem,
    Masonry,
    addDevice,
    EditDevice,
  },
  data() {
    return {
      editId: 0,

      showDeviceAdd: false,
      showDeviceEdit: false,
      search_text: "",
      items: [
        // Add more items here
      ],

      sqlStr: `SELECT i.*,
      (SELECT photo_url FROM xtbg_device_instrument_photos WHERE instrument_num=i.num order by id DESC LIMIT 1) as photo_urls,
      (SELECT attachment_url FROM xtbg_device_instrument_attachments WHERE instrument_num=i.num order by id DESC  LIMIT 1) as attachment_urls 
      FROM xtbg_device_instruments i  order by i.id DESC;
   `,
    };
  },
  async created() {
    await this.getList(this.sqlStr);
  },
  watch: {
    search_text(val) {
      if (val.length >= 2) {
        this.searchDevice();
      }
      if (val.length == 0) {
        this.getList(this.sqlStr);
      }
    },
  },
  methods: {
    editorDevice(id) {
      this.editId = id;
      this.showDeviceEdit = true;
    },
    deleteDevice(ob) {
      let id = ob.id;
      let num = ob.num;
      console.log(ob);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const deleteRes = await this.$db_delete(
            this.$http,
            "xtbg_device_instruments",
            "id=" + id
          );
          if (deleteRes.affectedRows > 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            //删除对应的图片和附件
            const deleteImageRes = await this.$db_delete(
              this.$http,
              "xtbg_device_instrument_photos",
              "instrument_num='" + num + "'"
            );
            console.log(deleteImageRes);
            const deleteAttachmentRes = await this.$db_delete(
              this.$http,
              "xtbg_device_instrument_attachments",
              "instrument_num='" + num + "'"
            );
            if (
              deleteRes.affectedRows > 0 &&
              deleteImageRes.affectedRows > 0 &&
              deleteAttachmentRes.affectedRows > 0
            ) {
              this.$message({
                type: "success",
                message: "删除图片和附件成功!",
              });
            } else {
              this.$message.error("删除图片和附件失败!");
            }

            // 重新获取列表

            this.getList(this.sqlStr);
          } else {
            this.$message.error("删除失败!");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    closeDialog() {
      this.showDeviceAdd = false;
      this.showDeviceEdit = false;
    },
    updateVisible(bool) {
      this.showDeviceAdd = bool;
    },
    showAdd() {
      this.showDeviceAdd = true;
    },
    showDetail(id) {
      this.$router.push({
        path: "/device/detail",
        query: {
          id: id,
        },
      });
    },
    async reloadData() {
      await this.getList(this.sqlStr);
    },

    async searchDevice() {
      const search_sql =
        "SELECT d.*,(SELECT photo_url FROM xtbg_device_instrument_photos WHERE instrument_num=d.num LIMIT 1) as photo_urls," +
        "(SELECT attachment_url FROM xtbg_device_instrument_attachments WHERE instrument_num=d.num LIMIT 1) as attachment_urls" +
        " FROM xtbg_device_instruments d WHERE d.`name` LIKE '%" +
        this.search_text +
        "%' OR d.model LIKE '%" +
        this.search_text +
        "%' OR d.purpose LIKE '%" +
        this.search_text +
        "%' OR d.address LIKE '%" +
        this.search_text +
        "%' OR d.administrator LIKE '%" +
        this.search_text +
        "%'";

      await this.getList(search_sql);
    },

    async getList(sqlStr) {
      this.items = [];

      let deviceData = await this.$dbquery(this.$http, sqlStr);
      // deviceData = deviceData.map((device) => {
      //   if (device.photo_urls) {
      //     device.photo_urls && device.photo_urls.indexOf(",") > 0
      //       ? device.photo_urls.split(",")[0]
      //       : device.photo_urls || "";
      //   }

      //   if (device.attachment_urls) {
      //     device.attachment_urls = device.attachment_urls
      //       ? device.attachment_urls.indexOf(",") > 0
      //         ? device.attachment_urls.split(",")[0]
      //         : device.attachment_urls
      //       : "";
      //   }
      // });

      // deviceData.sort((a, b) => a.name.localeCompare(b.name, "zh-CN"));
      this.items = deviceData;
   
    },
  },
};
</script>

<style scoped>
.masonry {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  flex-direction: row; /* Arrange items horizontally */
  overflow-x: auto; /* Allow horizontal scrolling */
}
.masonry > * {
  margin-bottom: 30px;
  padding-left: 30px; /* gutter size */
  flex: 0 0 auto; /* Prevent items from shrinking */
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px; /* 子项目之间的间距 */
  padding-top: 10px;

  background: #f8f8f8;
}
.top-container {
  background-color: #f8f8f8;
  padding: 10px;
  width: calc(100% - 20px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.search-container {
  display: flex;
  align-items: center;
  flex: 1;
}
.search-container input {
  width: 60%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
  font-size: 14px;
}

.search-container button {
  padding: 10px;
  background-color: #4caf50;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.search-container button img {
  width: 20px;
  height: 20px;
}

.search-container button:hover {
  background-color: #45a049;
}
</style>
