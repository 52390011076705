<template>
  <div class="bny_index-list">
    <div class="bny_index-list-title">
      <img :src="titlelogo" alt="" />
      <div>{{ title }}</div>
      <div @click="moreClick">更多>></div>
    </div>
    <div class="bny_index-list-content">
      <el-table
        :data="tableData"
        stripe
        style="width: 1000px; background: transparent"
        @row-click="tableRowClick"
      >
        <el-table-column
          v-for="item in TableColumns"
          :key="item.id"
          :prop="item.pname"
          :label="item.label"
          :width="item.width"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "index-line-item",
  props: {
    title: {
      type: String,
      default: "",
    },
    titlelogo: {
      type: String,
      default: "",
    },
    TableColumns: {
      type: Array,
      default: [],
      required: true,
    },
    tableData: {
      type: Array,
      default: [],
      required: true,
    },
  },
  data() {
    return {};
  },
  created(){

  },
  methods: {
    tableRowClick(row, column, event) {
      // console.log(this.title);
      // console.log(row);
      if (this.title.indexOf("咨询平台") == -1) {
        this.$router.push({
          path: "/newsdetail",
          query: {
            title: encodeURI(this.title),
            showTitle: encodeURI(row.title),
            id:row.id
          },
        });
      } else {
        this.$router.push({
          path: "/advise_detail",
          query: {
            title: row.title,
          },
        });
      }
    },
    moreClick() {
      if (this.title.indexOf("咨询平台") == -1) {
        this.$router.push({
          path: "/news",
          query: {
            name: this.title,
          },
        });
      } else {
        this.$router.push({
          path: "/advise",
          query: {
            name: this.title,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bny_index-list {
  height: 350px;
  width: 1000px;
  //background: url('../../assets/imgs/m001.png') no-repeat;
  background-size: 100% 100%;
  margin: 5px 0px 0px 0px;
  .bny_index-list-title {
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    width: 1000px;
    background-color: #ceded3;
    img {
      width: 25px;
      height: 25px;
      margin: 12px;
      float: left;
    }
    div {
      color: #2e6548;
      font-size: 14px;
      text-align: left;
      padding-left: 5px;
      float: left;
      &:last-child {
        float: right;
        margin-right: 15px;
        cursor: pointer;
      }
    }
  }
  .bny_index-list-content {
    width: 1000px;
    height: 300px;
    font-size: 14px;
    color: #2e6548;
  }
}
</style>
