<template>
  <div class="yqsb_mg" style="text-align: left">
    <el-dialog
      :visible.sync="visible"
      width="50%"
      @close="$parent.closeDialog"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template slot="title">
        <div class="custom-dialog-title">
          <span>修改仪器信息（已上传过的仪器照片和附件不可修改，只能增加对应的仪器照片和附件）</span>
          <el-button
            @click="$parent.closeDialog"
            class="close-btn"
            icon="el-icon-close"
            circle
          ></el-button>
        </div>
      </template>
      <div class="dialog-content">
        <el-form
          :model="formData"
          :rules="rules"
          ref="form"
          label-width="140px"
        >
          <div class="form-group">
            <el-form-item label="仪器编号：" prop="num" style="width: 80%">
              <div style="display: flex">
                <el-input
                  v-model.number="formData.num"
                  placeholder="请输入"
                  disabled
                ></el-input>
                <!-- <el-button type="primary" style="background: #3eaf7c" @click="handleMouseLeave">
                  检查是否存在
                </el-button> -->
              </div>
            </el-form-item>
          </div>
          <div class="form-group">
            <ImageUploadVue
              :uploadUrl="uploadUrl"
              :uploadData="{ num: formData.num }"
              @upload-success="handleUploadSuccess"
              @upload-error="handleUploadError"
              label="仪器照片:"
            >
            </ImageUploadVue>
          </div>

          <div class="form-group">
            <el-form-item label="仪器名称：" prop="name" style="width: 80%">
              <el-input v-model="formData.name" placeholder="请输入"></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="型号：" prop="model" style="width: 80%">
              <el-input
                v-model="formData.model"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item
              label="英文名称："
              prop="english_name"
              style="width: 80%"
            >
              <el-input
                v-model="formData.english_name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item
              label="购置时间："
              prop="purchase_date"
              style="width: 80%"
            >
              <el-date-picker
                v-model="formData.purchase_date"
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择日期"
              ></el-date-picker>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="采购价格：" prop="price" style="width: 80%">
              <el-input v-model="formData.price" placeholder="请输入采购价格">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="制造厂商：" prop="maker" style="width: 80%">
              <el-input
                v-model="formData.maker"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item
              label="功能与用途："
              prop="purpose"
              style="width: 80%"
            >
              <el-input
                type="textarea"
                v-model="formData.purpose"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="管理部门：" prop="dept" style="width: 80%">
              <!-- <el-input v-model="formData.dept" placeholder="请输入"></el-input> -->
              <el-autocomplete
                style="width: 96%"
                class="inline-input"
                v-model="formData.dept"
                :fetch-suggestions="querySearch"
                placeholder="请输入搜索选择"
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="所在园区：" prop="address" style="width: 80%">
              <el-select
                v-model="formData.address"
                placeholder="请选择"
                style="width: 96%"
              >
                <el-option
                  v-for="item in yqoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="存放地点：" prop="location" style="width: 80%">
              <el-input
                v-model="formData.location"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item
              label="管理员："
              prop="administrator"
              style="width: 80%"
            >
              <!-- <el-input
                v-model="formData.administrator"
                placeholder="请输入搜索选择"
              ></el-input> -->
              <el-autocomplete
                class="inline-input"
                v-model="formData.administrator"
                :fetch-suggestions="querySearchUser"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                value-key="name"
                @select="handleSelectUser"
              >
                <template slot-scope="{ item }">
                  <div>{{ item.name }}</div>
                </template>
              </el-autocomplete>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="联系方式：" prop="contact" style="width: 80%">
              <el-input
                v-model="formData.contact"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div class="form-group">
            <el-form-item label="附件：" prop="fileFileList" style="width: 80%">
              <el-upload
                ref="fileUpload"
                :action="uploadUrl"
                :data="uploadData"
                :multiple="true"
                :limit="5"
                :on-exceed="handleExceed"
                :file-list="fileFileList"
                :on-remove="handleFileRemove"
                :on-success="handleFileSuccess"
                :on-error="handleError"
                list-type="text"
              >
                <el-button size="small" type="primary">选取文件</el-button>
              </el-upload>
              <div class="file-tips">
                请上传格式为
                doc/xls/ppt/docx/xlsx/pptx/txt/pdf/jpg/jpeg/gif/png/zip/rar/mp3/mp4
                的文件
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="handleReset">重置</el-button> -->
        <el-button
          type="primary"
          @click="handleSubmit"
          style="background: #3eaf7c"
          >修改</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ImageUploadVue from "@/components/commom/ImageUpload.vue";
import { jubgeIsOrNot } from "@/utils/local_db";
let tableName= "xtbg_device_instruments";
export default {
  name: "device_add",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id:{
      type:Number,
      default: null,
    }
  },
  components: {
    ImageUploadVue,
  },
  data() {
    return {
      uploadUrl: "https://in.xtbg.ac.cn:8088/upload",
      formData: {
        num: "",
        name: "",
        model: "",
        english_name: "",
        purchase_date: "",
        price: null,
        maker: "",
        purpose: "",
        dept: "",
        address: "",
        location: "",
        administrator: "",
        contact: "",
      },
      yqoptions: [
        {
          value: "园部",
          label: "园部",
        },
        {
          value: "分部",
          label: "分部",
        },
        {
          value: "西南中心",
          label: "西南中心",
        },
      ],
      imageFileList: [],
      fileFileList: [],
      dialogImageUrl: "",
      dialogImageVisible: false,
      rules: {
        num: [{ required: true, message: "请输入仪器编号", trigger: "blur" }],
        name: [{ required: true, message: "请输入仪器名称", trigger: "blur" }],
        model: [{ required: true, message: "请输入型号", trigger: "blur" }],
        english_name: [
          { required: true, message: "请输入英文名称", trigger: "blur" },
        ],
        purchase_date: [
          { required: true, message: "请选择购置时间", trigger: "change" },
        ],
        price: [
          { required: true, message: "请输入采购价格", trigger: "blur" },
          { validator: this.validatePrice, trigger: "blur" },
        ],
        maker: [{ required: true, message: "请输入制造厂商", trigger: "blur" }],
        purpose: [
          { required: true, message: "请输入功能与用途", trigger: "blur" },
        ],
        dept: [{ required: true, message: "请输入管理部门", trigger: "blur" }],
        address: [
          { required: true, message: "请选择所在园区", trigger: "change" },
        ],
        location: [
          { required: true, message: "请输入存放地点", trigger: "blur" },
        ],
        administrator: [
          { required: true, message: "请输入管理员", trigger: "blur" },
        ],
        contact: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          { validator: this.validateContact, trigger: "blur" },
        ],
      },
      deptOptions: [],
      uploadImgs: [],
      uploadFiles: [],
    };
  },
  watch:{
    visible(val){
      if(val){
        
        this.getEditData();
      }
    }

  },
  computed: {
    uploadData() {
      return {
        num: this.formData.num,
      };
    },
  },
  methods: {
    async getEditData(){
      const result = await this.$db_select(this.$http,tableName,"*","id="+this.id);
      //console.log(result[0]);
      this.formData = result[0];
      delete this.formData.id;
      delete this.formData.create_by;
      delete this.formData.create_time;
      this.formData.purchase_date = this.$FormatDateToYMDHMS(this.formData.purchase_date);

    },
    async handleMouseLeave() {
      const numRes = await this.$db_select(
        this.$http,
        "xtbg_device_instruments",
        "num",
        "num='" + this.formData.num + "'"
      );
      if (numRes.length > 0) {
        this.$message.error("该仪器器已存在");
        return false;
      }else{
        this.$message({
          type:"info",
          message:"可添加仪器"
        })
      }
    },
    async getUsers() {
      //console.log(await this.$userList(this.$http));
    },
    async querySearchUser(queryString, cb) {
      var restaurants = await this.$userList(this.$http);

      var results = queryString
        ? restaurants.filter(this.createFilterUser(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilterUser(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelectUser(user) {
      this.formData.contact = user.phone;
    },

    handleUploadSuccess(response) {
      console.log("上传成功：", response);
      this.$message({
        type: "success",
        message: "上传成功",
      });
      this.uploadImgs.push(response.url);
      // 在此处理从上传成功返回的数据
    },
    handleUploadError(error) {
      console.error("上传失败：", error);
      this.$message({
        type: "error",
        message: "上传图片失败",
      });
      // 在此处理上传失败的错误
    },

    async getDepts() {
      const deptData = await this.$deptList(this.$http);
      this.deptOptions = deptData.map((item) => {
        return {
          value: item.dept_name,
        };
      });
      //  console.log(JSON.stringify(depts));
    },

    handleSelect(item) {
      this.formData.dept = item.value;
    },
    querySearch(queryString, cb) {
      var restaurants = this.deptOptions;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    validateContact(rule, value, callback) {
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!value) {
        return callback(new Error("联系方式不能为空"));
      }
      if (!phoneRegex.test(value)) {
        return callback(new Error("请输入正确的手机号码"));
      }
      return callback();
    },
    validatePrice(rule, value, callback) {
      if (!value) {
        return callback(new Error("采购价格不能为空"));
      }
      const price = parseFloat(value);
      if (isNaN(price)) {
        return callback(new Error("采购价格必须为数字"));
      }
      if (price <= 0) {
        return callback(new Error("采购价格必须大于零"));
      }
      return callback();
    },
    closeDialog() {
      this.$emit("close");
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    handleImageRemove(file, fileList) {
      this.imageFileList = fileList;
    },
    handleFileRemove(file, fileList) {
      this.fileFileList = fileList;
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    handleImageSuccess(response, file, fileList) {
      this.$message.success("图片上传成功");
      file.url = response.url; // 将服务器返回的文件路径赋值给file.url
      this.imageFileList = fileList.map((item) => {
        if (item.uid === file.uid) {
          return {
            ...item,
            url: response.url,
          };
        }
        return item;
      });
    },
    handleFileSuccess(response, file, fileList) {
      this.$message.success("文件上传成功");
      this.uploadFiles.push(response.url);

      file.url = response.url; // 将服务器返回的文件路径赋值给file.url
      this.fileFileList.push({
        name: file.name,
        url: response.url,
        ...response.file, // 把文件的其他详细信息也存储起来
      });
    },
    handleError(err, file, fileList) {
      this.$message.error("文件上传失败");
      console.error("Error:", err);
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.imageFileList = [];
      this.fileFileList = [];
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以要加1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          this.$message.error("请完整填写表单");
          return false;
        }

        this.formData.update_by= localStorage.getItem("userid");
        this.formData.update_time = this.$FormatDateToYMDHMS(new Date(), true);

        this.formData.purchase_date = this.$FormatDateToYMDHMS(
          this.formData.purchase_date
        );
       // console.log(this.formData);
      


        const updateRes = await this.$db_update(
          this.$http,
          "xtbg_device_instruments",
          this.formData,
          "id="+this.id
        );
        console.log(updateRes);

        if (updateRes.affectedRows > 0) {
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
            offset: 200,
          });
        } else {
          this.$message({
            showClose: true,
            message: "提交失败",
            type: "error",
            offset: 200,
          });
        }
        //保存照片
        if (this.uploadImgs.length > 0) {
          console.log(this.uploadImgs);
          const uploadImgReses = await Promise.all(
            this.uploadImgs.map(async (item) => {
              let photoForm = {
                instrument_num: this.formData.num,
                photo_url: item,
              };

              const uploadImgRes = await this.$db_insert(
                this.$http,
                "xtbg_device_instrument_photos",
                photoForm
              );
              return uploadImgRes;
            })
          );
          console.log(uploadImgReses);
        }
        //上传文件
        if (this.uploadFiles.length > 0) {
          console.log(this.uploadFiles);
          const uploadFileReses = await Promise.all(
            this.uploadFiles.map(async (item) => {
              let fileForm = {
                instrument_num: this.formData.num,
                attachment_url: item,
              };
              const uploadFileRes = await this.$db_insert(
                this.$http,
                "xtbg_device_instrument_attachments",
                fileForm
              );
              return uploadFileRes;
            })
          );
          console.log(uploadFileReses);
        }

        this.$emit("close");
      });
    },
  },
  mounted() {
    this.getDepts();
    this.getUsers();
  },
};
</script>

<style scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: 10px;
}

.form-group {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
}

.form-group label {
  width: 140px;
  font-size: 14px;
  color: #a6a6a6;
  margin-right: 1px;
}

.file-tips {
  color: red;
  font-size: 14px;
}

.custom-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid #3eaf7c;
  height: 36px;
  line-height: 36px;
}

.custom-dialog-title span {
  font-size: 15px;
  font-weight: bold;
  display: block;
  height: 36px;
  line-height: 36px;
  border-bottom: 5px solid #3eaf7c;
}

.custom-dialog-title .close-btn {
  font-size: 18px;
  color: #fff;
  background: #3eaf7c;
  position: absolute;
  right: -20px;
  top: -20px;
  &:hover {
    color: #f00;
  }
}
</style>
