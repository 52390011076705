/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-31 16:16:16
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-13 20:31:02
 * @FilePath: \app_wb\src\utils\local_db.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE24
 */


//用户登录时开始计时
export function setUserToken() {
    let setTime = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);
    localStorage.setItem("userInfo", JSON.stringify({
        data: "userToken",
        expiration: setTime
    }));
}
export function setUserToken2() {
    let setTime = new Date().getTime() + (1000 * 30);
    localStorage.setItem("userInfo", JSON.stringify({
        data: "userToken",
        expiration: setTime
    }));
}
/**
 * 判断是否超时
 * @returns 超时 return true 否则 return false
 */
export function jubgeIsOrNot() {
    let data = localStorage.userInfo;
    
        
    if(typeof(data)!="undefined"){
        let time = JSON.parse(data).expiration;
        // console.log(time);
        if (time != null) {
            if (new Date().getTime() > time) {
                //超时了 返回
                return true;
            } else {
                return false;
            }
        }
    }else{
        return true;
    }

    


}