/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-19 13:54:32
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-07 05:24:47
 * @FilePath: \app_wbyd\src\api\xtbg_leader_trip.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request5008.js';
import Vue from 'vue';
import VueResource from 'vue-resource'
Vue.use(VueResource)

//领导日程
const table_name = "xtbg_leader_trip";

export function ListInfoByMonth(leader,mdate){
    let query = {
        "query":"select * from "+table_name+" where leader like '%"+leader+"%' and mdate like '%"+mdate+"%'",
        "params":[]
    }
    return new Promise(resolve=>{
        Vue.http.post({
            url: "/dynamic",
        
            method: "post",
            data: query
        }).then(data=>{
            resolve(data);
        })
    })
}