<template>
  <div class="grid-item">
    <el-image
      :src="firstPhotoUrl"
      lazy
      alt="item image"
      :fit="mycontain"
      style="width: 100%; height: 140px; border-radius: 6px"
    />
    <div class="item-info">
      <div class="truncate" style="text-align: center !important">
        {{ name }}
      </div>
      <div class="truncate">
        <div class="divice_label">制造厂商：</div>
        <div>{{ maker }}</div>
      </div>
      <div class="truncate">
        <div class="divice_label">存放地点：</div>
        <div>{{ location }}</div>
      </div>
      <div class="truncate">
        <div class="divice_label">管理人员：</div>
        <div>{{ administrator }}</div>
        <el-button
          @click.stop="editDevice(id)"
          style="margin-left: auto; width: 26px; height: 26px; padding: 0px"
          type="success"
          icon="el-icon-edit"
          circle
        ></el-button>

        <el-button
          @click.stop="deleteDevice(id,num)"
          style="width: 26px; height: 26px; padding: 0px"
          type="danger"
          icon="el-icon-delete"
          circle
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { number } from "echarts";
export default {
  props: {
    id: number,
    num: String,
    photo_urls: String || Array,
    name: String,
    maker: String,
    location: String,
    administrator: String,
  },
  data() {
    return {
      mycontain: "cover",
    };
  },
  computed: {
    firstPhotoUrl() {
      if (
        typeof this.photo_urls === "string" &&
        this.photo_urls.trim() !== ""
      ) {
        return this.photo_urls.indexOf(",") > 0
          ? this.photo_urls.split(",")[0]
          : this.photo_urls;
      } else if (Array.isArray(this.photo_urls) && this.photo_urls.length > 0) {
        return this.photo_urls[0];
      }
      return ""; // 如果为空则返回空字符串
    },
  },
  methods: {
    deleteDevice(id, num) {
      event.stopPropagation(); // 阻止事件冒泡
      // 根据ID删除改设备信息
      // 并重新刷新表格
      //...
      // 表格刷新
      this.$emit("deleteDevice", { id, num });
    },
    editDevice(id) {
      this.$emit("editDevice", id);
    },
  },
};
</script>

<style scoped lang="scss">
.grid-item {
  cursor: pointer;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 5px;
  margin: 4px;
  width: 194px; /* Set specific width */

  display: flex;
  flex-direction: column;
  font-size: 12px;

  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);

  transition: box-shadow 0.3s ease; /* 添加过渡效果 */

  &:hover {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }
}
.grid-item img {
  max-width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  border-radius: 6px;
}
.item-info {
  padding: 5px;
  text-align: left; /* Ensure text is left-aligned */
}
.truncate {
  width: 100%; /* Ensure the text does not exceed the container width */

  display: flex;
  &:first-child {
    margin-bottom: 6px;
    font-weight: 600;
  }
  text-align: left;
}
.divice_label {
  color: #a6a6a6;
  font-size: 12px;
  max-width: 110px;
  min-width: 60px;
}
</style>
