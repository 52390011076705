<template>
  <div id="propose_table" class="flex-col f-15">
    <!-- 标题 -->
    <div class="padding-bottom-20 padding-top-20 f-22 font-weight-600">
      中国科学院西双版纳热带植物园<br />{{
        this.$congresConfig.propose_title
      }}职工代表大会提案表
    </div>
    <!-- 类别 -->
    <div class="flex padding-top-bottom-5">
      <div class="height-40 line-height-40 width-80">类别：</div>
      <div class="flex-1 flex align-items-center height-40 line-height-40">
        <el-radio
          :disabled="isDisabled"
          class="flex-1"
          v-model="formData.type"
          label="单位建设"
          >单位建设</el-radio
        >
        <el-radio
          :disabled="isDisabled"
          class="flex-1"
          v-model="formData.type"
          label="民主管理"
          >民主管理</el-radio
        >
        <el-radio
          :disabled="isDisabled"
          class="flex-1"
          v-model="formData.type"
          label="职工福利"
          >职工福利</el-radio
        >
        <div class="flex align-items-center width-600">
          <el-radio :disabled="isDisabled" v-model="formData.type" label="其他"
            >其他</el-radio
          >
          <div class="flex-1 height-40 line-height-40">
            <el-input
              :disabled="isDisabled"
              v-model="formData.type_review"
              placeholder="请输其他类型"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- 编号 填写日期-->
    <div
      class="flex margin-top-bottom-5"
      style="justify-content: space-between"
    >
      <div class="flex">
        <div class="height-40 line-height-40 width-80">编号：</div>
        <div>
          <el-input
            :disabled="isDisabled"
            v-model="formData.num"
            placeholder="20240301001"
          ></el-input>
        </div>
      </div>
      <div class="flex">
        <div class="height-40 line-height-40 width-80">填写日期：</div>
        <div class="height-40 line-height-40">
          <el-date-picker
            :disabled="isDisabled"
            v-model="formData.proposal_date"
            type="date"
            placeholder="选择日期"
            format="yyyy年MM月dd日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <!-- 提案人 联系电话 -->
    <div class="flex">
      <!-- 提案人 -->
      <div
        class="flex-1 flex boder-right-1 boder-top-1 boder-left-1 boder-bottom-1 height-50 align-items-center"
      >
        <div class="width-80 height-50 line-height-50 boder-right-1">
          提案人
        </div>
        <div class="flex-1">
          <el-input
            :disabled="isDisabled"
            v-model="formData.proposer"
            placeholder="提案人"
          ></el-input>
        </div>
      </div>
      <!-- 联系电话 -->
      <div
        class="flex-1 flex boder-right-1 boder-top-1 boder-bottom-1 height-50 align-items-center"
      >
        <div class="width-80 height-50 line-height-50 boder-right-1">
          联系电话
        </div>
        <div class="flex-1">
          <el-input
            :disabled="isDisabled"
            id="mobileInput"
            v-model="formData.p_phone"
            placeholder="联系电话"
          ></el-input>
        </div>
      </div>
    </div>
    <!-- 附议人 -->
    <div
      class="flex-1 flex boder-left-1 boder-right-1 boder-bottom-1 height-50 align-items-center"
    >
      <div class="width-80 height-50 line-height-50 boder-right-1">附议人</div>
      <div class="flex-1">
        <el-input
          :disabled="isDisabled"
          v-model="formData.supporters"
          placeholder="附议人,至少两人或两人以上"
        ></el-input>
      </div>
    </div>
    <!-- 提案内容 -->
    <div class="flex">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 683px; padding: 0px 30px"
      >
        提<br />案<br />内<br />容
      </div>
      <div class="flex-1 flex-col">
        <!-- 案名 -->
        <div class="flex align-items-center">
          <div
            class="height-80 line-height-80 boder-right-1 boder-bottom-1"
            style="padding: 0px 30px"
          >
            案名
          </div>
          <div
            class="flex-1 height-80 line-height-80 boder-bottom-1 boder-right-1"
          >
            <el-input
              :disabled="isDisabled"
              v-model="formData.title"
              style="font-size: 18px"
              placeholder="提案的题目，用简洁的文字说明提案要求解决什么问题，案名要和提案内容一致，比如“关于推进\解决\设立XXXX的建议”或“建议推进\解决\设立XXXX的提案”。一定要“一事一案”。"
            ></el-input>
          </div>
        </div>
        <!-- 案由 -->
        <div class="flex align-items-center">
          <div
            class="height-300 boder-bottom-1 boder-right-1 flex"
            style="align-items: center; padding: 0 30px"
          >
            案由
          </div>
          <div
            class="flex-1 height-300 boder-bottom-1 boder-right-1"
            style="position: relative"
          >
            <el-input
              :disabled="isDisabled"
              id="input_reason"
              type="textarea"
              resize="none"
              :rows="9"
              v-model="formData.reason"
              placeholder="提案的理由，是解决问题的重要依据，要开门见山，可进行现状分析，介绍背景或趋势、相关政策文件以及存在的问题等，在充分进行调研的基础上，以事实和数据为依据进行说明。"
            ></el-input>
            <Zoom :textid="'input_reason'"></Zoom>
          </div>
        </div>
        <!-- 具体意见建议 -->
        <div class="flex align-items-center">
          <div
            class="boder-bottom-1 boder-right-1 flex"
            style="align-items: center; height: 300px; padding: 0 23px 0 22px"
          >
            具体意<br />见建议
          </div>
          <div
            class="flex-1 boder-bottom-1 boder-right-1"
            style="height: 300px; position: relative"
          >
            <el-input
              :disabled="isDisabled"
              id="input_suggest"
              type="textarea"
              resize="none"
              :rows="9"
              v-model="formData.suggest"
              placeholder="该部分是提案的核心内容，也是提案能否被采纳的关键，必须针对案由反映的问题，提出解决问题的主张和办法，提出的建议要力求具体可行，实事求是，具有则针对性、可操作性。"
            ></el-input>
            <Zoom :textid="'input_suggest'"></Zoom>
          </div>
        </div>
      </div>
      <!-- 提案组审核意见 -->
    </div>
    <div class="flex" v-show="true">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 343px; padding: 0px 30px"
      >
        提<br />案<br />组<br />审<br />核<br />意<br />见
      </div>
      <div
        class="flex-1 flex-col width-20 boder-right-1 boder-bottom-1"
        style="height: 343px"
      >
        <!-- 提案状态 -->
        <div class="height-40 flex boder-bottom-1">
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.t_result"
              label="立案"
              >立案</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.t_result"
              label="退回重提"
              >退回重提</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.t_result"
              label="不予立案"
              >不予立案</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.t_result"
              label="转意见处理"
              >转意见处理</el-radio
            >
          </div>
          <!-- <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio v-model="formData.t_result" label="其他">其他</el-radio>
          </div> -->
        </div>
        <div class="boder-bottom-1">
          <el-input
            :disabled="isDisabled"
            type="textarea"
            resize="none"
            :rows="2"
            v-model="formData.t_review"
            placeholder="请说明以上选择原因"
          ></el-input>
        </div>
        <div class="flex" style="padding: 5px 0 0 5px; flex-wrap: wrap">
          <div
            class="height-40 line-height-40"
            style="font-size: 18px; text-indent: 2em"
          >
            建议由
          </div>
          <div>
            <el-select
              :disabled="isDisabled"
              v-model="formData.suggest_dept"
              filterable
              clearable
              placeholder="请选择部门"
            >
              <el-option
                v-for="item in zb_deptOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="height-40 line-height-40" style="font-size: 18px">
            主办，
          </div>
          <div>
            <el-select
              v-model="formData.suggest_dept_other"
              filterable
              clearable
              placeholder="请选择部门"
              :multiple="true"
            >
              <el-option
                :disabled="isDisabled"
                v-for="item in xb_deptOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="height-40 line-height-40" style="font-size: 18px">
            协办，并于
          </div>
          <div>
            <el-date-picker
              :disabled="isDisabled"
              v-model="formData.reply_time"
              type="date"
              placeholder="选择日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="height-40 line-height-40" style="font-size: 18px">
            时间前作出书面答复。
          </div>
        </div>
        <div class="flex" style="justify-content: space-between">
          <div></div>
          <div>
            <el-date-picker
              :disabled="isDisabled"
              v-model="formData.t_date"
              type="date"
              placeholder="选择日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="flex" v-show="true">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 160px; padding: 0px 30px"
      >
        分<br />管<br />领<br />导<br />意<br />见
      </div>
      <div
        class="flex-1 flex-col width-20 boder-right-1 boder-bottom-1"
        style="height: 160px"
      >
        <div class="flex" style="padding: 5px 0">
          <el-input
            :disabled="isDisabled"
            type="textarea"
            resize="none"
            :rows="2"
            v-model="formData.l_opinion"
            placeholder="具体意见内容"
          ></el-input>
        </div>
        <div class="flex">
          <div class="flex-1"></div>
          <div class="flex">
            <div class="height-40 line-height-40">签名：</div>
            <div>
              <el-input
                :disabled="isDisabled"
                type="text"
                v-model="formData.l_sign"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1"></div>
          <div>
            <el-date-picker
              :disabled="isDisabled"
              v-model="formData.l_sign_date"
              type="date"
              placeholder="选择日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="flex" v-show="true">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 323px; padding: 0px 30px"
      >
        承办<br />部门<br />处理<br />意见
      </div>
      <div
        class="flex-1 flex-col width-20 boder-right-1 boder-bottom-1"
        style="height: 323px"
      >
        <div class="flex" style="padding: 5px 0">
          <el-input
            :disabled="isDisabled"
            type="textarea"
            resize="none"
            :rows="7"
            v-model="formData.d_response"
            placeholder="具体意见内容"
          ></el-input>
        </div>
        <div class="flex">
          <div class="flex-1"></div>
          <div class="flex">
            <div class="height-40 line-height-40">负责人签名：</div>
            <div>
              <el-input
                :disabled="isDisabled"
                type="text"
                v-model="formData.d_head_sign"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1"></div>
          <div>
            <el-date-picker
              :disabled="isDisabled"
              v-model="formData.d_head_date"
              type="date"
              placeholder="选择日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="flex" v-show="true">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 323px; padding: 0px 30px"
      >
        提案<br />人反<br />馈意<br />见
      </div>
      <div
        class="flex-1 flex-col width-20 boder-right-1 boder-bottom-1"
        style="height: 323px"
      >
        <div class="height-40 flex boder-bottom-1">
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.p_feedback"
              label="满意"
              >满意</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.p_feedback"
              label="基本满意"
              >基本满意</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.p_feedback"
              label="一般"
              >一般</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio
              :disabled="isDisabled"
              v-model="formData.p_feedback"
              label="不满意"
              >不满意</el-radio
            >
          </div>
        </div>
        <div class="flex" style="padding: 5px 0">
          <el-input
            :disabled="isDisabled"
            type="textarea"
            resize="none"
            :rows="5"
            v-model="formData.p_f_detail"
            placeholder="具体意见内容"
          ></el-input>
        </div>
        <div class="flex">
          <div class="flex-1"></div>
          <div class="flex">
            <div class="height-40 line-height-40">提案人签名：</div>
            <div>
              <el-input
                :disabled="isDisabled"
                type="text"
                v-model="formData.p_f_sign"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1"></div>
          <div>
            <el-date-picker
              :disabled="isDisabled"
              v-model="formData.p_f_sign_date"
              type="date"
              placeholder="选择日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex-1 flex boder-left-1 boder-right-1 boder-bottom-1 height-50 align-items-center"
    >
      <div class="width-80 height-50 line-height-50 boder-right-1">备注</div>
      <div class="flex-1">
        <el-input
          :disabled="isDisabled"
          v-model="formData.remark"
          placeholder="备注"
        ></el-input>
      </div>
    </div>

    <div class="flex padding-top-10">
      <div class="flex-1"></div>
      <div class="flex">
        <!-- <el-button @click="saveFormData" style="margin-left: 5px; background: #25834b; color: #fff">保存</el-button> -->
        <el-button @click="back">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { dbquery, getDataTotal } from "../sys/proposeRoleUser.js";
import { Message } from "element-ui";
import Zoom from "../tools/zoom.vue";

import {
  generateDateSequence,
  getMaxId,
  getCurrentDate,
  getCurrentDateTime,
  extractNonEmptyValues,
  addQuotesToObjectProperties,
  decryptData,
  objectToSqlSet,
  formatDate,
} from "../../../utils/common.js";
var that = null;

const propose_table = "xtbg_congress_propose";
export default {
  components: {
    Zoom,
  },
  data() {
    return {
      isDisabled: true, // 控制所有组件的不可编辑状态
      zb_deptOptions: [],
      xb_deptOptions: [],

      //职工代表
      is_zgdb: false,

      //提案组
      is_taz: false,
      //分管领导
      is_yld: false,
      //主办部门
      is_zbdept: false,

      formData: {},
      propseID: "",
      from: "",
    };
  },
  async created() {
    that = this;
    this.getZbDepts();
  },
  methods: {
    // 提交数据
    async dbquery(sqlStr) {
      return dbquery(this.$http,  sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },
    async getZbDepts() {
      const sqlStr =
        "select dept_id from xtbg_congress_role_user where role_id=" +
        this.$congresConfig.congressRole.roleid_cbbm;
      const deptData = await this.dbquery(sqlStr);
      const dettIds = deptData.map((row) => row.dept_id);

      const sqlStr_dept =
        "select dept_id,dept_name from sys_dept where dept_id in (" +
        dettIds +
        ")";
      const zbDeptData = await this.dbquery(sqlStr_dept);
      this.xb_deptOptions = this.zb_deptOptions = zbDeptData.map((row) => ({
        label: row.dept_name,
        value: row.dept_name,
      }));
    },
    async getXbDepts() {},

    async getMaxNum() {},

    // 修改 状态(处了附议，需要判断是否有两个人以上)
    async updatePropose(status, message) {
      let myform = {
        status: status,
      };
      let myNewFormData = objectToSqlSet(myform);

      // 保存 修改
      const updateProposeSql =
        "update " +
        propose_table +
        " set " +
        myNewFormData +
        " where id=" +
        this.propseID;
      const res = await this.dbquery(updateProposeSql);

      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "提交成功",
          type: "success",
          duration: 1000,
          offset: 200,
        });
      } else {
        this.$message({
          showClose: true,
          message: "提交失败",
          type: "fail",
          duration: 1000,
          offset: 200,
        });
      }
    },
    async updateProposeFy() {
      //先要判断原来 附议 有没有
      const fySqlStr = "select supporters";

      let myform = {
        supporters: localStorage.getItem("username"),
      };
      let myNewFormData = objectToSqlSet(myform);

      // 保存 修改
      const updateProposeSql =
        "update " +
        propose_table +
        " set " +
        myNewFormData +
        " where id=" +
        this.propseID;
      const res = await this.dbquery(updateProposeSql);

      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "提交成功",
          type: "success",
          duration: 1000,
          offset: 200,
        });
      } else {
        this.$message({
          showClose: true,
          message: "提交失败",
          type: "fail",
          duration: 1000,
          offset: 200,
        });
      }
    },

    // 保存到当前临时表
    async saveFormData() {
      this.$confirm("是否提交该提案?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.updatePropose("待附议", true);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    back() {
      if (this.from == "my") {
        this.$router.push({
          path: "/proposal_meetting/taxt_my",
        });
      }
      if (this.from == "now") {
        this.$router.push({
          path: "/proposal_meetting/taxt_now",
        });
      }
    },
    async getDataById(id) {
      const selectSql = "select * from " + propose_table + " where id=" + id;
      const res = await this.dbquery(selectSql);
      this.formData = res[0];

      this.$set(this.formData,"p_feedback",this.formData.p_feedback)

      if (this.formData.proposal_date) {
        this.$set(
          this.formData,
          "proposal_date",
          formatDate(this.formData.proposal_date)
        );
      }
      if (this.formData.s_time) {
        this.$set(this.formData, "s_time", formatDate(this.formData.s_time));
      }
      if (this.formData.t_date) {
        this.$set(this.formData, "t_date", formatDate(this.formData.t_date));
      }
      if (this.formData.reply_time) {
        this.$set(
          this.formData,
          "reply_time",
          formatDate(this.formData.reply_time)
        );
      }
      if (this.formData.l_sign_date) {
        this.$set(
          this.formData,
          "l_sign_date",
          formatDate(this.formData.l_sign_date)
        );
      }
      if (this.formData.d_head_date) {
        this.$set(
          this.formData,
          "d_head_date",
          formatDate(this.formData.d_head_date)
        );
      }
      if (this.formData.p_f_sign_date) {
        this.$set(
          this.formData,
          "p_f_sign_date",
          formatDate(this.formData.p_f_sign_date)
        );
      }

      if (this.formData.suggest_dept_other.indexOf(",") > -1) {
        this.formData.suggest_dept_other =
          this.formData.suggest_dept_other.split(",");
      }else{
        this.formData.suggest_dept_other =
          this.formData.suggest_dept_other.split(",");
      }
    },
    scrollToTop() {
      // 使用window对象的scrollTo方法
      window.scrollTo({
        top: 0, // 目标位置（顶部）
        behavior: "smooth", // 平滑滚动
      });
    },
  },
  async mounted() {
    this.propseID = decryptData(this.$route.query.id);
    this.from = this.$route.query.from;
    await this.getDataById(this.propseID);
    this.scrollToTop();
  },
  beforeDestroy() {},
};
</script>
