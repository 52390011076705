<template>
  <div class="role-manager-container">
    <el-row style="margin: 14px 0; text-align: left">
      <el-button class="add" icon="el-icon-plus" @click="handleAdd"
        >新 增</el-button
      >
      <!-- <el-popover placement="top" width="160" v-model="batchDeleteVisible">
        <p>此操作将永久删除选中记录，确认删除吗？</p>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="text" @click="batchDeleteVisible = false"
            >取消</el-button
          >
          <el-button type="primary" size="mini" @click="handleBatchDelete"
            >确定</el-button
          >
        </div>
        <el-button
          slot="reference"
          type="danger"
          plain
          icon="el-icon-delete"
          :disabled="!selectRowKeys.length"
          style="margin: 0 10px"
          >批量删除</el-button
        >
      </el-popover> -->
      <!-- <el-button
        type="warning"
        :loading="exportLoading"
        style="width: 100px"
        icon="el-icon-download"
        plain
        @click="handleExport"
      >
        导 出
      </el-button> -->
    </el-row>
    <el-table
      :data="tableData"
      v-loading="loading"
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      border
      :style="tabbleStyle"
      height="670px"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column type="index" align="center" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="role_name" align="center" label="角色名称">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="mini"
            style="color: #008500"
            @click="handleEdit(scope.row)"
            >修 改</el-button
          >

          <el-button
            v-if="scope.row.id === 3"
            style="color: #409eff"
            type="text"
            icon="el-icon-user"
            size="mini"
            @click="handleEditDept(scope.row)"
            >部 门</el-button
          >

          <el-button
            v-else
            style="color: #409eff"
            type="text"
            icon="el-icon-user"
            size="mini"
            @click="handleEditUser(scope.row)"
            >用 户</el-button
          >

          <el-popover
            placement="top"
            width="160"
            v-model="scope.row.acceptVisible"
          >
            <p>此操作将永久删除该条记录，确认删除吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button
                size="mini"
                type="text"
                @click="scope.row.acceptVisible = false"
                >取消</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="handleDelete(scope.row)"
                >确定</el-button
              >
            </div>
            <!-- <el-button
              slot="reference"
              type="text"
              icon="el-icon-delete"
              size="mini"
              style="margin-left: 10px; color: #000"
            >
              删 除
            </el-button> -->
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 30px; float: right; padding-right: 0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
    >
    </el-pagination>
    <el-dialog
      class="admin-form-dialog"
      :title="title"
      top="20vh"
      :visible.sync="formVisible"
      width="520px"
      :close-on-click-modal="false"
    >
      <el-form :model="form" ref="roleForm" inline :rules="rules">
        <el-form-item label="角色名称" prop="role_name">
          <el-input v-model="form.role_name" style="width: 300px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="formVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSave">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑某个角色的用户 -->
    <el-dialog
      class="admin-form-dialog"
      :title="'编辑【' + selectRole.role_name + '】用户'"
      top="20vh"
      :visible.sync="userVisible"
      :close-on-click-modal="false"
      width="980px"
    >
      <el-form :model="filterUserForm" ref="roleForm" inline>
        <el-form-item label="用户姓名" prop="name">
          <el-input
            v-model="filterUserForm.name"
            clearable
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门名称" prop="dept_name">
          <el-input
            v-model="filterUserForm.dept_name"
            clearableming
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="width: 100px"
            icon="el-icon-s-operation"
            plain
            @click="handleFilterUser"
            >筛 选</el-button
          >
          <el-button class="add" icon="el-icon-plus" @click="handleAddUser"
            >添加用户</el-button
          >
          <el-popover placement="top" width="160" v-model="batchRemoveVisible">
            <p>
              此操作将从【{{
                selectRole.role_name
              }}】中移除选中的用户，确认移除吗？
            </p>
            <div style="text-align: right; margin: 0">
              <el-button
                size="mini"
                type="text"
                @click="batchRemoveVisible = false"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="handleBatchRemove"
                >确定</el-button
              >
            </div>
            <el-button
              slot="reference"
              type="danger"
              plain
              icon="el-icon-delete"
              :disabled="!userSelectRowKeys.length"
              style="margin: 0 10px"
              >批量移除</el-button
            >
          </el-popover>
        </el-form-item>
      </el-form>
      <el-table
        :data="userTableData"
        v-loading="userLoading"
        :header-row-style="headerRowStyle"
        :header-cell-style="headerCellStyle"
        :cell-style="cellStyle"
        border
        :style="tabbleStyle"
        @selection-change="handleSelectionUser"
      >
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="name" align="center" width="80" label="用户姓名">
        </el-table-column>
        <el-table-column prop="phone" align="center" width="150" label="电话">
        </el-table-column>
        <el-table-column prop="email" align="center" label="邮箱">
        </el-table-column>
        <el-table-column prop="dept_name" align="center" label="所属部门">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-popover
              placement="top"
              width="160"
              v-model="scope.row.batchDeleteVisible"
            >
              <p>此操作将永久删除选中记录，确认删除吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="scope.row.batchDeleteVisible = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleRemoveUser(scope.row)"
                  >确定</el-button
                >
              </div>
              <el-button
                type="text"
                slot="reference"
                icon="el-icon-delete"
                size="mini"
                style="color: #ff0000"
              >
                移 除
              </el-button>
            </el-popover>

            <el-button
              v-show="showDeptAdd"
              type="text"
              slot="reference"
              icon="el-icon-plus"
              size="mini"
              @click="addMangeDepts(scope.row)"
            >
              分管部门
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 30px; float: right; padding-right: 0"
        background
        @size-change="handleUserListSizeChange"
        @current-change="handleUserListCurrentChange"
        :current-page="userPage.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="userPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="userPage.total"
      >
      </el-pagination>
      <div slot="footer" class="dialog-footer" style="margin-top: 52px">
        <el-button @click="userVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 编辑承办部门的部门列表 -->
    <el-dialog
      class="admin-form-dialog"
      :title="'编辑【' + selectRole.role_name + '】'"
      top="20vh"
      :visible.sync="deptVisible"
      :close-on-click-modal="false"
      width="980px"
    >
      <div>
        <!-- 头部按钮 -->
        <div style="display: flex; margin-bottom: 5px">
          <el-select
            v-model="selectedDeptId"
            filterable
            clearable
            placeholder="请选择部门"
            style="width: 40%; margin-right: 5px"
          >
            <el-option
              v-for="item in deptOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button icon="el-icon-plus" @click="handleAddDept"
            >添加部门</el-button
          >
          <el-button icon="el-icon-link" @click="handleAddManyDept"
            >导入部门</el-button
          >
          <!-- <el-button @click="toggleSelection()">取消选择</el-button> -->
        </div>
        <!-- 部门列表 -->
        <div>
          <el-table
            tooltip-effect="dark"
            :data="deptTableData"
            border
            style="width: 100%"
            max-height="450"
          >
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column prop="id" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="dept_name" label="部门名称">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button @click="myDeleteDept(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: 5px">
        <el-button @click="deptVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog
      class="admin-form-dialog"
      title="批量导入部门"
      top="20vh"
      :visible.sync="deptAllVisible"
      :close-on-click-modal="false"
      width="980px"
    >
      <div style="margin-bottom: 5px; text-align: right">
        <el-button @click="toggleDeptSelection()">取消选择</el-button>
      </div>
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="deptALLTableData"
        max-height="450"
        @selection-change="handleAllDeptSelectionChange"
        border
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" label="序号" width="80"> </el-table-column>
        <el-table-column prop="dept_name" label="部门名称"> </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer" style="margin-top: 5px">
        <el-button @click="startExportDepts">导 入</el-button>
        <el-button @click="deptAllVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 园领导分管部门 -->
    <el-dialog
      class="admin-form-dialog"
      title="分管部门"
      top="20vh"
      :visible.sync="deptLeaderAllVisible"
      :close-on-click-modal="false"
      width="980px"
    >
      <div>
        <!-- 头部按钮 -->
        <div style="display: flex; margin-bottom: 5px">
          <el-select
            v-model="selectedLeaderDeptId"
            filterable
            clearable
            placeholder="请选择部门"
            style="width: 40%; margin-right: 5px"
          >
            <el-option
              v-for="item in deptOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button icon="el-icon-plus" @click="handleAddDeptForFg"
            >添加部门</el-button
          >

          <!-- <el-button @click="toggleSelection()">取消选择</el-button> -->
        </div>
        <!-- 分管 部门列表 -->
        <div>
          <el-table
            tooltip-effect="dark"
            :data="deptLeaderTableTable"
            border
            style="width: 100%"
            max-height="450"
          >
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column prop="id" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="dept_name" label="部门名称">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="danger" @click="DeleteLeaderDept(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: 5px">
        <el-button @click="deptLeaderAllVisible = false">关 闭</el-button>
      </div>
    </el-dialog>

    <!-- 用户列表 -->
    <select-user
      :visible.sync="selectUserVisible"
      multiple
      :select-rows-value="selectUserIds"
      @select="onSelectUser"
    ></select-user>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectUser from "./select-user.vue";
import { dbquery, getDataTotal } from "./proposeRoleUser.js";
const tableName = "xtbg_congress_role";
const congress_role_user = "xtbg_congress_role_user";
const sys_dept = "sys_dept";
export default {
  name: "RoleManager",
  components: {
    SelectUser,
  },
  data() {
    return {
      tableData: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      queryWrapper: "FROM xtbg_congress_role",
      lastQueryWrapper: "",
      loading: false,
      formVisible: false,
      title: "",
      form: {
        role_name: "",
      },
      rules: {
        role_name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
      exportLoading: false,
      selectRowKeys: [],
      userVisible: false,
      userLoading: false,
      userTableData: [],
      userAllTableData: [],
      userSelectRowKeys: [],
      filterUserForm: {
        name: "",
        dept_name: "",
      },
      userPage: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      selectUserVisible: false,
      batchDeleteVisible: false,
      batchRemoveVisible: false,
      selectRole: {},
      selectUsers: [],
      userQueryWrapper: "FROM xtbg_user Where 1=1",
      userLastQueryWrapper: "",
      // 部门
      deptTableData: [],
      deptVisible: false,
      deptOptions: [],
      selectedDeptId: "",

      deptAllVisible: false,
      deptALLTableData: [],
      multipleSelection: [],
      showDeptAdd: false,

      // 分管领导 分管部门列表
      deptLeaderAllVisible: false,
      deptLeaderTableTable: [],
      select_user_id: 0,
      selectedLeaderDeptId: "",
    };
  },
  computed: {
    tabbleStyle() {
      return {
        borderColor: "#D1EAD1",
      };
    },
    cellStyle() {
      return {
        borderLeftColor: "#E0EFE030",
        borderRightColor: "#E0EFE030",
        borderTopColor: "#D1EAD1",
        borderBottomColor: "#D1EAD1",
      };
    },
    headerRowStyle() {
      return {};
    },
    headerCellStyle() {
      return {
        background: "#E0EFE0",
        color: "#3D3D3D",
        borderColor: "#D1EAD1",
        fontFamily: "Source Han Sans",
        fontSize: "14px",
      };
    },
    selectUserIds() {
      return this.userAllTableData.map((item) => item.id);
    },
  },
  async mounted() {
    this.getData();
    // const sqlStr = "select * from xtbg_congress_role"
    // const roleData = await dbquery(this.$http,this.$db_dynamic_url,sqlStr)
    // console.log(roleData)
  },
  methods: {
    //园领导对应的管理部门
    async addMangeDepts(row) {
      this.deptLeaderAllVisible = true;

      this.select_user_id = row.id;
      await this.listDeptByLeader(this.select_user_id);
      this.getDeptNames();
    },
    //插入到 表xtbg_congress_leader_dept中
    async handleAddDeptForFg() {
      const insertSql =
        "INSERT INTO xtbg_congress_leader_dept (user_id,dept_id) values(" +
        this.select_user_id +
        "," +
        this.selectedLeaderDeptId +
        ")";
      const res = await this.dbquery(insertSql);
      if (res.affectedRows == 1) {
        this.$message.success({
          message: "添加成功",
          offset: 200,
          duration: 800,
        });
        this.listDeptByLeader(this.select_user_id);
      }
    },
    async DeleteLeaderDept(row) {
      const delStr =
        "delete from xtbg_congress_leader_dept  where dept_id=" + row.dept_id;
      const res = await this.dbquery(delStr);
      if (res.affectedRows > 0) {
        this.$message.success({
          message: "删除成功",
          offset: 200,
          duration: 800,
        });
        this.listDeptByLeader(this.select_user_id);
        await this.listDeptByRole(this.selectRole.id);
      } else {
        this.$message({
          message: "删除失败",
          offset: 200,
          duration: 800,
        });
      }
    },
    //初始化对应的部门
    async listDeptByLeader(user_id) {
      const sqlStr =
        "SELECT dept_id FROM xtbg_congress_leader_dept WHERE user_id=" +
        user_id;
      const results = await this.dbquery(sqlStr);
      let i = 1;

      const newRes = [];

      for (const row of results) {
        const newRow = {
          id: i++,
          dept_id: row.dept_id,
          dept_name: await this.getDeptNameByDeptId(row.dept_id),
        };
        newRes.push(newRow);
      }

      this.deptLeaderTableTable = newRes;
    },
    // 根据部门ID获取部门名称
    async getDeptNameByDeptId(deptId) {
      const selDeptName =
        "select dept_name from sys_dept where dept_id=" + deptId;
      const deptData = await this.dbquery(selDeptName);
      return deptData[0].dept_name;
    },
    async dbquery(sqlStr) {
      return dbquery(this.$http, sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },

    // 获取部门
    async getDeptNames() {
      const detpSqlStr = "select dept_id,dept_name from sys_dept";
      const depts = await this.dbquery(detpSqlStr);

      this.deptOptions = depts.map((item) => ({
        label: item.dept_name,
        value: item.dept_id,
      }));

      let id = 1;
      this.deptALLTableData = depts.map((item) => ({
        id: id++,
        dept_id: item.dept_id,
        dept_name: item.dept_name,
      }));
    },
    //导入部门
    async startExportDepts() {
      //就是批量插入
      this.multipleSelection.map(async (row) => {
        const deptId = row.dept_id;
        await this.insertRoleDept(deptId);
      });

      this.deptAllVisible = false;
      let that = this;

      setTimeout(async () => {
        await that.listDeptByRole(that.selectRole.id);
      }, 1200);
    },

    //分管领导 分管部门
    toggleLeaderDeptSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.leaderMultipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.leaderMultipleTable.clearSelection();
      }
    },
    handleLeaderAllDeptSelectionChange() {},
    startLeaderExportDepts() {},

    // 获取承办部门角色的部门列表
    async listDeptByRole(roleId) {
      const sqlStr =
        "SELECT dept_id,dept_name FROM " +
        sys_dept +
        " WHERE dept_id IN (SELECT dept_id FROM " +
        congress_role_user +
        " WHERE role_id=" +
        roleId +
        ")";
      const results = await this.dbquery(sqlStr);
      let i = 1;

      const deptData = results.map((row) => ({
        id: i++,
        dept_id: row.dept_id,
        dept_name: row.dept_name,
      }));
      this.deptTableData = deptData;
    },
    // 编辑 承办部门的部门列表
    handleEditDept(row) {
      this.deptVisible = true;
      this.selectRole = row;

      this.listDeptByRole(row.id);
      this.getDeptNames();
    },
    toggleDeptSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleAllDeptSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 新增部门
    async insertRoleDept(dept_id) {
      //先判断新增的部门是否已经存在
      const sSql =
        "select count(*) as total from " +
        congress_role_user +
        " where dept_id=" +
        dept_id;
      const sres = await this.dbquery(sSql);
      if (sres[0].total > 0) {
        this.$message({
          message: "添加部门已经存在",
          offset: 200,
          duration: 800,
        });
        return;
      }

      const insetDeptSql =
        "INSERT INTO " +
        congress_role_user +
        " (role_id,dept_id) values(" +
        this.selectRole.id +
        "," +
        dept_id +
        ")";

      const res = await this.dbquery(insetDeptSql);

      if (res.affectedRows == 1) {
        this.$message.success({
          message: "添加成功",
          offset: 200,
          duration: 800,
        });
      }
    },
    async handleAddDept() {
      await this.insertRoleDept(this.selectedDeptId);
      await this.listDeptByRole(this.selectRole.id);
    },
    // 批量导入
    handleAddManyDept() {
      this.deptAllVisible = true;
    },
    //移除部门
    async myDeleteDept(row) {
      console.log(row);
      const delStr =
        "delete from " + congress_role_user + " where dept_id=" + row.dept_id;
      const res = await this.dbquery(delStr);
      if (res.affectedRows > 0) {
        this.$message.success({
          message: "删除成功",
          offset: 200,
          duration: 800,
        });
        await this.listDeptByRole(this.selectRole.id);
      } else {
        this.$message({
          message: "删除失败",
          offset: 200,
          duration: 800,
        });
      }
    },

    async getData(current0) {
      this.loading = true;
      if (current0) {
        this.page.currentPage = 1;
        this.tableData = [];
      }
      this.page.total = (
        await this.$db_select(this.$http, tableName, "count(*) as total")
      )[0].total;

      const { currentPage, pageSize } = this.page;

      const sqlStr = `SELECT * ${this.queryWrapper} LIMIT ${pageSize} OFFSET ${
        (currentPage - 1) * pageSize
      }`;

      this.tableData = await this.dbquery(sqlStr);
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },

    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData(true);
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getData();
    },

    handleAdd() {
      this.title = "新增角色";
      this.formVisible = true;
      this.form = {
        role_name: "",
      };
    },
    handleEdit(row) {
      this.title = "编辑角色";
      this.formVisible = true;
      this.form = row;
    },
    handleSave() {
      let that = this;
      this.$refs.roleForm.validate(async (valid) => {
        if (valid) {
          let time = this.formatDate(new Date());
          if (this.form.id) {
            const sqlStr =
              `UPDATE ` +
              tableName +
              ` SET role_name = '${
                this.form.role_name
              }', update_by = '${localStorage.getItem(
                "userid"
              )}', update_time = '${time}' WHERE id = ${this.form.id}`;
            // this.$in.api('common/dynamic', {
            //     query: `UPDATE `+tableName+` SET role_name = '${this.form.role_name}', update_by = '${this.getUserId()}', update_time = '${time}' WHERE id = ${this.form.id}`
            // }).then(() => {
            //     this.$message.success('编辑成功')
            //     this.formVisible = false
            //     this.getData(true)
            // })

            await this.dbquery(sqlStr);

            this.$message.success({ message: "编辑成功", offset: 200 });
            this.formVisible = false;
            this.getData(true);
          } else {
            const insertSql =
              `INSERT INTO ` +
              tableName +
              ` (role_name, create_by, create_time) VALUES ('${
                this.form.role_name
              }', '${localStorage.getItem("userid")}', '${time}')`;
            await this.dbquery(insertSql);
            this.$message.success({ message: "新增成功", offset: 200 });
            this.formVisible = false;
            this.getData(true);
          }
        }
      });
    },
    formatDate(date) {
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let min = date.getMinutes();
      min = min < 10 ? "0" + min : min;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + m + "-" + d + " " + h + ":" + min + ":" + s;
    },
    handleDelete(row) {
      const deleteSql = `DELETE FROM ` + tableName + ` WHERE id = ${row.id}`;
      this.dbquery(deleteSql);
      this.$message.success({ message: "删除成功", offset: 200 });
      this.getData(true);
    },
    handleExport() {},
    handleSelectionChange(val) {
      this.selectRowKeys = val.map((item) => item.id);
    },

    handleEditUser(row) {
      localStorage.setItem("propose_rolename", row.role_name);
      if (row.role_name == "园领导") {
        this.showDeptAdd = true;
      } else {
        this.showDeptAdd = false;
      }
      this.selectRole = row;

      this.userVisible = true;
      this.userTableData = [];
      this.userLastQueryWrapper = "";
      this.getRoleAllUser();
      this.getRoleUsers();
    },
    async getRoleAllUser() {
      const param =
        `SELECT *  ${this.userQueryWrapper} AND id IN (SELECT user_id FROM ` +
        congress_role_user +
        ` WHERE role_id = '${this.selectRole.id}')`;

      const res = await this.$db_select(
        this.$http,
        "xtbg_user",
        "*",
        "1=1 AND id IN (SELECT user_id FROM " +
          congress_role_user +
          " WHERE role_id = " +
          this.selectRole.id +
          ")"
      );

      //console.log(res);

      //this.userTableData = res;

      this.userAllTableData = res;
    },
    async getRoleUsers(current0) {
      //this.getRoleAllUser();
      if (current0) {
        this.userPage.currentPage = 1;
        this.userTableData = [];
      }

      this.userPage.total = await this.getRoleUserTotal();
      const { currentPage, pageSize } = this.userPage;
      // const param =
      //   `SELECT *  ${this.userQueryWrapper} AND id IN (SELECT user_id FROM ` +
      //   congress_role_user +
      //   ` WHERE role_id = '${this.selectRole.id}') LIMIT ${pageSize} OFFSET ${
      //     (currentPage - 1) * pageSize
      //   }`;

      this.userTableData = await this.$db_select(
        this.$http,
        "xtbg_user",
        "*",
        "1=1 AND id IN (SELECT user_id FROM " +
          congress_role_user +
          " WHERE role_id = " +
          this.selectRole.id +
          ") LIMIT " +
          pageSize +
          " OFFSET " +
          (currentPage - 1) * pageSize
      );
      // console.log(this.userTableData);
    },
    async getRoleUserTotal() {
      if (this.userQueryWrapper === this.userLastQueryWrapper) {
        return this.userPage.total;
      }
      this.userLastQueryWrapper = this.userQueryWrapper;

      const countSql =
        `SELECT COUNT(*) as total ${this.userQueryWrapper} AND id IN (SELECT user_id FROM ` +
        congress_role_user +
        ` WHERE role_id = '${this.selectRole.id}')`;

      return (await this.dbquery(countSql))[0]["total"];
    },
    handleUserListSizeChange(val) {
      this.userPage.pageSize = val;
      this.getRoleUsers(true);
    },
    handleUserListCurrentChange(val) {
      this.userPage.currentPage = val;
      this.getRoleUsers();
    },
    handleSelectionUser(val) {
      this.userSelectRowKeys = val.map((item) => item.id);
    },
    handleFilterUser() {
      const { name, dept_name } = this.filterUserForm;
      let queryWrapper = `FROM xtbg_user WHERE 1=1`;
      if (name) {
        queryWrapper += ` AND name LIKE '%${name}%'`;
      }
      if (dept_name) {
        queryWrapper += ` AND dept_name LIKE '%${dept_name}%'`;
      }
      if (!name && !dept_name) {
        queryWrapper = "FROM xtbg_user Where 1=1";
      }
      this.userQueryWrapper = queryWrapper;
      this.getRoleUsers(true);
    },
    // 添加用户
    handleAddUser() {
      this.selectUserVisible = true;
    },
    // 已经选择用户
    async onSelectUser(rows, cancelSelectRows) {
      
      this.handleCancelSelectUser(cancelSelectRows);
      let roleId = this.selectRole.id;
      // 获取rows中的id,根据this.userAllTableData中的id进行过滤

      let userIds = rows
        .map((item) => item.id)
        .filter((item) => !this.userAllTableData.find((v) => v.id === item));
      
      // console.log(rows);
      // console.log(userIds);
      
      
      // if (!userIds.length) {
      //   this.$message.success({ message: "添加成功", offset: 200 });
      //   return;
      // }



      // const insertRoleUserSql =
      //   `INSERT INTO ` +
      //   congress_role_user +
      //   ` (role_id, user_id) VALUES ${userIds
      //     .map((item) => `(${roleId}, ${item})`)
      //     .join(",")}`;
      // await this.dbquery(insertRoleUserSql);

      await Promise.all(
        userIds.map(async (item) => {
          await this.$db_insert(this.$http, congress_role_user, {
            role_id: roleId,
            user_id: item,
          });
        })
      );
      this.$message.success({ message: "添加成功", offset: 200 });
      this.userTableData = [];
      this.userLastQueryWrapper = "";
      this.getRoleUsers();
    },
    async handleCancelSelectUser(cancelSelectRows) {
      if (cancelSelectRows.length) {
        const deleRoleUser =
          `DELETE FROM ` +
          congress_role_user +
          ` WHERE role_id = ${
            this.selectRole.id
          } AND user_id IN (${cancelSelectRows
            .map((item) => item.id)
            .join(",")})`;
        await this.dbquery(deleRoleUser);
        this.userTableData = [];
        this.userLastQueryWrapper = "";
        this.getRoleUsers();
      }
    },
    // 移除角色用户
    async handleRemoveUser(row) {
      const deleteOneStr =
        `DELETE FROM ` +
        congress_role_user +
        ` WHERE role_id = ${this.selectRole.id} AND user_id = ${row.id}`;
      const res = await this.dbquery(deleteOneStr);

      if (res.affectedRows === 0) {
        this.$message.error({ message: "移除失败", offset: 200 });
      }
      this.$message.success({ message: "移除成功", offset: 200 });
      this.userTableData = [];
      this.userLastQueryWrapper = "";
      this.getRoleUsers();
    },
    // 批量移除角色用户
    async handleBatchRemove() {
      const sqlStr =
        `DELETE FROM ` +
        congress_role_user +
        ` WHERE role_id = ${
          this.selectRole.id
        } AND user_id IN (${this.userSelectRowKeys.join(",")})`;
      const res = await this.dbquery(sqlStr);

      if (res.affectedRows === 0) {
        this.$message.error({ message: "移除失败", offset: 200 });
      }
      this.$message.success({
        message: `成功移除${res.affectedRows}条记录`,
        offset: 200,
      });
      this.batchRemoveVisible = false;
      this.userTableData = [];
      this.userLastQueryWrapper = "";
      this.getRoleUsers();
      this.userSelectRowKeys = [];
    },
  },
};
</script>

<style scoped lang="scss">
.role-manager-container {
  ::v-deep {
    .el-button {
      &.add {
        border-color: #a3d3ff;
        background-color: #e8f4ff;
        color: #1890ff;

        &:hover {
          background-color: #a3d3ff;
          color: #fff;
        }
      }
    }
  }
}
</style>
