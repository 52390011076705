<template>
  <el-dialog
    title="核销药品"
    :visible.sync="dialogVisible"
    width="50%"
    :close-on-click-modal="false"
    id="dialog_shenling"
    :show-close="false"
    :fullscreen="true"
  >
    <el-form
      ref="formData"
      :model="formData"
      label-width="120px"
      :rules="rules"
    >
      <!-- 药品信息 -->
      <div class="section">
        <h3 class="section-title">药品信息</h3>
        <el-form-item label="核销药品:" prop="name">
          <el-autocomplete
            v-model="formData.name"
            :fetch-suggestions="querySearch"
            placeholder="请输入核销药品搜索"
            @select="handleSelect"
            style="width: 100%"
          >
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="规格:">
          <el-input v-model="formData.spec" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="核销量:">
          <el-input v-model="formData.weight"></el-input>
        </el-form-item>
        <el-form-item label="废物类型:">
          <el-radio-group v-model="formData.waste_type">
            <el-radio
              v-for="item in wasttypes"
              :key="item.id"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- main_hazard -->
        <el-form-item label="危害成分:">
          <el-checkbox-group v-model="main_hazards">
            <el-checkbox
              v-for="item in hazardousComponents"
              :key="item.id"
              :label="item.value"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="核销原因:">
          <el-checkbox-group v-model="selectedReasons">
            <el-checkbox
              v-for="reasonItem in cancellationReasons"
              :key="reasonItem.id"
              :label="reasonItem.value"
            >
              {{ reasonItem.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="formData.remark"></el-input>
        </el-form-item>
      </div>

      <!-- 领用情况 -->
    </el-form>

    <div class="dialog-footer">
      <el-button @click="close" icon="el-icon-close">取消</el-button>
      <el-button type="primary" @click="handleSubmit" icon="el-icon-check"
        >确定</el-button
      >
    </div>
    <!-- 核销记录 -->
    <div class="sl_records">
      <h3 class="section-title">核销记录</h3>
      <el-table :data="tableData" style="width: 100%" border height="300px">
        <el-table-column prop="uid" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="name" label="核销药品"></el-table-column>
        <el-table-column prop="spec" label="规格" width="100"></el-table-column>
        <el-table-column
          prop="weight"
          label="核销量"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="waste_type"
          label="废物类别"
       
        ></el-table-column>
        <el-table-column
          prop="reason"
          label="核销原因"
         
        ></el-table-column>
        <el-table-column
          prop="main_hazard"
          label="主要危害成分"
          
        ></el-table-column>

        <!-- <el-table-column
          prop="purpose"
          label="领用用途"
          width="150"
        ></el-table-column> -->
        <el-table-column
          prop="create_time"
          label="核销时间"
          width="160"
        ></el-table-column>

        <el-table-column prop="status" label="核销状态">
          <template slot-scope="scope">
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <el-steps
                  direction="vertical"
                  :active="getActiveStep(scope.row)"
                >
                  <el-step
                    :title="scope.row.create_username"
                    description="
                      提交核销
                    "
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.group_userid
                        ? scope.row.group_username
                        : '研究组负责人'
                    "
                    :description="
                      scope.row.group_userid
                        ? scope.row.guser_result +
                          ' - ' +
                          scope.row.guser_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.admin_userid
                        ? scope.row.admin_username
                        : '管理员'
                    "
                    :description="
                      scope.row.admin_userid
                        ? scope.row.admin_result +
                          ' - ' +
                          scope.row.admin_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.admin_result)"
                  ></el-step>
                </el-steps>
              </div>
              <span>
                <span
                  style="color: #085"
                  v-if="scope.row.status === 0 || scope.row.status === null"
                  >待审核</span
                >
                <span style="color: #085" v-if="scope.row.status === 1"
                  >已审核</span
                >

                <span style="color: #085" v-if="scope.row.status === 4"
                  >已退回</span
                >
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleDeleteClick(scope.row)"
              type="text"
              icon="el-icon-delete"
              size="small"
              v-if="
                scope.row.group_userid == null || scope.row.group_userid == ''
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
  
  <script>
export default {
  props: {
    dialogVisible: Boolean,
    hexiaoData: Object,
    isShowShenlingAndHeXiaoShenPin: Boolean,
  },
  data() {
    return {
      formData: {
        name: "",
        info_id: "",
        weight: "",
        waste_type: "",
        main_hazard: "",
        reason: "",
        remark: "",
        create_by: null,
        create_time: this.$FormatDateToYMDHMS(new Date(), true),
      },
      current_stock: "",
      create_name: localStorage.getItem("username"),
      names: [],
      allnames: [],
      rules: {
        name: [{ required: true, message: "请选择药品", trigger: "blur" }],
        weight: [{ required: true, message: "请输入核销量", trigger: "blur" }],
        waste_type: [
          { required: true, message: "请输入废物类型", trigger: "blur" },
        ],
        waste_type: [
          { required: true, message: "请输入废物类型", trigger: "blur" },
        ],
        waste_type: [
          { required: true, message: "请输入废物类型", trigger: "blur" },
        ],
      },

      tableData: [],

      //废物类别

      wasttypes: [
        {
          id: 17,
          type: "废物类别",
          name: "HW03废药物、药品",
          value: "HW03废药物、药品",
          remark: "",
        },
        {
          id: 18,
          type: "废物类别",
          name: "HW04农药废物",
          value: "HW04农药废物",
          remark: "",
        },
        {
          id: 19,
          type: "废物类别",
          name: "HW49其他废物",
          value: "HW49其他废物",
          remark: "",
        },
      ],
      //有害成分
      main_hazards: [], // Initialize as an empty array to store multiple selections
      hazardousComponents: [
        {
          id: 20,
          type: "有害成分",
          name: "含氯、溴、硫、磷、氟等元素",
          value: "含氯、溴、硫、磷、氟等元素",
          remark: "",
        },
        {
          id: 21,
          type: "有害成分",
          name: "含铜、铅、锌、铬、镍、镉、铍、钡重金属永元素",
          value: "含铜、铅、锌、铬、镍、镉、铍、钡重金属永元素",
          remark: "",
        },
        {
          id: 22,
          type: "有害成分",
          name: "挥发性有机物",
          value: "挥发性有机物",
          remark: "",
        },
        { id: 23, type: "有害成分", name: "强酸", value: "强酸", remark: "" },
        { id: 24, type: "有害成分", name: "强碱", value: "强碱", remark: "" },
        {
          id: 25,
          type: "有害成分",
          name: "氰化物",
          value: "氰化物",
          remark: "",
        },
        {
          id: 26,
          type: "有害成分",
          name: "可溶性盐",
          value: "可溶性盐",
          remark: "",
        },
        {
          id: 27,
          type: "有害成分",
          name: "含汞、重金属元素",
          value: "含汞、重金属元素",
          remark: "",
        },
        { id: 28, type: "有害成分", name: "其它", value: "其它", remark: "" },
      ],
      //核销原因
      selectedReasons: [], // Initialize as an empty array to store multiple selections
      cancellationReasons: [
        {
          id: 54,
          type: "核销原因",
          name: "使用完",
          value: "使用完",
          remark: "",
        },
        { id: 55, type: "核销原因", name: "参杂", value: "参杂", remark: "" },
        { id: 56, type: "核销原因", name: "过期", value: "过期", remark: "" },
      ],
    };
  },
  watch: {
    hexiaoData(obj) {
      if (obj) {
        this.formData.name = obj.spec ? `${obj.name} (${obj.spec})` : obj.name;
        this.formData.info_id = obj.id;
        this.formData.create_by = localStorage.getItem("userid");
        this.formData.spec = obj.spec;
        this.formData.weight = obj.stock;
      }
    },
    dialogVisible(val) {
      if (val) {
        this.getHXList();
      }
    },
  },
  methods: {
    getStepStatus(result) {
      if (result === "通过") return "success";
      if (result === "未通过") return "error";
      return "process";
    },
    getActiveStep(row) {
      if (row.admin_result === "通过" || row.admin_result === "未通过") {
        return 2;
      } else if (row.guser_result === "通过" || row.guser_result === "未通过") {
        return 1;
      } else {
        return 0;
      }
    },

    reset() {
      this.formData = {
        name: "",
        info_id: "",
        weight: "",
        waste_type: "",
        main_hazard: "",
        reason: "",
        remark: "",
        create_by: null,
        create_time: this.$FormatDateToYMDHMS(new Date(), true),
        status: 0,
      };
      this.main_hazards=[];
      this.selectedReasons=[];
    },
    querySearch(queryString, cb) {
      var restaurants = this.names;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    //危害类型
    //核销原因

    handleSelect(item) {
      //this.formData.cas = item.cas;
      // 在这里处理选中的项目\
      console.log(item);

      this.formData.info_id = item.id;

      this.current_stock = item.stock;

      this.formData.spec = item.spec;

      this.formData.weight = item.stock;
      this.$refs.formData.validateField("name");

      //查询该药品是否已经申领过。申领过了。需要核销结束后，才能再次申领
    },
    handleMedicineSelect() {
      // 处理药品选择的逻辑
      console.log("选择药品");
    },
    close() {
      this.$parent.closeDialogHexiao();
    },
    async getHXList() {
      // const res = await this.$db_select(
      //   this.$http,
      //   "xtbg_hazard_writoff",
      //   "*",
      //   "create_by=" +
      //     localStorage.getItem("userid") +
      //     " order by create_time DESC"
      // );
      // this.tableData = await Promise.all(
      //   res.map(async (row) => {
      //     if (row.group_userid) {
      //       row.group_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.group_userid
      //         )
      //       )[0].name;
      //       row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);
      //     }
      //     if (row.admin_userid) {
      //       row.admin_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.admin_userid
      //         )
      //       )[0].name;
      //       row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);
      //     }

      //     return {
      //       ...row,
      //       name:
      //         (
      //           await this.$db_select(
      //             this.$http,
      //             "xtbg_hazard_info_group",
      //             "name",
      //             "id=" + row.info_id
      //           )
      //         )[0]?.name || "",
      //       create_username: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.create_by
      //         )
      //       )[0].name,
      //       create_time: this.$FormatDateToYMDHMS(row.create_time, true),
      //     };
      //   })
      // );

      this.loading = true;

      try {
        const userid = localStorage.getItem("userid");

        // 获取符合条件的数据
        const res = await this.$db_select(
          this.$http,
          "xtbg_hazard_writoff",
          "*",
          "create_by=" + userid + " order by create_time DESC"
        );

        if (!res || res.length === 0) {
          this.tableData = [];
          this.loading = false;
          return;
        }

        // 提取所有可能的 group_userid、admin_userid、info_id 和 create_by
        const groupUserIds = [
          ...new Set(res.map((row) => row.group_userid).filter(Boolean)),
        ];
        const adminUserIds = [
          ...new Set(res.map((row) => row.admin_userid).filter(Boolean)),
        ];
        const infoIds = [
          ...new Set(res.map((row) => row.info_id).filter(Boolean)),
        ];
        const createByIds = [
          ...new Set(res.map((row) => row.create_by).filter(Boolean)),
        ];

        // 合并所有用户 ID，避免重复查询
        const userIds = [
          ...new Set([...groupUserIds, ...adminUserIds, ...createByIds]),
        ];

        // 批量查询所有相关信息
        const [users, infos] = await Promise.all([
          userIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_user",
                "id, name",
                `id IN (${userIds.join(",")})`
              )
            : [],
          infoIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_hazard_info_group",
                "id, name",
                `id IN (${infoIds.join(",")})`
              )
            : [],
        ]);

        // 构建 ID 到名称的映射
        const userMap = Object.fromEntries(
          users.map((user) => [user.id, user.name])
        );
        const infoMap = Object.fromEntries(
          infos.map((info) => [info.id, info.name])
        );

        // 处理数据
        let rowIndex = 0;
        this.tableData = res.map((row) => {
          rowIndex++;
          return {
            ...row,
            uid:rowIndex,
            group_username: userMap[row.group_userid] || "",
            guser_aptime: this.$FormatDateToYMDHMS(row.guser_aptime, true),
            admin_username: userMap[row.admin_userid] || "",
            admin_aptime: this.$FormatDateToYMDHMS(row.admin_aptime, true),
            name: infoMap[row.info_id] || "",
            create_username: userMap[row.create_by] || "",
            create_time: this.$FormatDateToYMDHMS(row.create_time, true),
          };
        });
      } catch (error) {
        console.error("加载数据时发生错误:", error);
        this.$message.error("加载数据时发生错误");
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteClick(row) {
      if (
        this.isShowShenlingAndHeXiaoShenPin &&
        row.admin_userid != "" &&
        row.admin_userid != null
      ) {
        console.log(44);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }

      if (row.group_userid != "" && row.group_userid != null) {
        console.log(66);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }

      //删除
      const deleteRes = await this.$db_delete(
        this.$http,
        "xtbg_hazard_writoff",
        "id=" + row.id
      );
      if (deleteRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        await this.getHXList();
      } else {
        this.$message({
          type: "error",
          message: "删除失败",
        });
      }
    },
    async handleSubmit() {
      // 处理表单提交逻辑
      //console.log("表单提交", this.formData);
      //this.dialogVisible = false;
      //获取组的id
      let groupidRes = await this.$db_select(
        this.$http,
        "xtbg_hazard_studies",
        "groupid",
        "userid=" + localStorage.getItem("userid")
      );
      if (groupidRes) {
        this.formData.group_id = groupidRes[0].groupid;
      }

      this.formData.create_by = parseInt(localStorage.getItem("userid"));
      if (this.formData.weight == 0) {
        this.$message({
          type: "warning",
          message: "核销量不能为0",
        });
        return false;
      }

      delete this.formData.name;

      this.$refs.formData.validate(async (valid) => {
        this.formData.main_hazard = this.main_hazards.join(",");
        this.formData.reason = this.selectedReasons.join(",");
        // console.log(this.formData);
        //return false;
        // info_id	核销药品
        // weight	核销重量
        // waste_type	废物类别
        // reason	核销原因（使用完、参杂、过期）
        // main_hazard	主要危害成分
        // remark	核销说明
        // group_userid	研究组负责人
        // guser_aptime	研究组负责人审核时间
        // guser_result	研究组负责人审核结果(通过、未通过)
        // guser_remark	研究组负责人审核意见
        // admin_aptime	管理员审核时间
        // admin_result	管理员审核结果(通过、未通过)
        // admin_remark	管理员审核意见
        // status	状态(0、待审核 1、已审核 4 已退回
        // create_by	核销人
        // create_time	核销时间
        // update_by	修改人
        // update_time	修改时间
        // admin_userid	审核管理员
        // spec	规格

        if (valid) {
          //开始提交
          this.formData.create_time = this.$FormatDateToYMDHMS(
            new Date(),
            true
          );
          //console.log(this.formData);

          // 判断是否是负责人
          if (this.isShowShenlingAndHeXiaoShenPin) {
            this.formData.group_userid = parseInt(localStorage.getItem("userid"));
            this.formData.guser_result = "通过";
            this.formData.guser_remark = "通过";
            this.formData.guser_aptime = this.$FormatDateToYMDHMS(
              new Date(),
              true
            );
          }

          const insertRes = await this.$db_insert(
            this.$http,
            "xtbg_hazard_writoff",
            this.formData
          );
          //console.log(insertRes);
          if (insertRes.affectedRows > 0) {
            this.reset();
            this.$message({
              type: "success",
              message: "核销成功!",
            });
            this.getHXList();
          } else {
            this.$message({
              type: "error",
              message: "核销失败!",
            });
          }
        } else {
          console.log("表单验证失败");
        }
      });
    },
  },
  async mounted() {
    this.reset();
    this.allnames = await this.$db_select(
      this.$http,
      "xtbg_hazard_info_group",
      "*"
    );

    this.names = this.allnames.map((item) => {
      if (item.cas) {
        item.name = item.name + "(" + item.cas + ")";
        item.label = item.name + "(" + item.cas + ")";
      }

      return {
        id: item.id,
        value: item.name,
        label: item.label,
        cas: item.cas,
        stock: item.stock,
        spec: item.spec,
      };
    });

    //申领记录
  },
};
</script>
  
  <style scoped>
.steps-tooltip {
  width: 300px;
  white-space: normal;
}
.section {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f7fa;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: #67c23a;
  margin-bottom: 10px;
  border-left: 4px solid #67c23a;
  padding-left: 10px;
}

.medicine-info {
  display: flex;
  align-items: center;
}

.medicine-info span {
  flex-grow: 1;
}

.inventory-status {
  color: red;
  margin-left: 10px;
}

.dialog-footer {
  text-align: right;
  padding: 10px 0;
}

.sl_records {
  /* display: flex;
  flex-direction: column; */
}
</style>
  