<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-06 14:58:46
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-11 15:10:17
 * @FilePath: \app_wb\src\views\proposal\tools\zoom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template lang="">
  <div class="flex-column" style="position: absolute; right: 5px; bottom: 5px">
    <i
      class="el-icon-s-home"
      style="font-size: 24px; cursor: pointer"
      @click="zoomHome"
    ></i>
    <i
      class="el-icon-zoom-in"
      style="font-size: 24px; cursor: pointer"
      @click="zoomOut"
    ></i>
    <i
      class="el-icon-zoom-out"
      style="font-size: 24px; cursor: pointer"
      @click="zoomIn"
    ></i>
  </div>
</template>
<script>
export default {
  props: {
    textid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fontSize: 20,
    };
  },
  methods: {
    zoomOut() {
      this.fontSize = this.fontSize + 2;
      document.getElementById(this.textid).style.fontSize =
        this.fontSize + "px";
    },
    zoomIn() {
      if (this.fontSize < 20) {
        this.fontSize = 20;
        return;
      }
      this.fontSize = this.fontSize - 2;
      document.getElementById(this.textid).style.fontSize =
        this.fontSize + "px";
    },
    zoomHome() {
      document.getElementById(this.textid).style.fontSize = "20px";
    },
  },
};
</script>
<style lang=""></style>
