<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-29 16:06:03
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-26 16:18:12
 * @FilePath: \app_wb\src\components\commom\index_list_item_kzss.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index_list-dktz">
    <div class="index_list-dktz-title">
      <img :src="dklogo" alt="" />
      <div style="height: 33px; border-bottom: 3px solid rgb(81 159 109);">{{ title }}</div>
      <div v-show="false">更多>></div>
    </div>

    <div class="index_list-dktz-content">
      <el-table
        :data="tableData"
        stripe
        style="background: transparent;"
        
      >
        <el-table-column
          v-for="(item, index) in TableColumns"
          :key="index"
          :prop="item.pname"
          :label="item.label"
          :width="item.width"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { ListPayReceive } from "@/api/content/xtbg_pay_received";
export default {
  props: {
    dklogo: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "到款通知",
    },
    TableColumns: {
      type: Array,
      default: () => [
        {
          id: 1,
          label: "来款单位",
          pname: "unit",
          width: "200px",
        },

        {
          id: 2,
          label: "到款日期",
          pname: "date",
          width: "100px",
        },

        {
          id: 3,
          label: "金额(元)",
          pname: "amount",
          width: "85px",
        },
      ],
    },
  },
  data() {
    return {
      queryPramas: {
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [],
    };
  },
  created() {
    this.getListPayReceive();
  },
  methods: {
    getListPayReceive() {
      ListPayReceive(this.queryPramas).then((res) => {
        this.tableData = res.data.rows;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.index_list-dktz {
  margin-top: 5px;
  height: auto;

  width: 385px;
  padding-left: 10px;
  .index_list-dktz-title {
    font-weight: bold;
    height:30px;
    line-height: 30px;
    
    width: 385px;
   // background-color: rgb(81 159 109);
   
    img {
      width: 25px;
      height: 25px;
      margin: 4px 12px;
      float: left;
    }
    div {
      
      font-size: 16px;
      text-align: left;
      padding-left: 5px;
      color: #444;
      float: left;
      &:first-child{
        border-bottom: 2px solid rgb(81 159 109);
      }
      &:last-child {
        float: right;
        margin-right: 15px;
        cursor: pointer;
        font-size: 14px;
        font-weight:normal;
      }
    }
  }
  .index_list-dktz-content {
    width: 385px;
    height: 320px;
    font-size: 14px;
    color: rgb(81 159 109);
    overflow: hidden;
    overflow: hidden;
    border-top: 1px solid rgb(81 159 109);
    background: #fafafa;
    
  }
  ::v-deep .index_list-dktz-content .el-table__row{
    width: 385px !important;
  } 
}
</style>