<template>
  <el-form-item :label="label" prop="imageFileList">
    <el-upload
      ref="imageUpload"
      :http-request="customUploadRequest"
      :action="''"
      :multiple="true"
      :limit="5"
      :on-exceed="handleExceed"
      :file-list="imageFileList"
      :on-preview="handlePreview"
      :on-remove="handleImageRemove"
      list-type="picture-card"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogImageVisible">
      <img width="100%" :src="dialogImageUrl" alt="预览" />
    </el-dialog>
  </el-form-item>
</template>

<script>
import axios from "axios";

export default {
  name: "ImageUpload",
  props: {
    uploadUrl: {
      type: String,
      required: true,
    },
    uploadData: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: "仪器图片：",
    },
    maxFiles: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      imageFileList: [],
      dialogImageVisible: false,
      dialogImageUrl: "",
    };
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(
        `上传文件数量超出限制，最多只能上传 ${this.maxFiles} 张`
      );
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    handleImageRemove(file, fileList) {
      this.imageFileList = fileList;
      this.$emit("file-remove", file, fileList);
    },
    async customUploadRequest({ file }) {
      if (!this.uploadData.num) {
        this.$message({
          type: "error",
          message: "请先输入仪器编号",
        });
        return;
      }

      const form = new FormData();

      form.append("file", file);

      // 从父组件传入的外部数据追加到表单中
      form.append("num", this.uploadData.num);

      console.log("开始上传");
      this.$http
        .post(this.uploadUrl, form)
        .then((response) => {
          console.log("上传成功");
          console.log(response);
          this.$emit("upload-success", response.data);
        })
        .catch((error) => {
          this.$emit("upload-error", error);
        });

      // 将上传成功的响应数据传递回父组件

      // 清空文件列表
      //this.imageFileList = [];
    },
  },
};
</script>

<style scoped>
/* 在此处添加任何组件特定的样式 */
</style>
