<template>
  <div class="minglu_main">
    <div style="display: flex; padding-bottom: 10px">
      <el-input
        placeholder="类型/键名/键值"
        v-model="search_txt"
        class="input-with-select"
        style="width: 50%; margin-left: auto; margin-right: 20px"
      >
      </el-input>
      <el-button type="text" icon="el-icon-plus" @click="openDialog('add')">
        添加
      </el-button>
    </div>
    <el-table :data="paginatedData" height="550" border style="width: 100%">
      <el-table-column
        prop="uid"
        label="序号"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="name" label="键名"></el-table-column>
      <el-table-column prop="value" label="键值"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="color: #008500"
            icon="el-icon-edit"
            @click="openDialog('edit', scope.row)"
          >
            编辑
          </el-button>
          <el-button
            type="text"
            style="color: #f00"
            icon="el-icon-delete"
            @click="deleteRow(scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, prev, pager, next"
      :total="tableData.length"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
      style="margin-top: 20px; text-align: right"
    />

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-input v-model="form.type"></el-input>
        </el-form-item>
        <el-form-item label="键名" :label-width="formLabelWidth">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="键值" :label-width="formLabelWidth">
          <el-input v-model="form.value"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        // 示例数据
        { type: "类型1", name: "键名1", value: "键值1" },
        { type: "类型2", name: "键名2", value: "键值2" },
        { type: "类型3", name: "键名3", value: "键值3" },
        { type: "类型4", name: "键名4", value: "键值4" },
        { type: "类型5", name: "键名5", value: "键值5" },
        { type: "类型6", name: "键名6", value: "键值6" },
        { type: "类型7", name: "键名7", value: "键值7" },
        { type: "类型8", name: "键名8", value: "键值8" },
        { type: "类型9", name: "键名9", value: "键值9" },
        { type: "类型10", name: "键名10", value: "键值10" },
        { type: "类型11", name: "键名11", value: "键值11" },
        { type: "类型12", name: "键名12", value: "键值12" },
        { type: "类型13", name: "键名13", value: "键值13" },
        { type: "类型14", name: "键名14", value: "键值14" },
        { type: "类型15", name: "键名15", value: "键值15" },
        { type: "类型16", name: "键名16", value: "键值16" },
        { type: "类型17", name: "键名17", value: "键值17" },
      ],
      dialogVisible: false,
      dialogTitle: "",
      form: {
        type: "", // 类型
        name: "", // 键名
        value: "", // 键值
        remark:"",
      },
      formLabelWidth: "120px",
      isEdit: false,
      editIndex: -1,
      currentPage: 1,
      pageSize: 10,
      editId: 0,
      search_txt: "",
    };
  },
  created() {
    this.getList();
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.tableData.slice(start, end);
    },
  },
  watch: {
    search_txt(val) {
      if (val.length >= 1) {
        this.search(val);
        return false;
      }
      this.getList();
    },
  },
  methods: {
    async search(stxt) {
      const selectSql = `SELECT * FROM xtbg_hazard_dict where type like '%${stxt}%' or name like '%${stxt}%' or value like '%${stxt}%'  order by id desc`; // 查询sql
      const data = await this.$dbquery(this.$http, selectSql);
      let index = 0;
      this.tableData = data.map((row) => {
        index++;
        return {
          ...row,
          uid: index,
        };
      });
    },
    async getList() {
      const selectSql = `SELECT * FROM xtbg_hazard_dict order by id desc`; // 查询sql
      const data = await this.$dbquery(this.$http, selectSql);
      let index = 0;
      this.tableData = data.map((row) => {
        index++;
        return {
          ...row,
          uid: index,
        };
      });
    },
    openDialog(type, row) {
      this.dialogVisible = true;
      if (type === "add") {
        this.dialogTitle = "添加";
        this.form = { type: "", name: "", value: "" };
        this.isEdit = false;
      } else if (type === "edit") {
        this.dialogTitle = "编辑";
        this.form = { ...row };

        this.editId = this.form.id;
        delete this.form.id;
        delete this.form.uid;

        this.isEdit = true;
        this.editIndex = this.tableData.indexOf(row);
      }
    },
    async saveData() {
      if (this.isEdit) {
        this.$set(this.tableData, this.editIndex, { ...this.form });
        //保存数据库
        const tableName = "xtbg_hazard_dict"; // Replace with your actual table name
        const primaryKey = "id"; // Replace with your actual primary key

        const updates = Object.entries(this.form)
          .map(([key, value]) => `${key}='${String(value).trim()}'`)
          .join(",");

        const updateSQL = `UPDATE ${tableName} SET ${updates} WHERE ${primaryKey}=${this.editId};`;

        const result = await this.$dbquery(this.$http, updateSQL);
        if (result.affectedRows > 0) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.getList();
        } else {
          this.$message({
            type: "error",
            message: "修改失败!",
          });
        }
      } else {
        this.tableData.unshift({ ...this.form });

        //插入数据库
        const tableName = "xtbg_hazard_dict"; // Replace with your actual table name

        const keys = Object.keys(this.form).join(",");
        const values = Object.values(this.form)
          .map((value) => `'${String(value).trim()}'`)
          .join(",");

        const insertSQL = `INSERT INTO ${tableName} (${keys}) VALUES (${values});`;

        const result = await this.$dbquery(this.$http, insertSQL);

        if (result.affectedRows > 0) {
          this.getList();
          this.$message({
            type: "success",
            message: "添加成功!",
          });
        } else {
          this.$message({
            type: "error",
            message: "添加失败!",
          });
        }
      }
      this.dialogVisible = false;
      this.handleCurrentChange(this.currentPage); // 重新计算分页数据
    },
    deleteRow(row) {
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.tableData.splice(this.tableData.indexOf(row), 1);
          this.handleCurrentChange(this.currentPage); // 重新计算分页数据
          // 数据库里删除
         
          const deleteSql =
            "delete from xtbg_hazard_dict where id=" + row.id + "";
          const result = await this.$dbquery(this.$http, deleteSql);

          if (result.affectedRows > 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
  },
};
</script>

<style scoped>
.minglu_main {
}
</style>
