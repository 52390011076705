<template>
  <div class="gongjiao">
    <div
      style="
        margin: 10px 0px;
        height: 100px;
        background-color: #f5f8f7;
        text-align: left;
        display: flex;
        align-items: center;
      "
    >
      <img
        src="../../../assets/imgs/gongwu/zuixingonggao.png"
        style="width: 60px; height: auto; margin-left: 20px"
        alt=""
        srcset=""
      />

      <div style="flex: 1; font-size: 14px; padding: 0px 20px">
        <div style="text-indent: 2em">
          尊敬的用户们， 公务车预约，上线试运行。
          一、内网用户端：第一，内网所有用户可以申请公务车，由车队管理审核指派公务车，其他用户可以搭乘可搭乘的公务车，搭乘需由对应用车人审核同意，即可搭乘。第二，可以自由发布顺风车信息。
          二、车队管理员端（只在PC端管理车辆基础信息，权限管理）。（pc端和小程序端均可审核和指派）审核内网用户公务车申请单，并指派对应车辆和司机（目前，司机和公务车属于一对一）。
          三、司机端（只在小程序端），查看跟自己有关系的公务车订单并可结束行程。
        </div>
        <div class="flex">
          <span style="color: #808080; margin-left: auto"> 2024年9月24日 </span>
        </div>
      </div>
    </div>
    <div class="flex" style="padding: 10px">
      <el-input
        clearable
        style="margin-left: auto; margin-right: 10px; max-width: 200px"
        v-model="searchStartAddress"
        placeholder="搜索起点"
      ></el-input>

      <el-input
        clearable
        style="margin-right: 10px; max-width: 200px"
        v-model="searchEndAddress"
        placeholder="搜索终点"
      ></el-input>
      <el-input
        clearable
        style="margin-right: 10px; max-width: 200px"
        v-model="searchUser"
        placeholder="搜索用车人"
      ></el-input>
      <el-button @click="searchApplications" icon="el-icon-refresh"  type="text"
        >刷新</el-button
      >
    </div>

    <el-table border height="560px" :data="bookings" style="width: 100%">
      <el-table-column
        prop="uid"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="xingcheng"
        label="行程"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carinfo"
        label="车辆信息"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.carinfo.split("-")[0] }}-</span>
          <span
            style="color: #409eff; cursor: pointer"
            @click="searchBook(scope.row.carinfo.split('-')[1])"
            >{{ scope.row.carinfo.split("-")[1] }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="start_time"
        label="开始时间"
        align="center"
        width="156"
      ></el-table-column>
      <el-table-column prop="user_name" label="用车人" align="center">
        <template slot-scope="scope">
          <span
            style="color: #409eff; cursor: pointer"
            @click="searchBook(scope.row.user_name)"
            >{{ scope.row.user_name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="reason"
        label="用车事由"
        width="140"
      ></el-table-column>
      <el-table-column
        prop="capacity"
        label="乘车人数"
        align="center"
        width="78"
      ></el-table-column>
      <el-table-column
        prop="oseats"
        label="剩余座位"
        align="center"
        width="78"
      ></el-table-column>
      <el-table-column prop="carpool" label="可搭乘" align="center" width="66">
        <template slot-scope="scope">
          <span v-if="scope.row.oseats <= 0" style="color: #f00">否</span>
          <div v-else>
            <span v-if="scope.row.carpool == '是'" style="color: #085">是</span>
            <span v-if="scope.row.carpool != '是'" style="color: #f00">否</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="cstatus"
        label="状态"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-thumb"
            v-if="
              scope.row.status == 0 &&
              scope.row.oseats > 0
            "
            @click="editBooking(scope.row)"
            >审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total,prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
      style="margin-top: 20px; text-align: center"
    />
    <!-- 审核用车 -->
    <el-drawer
      :title="drawerTitle"
      :visible.sync="isDrawerVisible"
      :direction="'rtl'"
      :show-close="false"
      :wrapperClosable="false"
      :with-header="false"
      :size="drawerWidth"
    >
      <div style="padding: 20px; background-color: #f5f5f5">
        <div style="display: flex; padding: 0px 10px 20px 0px">
          <div
            style="
              flex: 1;
              border-bottom: 1px solid #085;
              text-align: left;
              padding-bottom: 14px;
            "
          >
            <span
              style="
                border-bottom: 6px solid #085;
                padding-bottom: 10px;
                margin-left: 15px;
                font-weight: 600;
              "
              >申请用车审核</span
            >
          </div>
          <div style="cursor: pointer" @click="isDrawerVisible = false">
            <i class="el-icon-close" style="font-size: 18px"></i>
          </div>
        </div>
        <el-form class="myform" label-width="90px" label-position="left">
          <div
            style="
              padding: 10px;
              align-items: center;
              background-color: #fff;
              min-height: 100px;
              margin-bottom: 10px;
            "
          >
            <div class="flex" style="padding-bottom: 5px; align-items: center">
              <el-input
                v-model="mFormData.start_address"
                placeholder="出发地"
                readonly
              ></el-input>

              <img
                src="../../../assets/imgs/gongwu/goto.png"
                alt=""
                @click="huzhuan"
                style="height: 30px; cursor: pointer"
                srcset=""
              />

              <el-input
                v-model="mFormData.end_address"
                placeholder="目的地"
                readonly
              ></el-input>
            </div>
            <div class="flex" style="flex-wrap: wrap">
              <div
                style="
                  color: #fff;
                  font-size: 12px;
                  cursor: pointer;
                  background-color: #808080;
                  margin: 5px;
                  padding: 5px;
                  border-radius: 5px;
                "
                v-for="item in changyong"
                :key="item"
                @click="chooseAddress(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>

          <el-form-item label="选择车辆">
            <div
              class="gongjiao"
              style="
                display: flex;
                flex-direction: column;
                text-align: left;
                padding: 10px;
                background-color: #fff;
                height: 150px;
                overflow-y: auto;
                overflow-x: hidden;
              "
            >
              <el-alert
                title="应急车 左侧车辆运行 找到对应的车牌指派司机"
                type="warning"
                style="margin-bottom: 10px"
              >
              </el-alert>
              <el-radio-group v-model="mFormData.vehicle_id ">
                <el-radio
                  style="flex: 1; margin-bottom: 10px"
                  :label="item.id"
                  v-for="(item, i) in vehicles"
                  :key="'ve_' + i"
                  >{{ i + 1 }}-{{ item.license_plate }}（{{ item.type }}|{{
                    item.seats
                  }}座）-<span
                    style="color: #409eff; cursor: pointer"
                    @click="searchBook(item.user_name)"
                    v-if="item.user_name.indexOf('应急车') == -1"
                    >{{ item.user_name || "应急车" }}
                  </span>
                  <el-tooltip
                    v-else
                    class="item"
                    effect="dark"
                    content="左侧车辆运行 找到对应的车牌指派司机"
                    placement="bottom-start"
                  >
                    <span style="color: #f00; cursor: pointer">
                      {{ item.user_name || "应急车" }}
                    </span>
                  </el-tooltip>
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="用车类型">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                padding-top: 15px;
              "
              class="gongjiao"
            >
              <el-radio v-model="mFormData.use_type" label="内部用车" disabled
                >内部用车</el-radio
              >
              <el-radio v-model="mFormData.use_type" label="外部用车" disabled
                >外部用车</el-radio
              >
            </div>
          </el-form-item>
          <el-form-item label="用车人">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                text-decoration: underline;
                cursor: pointer;
                color: #409eff;
              "
              @click="searchBook(mFormData.user_name)"
            >
              {{ mFormData.user_name }}
            </div>
          </el-form-item>
          <el-form-item label="乘车人数">
            <div style="display: flex; justify-content: flex-start">
              <el-input-number
                disabled
                v-model="mFormData.capacity"
                :min="1"
              ></el-input-number>
            </div>
          </el-form-item>
          <el-form-item label="用车事由">
            <el-input
              type="textarea"
              v-model="mFormData.reason"
              placeholder="请输入用车事由"
              readonly
            >
            </el-input>
          </el-form-item>
          <el-form-item label="出发时间">
            <div style="display: flex; justify-content: flex-start">
              <el-date-picker
                v-model="mFormData.start_time"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="是否可搭乘">
            <div
              class="gongjiao"
              style="
                display: flex;
                justify-content: flex-start;
                padding-top: 10px;
              "
            >
              <el-switch
                v-model="mFormData.carpool"
                :active-value="'是'"
                :inactive-value="'否'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item>
            <div style="display: flex" class="gongjiao">
              <el-button
                type="success"
                style="margin-left: auto"
                @click="submitApplication"
                >同意</el-button
              >
              <el-button type="default" @click="cancelApplication"
                >驳回</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
let tableBookingApplication = "xtbg_officecar_application";
export default {
  data() {
    return {
      activeName: "first",

      isDrawerDcVisible: false,
      total_capacity: 0,
      selected_row: {},
      logDcHistory: [], //搭乘历史
      currentPage3: 1,
      total3: 0,
      pageSize3: 8,

      showHistory: false,
      applicationHistory: [], //申请历史
      currentPage2: 1,
      total2: 0,
      pageSize2: 8,

      changyong: [
        "昆明--版纳",
        "版纳--昆明",
        "曲靖--昆明",
        "昆明--曲靖",
        "版纳--普洱",
        "版纳--玉溪",
        "版纳--大理",
        "版纳--楚雄",
        "版纳--文山",
        "版纳--红河",
      ],

      isDrawerVisible: false,
      drawerWidth: "25%",
      drawerTitle: "申请用车",
      mFormData: {
        start_address: "",
        end_address: "",
        capacity: 1,
        carpool: "是",
        reason: "",
        start_time: "",
        status: 0,
        vehicle_id: 0,
        use_type: "内部用车",
      },
      nFormData: {
        start_address: "",
        end_address: "",
        capacity: 1,
        carpool: "是",
        reason: "",
        start_time: "",
        status: 0,
        use_type: "内部用车",
      },
      searchStartAddress: "",
      searchEndAddress: "",
      searchUser: "",
      dialogVisible: false,
      bookings: [],

      vehicles: [],
      currentBooking: {
        vehicle_id: "",
        start_address: "",
        end_address: "",
        start_time: "",
        end_time: "",
        carpool: "",
        capacity: "",
        other_seats: "",
        reason: "",
        status: "",
      },

      currentPage: 1,
      total: 0,
      pageSize: 8, // 每页显示的数据量
    };
  },

  watch: {
    "nFormData.capacity": {
      handler: function (val) {
        if (val > this.total_capacity) {
          this.$message({
            message: "搭乘人数超出了，最多可搭乘人数" + this.total_capacity,
            type: "error",
            duration: 2000,
          });
        }
      },
      deep: true,
    },
    isDrawerVisible(val) {
      if (val) {
        // this.mFormData.start_time = this.$FormatDateToYMDHMS(new Date(), true);

        this.getVihicleinos();
      }
    },
    showHistory(val) {
      if (val) {
        this.getApplicationHistorys();
      }
    },
    searchStartAddress(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    },
    searchEndAddress(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    },
    searchUser(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    },
  },

  methods: {
    handleTabClick() {
      if (this.activeName == "first") {
        this.getApplicationHistorys();
      } else {
        this.getLogDcHistory();
      }
    },
    async searchApplications() {
      //SELECT * FROM xtbg_officecar_application WHERE start_address like '%昆明%' AND end_address LIKE '%%' AND create_by in (SELECT id FROM xtbg_user WHERE name like '%刘%') AND status=1
      this.currentPage = 1;
      this.bookings = [];
      let _tableName = tableBookingApplication + ` a`;
      let _sfd = `a.*,CONCAT(a.start_address,'-',a.end_address) as xingcheng,
        (SELECT CONCAT(v.license_plate,'(',v.type,')',
        IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'应急车')) FROM xtbg_officecar_vihicleinfo v WHERE v.id=a.vehicle_id) as carinfo,
        (SELECT name FROM xtbg_user WHERE id=a.create_by) as user_name,
        (SELECT (seats-1)-(IFNULL((SELECT capacity FROM xtbg_officecar_application WHERE id=a.id),0))-(IFNULL((SELECT SUM(capacity) FROM xtbg_officecar_log WHERE application_id=a.id),0)) as seats FROM xtbg_officecar_vihicleinfo WHERE id=a.vehicle_id) as oseats`;
      let _where =
        `start_address like '%${this.searchStartAddress}%' 
            AND end_address LIKE '%${this.searchEndAddress}%' 
            AND create_by in (SELECT id FROM xtbg_user WHERE name like '%${this.searchUser}%') 
            AND status=0 AND a.vehicle_id is not NULL ORDER BY id DESC LIMIT ${this.pageSize} offset ` +
        (this.currentPage - 1) * this.pageSize;

      //console.log("select "+_sfd+" from "+ _tableName+" where "+_where);
      const dataRes = await this.$db_select(
        this.$http,
        _tableName,
        _sfd,
        _where
      );

      const data = dataRes.length > 0 ? dataRes : [];
      let index = 0;
      this.bookings = data.map((item) => {
        item.uid = ++index;
        item.cstatus = "待发车";
        item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
        return item;
      });
      const totalRes = await this.$db_select(
        this.$http,
        tableBookingApplication,
        "count(*) as total",
        _where.split("ORDER BY")[0]
      );
      this.total = totalRes.length > 0 ? totalRes[0].total : 0;
    },
    async getVihicleinos() {
      const data = await this.$db_select(
        this.$http,
        "xtbg_officecar_vihicleinfo v",
        "id,seats,license_plate,type,IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'应急车') as user_name",
        "status=0"
      );
      this.vehicles = data;
    },
    chooseAddress(item) {
      this.mFormData.start_address = item.split("--")[0];
      this.mFormData.end_address = item.split("--")[1];
    },
    //始发地和目的地 互换
    huzhuan() {
      let temp = this.mFormData.start_address;
      this.mFormData.start_address = this.mFormData.end_address;
      this.mFormData.end_address = temp;
    },
    //打开申请用车
    editBooking(row) {
      this.selected_row = row;
      console.log(this.selected_row.create_by);

      for (let key in this.mFormData) {
        this.mFormData[key] = row[key];
      }
      this.mFormData.vehicle_id = row.vehicle_id;
      this.mFormData.user_name = row.user_name;

      //mFormData.start_time = this.mFormData.

      //this.$set(this.mFormData,"start_time",this.$FormatDateToYMDHMS(row.start_time,true));

      this.isDrawerVisible = true;
    },
    //驳回
    async cancelApplication() {
      const dataRes = await this.$db_update(
        this.$http,
        tableBookingApplication,
        { status: 2 },
        "id=" + this.selected_row.id
      );

      const phoneRes = await this.$GetUserPhone(
        this.$http,
        this.selected_row.create_by
      );

      if (dataRes.affectedRows > 0) {
        if (phoneRes.length > 0) {
          this.$SendMSG(phoneRes[0].phone, "2275755");
        }
        this.$message({
          message: "驳回用车申请",
          type: "success",
          duration: 2000,
        });
      }

      this.isDrawerVisible = false;
    },
    //同意用车
    async submitApplication() {
      //去修改
      let form = {
        vehicle_id: this.mFormData.vehicle_id,
        status: 1,
        start_time: this.$FormatDateToYMDHMS(this.mFormData.start_time, true),
      };
     

      const dataRes = await this.$db_update(
        this.$http,
        tableBookingApplication,
        form,
        "id=" + this.selected_row.id
      );
      //并且 修改 车辆状态
      const ckRes = await this.$db_update(
        this.$http,
        "xtbg_officecar_vihicleinfo",
        { status: 1 },
        "id=" + this.mFormData.vehicle_id
      );

      const phoneRes = await this.$db_select(this.$http,"xtbg_user","phone","id="+this.selected_row.create_by);
        
      

      if (dataRes.affectedRows > 0) {
        
        if (phoneRes.length > 0) {
          await this.$SendMSG(phoneRes[0].phone, "2275754");
        }

        //发送短信通知审核通过
        this.$message({
          message: "成功同意用车",
          type: "success",
          duration: 2000,
        });
        this.getBookings();
      } else {
        //发送短信通知审核通过
        if (phoneRes.length > 0) {
          await this.$SendMSG(phoneRes[0].phone, "2275755");
        }
        this.$message({
          message: "同意用车失败",
          type: "error",
          duration: 2000,
        });
      }

      this.isDrawerVisible = false;
    },
    saveDraft() {
      // 保存草稿逻辑
      console.log("保存草稿", this.formData);
      this.isDrawerVisible = false;
    },
    //判断是否是自己的申请用车记录
    isSelfApplication() {
      return this.currentBooking.create_by == localStorage.getItem("userid");
    },
    searchBookings() {
      // 搜索逻辑
    },
    //搭乘

    lookApplications() {},
    //获取 公务车信息 需要审核的
    async getBookings() {
      // 获取公务车信息
      // 假设数据为 bookings
      const data = await this.$db_select(
        this.$http,
        tableBookingApplication + " a ",
        `a.*,CONCAT(a.start_address,'-',a.end_address) as xingcheng,
                (SELECT CONCAT(v.license_plate,'(',v.type,')-',IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'待定')) FROM xtbg_officecar_vihicleinfo v WHERE v.id=a.vehicle_id) as carinfo, 
                (SELECT name FROM xtbg_user WHERE id=a.create_by) as user_name,
                (SELECT (seats-1)-(IFNULL((SELECT capacity FROM xtbg_officecar_application WHERE id=a.id),0))-(IFNULL((SELECT SUM(capacity) FROM xtbg_officecar_log WHERE application_id=a.id),0)) as seats FROM xtbg_officecar_vihicleinfo WHERE id=a.vehicle_id) as oseats`,
        "a.status=0 and a.vehicle_id is not NULL order by id desc limit " +
          this.pageSize +
          " offset " +
          (this.currentPage - 1) * this.pageSize
      );

      this.total = (
        await this.$db_select(
          this.$http,
          tableBookingApplication,
          "count(*) as total",
          "status=1"
        )
      )[0].total;
      let index = 0;
      this.$nextTick(() => {
        this.bookings = data.map((item) => {
          item.uid = ++index;
          item.cstatus = "待发车";
          item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
          return item;
        });
        //console.log(this.bookings);
      });
    },
    // 翻页
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getBookings();
    },
    searchBook(username) {
      this.$router.push({
        path: "/books",
        query: {
          name: username,
        },
      });
    },
  },
  mounted() {
    this.getBookings();
  },
};
</script>

<style scoped>
/* 添加样式 */
</style>
