<template>
  <div class="PastSsession-parent-component">
    <div
      ref="barChart"
      :style="{ width: chartWidth, height: chartHeight }"
    ></div>
  </div>
</template>

<script>
// npm install echarts => 获取echarts
import * as echarts from "echarts";
export default {
  props: {
    Statistics: Object,
    chartWidth: {
      type: String,
      default: "100%",
    },
    chartHeight: {
      type: String,
      default: "90%",
    },
    categories: {
      type: Array,
      default: function(){
        return ["单位建设", "民主管理", "职工福利", "其他方面"]
      },
    },
  },
  watch: {
    // 监听父组件传递的对象数据变化
    Statistics: {
      handler(newVal) {
        this.updata(newVal);
      },
      deep: true, // 深度监听对象内部数据的变化
    },
  },
  data() {
    return {
      chartData: {
        data: [], // 数据
      },
      colors: ["#0074FF", "#4CB050", "#FF9292", "#71E2A3"], // 自定义颜色数组
      chart: null, // 保存图表实例的引用
      screenWidth: window.innerWidth, // 保存当前窗口宽度
      
    };
  },
  created() {
    // 将 Statistics 中的 UnitConstruction、DemocracyControl、EmployeeBenefits 和 Miscellaneous 放入 chartData 中的 data 数组中
    this.chartData.data.push(this.Statistics.UnitConstruction);
    this.chartData.data.push(this.Statistics.DemocracyControl);
    this.chartData.data.push(this.Statistics.EmployeeBenefits);
    this.chartData.data.push(this.Statistics.Miscellaneous);
  },
  computed: {
    // 计算百分比数据
    percentData() {
      const total = this.chartData.data.reduce((acc, cur) => acc + cur, 0);
      return this.chartData.data.map((value) =>
        ((value / total) * 100).toFixed(1)
      );
    },
  },
  methods: {
    // 初始化图表
    initChart() {
      // 初始化 ECharts 实例
      this.chart = echarts.init(this.$refs.barChart);
      // 指定图表的配置项和数据
      const option = {
        tooltip: {
          formatter: "{b}: {c}%",
        },
        xAxis: {
          data: this.categories,
        },
        yAxis: {
            min:0,
            max:60,
          axisLabel: {
            // 设置竖轴标签的格式
            formatter: "{value}", // 格式化数值为百分比形式
          },
          interval: 20, // 设置竖轴的间隔为20
        },
        grid: {
          left: "8%", // left padding
          right: "6%", // right padding
          bottom: "20%", // bottom padding
          top: "5%",
        },
        series: [
          {
            type: "bar",
            barWidth: "40%",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(244, 244, 244, 1)",
              borderRadius: 5,
            },
            data: this.percentData, // 使用百分比数据
            label: {
              show: true,
              position: "center",
              formatter: "{c}%", // 标签格式，显示百分比
            },
            itemStyle: {
              barBorderRadius: 2,
              borderColor: "transparent",
              borderWidth: 8,
              color: function (params) {
                return this.colors[params.dataIndex]; // 根据数据索引设置柱子颜色
              }.bind(this),
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表
      this.chart.setOption(option);
    },
    // 更新图表宽度
    updateChartWidth() {
      const newScreenWidth = window.innerWidth;
      if (newScreenWidth !== this.screenWidth) {
        this.screenWidth = newScreenWidth;
        this.chart.resize();
      }
    },
    updata() {
      // 将 Statistics 中的 UnitConstruction、DemocracyControl、EmployeeBenefits 和 Miscellaneous 放入 chartData 中的 data 数组中
      this.chartData.data = [];
      this.chartData.data.push(this.Statistics.UnitConstruction);
      this.chartData.data.push(this.Statistics.DemocracyControl);
      this.chartData.data.push(this.Statistics.EmployeeBenefits);
      this.chartData.data.push(this.Statistics.Miscellaneous);
      this.initChart();
    },
  },
  mounted() {
    this.initChart(); // 初始化图表
    // 监听窗口大小变化，更新图表宽度
    window.addEventListener("resize", this.updateChartWidth);
  },
  beforeDestroy() {
    // 在组件销毁前移除 resize 事件监听
    window.removeEventListener("resize", this.updateChartWidth);
    // 销毁图表实例
    if (this.chart) {
      this.chart.dispose();
    }
  },
};
</script>
<style scoped>
.PastSsession-parent-component {
  width: 100%;
  height: 100%;
}
</style>
