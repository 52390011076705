<template>
  <div class="detail-page">
    <div class="detail-content">
      <!-- <el-image :src="imageSrc" lazy alt="item image" :preview-src-list="[imageSrc]" :fit="contain" class="detail-image"/> -->
      <el-image
        :src="firstImageSrc"
        lazy
        alt="item image"
        :preview-src-list="imageSrcArray"
        fit="contain"
        class="detail-image"
      />
      <div class="detail-info">
        <h2>{{ title }}</h2>
        <div class="info-row">
          <strong>&emsp;&emsp;型号：</strong> {{ model }}
        </div>
        <div class="info-row">
          <strong>英文名称：</strong> {{ englishName }}
        </div>
        <div class="info-row">
          <strong>购置时间：</strong>
          {{ $FormatDateToYMDHMS(purchaseDate, false) }}
        </div>
        <div class="info-row"><strong>&emsp;仪器ID：</strong> {{ id }}</div>
        <div class="info-row">
          <strong>制造厂商：</strong> {{ manufacturer }}
        </div>
        <div class="info-row">
          <strong>功能与用途：</strong> {{ functions }}
        </div>

        <div class="info-row"><strong>管理部门：</strong> {{ department }}</div>
        <div class="info-row"><strong>存放地点：</strong> {{ location }}</div>
        <div class="info-row">
          <strong>管理员及联系方式：</strong> {{ manager }} ({{ contact }})
        </div>
        <div class="flex">
          <div class="info-row"><strong>附件信息：</strong></div>
          <div class="flex1 flex" style="flex-direction: column">
            <a
              v-for="item in fileList"
              :key="item"
              :href="item.url"
              v-show="pdfLink"
              target="_blank"
              >{{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "DetailPage",
  props: {
    imageSrc: String,
    title: String,
    model: String,
    englishName: String,
    purchaseDate: String,
    id: String,
    manufacturer: String,
    functions: String,
    performance: String,
    department: String,
    location: String,
    manager: String,
    contact: String,
    pdfLink: String,
  },
  computed: {
    imageSrcArray() {
      // 将 imageSrc 按逗号分隔成数组，并移除空白字符
      return this.imageSrc
        ? this.imageSrc.split(",").map((src) => src.trim())
        : [];
    },
    firstImageSrc() {
      // 获取第一张图片的 URL，如果数组为空则返回空字符串
      return this.imageSrcArray.length > 0 ? this.imageSrcArray[0] : "";
    },
    fileList() {
      if (this.pdfLink) {
        const urls = this.pdfLink.split(",");
        const filenames = urls.map((url) => {
          const parts = url.split("/"); // 按斜杠分割URL
          return {
            name: parts[parts.length - 1],
            url: url,
          }; // 返回最后一个部分，即文件名
        });
        return filenames;
      }else{
        return [];
      }

     
    },
  },
};
</script>
  
  <style scoped>
.detail-page {
  display: flex;

  font-size: 14px;
}

.detail-content {
  display: flex;

  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: calc(100% - 10px);
  text-align: left;
}

.detail-image {
  width: 50%;
  height: 400px;
  margin-right: 20px;
  border-radius: 8px;
}

.detail-info {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.detail-info h2 {
  margin-top: 0;
  font-size: 14px;
}

.info-row {
  min-height: 30px;
  line-height: 30px;
  margin: 5px 0;
  font-size: 14px;
}

.info-row strong {
  color: #a6a6a6;
  font-weight: normal;
}

.detail-info a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.detail-info a:hover {
  text-decoration: underline;
}
</style>
  