
<template>
  <div class="main_taxt flex-col padding-top-10">
    <!-- 搜索栏目 -->
    <div class="flex flex-space-between" style="padding-bottom: 10px">
      <div class="flex">
        <el-button
          icon="el-icon-plus"
          @click="startPropose"
          style="margin-left: 5px; background: #25834b; color: #fff"
          >提案</el-button
        >
        <!-- <el-button
          icon="el-icon-plus"
          @click="startSendSms"
          style="margin-left: 5px; background: #25834b; color: #fff"
          >发送短信</el-button
        >
        <el-input type="text" v-model="text_phone"></el-input> -->
      </div>

      <div class="flex">
        <div class="height-38 line-height-38 margin-left-right-5">年份</div>
        <div>
          <el-select
            v-model="select_year"
            placeholder="请选年份"
            clearable
            @change="changeYear"
          >
            <el-option
              v-for="item in option_years"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="height-40 line-height-40 margin-left-right-5">类别</div>
        <div>
          <el-select
            v-model="select_type"
            clearable
            placeholder="请选择类别"
            @change="changeType"
          >
            <el-option
              v-for="item in option_types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="height-40 line-height-40 margin-left-right-5">状态</div> -->
        <!-- <div>
        <el-select v-model="select_status" placeholder="请选择状态">
          <el-option
            v-for="item in option_status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
        <div>
          <el-button
            icon="el-icon-refresh"
            @click="realodData"
            style="margin-left: 5px; background: #25834b; color: #fff"
            >重载</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表格列表 -->

    <div class="main_taxt_table">
      <dynamic-data-table
        ref="dynamicDatatable"
        :fetchData="fetchData"
        :pageSize="pageSize"
      >
        <template v-slot:actions="scope">
          <el-button
            @click="handleViewClick('查看', scope.row)"
            type="text"
            size="small"
            >查看</el-button
          >
          <el-button
            v-show="
              scope.row.status == '待提交' ||
              scope.row.status == '待附议' ||
              scope.row.status == '待审查' ||
              scope.row.status == '退回重提'
            "
            @click="handleViewClick('修改', scope.row)"
            type="text"
            size="small"
            style="color: #ff0000"
            >修改</el-button
          >
          <el-button
            v-show="scope.row.status == '待提交'"
            @click="handleViewClick('提交', scope.row)"
            type="text"
            size="small"
            style="color: #008500"
            >提交</el-button
          >
          <el-button
            v-show="
              scope.row.status == '已处理' || scope.row.status == '转意见处理'
            "
            @click="handleViewClick('反馈', scope.row)"
            type="text"
            size="small"
            style="color: #008500"
            >反馈</el-button
          >
          <el-button
            v-show="scope.row.status == '待提交'"
            @click="handleViewClick('删除', scope.row)"
            type="text"
            size="small"
            style="color: #f39d1b"
            >删除</el-button
          >

          <el-button
            @click="handleViewClick('流程状态', scope.row)"
            type="text"
            size="small"
            style="color: #4e4e4e"
          >
            流程状态
          </el-button>

          <el-button
            @click="handleViewClick('导出', scope.row)"
            type="text"
            size="small"
            style="color: #008500"
            >导出</el-button
          >
        </template>
      </dynamic-data-table>
    </div>

    <!-- 流程状态对话框 -->
    <el-dialog
      title="当前流程状态"
      top="20vh"
      :visible.sync="dialogFlowVisible"
      :close-on-click-modal="false"
    >
      <proposal-status-timeline
        :status-steps="proposalSteps"
        :current-process-type="currentProcessType"
      />

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFlowVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dbquery, getDataTotal } from "./sys/proposeRoleUser.js";
import DynamicDataTable from "../../components/commom/dynamicDataTable.vue";
import ProposalStatusTimeline from "../../components/commom/ProposalStatusTimeline.vue";

import {
  getCurrentDate,
  formatDate,
  encryptData,
  objectToSqlSet,
  formatDateForYMD,
  replaceEmptyValuesWithEmptyString,
} from "../../utils/common.js";
import { exportToWordFromStatic } from "../../utils/exportToWord.js";
import { toUint8Array } from "js-base64";

const propose_table = "xtbg_congress_propose";
export default {
  components: {
    DynamicDataTable,
    ProposalStatusTimeline,
  },
  data() {
    return {
      text_phone: "",
      dialogFlowVisible: false,
      // 提案的状态步骤数据
      proposalSteps: [
        // {
        //   status: "待提交",
        //   user: "用户A",
        //   timestamp: "2024-03-10 08:00",
        //   color: "grey",
        // },
        // {
        //   status: "待附议",
        //   user: "用户B",
        //   timestamp: "2024-03-11 09:30",
        //   color: "blue",
        // },
        // {
        //   status: "待审查",
        //   user: "用户C",
        //   timestamp: "2024-03-12 10:00",
        //   color: "green",
        // },
        // 根据提案的实际状态，可能会包含更多的步骤
      ],
      // 当前提案的流程类型
      currentProcessType: "normal",

      select_year: "",
      select_type: "",
      select_status: "",
      tableData: [],
      current_year: "",
      option_types: [
        {
          value: "单位建设",
          label: "单位建设",
        },
        {
          value: "民主管理",
          label: "民主管理",
        },
        {
          value: "职工福利",
          label: "职工福利",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      option_status: [
        {
          value: "待审核",
          label: "待审核",
        },
        {
          value: "立案",
          label: "立案",
        },
        {
          value: "退回重提",
          label: "退回重提",
        },
        {
          value: "不予立案",
          label: "不予立案",
        },
        {
          value: "转意见处理",
          label: "转意见处理",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      option_years: [],
      total: 0,
      pageSize: 10,
      propseID: "",
      formData: {},
      querySqlStr: "",
      querySelectSqlStr: "",
      page: 1,
      templateFile: "../config/xtbgta.docx",
      mywhere: "",
    };
  },
  async created() {
    this.current_year = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      let myyear = this.current_year - i;
      this.option_years.push({
        value: myyear,
        label: myyear + "年",
      });
    }
  },

  methods: {
    async startSendSms() {
     
    },
    async dbquery(sqlStr) {
      return dbquery(this.$http, sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },

    async getDataTrueTotal(where) {
      const sqlStr =
        "select count(*) as total from " + propose_table + " where " + where;

      const res = await this.$dbquery(this.$http, sqlStr);

      if (res[0]) {
        return res[0].total;
      } else {
        return 0;
      }
    },
    realodData() {
      this.select_type = "";
      this.select_year = "";
      this.querySelectSqlStr = "";

      this.$refs.dynamicDatatable.loadData();
    },
    changeYear() {
      this.querySelectSqlStr =
        "select * from " +
        propose_table +
        " where proposal_date like '" +
        this.select_year +
        "%' and proposer_userid=" +
        localStorage.getItem("userid") +
        " order by id desc LIMIT " +
        this.pageSize +
        " OFFSET " +
        (this.page - 1) * this.pageSize;
      this.$refs.dynamicDatatable.loadData();
    },
    changeType() {
      this.querySelectSqlStr =
        "select * from " +
        propose_table +
        " where proposal_date like '" +
        this.select_year +
        "%' and type = '" +
        this.select_type +
        "' and proposer_userid=" +
        localStorage.getItem("userid") +
        " order by id desc LIMIT " +
        this.pageSize +
        " OFFSET " +
        (this.page - 1) * this.pageSize;
      this.$refs.dynamicDatatable.loadData();
    },

    async updatePropose(row, status, bool = false) {
      this.propseID = row.id;

      this.formData["status"] = status;
      let myNewFormData = objectToSqlSet(this.formData);

      // 保存 修改
      const updateProposeSql =
        "update " +
        propose_table +
        " set " +
        myNewFormData +
        " where id=" +
        this.propseID;
      const res = await this.dbquery(updateProposeSql);
      if (bool) {
        if (res.affectedRows > 0) {
          this.$message({
            showClose: true,
            message: "提交成功",
            type: "success",
            duration: 1000,
            offset: 200,
          });
        } else {
          this.$message({
            showClose: true,
            message: "提交失败",
            type: "fail",
            duration: 1000,
            offset: 200,
          });
        }
      }

      this.$refs.dynamicDatatable.loadData();
    },
    async getUserNameByUserid(usesrid) {
      if (usesrid) {
        const userSql = "select name from xtbg_user where id=" + usesrid;
        const res = await this.$dbquery(this.$http, userSql);
        if (res[0]) {
          return res[0].name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    //表格操作
    async handleViewClick(operate, row) {

     
      switch (operate) {
        case "查看":
          this.$router.push({
            path: "/proposal_meetting/propose_view",
            query: {
              id: encryptData(row.id + ""),
              from: "my",
            },
          });
          break;
        case "修改":
          if (
            row.status == "待附议" ||
            row.status == "待审查" ||
            row.status == "退回重提"
          ) {
            // this.updatePropose(row, "待提交");
            this.$router.push({
              path: "/proposal_meetting/propose_update",
              query: {
                id: encryptData(row.id + ""),
                status: row.status,
              },
            });

            return;
          }
          if (row.status == "待提交") {
            this.$router.push({
              path: "/proposal_meetting/propose_update",
              query: {
                id: encryptData(row.id + ""),
              },
            });
          }

          break;
        case "提交":
          if (row.status != "待提交") {
            this.$message({
              showClose: true,
              message: "此提案已提交过了",
              type: "fail",
              duration: 1000,
              offset: 200,
            });
            return;
          }

          this.$confirm("是否提交该提案?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.updatePropose(row, "待附议", true);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
                offset: 200,
              });
            });

          break;
        case "反馈":
          this.$router.push({
            path: "/proposal_meetting/fk",
            query: {
              id: encryptData(row.id + ""),
            },
          });
          break;
        case "删除":
          this.$confirm("是否删除该提案?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.deletePropose(row.id);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
                offset: 200,
              });
            });
          break;
        case "导出":
          console.log(row);
          let export_row = row;
          export_row.proposal_date = formatDateForYMD(export_row.proposal_date);
          if (export_row.reply_time) {
            export_row.reply_time = formatDateForYMD(export_row.reply_time);
          }
          if (export_row.t_date) {
            export_row.t_date = formatDateForYMD(export_row.t_date);
          }
          if (export_row.d_head_date) {
            export_row.d_head_date = formatDateForYMD(export_row.d_head_date);
          }
          if (export_row.l_sign_date) {
            export_row.l_sign_date = formatDateForYMD(export_row.l_sign_date);
          }
          if (export_row.p_f_sign_date) {
            export_row.p_f_sign_date = formatDateForYMD(
              export_row.p_f_sign_date
            );
          }
          export_row = replaceEmptyValuesWithEmptyString(export_row);

          //类型 处理
          switch (export_row.type) {
            case "单位建设":
              export_row["type1"] = "☑";
              export_row["type2"] = "☐";
              export_row["type3"] = "☐";
              export_row["type4"] = "☐";
              break;
            case "民主管理":
              export_row["type2"] = "☑";
              export_row["type1"] = "☐";
              export_row["type3"] = "☐";
              export_row["type4"] = "☐";

              break;
            case "职工福利":
              export_row["type3"] = "☑";
              export_row["type1"] = "☐";
              export_row["type2"] = "☐";
              export_row["type4"] = "☐";
              break;
            case "其他":
              export_row["type4"] = "☑";
              export_row["type1"] = "☐";
              export_row["type2"] = "☐";
              export_row["type3"] = "☐";

              break;
            default:
              export_row["type1"] = "☐";
              export_row["type2"] = "☐";
              export_row["type3"] = "☐";
              export_row["type4"] = "☐";
              break;
          }
          // 提案组 审查结果
          switch (export_row.t_result) {
            case "立案":
              export_row["t_result1"] = "✔";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
            case "退回重提":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "✔";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
            case "不予立案":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "✔";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
            case "转意见处理":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "✔";
              export_row["t_result5"] = "";
              break;
            case "其他":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "✔";
              break;
            default:
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
          }
          // 提案人返回结果
          switch (export_row.p_feedback) {
            case "满意":
              export_row["p_fd1"] = "☑";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☐";
              break;
            case "基本满意":
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☑";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☐";
              break;
            case "一般":
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☑";
              export_row["p_fd4"] = "☐";
              break;
            case "不满意":
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☑";

              break;
            default:
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☐";
              break;
          }
          //导出 wordd 提案
          export_row["propose_name"] = this.$congresConfig.propose_title;
          exportToWordFromStatic(
            this.templateFile,
            export_row,
            export_row.title + ".docx"
          );
          break;
        case "流程状态":
          this.dialogFlowVisible = true;
          this.proposalSteps = [];
          if (row.t_result) {
            switch (row.t_result) {
              case "立案":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }

                if (row.l_sign) {
                  let status_dsp = {
                    status: "已审批",
                    user: row.l_sign,
                    timestamp: formatDateForYMD(row.l_sign_date),
                  };
                  this.proposalSteps.push(status_dsp);
                }

                if (row.d_head_sign) {
                  let status_ycl = {
                    status: "已处理",
                    user: row.d_head_sign,
                    timestamp: formatDateForYMD(row.d_head_date),
                  };
                  this.proposalSteps.push(status_ycl);
                }

                if (row.p_f_sign) {
                  let status_yja = {
                    status: "已结案",
                    user: row.p_f_sign,
                    timestamp: formatDateForYMD(row.p_f_sign_date),
                  };
                  this.proposalSteps.push(status_yja);
                }

                this.currentProcessType = "normal";
                break;
              case "退回重提":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.t_userid) {
                  let status_dsc = {
                    status: "退回重提",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }

                this.currentProcessType = "resubmit";
                break;
              case "不予立案":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.t_userid) {
                  let status_dsc = {
                    status: "不予立案",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }

                this.currentProcessType = "rejected";
                break;
              case "转意见处理":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.t_userid) {
                  let status_dsc = {
                    status: "转意见处理",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.p_f_sign) {
                  let status_yja = {
                    status: "已结案",
                    user: row.p_f_sign,
                    timestamp: formatDateForYMD(row.p_f_sign_date),
                  };
                  this.proposalSteps.push(status_yja);
                }
                this.currentProcessType = "opinion";
                break;
            }
          } else {
            if (row.proposer) {
              let status_dtj = {
                status: "提案",
                user: row.proposer,
                timestamp: formatDateForYMD(row.proposal_date),
              };
              this.proposalSteps.push(status_dtj);
            }

            if (row.supporters) {
              let status_dfy = {
                status: "已附议",
                user: row.supporters,
                timestamp: formatDateForYMD(row.s_time),
              };
              this.proposalSteps.push(status_dfy);
            }
          }

          break;
      }
    },
    async deletePropose(id) {
      const delSqlStr = "delete from " + propose_table + " where id=" + id;
      const res = await this.dbquery(delSqlStr);

      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
          duration: 1000,
          offset: 200,
        });
      } else {
        this.$message({
          showClose: true,
          message: "删除失败",
          type: "fail",
          duration: 1000,
          offset: 200,
        });
      }
      this.$refs.dynamicDatatable.loadData();
    },

    async fetchData({ page, pageSize }) {
      this.page = page;
      // if (this.querySqlStr.length == 0) {
      //   this.querySqlStr =
      //     "select * from " +
      //     propose_table +
      //     " where proposer_userid=" +
      //     localStorage.getItem("userid") +
      //     " order by id desc LIMIT " +
      //     pageSize +
      //     " OFFSET " +
      //     (page - 1) * pageSize;
      // }
      let myProposeData = [];
      if (this.querySelectSqlStr == "") {
        this.querySqlStr =
          "select * from " +
          propose_table +
          " where proposer_userid=" +
          localStorage.getItem("userid") +
          " order by id desc LIMIT " +
          pageSize +
          " OFFSET " +
          (page - 1) * pageSize;
        this.mywhere = " proposer_userid=" + localStorage.getItem("userid");
        myProposeData = await this.dbquery(this.querySqlStr);
      } else {
        if (this.select_year != "") {
          this.querySelectSqlStr =
            "select * from " +
            propose_table +
            " where proposal_date like '" +
            this.select_year +
            "%' and proposer_userid=" +
            localStorage.getItem("userid") +
            " order by id desc LIMIT " +
            pageSize +
            " OFFSET " +
            (page - 1) * pageSize;
          this.mywhere =
            " proposal_date like '" +
            this.select_year +
            "%' and proposer_userid=" +
            localStorage.getItem("userid");
        }
        if (this.select_type != "") {
          this.querySelectSqlStr =
            "select * from " +
            propose_table +
            " where proposal_date like '" +
            this.select_year +
            "%' and type = '" +
            this.select_type +
            "' and proposer_userid=" +
            localStorage.getItem("userid") +
            " order by id desc LIMIT " +
            pageSize +
            " OFFSET " +
            (page - 1) * pageSize;
          this.mywhere =
            " proposal_date like '" +
            this.select_year +
            "%' and type = '" +
            this.select_type +
            "' and proposer_userid=" +
            localStorage.getItem("userid");
        }

        myProposeData = await this.dbquery(this.querySelectSqlStr);
      }

      const total = await this.getDataTrueTotal(this.mywhere);
      // console.log("数据总数：", total);

      let i = 0;
      const newData = myProposeData.map((row) => {
        i++;
        return {
          ...row,
          uid: i,
          proposal_date: formatDate(row.proposal_date),
        };
      });
      // 这里用你的API请求逻辑替换
      // 模拟API响应格式
      return {
        data: newData, // 从服务器获取的数据
        total: total, // 总条目数
        columns: [
          // { prop: "uid", label: "序号", width: "50" },
          { prop: "num", label: "编号", width: "108" },
          { prop: "type", label: "类型", width: "80" },
          { prop: "title", label: "案名", width: "300" },
          { prop: "proposer", label: "提案人", width: "70" },
          { prop: "proposal_date", label: "提案日期", width: "100" },
          { prop: "supporters", label: "附议人" },
          { prop: "status", label: "状态" },
        ],
      };
    },

    startPropose() {
      this.$router.push({
        path: "/proposal_meetting/propose_table",
      });
    },
    scrollToTop() {
      // 使用window对象的scrollTo方法
      window.scrollTo({
        top: 0, // 目标位置（顶部）
        behavior: "smooth", // 平滑滚动
      });
    },
  },
  mounted() {
    this.scrollToTop();
  },
};
</script>
