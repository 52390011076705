/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-28 17:55:37
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-10-19 13:21:03
 * @FilePath: \app_wb\src\api\content\xtbg_news.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js'
import axios from 'axios';
export function ListNews(queryParam) {
    return request('/content/news/list', {
        method: "get",
       
        params: queryParam
    });
}
export function getNewById(id) {
    return request('/content/news/' + id, {
        method: "get",
        

    });
}

//https://www.xtbg.cas.cn/tjzy/mneaw/
export function ListNewsFromGw() {
    // return request("https://www.xtbg.cas.cn/tjzy/mneaw",{
    //     method: "get",

    // });
    return new Promise(resolve => {
        axios.get("mynews/tjzy/mneaw").then(res => {
            resolve(res);
        })
    })
}

export function getNewNews() {
    return new Promise(function (resolve) {

        axios.get('https://in.xtbg.ac.cn/tjzy/mneaw/').then(res => {
            resolve(res.data);
        })
    });
}