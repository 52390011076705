<template>
  <div style="padding: 20px">
    <div
      style="
        height: 30px;
        line-height: 30px;
        text-align: right;
        padding-bottom: 20px;

      "
      v-if="isAdmin"
    >
      <el-input
        placeholder="组名/编号"
        v-model="search_txt"
        class="input-with-select"
        style="width: 50%; margin-right: 20px"
      >
      </el-input>
      <el-button icon="el-icon-plus" type="text" @click="addGroup"
        >添加</el-button
      >
    </div>
    <el-table :data="currentTableData" style="width: 100%" height="640" border>
      <el-table-column
        prop="id"
        label="研究组编号"
        width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="研究组名"
        width="300"
      ></el-table-column>
      <el-table-column
        prop="user_name"
        label="研究组主任（组长）"
        width="110"
      ></el-table-column>
      <el-table-column prop="remark" label="说明"></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            style="color: #008500"
            type="text"
            icon="el-icon-edit"
            size="mini"
            @click="handleEdit(scope.row)"
            >修 改</el-button
          >
          <el-button
            style="color: #f00"
            type="text"
            icon="el-icon-delete"
            size="mini"
            @click="handleDelete(scope.row)"
            >删 除</el-button
          >
          <el-button
            style="color: #409eff"
            type="text"
            icon="el-icon-user"
            size="mini"
            @click="handleEditUser(scope.row)"
            >研究组员</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total,prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handlePageChange"
      style="margin-top: 20px; text-align: center"
    />

    <list_users
      :visible.sync="selectUserVisible"
      :groupid="select_role_id"
    ></list_users>

    <!-- 修改页面 -->
    <el-dialog
      :title="mytitle"
      :visible.sync="AddEditVisible"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <el-form-item label="研究编号">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="研究组名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="研究组主任">
          <el-select
            v-model="form.manage_userid"
            filterable
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in useroptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin-top: 20px">
        <el-button @click="onCancel">取消</el-button>
        <el-button type="primary" @click="onSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { from } from "rxjs";
import list_users from "./components/studygroup/list_users.vue";
export default {
  components: {
    list_users,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      selectUserVisible: false,
      userAllTableData: [],
      select_role_id: 0,

      //
      AddEditVisible: false,
      form: {
        id: "",
        name: "",
        manage_userid: "",
        remark: "",
      },
      useroptions: [],
      mytitle: "",
      editorBool: false,
      search_txt: "",
      isYjzManage: false,
     
    };
  },

  computed: {
    currentTableData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.tableData.slice(start, end);
    },
    selectUserIds() {
      return this.userAllTableData.map((item) => item.id);
    },
    isAdmin(){
      if(localStorage.getItem('weihuapin_cerrent_roleid')<4){
        return true;
      }else{
        return false;
      }
    }
  },
  watch: {
    search_txt(val) {
      if (val.length >= 1) {
        this.search(val);
        return false;
      }
      this.getList();
    },
  },
  async created() {
    this.isYjzManage = await this.$judgeTrueOrFalse(
      this.$http,
      "xtbg_hazard_studies",
      "userid=" + localStorage.getItem("userid")
    );
  
    //获取用户列表
    const uerRes = await this.$dbquery(
      this.$http,
      "select id,name from xtbg_user"
    );

    this.useroptions = uerRes;

    await this.getList();
  },
  methods: {
    async search(stxt) {
      const selectSql = `SELECT * FROM xtbg_hazard_study_group where id like '%${stxt}%' or name like '%${stxt}%'  order by id desc`; // 查询sql
      const data = await this.$dbquery(this.$http, selectSql);

      this.tableData = await Promise.all(
        data.map(async (row) => {
          let user_name = "";
          if (row.manage_userid) {
            user_name = (
              await this.$dbquery(
                this.$http,
                `SELECT name FROM xtbg_user WHERE id = ${row.manage_userid} `
              )
            )[0].name;
          }
          return {
            ...row, // 其他字段需要自行添加

            user_name: user_name,
          };
        })
      );
      this.total = data.length; // 设置数据总数
    },
    async getList() {
      let sqlStr = `SELECT * FROM xtbg_hazard_study_group  order by id asc`;
      if (this.isYjzManage) {
        sqlStr = `SELECT * FROM xtbg_hazard_study_group where manage_userid=${localStorage.getItem(
          "userid"
        )}  order by id asc`;
      }
      
      const data = await this.$dbquery(this.$http, sqlStr);

      this.tableData = await Promise.all(
        data.map(async (row) => {
          let user_name = "";
          if (row.manage_userid) {
            user_name = (
              await this.$dbquery(
                this.$http,
                `SELECT name FROM xtbg_user WHERE id = ${row.manage_userid} `
              )
            )[0].name;
          }
          return {
            ...row, // 其他字段需要自行添加

            user_name: user_name,
          };
        })
      );
      this.total = data.length; // 设置数据总数
    },
    async handleDelete(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const deleteSql =
            "delete from xtbg_hazard_study_group where uid=" + row.uid;

          const result = await this.$dbquery(this.$http, deleteSql);
          if (result.affectedRows > 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            await this.getList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async addGroup() {
      this.AddEditVisible = true;
      this.editorBool = false;
      // 获取 当前 组id的最大值
      const maxId = await this.$dbquery(
        this.$http,
        "SELECT MAX(id) as maxId FROM xtbg_hazard_study_group"
      );
      this.form = {};
      this.form.id = maxId[0].maxId + 1;
      this.mytitle = "新增研究组";
    },
    async onSubmit() {
      //console.log(this.form);
      if (this.editorBool) {
        //修改
        const updateSql =
          "update xtbg_hazard_study_group set id=" +
          this.form.id +
          ",name='" +
          this.form.name +
          "',manage_userid=" +
          this.form.manage_userid +
          ",remark='" +
          this.form.remark +
          "' where uid=" +
          this.form.uid;
        const result = await this.$dbquery(this.$http, updateSql);
        if (result.affectedRows > 0) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
        }
      } else {
        //新增
        let insertForm = {};
        for (let key in this.form) {
          // 构建 插入 sql语句
          if (typeof this.form[key] === "string") {
            insertForm[key] = `'${this.form[key]}'`;
          } else {
            insertForm[key] = this.form[key];
          }
        }
        //console.log(insertForm);

        const insertSql =
          "INSERT INTO xtbg_hazard_study_group (" +
          Object.keys(insertForm).join(",") +
          ") VALUES (" +
          Object.values(insertForm).join(",") +
          ")";
        const result = await this.$dbquery(this.$http, insertSql);
        if (result.affectedRows > 0) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
        }
      }
      this.AddEditVisible = false;
      await this.getList();
    },
    onCancel() {
      this.AddEditVisible = false;
    },

    handlePageChange(page) {
      this.currentPage = page;
    },
    handleEditUser(row) {
      this.select_role_id = row.id;
      this.selectUserVisible = true;
    },
    async handleEdit(row) {
      this.$nextTick(() => {
        console.log(row);
        this.form = row;
        //console.log(row);
        this.AddEditVisible = true;
        this.editorBool = true;
        if (this.editorBool) {
          this.mytitle = "编辑研究组";
        } else {
          this.mytitle = "新建研究组";
        }
      });
    },
    closeUser() {
      this.selectUserVisible = false;
    },
  },
};
</script>

<style scoped>
.el-table {
  width: 100%;
}

::deep .el-form-item__content,
.el-form-item__content {
  margin-bottom: 0 !important;
}
</style>
