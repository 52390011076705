<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 14:00:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-11 16:45:15
 * @FilePath: \app_wb\src\views\News.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny_news-list">
      <div class="bny_news-list-left">
        <div class="bny_news-list-left-top" style="font-size: 22px">
          公务车预约系统
        </div>
        <div class="bny_news-list-left-items">
          <ul class="list_news-items" id="list_zdh">
            <li
              v-for="(item, index) in list_items_pt"
              :key="index"
              @click="clickListItem($event, item.name, item.url)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bny_news-list-right">
        <div class="bny_news-list-right-top">
          您当前的位置：<span>{{ current_addr }}</span>
        </div>
        <div class="bny_news-list-right-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mFooter from "../../components/mFooter.vue";

export default {
  inject: ["reload"],
  components: {
    mFooter,
  },

  data() {
    return {
      total: 0,
      pageSize: 20,
      current_title: "",
      current_page: 1,
      current_addr: "",
      item_bg_image: require("../../assets/imgs/news/right.png"),
      item_active_bg_image: require("../../assets/imgs/news/right1.png"),

      list_items_gl: [
        {
          name: "公务用车",
          url: "/gongjiao/gongwu",
        },
        {
          name: "顺风车",
          url: "/gongjiao/shunfeng",
        },
        {
          name: "搭车信息",
          url: "/gongjiao/dcshenpi",
        },
        {
          name: "用车审批",
          url: "/gongjiao/shenpi",
        },
        {
          name: "车辆运行",
          url: "/gongjiao/jichuxinxi",
        },
        {
          name: "权限管理",
          url: "/gongjiao/quanxian",
        },
      ],
      list_items_pt: [
        {
          name: "公务用车",
          url: "/gongjiao/gongwu",
        },
        {
          name: "顺风车",
          url: "/gongjiao/shunfeng",
        },
        {
          name: "搭车信息",
          url: "/gongjiao/dcshenpi",
        },
      ],
      list_content_items: [],
      list_data: [],
      dept: "",
      bm_list: [
        {
          value: "部门名称",
        },
        {
          value: "部门名称",
        },
        {
          value: "部门名称",
        },
      ],
      choosedate: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      serachtext: "",
    };
  },
  computed: {},

  watch: {
    $route: function (to, from) {
      var _this = this;
      setTimeout(() => {
        if (to.query) {
          if (typeof to.query.title != "undefined") {
            _this.current_title = localStorage.getItem("current_title");
            _this.current_addr =
              "首页" +
              ">" +
              localStorage.getItem("current_title") +
              ">" +
              to.query.title;
          }
        }

        if (to.path.indexOf("gongwu") > -1) {
          _this.current_addr = "首页>公务车预约系统>公务用车";
        } else if (to.path.indexOf("shunfeng") > -1) {
          _this.current_addr = "首页>公务车预约系统>顺风车";
        } else if (to.path.indexOf("shenpi") > -1) {
          if(to.path.indexOf("dcshenpi") > -1){
            _this.current_addr = "首页>公务车预约系统>搭乘信息";
          }else{
            _this.current_addr = "首页>公务车预约系统>用车审批";
          }
        } else if (to.path.indexOf("jichuxinxi") > -1) {
          _this.current_addr = "首页>公务车预约系统>车辆运行";
        } else {
          _this.current_addr = "首页>公务车预约系统>权限管理";
        }
      }, 200);

      this.$nextTick(function () {
        try {
          this.selectItem(this.$route.query.name);
        } catch (err) {}
      });
    },
  },
  methods: {
    selectItem(name) {
      let mlis = document
        .getElementsByClassName("list_news-items")[0]
        .querySelectorAll("li");
      for (let i = 0; i < mlis.length; i++) {
        let current_li = mlis[i];
        this.current_title = name;
        this.current_addr = "首页>" + name;

        current_li.style.background =
          "url('" + this.item_bg_image + "') no-repeat";

        current_li.style.backgroundPosition = "right center";
        current_li.style.backgroundPositionX = "250px";
        current_li.style.backgroundSize = "16px 16px";
        current_li.style.color = "#000";

        if (current_li.innerText == name) {
          this.current_title = name;
          this.current_addr = "首页>" + name;

          current_li.style.background =
            "url('" +
            this.item_active_bg_image +
            "') no-repeat rgb(81, 159, 109)";

          current_li.style.backgroundPosition = "right center";
          current_li.style.backgroundPositionX = "250px";
          current_li.style.backgroundSize = "16px 16px";
          current_li.style.color = "#fff";
        }
      }
    },
    async clickListItem(e, name, url) {
      console.log(e, name, url);
      var _this = this;
      var $event = e;

      setTimeout(() => {
        localStorage.setItem("current_title", name);
        _this.activeEl($event);
        _this.current_addr = "首页>" + name;
        _this.current_title = name;
      }, 50);

      // if (name.indexOf("用车审核") > -1) {
      //   //SELECT COUNT(*) as total FROM xtbg_officecar_log WHERE admin_userid is NULL and application_id in (SELECT id FROM  xtbg_officecar_application WHERE create_by=13394)
      //   const shRes = await this.$db_select(
      //     this.$http,
      //     "xtbg_officecar_log",
      //     " COUNT(*) as total",
      //     "admin_userid is NULL and application_id in (SELECT id FROM  xtbg_officecar_application WHERE create_by=" +
      //       localStorage.getItem("userid") +
      //       ")"
      //   );
      //   if (shRes != null && shRes[0] != null && shRes[0].total > 0) {
      //     this.$nextTick(function () {
      //       _this.$router
      //         .push({
      //           path: "/gongjiao/dcshenpi",
      //         })
      //         .catch((e) => {});
      //     });
      //   } else {
      //     this.$nextTick(function () {
      //       _this.$router
      //         .push({
      //           path: url,
      //         })
      //         .catch((e) => {});
      //     });
      //   }
      // } else {
      //   this.$nextTick(function () {
      //     _this.$router
      //       .push({
      //         path: url,
      //       })
      //       .catch((e) => {});
      //   });
      // }
      this.$nextTick(function () {
          _this.$router
            .push({
              path: url,
            })
            .catch((e) => {});
        });

      //判断当前用户 有没有需要审核的搭乘信息 有的话

      // if(){

      // }

      //this.selectItem(item.name);

      // this.initeData();
    },
    activeEl(e) {
      let parentEL = e.target.parentElement;

      let lis = parentEL.querySelectorAll("li");
      for (let i = 0; i < lis.length; i++) {
        let mli = lis[i];
        mli.style.background = "url('" + this.item_bg_image + "') no-repeat";
        mli.style.backgroundPosition = "right center";
        mli.style.backgroundPositionX = "250px";
        mli.style.backgroundSize = "16px 16px";
        mli.style.color = "#000";
      }

      let current_li = e.target;
      current_li.style.background =
        "url('" + this.item_active_bg_image + "') no-repeat #519f6d";
      current_li.style.backgroundPosition = "right center";
      current_li.style.backgroundPositionX = "250px";
      current_li.style.backgroundSize = "16px 16px";
      current_li.style.color = "#fff";

      // this.current_addr = "首页>" + e.currentTarget.innerText;
    },
  },

  async mounted() {
    if (this.$route.name == "gongjiao") {
      this.selectItem("公务用车");
      this.current_addr = "首页>公务车预约系统>公务用车";

      this.$router.push({
        path: "/gongjiao/gongwu",
      });
      this.selectItem("公务用车");
    }
    //判断当前用户是否是管理员
    const userid = localStorage.getItem("userid");
    //console.log(userid);

    const mgData = await this.$db_select(
      this.$http,
      "xtbg_officecar_role_user",
      "role_id",
      "user_id=" + userid
    );
    console.log(mgData);

    if (mgData[0].role_id == 1) {
      localStorage.setItem("gongjiaogly", true);
      this.list_items_pt = this.list_items_gl;
    }
  },
};
</script>

<style lang="scss" scoped>
.bny_news-list {
  padding: 0px 0px 20px 0px;
  width: 1400px;
  min-height: 898px;
  margin: 0px auto;
  /* background: #f2f2f2; */
  .bny_news-list-left {
    width: 298px;
    float: left;
    height: auto;
    background-color: #fff;
    border: 1px solid #f2f2f2;

    .bny_news-list-left-top {
      height: 120px;
      line-height: 120px;
      width: 100%;
      background: url("../../assets/imgs/news/list-top.jpg") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
    }
    .bny_news-list-left-items {
      height: auto;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          height: 70px;
          line-height: 70px;
          text-align: center;
          color: #000;
          font-size: 16px;
          font-weight: 600;
          border-bottom: 1px solid #f2f2f2;
          background: url("../../assets/imgs/news/right.png") no-repeat;
          background-position: right center;
          background-position-x: 250px;
          background-size: 16px 16px;
          &:hover {
            cursor: pointer;

            background: url("../../assets/imgs/news/right1.png") no-repeat
              #008500;
            background-position: right center;
            background-position-x: 250px;
            background-size: 16px 16px;
            color: #fff;
          }
        }
      }
    }
  }
  .bny_news-list-left-items-li-div {
    height: 70px;
    line-height: 70px;
    text-align: right;
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }

  .bny_news-list-right {
    width: 1080px;
    float: left;
    height: auto;
    margin-left: 20px;
    background-color: #fff;

    .bny_news-list-right-top {
      height: 40px;
      line-height: 40px;
      color: #000;
      background: #f3f4f9;
      width: calc(100% - 5px);
      text-align: left;
      padding-left: 5px;
      font-size: 14px;
      span {
        color: #519f6d;
      }
      box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.1);
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
        li {
          float: left;
          margin: 0px 10px;
          width: 250px;
        }
      }
    }
    .bny_news-list-right-content {
      padding-top: 5px;
      height: auto;
      padding-bottom: 20px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          height: 180px;
          width: 100%;
          box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
          margin: 5px 0px;
          &:hover {
            box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha: 0.1);
            margin: 5px 0px;
            cursor: pointer;
          }
          .bny_news-list-right-content-left {
            float: left;
            height: 150px;
            width: 150px;
            padding: 15px;
            img {
              height: 148px;
              width: 148px;
              border: 1px solid #f2f2f2;
              box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
              &:hover {
                box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha: 0.1);
              }
            }
          }
          .bny_news-list-right-content-right {
            padding: 30px 10px;
            float: left;
            height: 120px;
            width: 870px;
            font-size: 14px;
            div:first-child {
              text-align: left;
              height: 40px;
              line-height: 40px;
              font-weight: bold;
            }
            div:nth-child(2) {
              text-align: left;
            }
            div:last-child {
              text-align: left;
              color: #948b8b;
              padding: 5px 0 0 0;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .el-pagination {
    width: 42%;
    margin-top: 30px;
    margin-left: 403px;
    ::v-deep .btn-prev,
    ::v-deep .btn-next {
      border: 1px solid #f2f2f2;
      background-color: transparent;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    ::v-deep .el-icon-arrow-left:before,
    ::v-deep .el-icon-arrow-right:before {
      color: #d5bfa1;
      font-size: 14px;
    }
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #f2f2f2;
    background-color: #fff;
    color: #000;
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 14px;
    margin-right: 15px;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #519f6d;
    color: #fff;
  }
}
</style>
