<template>
  <div class="PastSsession-message-background">
    <!-- 头部信息 -->
    <div
      style="
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="font-size: 18px; font-weight: 600">
        {{ Statistics.title }}
      </div>
      <div class="PastSsession-topmeg-time">{{ Statistics.time }}</div>
    </div>
    <!-- 竖状图 -->

    <vertical-view
     
      :Statistics="Statistics"
    ></vertical-view>

    <!-- 数据统计 -->

    <div class="statics-main">
      <div class="statics">
        <div class="staticslable" style="width: 100px">共收到提案</div>
        <div class="staticsvalue">{{ Statistics.allproposal }}</div>
      </div>
      <div class="statics">
        <div class="staticslable">落实或正在落实</div>
        <div class="staticsvalue">{{ Statistics.Implement }}</div>
      </div>
    </div>
    <div class="statics-main">
      <div class="statics">
        <div class="staticslable" style="width: 100px">立案数量</div>
        <div class="staticsvalue">{{ Statistics.filenumber }}</div>
      </div>
      <div class="statics">
        <div class="staticslable">落实比例</div>
        <div class="staticsvalue">{{ Statistics.Implementratio }}</div>
      </div>
    </div>

    <!-- 详情 -->
    <div style="text-align: right; padding-right: 10px">
      <el-button
        type="text"
        style="color: #0e8313; text-decoration: underline"
        @click="openFile(Statistics.file)"
        >了解详情></el-button
      >
    </div>
  </div>
</template>

<script>
import VerticalView from "./VerticalView.vue";
let Base64 = require("js-base64").Base64;
export default {
  components: { VerticalView },
  props: {
    dataObject: Object,
  },
  watch: {
    // 监听父组件传递的对象数据变化
    dataObject: {
      handler(newVal) {
        this.Statistics = newVal;
      },
      deep: true, // 深度监听对象内部数据的变化
    },
  },
  data() {
    return {
      Statistics: {},
    };
  },
  created() {
    // 将 dataObject 数据存储到 Statistics 中
    this.Statistics = this.dataObject;
  },
  methods: {
    openFile(file) {
      if (file) {
        window.open(
          "http://42.193.22.219:8012/onlinePreview?url=" +
            encodeURIComponent(Base64.encode(file))
        );
      } else {
        this.$message({
          message: "暂无详情",
          type: "warning",
          offset:200
        });
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.PastSsession-message-background {
  width: calc((100% - 108px) / 3);
  padding: 10px;
  display: flex;
  height: 360px;
  flex-direction: column;
  /* background: #F3F4F9; */
  border: 1px solid #f3f4f9;
  margin: 7px;
  font-size: 14px;
  border-radius: 5px;
  .statics-main {
    display: flex;
    height: 40px;
    border-bottom: 1px solid #e5e4e4;
    .statics {
      flex: 1;
      display: flex;
      height: 30px;
      align-items: center;
      .staticslable {
        width: 120px;
        text-align: right;
        font-weight: 300;
      }
      .staticsvalue {
        flex: 1;
        color: #0e8313;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
</style>
