<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-14 22:10:48
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-15 00:24:14
 * @FilePath: \app_wb\src\components\commom\ProposalStatusTimeline.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-timeline>
      <el-timeline-item
        v-for="step in currentProcessSteps"
        :key="step.status"
        :timestamp="step.timestamp"
        :color="getColor(step.status)"
        :hide-timestamp="true"
      >
        <el-card>
          <div class="flex">
            <div class="flex-1">{{ step.status }}</div>
            <div :style="{ width: '100px', color: getColor(step.status) }">
              {{ step.user }}
            </div>
            <div>{{ step.timestamp }}</div>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
export default {
  name: "ProposalStatusTimeline",
  props: {
    statusSteps: {
      type: Array,
      required: true,
    },
    currentProcessType: {
      type: String,
      default: "normal",
    },
  },
  computed: {
    currentProcessSteps() {
      return this.statusSteps.filter((step) => {
        const processTypes = {
          normal: [
            "提案",
            "已附议",
            "已审查",
            "已会审",
            "已审批",
            "已处理",
            "已结案",
          ],
          resubmit: ["提案", "已附议", "已审查", "退回重提"],
          rejected: ["提案", "已附议", "已审查", "不予立案"],
          opinion: ["提案", "已附议", "已审查", "转意见处理", "已结案"],
        };
        return processTypes[this.currentProcessType].includes(step.status);
      });
    },
  },
  methods: {
    getColor(status) {
      const statusColorMap = {
        提案: "#E6A23C",
        已附议: "#409EFF",
        已审查: "#F56C6C",
        已会审: "#F56C6C",
        退回重提: "#000000",
        已审批: "#F56C6C",
        已处理: "#67C23A",
        已结案: "#008500",
        不予立案: "#909399",
        转意见处理: "#909399",
      };
      return statusColorMap[status] || "grey";
    },
  },
};
</script>

<style>
/* 在此处添加样式 */
</style>
