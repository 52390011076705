<template>
  <div class="fzgh_item">
    <div class="fzgh_item_title">
      <div class="fzgh_item_title_icon">
        <img :src="title_logo" />
      </div>
      <div class="fzgh_item_title_title">{{ title }}</div>
      <div class="fzgh_item_title_more">更多>></div>
    </div>
    <div class="fzgh_item_body">
      <div
        class="fzgh_item_body_listitem"
        v-for="(item, index) in list_data"
        :key="index" @click="clickItem(item)"
      >
        <div>
          <div
            style="
              width: 6px;
              height: 6px;
              border-radius: 6px;
              background: #ddd;
              margin-left: 20px;
            "
          ></div>
        </div>
        <div>{{item.title}}</div>
        <div>{{item.createTime}}</div>
      </div>
      
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    title_logo: {
      type: String,
    },
    list_data: {
      type: Array,
      default: [],
    },
  },
  methods:{
    clickItem(item){
      //this.$store.commit("updateSelectedTitle", this.current_title);

      

      localStorage.setItem("title", encodeURI(localStorage.getItem("current_title")));
      localStorage.setItem("showTitle", encodeURI(item.title));
      localStorage.setItem("deptName", item.deptName);
      this.$router.push({
          path: "/fzghDetail",
          query: {
            // title: encodeURI(this.current_title),
            // showTitle: encodeURI(item.title),
            id: item.id,
          },
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.fzgh_item {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.fzgh_item_title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #fafafa;
  display: grid;
  grid-template-columns: 50px 1fr 80px;

  img {
    width: 25px;
    height: 25px;
    margin-top: 12px;
  }
}
.fzgh_item_title_title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
}
.fzgh_item_title_more {
  font-size: 14px;
  cursor: pointer;
}
.fzgh_item_body {
  width: 100%;
  height: 250px;
  overflow-y: auto;

}
.fzgh_item_body_listitem {
  height: 49px;
  border-bottom: 1px dotted #bebebe;
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr 180px;
  font-size: 14px;
  &:hover {
    color: #519f6d;
    cursor: pointer;
    background: #f0f0f0;
  }
  div:nth-child(2) {
    text-align: left;
  }
}
</style>