<template>
  <div>
    <div class="bny__meetting">
      <div class="bny_meetting-top">
        <div>会议室预定</div>
      </div>
      <div style="text-align: left; padding: 20px 120px">
        <el-row :gutter="20">
          <el-col :span="6">
            会议室：<el-select
              v-model="form.room"
              multiple
              clearable
              filterable
              placeholder="请选择(可多选)"
              style="width: 60%"
              @change="selectMeettingRoom"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option> </el-select
          ></el-col>
          <el-col :span="6" style="text-align: left">
            <el-row>
              <el-col :span="4" style="padding-top: 0.7rem">
                <el-checkbox v-model="sphychecked">视频会议</el-checkbox>
              </el-col>
              <el-col :span="19" style="padding-left: 50px">
                <el-select v-model="sphyroom" v-show="sphychecked">
                  <el-option
                    v-for="item in videooptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            日期：
            <el-date-picker
              v-model="meetting_date"
              type="date"
              placeholder="选择日期"
              format="yyyy-MM-dd"
              @change="selectMeettingDate"
            ></el-date-picker>
          </el-col>
          <el-col :span="3">
            <el-button @click="resetYd" v-show="showCxButtion">撤消</el-button>
          </el-col>

          <el-col :span="5">
            <el-alert
              :title="alerttitle"
              type="error"
              v-show="alertshow"
              effect="dark"
            >
            </el-alert
          ></el-col>
        </el-row>

        <div
          class="bny__meetting-history"
          style="width: 100%; height: auto; margin-top: 20px"
        >
          <el-row class="bny__meetting-history-first">
            <ul>
              <li
                v-for="(item, index) in timers"
                :key="index"
                style="position: relative"
              >
                {{ item.start_time }}
              </li>
            </ul>
          </el-row>
          <el-row class="bny__meetting-history-kd">
            <ul>
              <li v-for="i in 16" :key="'li_' + i"></li>
            </ul>
          </el-row>
          <el-row>
            <ul ref="ul_timers" class="bny__meetting-history-second">
              <el-tooltip
                class="item"
                effect="dark"
                :content="getIndexFromMeetingTime(i)"
                placement="bottom"
                v-for="i in 91"
                :key="i"
              >
                <li
                  @click="chooseStartAndEnd(i)"
                  @mouseover="liMouseOver(i, $event)"
                ></li>
              </el-tooltip>
            </ul>
          </el-row>
        </div>
        <div style="margin-top: 20px; padding: 20px 0; border: 1px solid #ddd">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="会议名称">
              <el-input v-model="form.name" style="width: 46.3%"></el-input>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="6"
                ><el-form-item label="开始时间">
                  <el-input
                    type="text"
                    v-model="form.starttime"
                    placeholder="08:30"
                  /> </el-form-item
              ></el-col>
              <el-col :span="6"
                ><el-form-item label="结束时间">
                  <el-input
                    type="text"
                    v-model="form.endtime"
                    placeholder="18:00"
                  />
                  <!-- <el-date-picker
                    v-model="form.endtime"
                    type="datetime"
                    placeholder="选择结束时间"
                    format="HH:mm"
                   
                  >
                  </el-date-picker>  -->
                </el-form-item></el-col
              >
            </el-row>

            <el-form-item label="会议室" v-show="false">
              <el-select
                auto-complete
                v-model="room"
                clearable
                filterable
                placeholder="请选择会议室"
                style="width: 46.3%"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="预定人">
              <el-input v-model="form.booker" style="width: 46.3%"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即预定</el-button>
              <el-button @click="resetYd" v-show="false">撤回预定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div style="clear: both"></div>
      <div id="meettingTable">
        <div
          style="
            background: #eff6ef;
            height: 40px;
            line-height: 40px;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
          "
        >
          会议预定记录
        </div>
        <div class="flex" style="padding: 5px 20px">
          <div class="height-40 line-height-40 flex">
            <div style="width: 80px">会议室：</div>
            <el-select
              v-model="select_room"
              clearable
              filterable
              placeholder="请选择会议室"
              style="width: 50%; margin: 0px 10px"
              @change="MyMeettingRoom"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-date-picker
              v-model="select_time"
              type="datetimerange"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeDate"
            >
            </el-date-picker>
            <el-button
              icon="el-icon-refresh-left
            "
              style="
                margin-left: 10px;
                background: rgb(37, 131, 75);
                color: #fff;
              "
              @click="getHistoryDue"
              >重载</el-button
            >
          </div>
        </div>
        <el-table
          :data="
            tableData.slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize
            )
          "
          style="width: 100%"
        >
          <el-table-column prop="starttime" label="开始时间" width="180">
          </el-table-column>
          <el-table-column prop="endtime" label="结束时间" width="180">
          </el-table-column>
          <el-table-column prop="name" label="会议主题"> </el-table-column>
          <el-table-column prop="room" label="会议室"> </el-table-column>
          <el-table-column prop="booker" label="预定人" width="100">
          </el-table-column>
          <el-table-column prop="create_by" label="创建人" width="200">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <!-- <el-button
                @click="deleteClick(scope.row)"
                type="text"
                size="small"
                v-show="isFutureDateTime(scope.row.starttime)"
                >取消预定</el-button
              > -->
              <el-button
                @click="deleteClick(scope.row)"
                type="text"
                size="small"
                >取消预定</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin-top: 10px"
          background
          layout="total,prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div style="clear: both"></div>

    <mFooter></mFooter>
  </div>
</template>

<script>
import mFooter from "@/components/mFooter.vue";
import {
  ListMeettinRooms,
  ListMeettinVideoRooms,
} from "@/api/system/sys_dict_data.js";

import {
  ListMettingDue,
  PostMeettingDue,
  deleteMeettingDue,
} from "@/api/content/xtbg_meetting_due.js";

import {
  getNowFormatDate,
  getFormatDate,
  repeatElement1,
  mouseOverShowText,
  formatDate,
  formatDateToYmdhs,
  isFutureDateTime,
} from "../../utils/common.js";
import {
  meetting_data,
  meetting_room,
  meetting_date_minute,
} from "../../utils/database.js";
import CryptoJS from "crypto-js";

export default {
  components: {
    mFooter,
  },
  data() {
    return {
      total: 0,
      pageSize: 10,
      currentPage: 1,
      select_room: "",
      select_time: "",
      sphyroom: "",
      sphychecked: false,
      tableData: [],
      alerttitle: "",
      alertshow: false,
      timers: [
        {
          start_time: "08:00",
          end_time: "08:30",
        },
        {
          start_time: "09:00",
          end_time: "09:30",
        },
        {
          start_time: "10:00",
          end_time: "10:30",
        },
        {
          start_time: "11:00",
          end_time: "11:30",
        },
        {
          start_time: "12:00",
          end_time: "12:30",
        },
        {
          start_time: "13:00",
          end_time: "13:30",
        },
        {
          start_time: "14:00",
          end_time: "14:30",
        },
        {
          start_time: "15:00",
          end_time: "15:30",
        },
        {
          start_time: "16:00",
          end_time: "16:30",
        },
        {
          start_time: "17:00",
          end_time: "17:30",
        },
        {
          start_time: "18:00",
          end_time: "18:30",
        },
        {
          start_time: "19:00",
          end_time: "19:30",
        },
        {
          start_time: "20:00",
          end_time: "20:30",
        },
        {
          start_time: "21:00",
          end_time: "21:30",
        },
        {
          start_time: "22:00",
          end_time: "22:30",
        },

        {
          start_time: "23:00",
          end_time: "23:30",
        },
      ],
      showCxButtion: true,
      form: {
        starttime: "",
        endtime: "",
        room: "",
        booker: "",
        name: "",
        createBy: "",
      },

      room: "",
      meetting_date: "",
      options: [],
      videooptions: [],
      meettingData: [],
      clickCount: 0,
      startIndex: 0,
      endIndex: 0,
      oldIndexs: [],
      oldItems: [],

      queryParams: {
        pageSize: 100,
        pageNum: 1,
      },
      oldSelectedIndex: [],
      nowIndexs: [],
      selectStr: "SELECT * FROM xtbg_meetting_due WHERE ",
    };
  },
  created() {
    this.getListRoom();

    //this.meettingData = meetting_data();
    this.getListVideoRoom();
    this.getMeettingDue();
    this.meetting_date = getNowFormatDate();

    this.getHistoryDue();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    isFutureDateTime(time) {
      return isFutureDateTime(time);
    },
    async changeDate(date) {
      const startDate = formatDate(this.select_time[0]);
      const endDate = formatDate(this.select_time[1]);
      if (this.select_room.length > 0 && this.select_time.length > 1) {
        this.selectStr =
          "SELECT * FROM xtbg_meetting_due WHERE room = '" +
          this.select_room +
          "'  AND starttime >= '" +
          startDate +
          "' AND endtime <= '" +
          endDate +
          "' AND (booker='" +
          localStorage.getItem("username") +
          "' OR create_by='" +
          localStorage.getItem("email") +
          "') order by starttime desc";
      }
      if (this.select_room.length == 0) {
        this.selectStr =
          "SELECT * FROM xtbg_meetting_due WHERE starttime >= '" +
          startDate +
          "' AND endtime <= '" +
          endDate +
          "' AND (booker='" +
          localStorage.getItem("username") +
          "' OR create_by='" +
          localStorage.getItem("email") +
          "') order by starttime desc";
      }
      const res = await this.$dbquery(this.$http, this.selectStr);
      this.tableData = res.map((row) => {
        return {
          ...row,
          starttime: formatDateToYmdhs(row.starttime),
          endtime: formatDateToYmdhs(row.endtime),
        };
      });
      this.total = this.tableData.length;
    },
    async MyMeettingRoom(room) {
      if (room.length > 0 && this.select_time.length > 1) {
        const startDate = formatDate(this.select_time[0]);
        const endDate = formatDate(this.select_time[1]);
        this.selectStr =
          "SELECT * FROM xtbg_meetting_due WHERE room = '" +
          this.select_room +
          "'  AND starttime >= '" +
          startDate +
          "' AND endtime <= '" +
          endDate +
          "' AND (booker='" +
          localStorage.getItem("username") +
          "' OR create_by='" +
          localStorage.getItem("email") +
          "') order by starttime desc";
      }
      if (this.select_time.length == 0) {
        this.selectStr =
          "SELECT * FROM xtbg_meetting_due WHERE room = '" +
          this.select_room +
          "' AND (booker='" +
          localStorage.getItem("username") +
          "' OR create_by='" +
          localStorage.getItem("email") +
          "') order by starttime desc";
      }
      const res = await this.$dbquery(this.$http, this.selectStr);
      this.tableData = res.map((row) => {
        return {
          ...row,
          starttime: formatDateToYmdhs(row.starttime),
          endtime: formatDateToYmdhs(row.endtime),
        };
      });
      this.total = this.tableData.length;
    },
    async getHistoryDue() {
      this.select_room = "";
      this.select_time = "";
      this.selectStr =
        "SELECT * FROM xtbg_meetting_due WHERE booker='" +
        localStorage.getItem("username") +
        "' OR create_by='" +
        localStorage.getItem("email") +
        "' order by starttime desc";
      const res = await this.$dbquery(this.$http, this.selectStr);
      this.tableData = res.map((row) => {
        return {
          ...row,
          starttime: formatDateToYmdhs(row.starttime),
          endtime: formatDateToYmdhs(row.endtime),
        };
      });
      this.total = this.tableData.length;
      //console.log(localStorage.getItem("email"))

      // if (localStorage.getItem("email").length > 0) {
      //   ListMettingDue({
      //     pageSize: 100,
      //     pageNum: 1,
      //     booker: this.form.booker,
      //   }).then((res) => {
      //     //res.data.map(row=>row.createBy==localStorage.getItem("email"))
      //     let tData = res.data.rows.filter((row) => {
      //       return row.createBy == localStorage.getItem("email");
      //     });

      //     tData.map(async (row) => {
      //       let mrow = row;
      //       mrow["createBy"] = await this.getUserNameByEmail(row.createBy);
      //       this.tableData.push(mrow);
      //     });
      //   });
      // }
    },
    async getUserNameByEmail(email) {
      const res = await this.$dbquery(
        this.$http,
        "select name from xtbg_user where email='" + email + "'"
      );

      return res[0].name;
    },

    deleteClick(row) {
      this.$confirm("确定取消当前预定会议？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMeettingDue(row.id).then((res) => {
            if (res.data.code == 200) {
              window.location.reload();
              this.$message({
                message: "成功取消会议预定",
                type: "success",
                offset: 200,
              });
              this.getHistoryDue();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getMeettingDue() {
      this.meettingData = [];
      ListMettingDue(this.queryParams).then((res) => {
        this.meettingData = res.data.rows;
      });
    },
    //会议室列表
    getListRoom() {
      this.options = [];
      ListMeettinRooms().then((res) => {
        res.data.rows.map((row) => {
          this.options.push({
            label: row.dictLabel,
            value: row.dictValue,
          });
        });
      });
    },
    getListVideoRoom() {
      this.videooptions = [];
      ListMeettinVideoRooms().then((res) => {
        res.data.rows.map((row) => {
          this.videooptions.push({
            label: row.dictLabel,
            value: row.dictValue,
          });
        });
      });
    },
    liMouseOver(i, e) {
      mouseOverShowText(this.getIndexFromMeetingTime(i), e);
    },
    chooseStartAndEnd(index) {
      var _this = this;

      setTimeout(function () {
        _this.alertshow = false;
        _this.clickCount++;

        if (_this.clickCount == 1) {
          //_this.clearTimerEL(); //开始时候清空之前的时间选择
          //渲染之前已有的历史记录
          _this.clearTimerEL();
          _this.setOldIndex();
          _this.showCxButtion = true;

          //this.oldIndexs.push(i);
          //console.log(_this.oldIndexs);

          //填充开始时间
          _this.startIndex = index;
          if (_this.startIndex < _this.endIndex) {
            _this.form.starttime =
              _this.meetting_date + " " + _this.getTime(_this.startIndex);
          }

          _this.setTimerEL(index - 1);
        }
        if (_this.clickCount == 2) {
          //填充到结束时间
          _this.endIndex = index;
          // this.clearTimerEL();
          let mindex = [];
          var tempIndex = null;
          if (_this.startIndex > _this.endIndex) {
            tempIndex = _this.endIndex;
            _this.endIndex = _this.startIndex;
            _this.startIndex = tempIndex;

            // _this.form.starttime =
            //   _this.meetting_date + " " + _this.getTime(_this.startIndex);
            // _this.form.endtime =
            //   _this.meetting_date + " " + _this.getTime(_this.endIndex);
            _this.form.starttime = _this.getTime(_this.startIndex);
            _this.form.endtime = _this.getTime(_this.endIndex);
          }
          _this.form.starttime = _this.getTime(_this.startIndex);
          _this.form.endtime = _this.getTime(_this.endIndex);

          // _this.endIndex =
          //   _this.startIndex > _this.endIndex
          //     ? _this.startIndex
          //     : _this.endIndex;

          for (let i = _this.startIndex; i < _this.endIndex; i++) {
            mindex.push(i);
          }
          _this.oldSelectedIndex = mindex;

          //判断是否已经有会议安排
          let newArr = repeatElement1(_this.oldIndexs, mindex);
          if (newArr.length > 0) {
            _this.clearTimerELM(_this.startIndex - 1);
            _this.clickCount = 0;
            _this.alertshow = true;
            _this.alerttitle = "该时间段已有预定的会议。";
            _this.clearTimerEL();
            _this.setOldIndex();

            setTimeout(() => {
              _this.alertshow = false;
            }, 3000);

            //  _this.form.starttime = "";
            //  _this.form.endtime = "";

            return false;
          } else {
            _this.nowIndexs = [];
            for (let i = _this.startIndex - 1; i < _this.endIndex; i++) {
              _this.setTimerEL(i);

              _this.nowIndexs.push(i);
            }

            _this.clickCount = 0;
          }
        }
      }, 50);
    },
    encrypt(text) {
      const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
      const IV_LENGTH = 16; // 初始化向量的长度

      // 生成随机的初始化向量（IV）
      const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

      // 使用 AES 加密
      const encrypted = CryptoJS.AES.encrypt(
        text,
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // 返回加密后的字符串和 IV
      return (
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    onSubmit() {
      // this.form.starttime = this.form.starttime + ":00";
      // this.form.endtime = this.form.endtime + ":00";
      // console.log(this.form.starttime);
      // console.log(this.form.endtime);

      if (this.sphychecked) {
        if (this.shhyroom == "") {
          this.$message({
            message: "视频会议类型不能为空",
            type: "error",
            offset: 200,
          });
          return false;
        }
      }

      if (this.form.name.length == 0) {
        this.$message({
          message: "会议名称不能为空",
          type: "error",
          offset: 200,
        });
        return false;
      }
      if (this.form.room.length == 0) {
        this.$message({
          message: "请选择会议室",
          type: "error",
          offset: 200,
        });
        return false;
      }
      if (this.form.starttime == 0) {
        this.$message({
          message: "会议开始时间不能为空",
          type: "error",
          offset: 200,
        });
        return false;
      }
      if (this.form.endtime == 0) {
        this.$message({
          message: "会议结束时间不能为空",
          type: "error",
          offset: 200,
        });
        return false;
      }
      if (this.form.booker == 0) {
        this.$message({
          message: "会议预定人不能为空",
          type: "error",
          offset: 200,
        });
        return false;
      }

      //确认按钮
      this.$confirm("确定预定" + this.form.room + "", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //先查询是否有该会议记录
          this.form.starttime =
            this.meetting_date + " " + this.form.starttime + ":00";
          this.form.endtime =
            this.meetting_date + " " + this.form.endtime + ":00";

          //判断有没有该会议该事件段有没有被预定

          let room = this.form.room[0];
          let s_time = this.form.starttime;
          let e_time = this.form.endtime;
          let sdate = this.meetting_date;
          let qStr = this.encrypt(
            "SELECT * from xtbg_meetting_due WHERE room LIKE '%" +
              room +
              "%' and '" +
              s_time +
              "'>=starttime AND '" +
              e_time +
              "'<=endtime AND starttime like '%" +
              sdate +
              "%' UNION " +
              "SELECT * from xtbg_meetting_due WHERE room LIKE '%" +
              room +
              "%' and '" +
              s_time +
              "'>=starttime AND '" +
              s_time +
              "'<=endtime AND starttime like '%" +
              sdate +
              "%' UNION " +
              "SELECT * from xtbg_meetting_due WHERE room LIKE '%" +
              room +
              "%' and '" +
              e_time +
              "'>=starttime AND '" +
              e_time +
              "'<=endtime AND starttime like '%" +
              sdate +
              "%' UNION " +
              "SELECT * from xtbg_meetting_due WHERE room LIKE '%" +
              room +
              "%' and '" +
              s_time +
              "'<=starttime AND '" +
              e_time +
              "'>=endtime AND starttime like '%" +
              sdate +
              "%'"
          );

          let s_query = {
            query: qStr,
            params: [],
          };
          this.$http
            .post(this.$db_dynamic_url, s_query, {
              headers: {
                Authorization:
                  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
              },
            })
            .then((res) => {
              if (res.data.length > 0) {
                this.$message({
                  type: "error",
                  message: "该时段已经有预定。",
                  offset: 200,
                });
              } else {
                this.form.room = this.form.room.join("-");
                if (this.sphychecked) {
                  this.form.room = this.form.room + "(" + this.sphyroom + ")";
                }

                ListMettingDue({
                  pageSize: 10,
                  pageNum: 1,
                  room: this.form.room,
                  starttime: this.form.starttime,
                  endtime: this.form.endtime,
                }).then((res) => {
                  if (res.data.total == 0) {
                    this.form["createBy"] = localStorage.getItem("email");

                    PostMeettingDue(this.form).then((res) => {
                      if (typeof res != "undefined") {
                        if (res.data.code == 200) {
                          // setTimeout(() => {
                          //   this.searchMeetting();

                          // }, 1000);

                          this.oldIndexs = this.oldIndexs.concat(
                            this.nowIndexs
                          );
                          this.setOldIndex();
                          this.showCxButtion = false;

                          this.$message({
                            message: "预定成功",
                            type: "success",
                            offset: 200,
                          });
                          this.getHistoryDue();
                        } else {
                          this.$message({
                            message: "预定失败",
                            type: "error",
                            offset: 200,
                          });
                        }
                      } else {
                        this.$message({
                          message: "预定失败",
                          type: "error",
                          offset: 200,
                        });
                      }
                    });
                  } else {
                    // this.$message("请不要重复提交");
                    this.$message({
                      message: "请不要重复提交",
                      type: "error",
                      offset: 200,
                    });
                  }
                });
              }
            });

          // console.log(this.form.room.join('-'));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
            offset: 200,
          });
        });
    },
    searchMeetting() {
      var _this = this;
      //this.meetting_date = getFormatDate(this.meetting_date);
      if (this.form.room.length == 0) {
        this.$message({
          message: "请选择会议室",
          type: "warning",
        });
        return false;
      }
      if (this.meetting_date == null) {
        this.$message({
          message: "请选择日期",
          type: "warning",
        });
        return false;
      }

      this.clearTimerEL();
      this.oldIndexs = [];
      this.oldItems = [];

      for (let i = 0; i < this.meettingData.length; i++) {
        this.meetting_date = getFormatDate(new Date(this.meetting_date));

        let row = this.meettingData[i];

        this.form.room.map((froom) => {
          if (
            row.starttime.indexOf(this.meetting_date) > -1 &&
            row.room.indexOf(froom) > -1
          ) {
            //去渲染前端对应的方格子
            let mstime = row.starttime.split(" ")[1];
            let mstimes = mstime.split(":");
            let lstime = mstimes[0] + ":" + mstimes[1];
            let mstime_index = this.getIndexFromMeetingDate(lstime);

            let metime = row.endtime.split(" ")[1];
            let metimes = metime.split(":");
            let letime = metimes[0] + ":" + metimes[1];

            let metime_index = this.getIndexFromMeetingDate(letime);

            this.oldItems.push(row);

            for (let i = mstime_index; i <= metime_index; i++) {
              this.setTimerEL2(i);
              this.oldIndexs.push(i);
            }
          }
        });
      }

      //this.mouserOverShow();
    },
    getTime(idx) {
      let meetting_date_minutes = meetting_date_minute();
      for (let i = 0; i < meetting_date_minutes.length; i++) {
        if (i == idx) {
          return meetting_date_minutes[i].time;
        }
      }
    },
    clearTimerEL() {
      if (this.$refs.ul_timers != undefined) {
        var el_lis = this.$refs.ul_timers.querySelectorAll("li");
        for (let i = 0; i < el_lis.length; i++) {
          let li = el_lis[i];

          li.style.backgroundColor = "#fff";

          //li.style.backgroundColor = "#fff";
        }
      }
    },
    setOldIndex() {
      if (this.$refs.ul_timers != undefined) {
        if (this.oldIndexs.length > 0) {
          var el_lis = this.$refs.ul_timers.querySelectorAll("li");

          //console.log(this.oldIndexs);

          this.oldIndexs.map((i) => {
            let li = el_lis[i];

            if (li != undefined) {
              li.style.backgroundColor = "#fff";
              li.style.backgroundColor = "rgb(204, 255, 255)";
            }
          });
        }
      }
    },
    //清除之前选择的
    clearOldIndex() {
      if (this.$refs.ul_timers != undefined) {
        if (this.oldSelectedIndex.length > 0) {
          var el_lis = this.$refs.ul_timers.querySelectorAll("li");
          let sindex = parseInt(this.oldSelectedIndex[0]) - 1;
          for (let i = 0; i < this.oldSelectedIndex.length + 1; i++) {
            let li = el_lis[parseInt(this.oldSelectedIndex[i])];
            if (li != undefined) {
              li.style.backgroundColor = "#fff";
            }
            //清除第一个
            el_lis[sindex].style.backgroundColor = "#fff";
          }
        }
      }
    },
    clearTimerELM(index) {
      if (this.$refs.ul_timers != undefined) {
        var el_lis = this.$refs.ul_timers.querySelectorAll("li");
        for (let i = 0; i < el_lis.length; i++) {
          if (index == i) {
            let li = el_lis[i];

            li.style.backgroundColor = "#fff";
          }
        }
      }
    },
    setTimerEL(mindex) {
      var el_lis = this.$refs.ul_timers.querySelectorAll("li");

      for (let i = 0; i < el_lis.length; i++) {
        let li = el_lis[i];
        if (mindex == i) {
          li.style.backgroundColor = "rgb(253, 226, 226)";
        }
      }
    },
    setTimerEL2(mindex) {
      var el_lis = this.$refs.ul_timers.querySelectorAll("li");

      for (let i = 0; i < el_lis.length; i++) {
        let li = el_lis[i];
        if (mindex == i) {
          li.style.backgroundColor = "#CCFFFF";
        }
      }
    },
    /**
     *
     * @param {根据时间 获取 对应的索引值} mtime
     */
    getIndexFromMeetingDate(mtime) {
      let meettingDate_data = meetting_date_minute();
      return meettingDate_data.filter(function (row) {
        return row["time"] == mtime;
      })[0]["index"];
    },
    /**
     *
     * @param {根据索引值获取对应的时间} mindex
     */
    getIndexFromMeetingTime(mindex) {
      let meettingDate_data = meetting_date_minute();
      var mitem = meettingDate_data.filter(function (row, index) {
        return index == mindex;
      })[0];
      if (mitem != null || typeof mitem != "undefined") {
        return mitem["time"];
      } else {
        return "";
      }
    },
    selectMeettingRoom(vaue) {
      //如果 会议室和时间都不为空就开始查询对应的日程
      this.searchMeetting();
    },
    selectMeettingDate() {
      //如果 会议室和时间都不为空就开始查询对应的日程
      this.searchMeetting();
    },
    resetYd() {
      // for (let i = this.startIndex - 1; i < this.endIndex; i++) {
      //   this.clearTimerELM(i);
      // }
      this.nowIndexs.map((i) => {
        this.clearTimerELM(i);
      });
      this.form.starttime = "";
      this.form.endtime = "";
      this.clearTimerEL();
      this.setOldIndex();
    },
  },

  mounted() {
    //this.meetting_date = getNowFormatDate();
    //this.clearTimerEL();
    this.form.booker = localStorage.getItem("username");

    //this.mouserOverShowTime();
  },
};
</script>

<style lang="scss" scoped>
.bny__meetting {
  width: 1400px;
  height: auto;
  background: #fff;
  margin: 10px auto;
  font-size: 14px;

  .bny_meetting-top {
    width: calc(100% - 240px);
    height: 100px;
    line-height: 100px;
    background: url("../../assets/imgs/advise/advise-top.png") no-repeat #519f6d;
    background-position-x: 60px;
    background-position-y: 10px;
    background-size: 1280px 80px;
    text-align: left;
    padding: 0 120px;
    font-size: 18px;
    font-weight: bold;
    color: #2e6548;
    div {
      float: left;
    }
    .bny_meetting-top-btn {
      float: right;
    }
  }
  .bny__meetting-history {
    .bny__meetting-history-first {
      ul {
        margin: 0;
        padding: 0px 0 0 6px;
        width: 100%;
        list-style: none;
        li {
          font-size: 12px;
          width: 76px;
          height: 20px;
          font-weight: 600;

          text-align: center;
          float: left;
          &:first-child {
            width: 44px;
            text-align: left;
          }
          &:last-child {
            width: 44px;
            text-align: right;
          }
        }
      }
    }

    .bny__meetting-history-kd {
      padding: 0px 0px 1px 2px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          float: left;
          background: #444;
          height: 20px;
          width: 1px;
          margin-right: 75.1px;

          &:first-child {
            margin-left: 4.5px;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
    ul.bny__meetting-history-second {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        width: 11.7px;
        height: 60.63px;
        display: inline-block;
        border: 1px solid #ddd;
        border-right: none;

        cursor: pointer;
        &:last-child {
          border-right: 1px solid #ddd;
        }
      }
    }
  }
}
::v-deep .el-input.is-disabled .el-input__inner {
  color: #000;
}
.div-time-yline {
  width: 2px;
  height: 14px;
  background: #ddd;
  position: absolute;
  left: 0px;
  bottom: -2px;
  z-index: 999;

  /* &:not(:first-child){
    left: 2px;

  } */
}
</style>
