<template>
  <div>
    <div class="search-container">
      <input
        type="text"
        v-model="search_text"
        placeholder="仪器名称/仪器型号/功能/存放地点/仪器责任人"
      />
      <button type="submit" style="width: 40px" @click="searchDevice">
        <i class="el-icon-search" style="color: #fff"></i>
      </button>

      <button
        type="default"
        style="width: 40px; margin-left: 10px; width: 90px; color: #fff"
        @click="reloadData"
      >
        <i class="el-icon-refresh" style="color: #fff"></i>重载数据
      </button>
    </div>
    <div class="grid-container" ref="masonry">
      <!-- <masonry :cols="{ default: 5, 798: 2, 700: 1 }" gutter="5px">
     
    </masonry> -->

      <GridItem
        @click.native="showDetail(item.id)"
        v-for="(item, index) in items"
        :key="index"
        :photo_urls="item.photo_urls"
        :name="item.name"
        :maker="item.maker"
        :location="item.location"
        :administrator="item.administrator"
      />
    </div>
  </div>
</template>

<script>
import GridItem from "./components/GridItem.vue";
import { Masonry } from "vue-masonry-css";

import Isotope from 'isotope-layout';

export default {
  name: "App",
  components: {
    GridItem,
    Masonry,
  },
  data() {
    return {
      search_text: "",
      items: [
        // Add more items here
      ],

      sqlStr: `SELECT 
                      i.*, 
                      p.photo_urls, 
                      a.attachment_urls
                  FROM xtbg_device_instruments i
                  LEFT JOIN (
                      SELECT 
                          instrument_num, 
                          GROUP_CONCAT(DISTINCT photo_url) AS photo_urls
                      FROM xtbg_device_instrument_photos
                      GROUP BY instrument_num
                  ) p ON p.instrument_num = i.num
                  LEFT JOIN (
                      SELECT 
                          instrument_num, 
                          GROUP_CONCAT(DISTINCT attachment_url) AS attachment_urls
                      FROM xtbg_device_instrument_attachments
                      GROUP BY instrument_num
                  ) a ON a.instrument_num = i.num;
                  `,
    };
  },
  async created() {
    await this.getList(this.sqlStr);
  },

  watch: {
    search_text(val) {
      if (val.length >= 2) {
        this.searchDevice();
      }
      if (val.length == 0) {
        this.getList(this.sqlStr);
      }
    },
  },
  methods: {
    createMasonry(container, items) {
      const columnCount = 5;
      const columnWidth = 100 / columnCount + "%";

      for (let i = 0; i < columnCount; i++) {
        const column = document.createElement("div");
        column.style.width = columnWidth;
        column.style.display = "inline-block";
        column.style.verticalAlign = "top";
        container.appendChild(column);
      }

      items.forEach((item, index) => {
        const column = container.children[index % columnCount];
        column.appendChild(item);
      });
    },

    async searchDevice() {
      const search_sql =
        "SELECT d.*,(SELECT photo_url FROM xtbg_device_instrument_photos WHERE instrument_num=d.num LIMIT 1) as photo_urls," +
        "(SELECT attachment_url FROM xtbg_device_instrument_attachments WHERE instrument_num=d.num LIMIT 1) as attachment_urls" +
        " FROM xtbg_device_instruments d WHERE d.`name` LIKE '%" +
        this.search_text +
        "%' OR d.model LIKE '%" +
        this.search_text +
        "%' OR d.purpose LIKE '%" +
        this.search_text +
        "%' OR d.address LIKE '%" +
        this.search_text +
        "%' OR d.administrator LIKE '%" +
        this.search_text +
        "%'";
      await this.getList(search_sql);
    },
    showDetail(id) {
      this.$router.push({
        path: "/device/detail",
        query: {
          id: id,
        },
      });
    },
    async reloadData() {
      await this.getList(this.sqlStr);
    },
    async getList(sqlStr) {
      this.items = [];

      const deviceData = await this.$dbquery(this.$http, sqlStr);
      deviceData.sort((a, b) => a.name.localeCompare(b.name, "zh-CN"));
      this.items = deviceData;
    },
  },
  mounted() {
    //let that = this;
    // document.addEventListener("DOMContentLoaded", function () {
    //   document.querySelector(".grid-container").innerHTML = "";
    //   that.createMasonry(
    //     document.querySelector(".grid-container"),
    //     Array.from(document.querySelectorAll(".grid-item"))
    //   );
    // });

    // this.$nextTick(() => {
    //   this.iso = new Isotope(this.$refs.masonry, {
    //     itemSelector: '.grid-item',
    //     layoutMode: 'masonry',
    //     masonry: {
    //       columnWidth: '.grid-item',
    //       gutter: 10,
    //     },
    //   });
    // });
  },
};
</script>

<style  scoped>
.masonry {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
  flex-direction: row; /* Arrange items horizontally */
  overflow-x: auto; /* Allow horizontal scrolling */
}
.masonry > * {
  margin-bottom: 30px;
  padding-left: 30px; /* gutter size */
  flex: 0 0 auto; /* Prevent items from shrinking */
}
.grid-container {
  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px; */
  /* display: flex;
  flex-wrap: wrap;

  gap: 2px;
  padding-top: 10px; */

  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  padding-top: 10px;

  background: #f8f8f8;

  /* display: inline-block;
            white-space: normal;
            vertical-align: top; */

  /* align-items:flex-start;
  justify-content: space-between; */
  /* display: grid;
            grid-template-columns: repeat(auto-fill, minmax(194px, 1fr));
            gap: 2px; 
            padding-top: 10px; */
}

.search-container {
  display: flex;
  align-items: center;

  background-color: #f8f8f8;
  padding: 10px;
  width: calc(100% - 20px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-container input {
  width: 60%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  outline: none;
  font-size: 14px;
}

.search-container button {
  padding: 10px;
  background-color: #4caf50;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.search-container button img {
  width: 20px;
  height: 20px;
}

.search-container button:hover {
  background-color: #45a049;
}
</style>
