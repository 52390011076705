<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 14:00:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-31 18:44:14
 * @FilePath: \app_wb\src\views\News.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny_news-list">
      <div class="bny_news-list-left">
        <div class="bny_news-list-left-top">内网平台</div>
        <div class="bny_news-list-left-items">
          <ul class="list_news-items">
            <li
              v-for="(item, index) in list_items"
              :key="index"
              @click="clickListItem($event, item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bny_news-list-right">
        <div class="bny_news-list-right-top">
          您当前的位置：<span v-html="current_addr"></span>
        </div>
        <div class="bny_news-list-right-content">
          <!-- 文章标题 -->
          <div class="bny_news-mcontent-title">{{ essay.title }}</div>
          <!-- 发布时间，浏览量，来源 -->
          <div
            class="bny_news-mcontent-subtitle"
            style="color: rgb(99, 102, 105)"
          >
            发布时间：{{ essay.createTime }}&nbsp;&nbsp;&nbsp;&nbsp;浏览量：{{
              essay.viewNum
            }}&nbsp;&nbsp;&nbsp;&nbsp;来源：{{ essay.deptName }}
          </div>
          <!-- 文章内容 -->
          <div
            id="news-content"
            class="bny_news-mcontent-content"
            v-html="essay.content"
          ></div>

          <div
            v-show="fj_show"
            style="
              text-align: left;
              border-top: 1px solid #ddd;
              padding-top: 15px;
              margin-top: 20px;
            "
          >
            <div v-show="fjfiles.length > 0 ? true : false">
              附件列表：
              <div
                style="height: 30px"
                v-for="(file, index) in fjfiles"
                :key="'file_' + index"
              >
                <div style="float: left; height: 30px; line-height: 30px">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="点击预览附件"
                    placement="top-start"
                    ><a
                      style="cursor: pointer"
                      @click="yulangfile(file.path)"
                      >{{ file.filename }}</a
                    ></el-tooltip
                  >
                </div>
                <div
                  style="
                    float: left;
                    height: 30px;
                    line-height: 30px;
                    padding-left: 10px;
                  "
                >
                  [<el-button
                    type="text"
                    @click="downloadFile(file.path)"
                    style="
                      height: 30px;
                      line-height: 30px;
                      padding: 0px !important;
                    "
                    >下载</el-button
                  >] [<el-button
                    type="text"
                    @click="previewFile(file.path)"
                    style="
                      height: 30px;
                      line-height: 30px;
                      padding: 0px !important;
                    "
                    >预览</el-button
                  >]
                </div>
              </div>
            </div>
            <div v-show="fjfiles.length == 0 ? true : false">
              附件：

              <div>
                <div style="float: left; height: 30px; line-height: 30px">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="点击预览附件"
                    placement="top-start"
                    ><a
                      style="cursor: pointer"
                      @click="yulangfile(essay.files)"
                      >{{
                        essay.files == undefined
                          ? ""
                          : essay.files.substring(
                              essay.files.lastIndexOf("/") + 1
                            )
                      }}</a
                    ></el-tooltip
                  >
                </div>
                <div
                  style="
                    float: left;
                    height: 30px;
                    line-height: 30px;
                    padding-left: 10px;
                  "
                >
                  [<el-button
                    type="text"
                    @click="downloadFile(essay.files)"
                    style="
                      height: 30px;
                      line-height: 30px;
                      padding: 0px !important;
                    "
                    >下载</el-button
                  >] [<el-button
                    type="text"
                    @click="previewFile(essay.files)"
                    style="
                      height: 30px;
                      line-height: 30px;
                      padding: 0px !important;
                    "
                    >预览</el-button
                  >]
                </div>
              </div>
            </div>
          </div>
          <div style="clear: both"></div>

          <el-row v-if="nwnewsBool">
            <el-col :span="20" style="text-align: right"> 本文作者： </el-col>
            <el-col :span="4" style="text-align: left">
              {{
                essay.auhtor != undefined
                  ? essay.auhtor
                  : essay.createBy != undefined
                  ? essay.createBy
                  : essay.create_by
              }}
            </el-col>
          </el-row>
          <el-row v-if="nwnewsBool" style="text-align: right; margin-top: 10px">
            <el-col :span="20"> 责任编辑： </el-col>
            <el-col :span="4" style="text-align: left">
              {{
                essay.createBy != undefined ? essay.createBy : essay.create_by
              }}
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>

    <!--  -->

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="60%"
      top="20vh"
      :before-close="handleClose"
    >
      <iframe width="100%" height="768px" :src="docx" frameborder="0"></iframe>

      <!-- <vue-office-docx :src="docx" style="height: 100vh" @rendered="rendered" /> -->
    </el-dialog>
    <mFooter></mFooter>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import mFooter from "../../components/mFooter.vue";
//官网动态
import { getNewById } from "@/api/content/xtbg_news.js";
//内网公告
import { getNoticeById } from "@/api/content/xtbg_notice.js";
// 反腐倡廉
import { getGraft } from "@/api/content/graft.js";

//项目资讯
import { getScienceinfoById } from "@/api/content/xtbg_science_info.js";

//内网新闻
import { getImportantpublicityById } from "@/api/content/xtbg_important_publicity.js";

//合同公示
import { getWorkprogressById } from "@/api/content/xtbg_work_progress.js";

//规章制度
import { getRulesisminfoById } from "@/api/content/xtbg_rules_ism.js";

//园务公开
import { getReponseopeninfoById } from "@/api/content/xtbg_reponse_open.js";

//办事指南
import { getWorkguideinfoById } from "@/api/content/xtbg_work_guide.js";

//学术报告
import { getAcademicreportById } from "@/api/content/xtbg_academic_report";
import { removeHtml, getYearMonthDay, goToTop } from "@/utils/common";
//内网新闻
import { getInNewById } from "@/api/content/xtbg_innews.js";
//引入VueOfficeDocx组件
import VueOfficeDocx from "@vue-office/docx";
//引入相关样式
import "@vue-office/docx/lib/index.css";
import CryptoJS from "crypto-js";

import { getUser, getUserByEmail } from "@/api/content/xtbg_user";
import { getSysUser } from "@/api/system/user";

let Base64 = require("js-base64").Base64;

let docx = require("docx-preview");
window.JSZip = require("jszip");

var _this = null;
export default {
  inject: ["reload"],
  components: {
    mFooter,
    VueOfficeDocx,
  },

  data() {
    return {
      fj_show: true,
      dialogVisible: false,
      docx: "", //"http://static.shanhuxueyuan.com/test6.docx", //设置文档网络地址，可以是相对地址
      essay: {},
      nwnewsBool: false,
      total: 0,
      pageSize: 20,
      current_title: "最新新闻",
      content_title: "",
      current_page: 1,
      current_addr: "",
      item_bg_image: require("../../assets/imgs/news/right.png"),
      item_active_bg_image: require("../../assets/imgs/news/right1.png"),

      list_items: [
        {
          name: "反腐倡廉",
          url: "",
        },
        {
          name: "综合服务",
          url: "",
        },

        {
          name: "项目资讯",
          url: "",
        },
        {
          name: "内网新闻",
          url: "",
        },
        {
          name: "内网公告",
          url: "",
        },
        {
          name: "官网动态",
          url: "",
        },
        {
          name: "园务公开",
          url: "",
        },
        {
          name: "规章制度",
          url: "",
        },
        // {
        //   name: "合同公示", 合并于内网公告
        //   url: "",
        // },
        // {
        //   name: "办事指南",删除
        //   url: "",
        // },
        // {
        //   name: "学术报告", 删除
        //   url: "",
        // },
      ],
      list_content_items: [],
      list_data: [],
      fjfiles: [],
    };
  },
  created() {
    //模拟数据
    _this = this;
  },
  watch: {},
  methods: {
    rendered() {
      console.log("渲染完成");
    },
    // 判断链接是否是可下载的文件
    validateFileIsDownload(url) {
      const validExts =
        /\.(doc|xls|ppt|docx|xlsx|pptx|txt|pdf|jpg|jpeg|gif|png|zip|rar|mp3|mp4)$/i;
      console.log(validExts.test(url));
      if (validExts.test(url)) {
        return true;
      }
      return false;
    },
    yulangfile(file) {
      // //console.log(file);
      //this.docx = window.URL.createObjectURL(new Blob([file]));
      // docx.
      // window.open(file);

      window.open(
        "http://42.193.22.219:8012/onlinePreview?url=" +
          encodeURIComponent(Base64.encode(file))
      );
    },
    downloadFile(file) {
      if (this.validateFileIsDownload(file)) {
        window.open(file);
      } else {
        this.$message({
          message: "没有对应的文件，请检查文件是否存在",
          type: "error",
          offset: 300,
        });
      }
    },
    previewFile(file) {
      // if (file.indexOf("pdf") > -1) {
      //   window.open(file);
      // } else {

      //   window.open(file);
      // }

      window.open(
        "http://42.193.22.219:8012/onlinePreview?url=" +
          encodeURIComponent(Base64.encode(file))
      );
    },
    handleClose() {
      this.dialogVisible = false;
    },

    rendered() {
      console.log("渲染完成");
    },
    errorHandler() {
      console.log("渲染失败");
    },
    initeData(title) {
      this.loadingInstance1 = Loading.service({
        fullscreen: true,
        text: "正在加载...",
      });
      this.nwnewsBool = false;
      switch (title) {
        case "官网动态":
          getNewById(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
        case "内网公告":
          getNoticeById(parseInt(this.$route.query.id)).then(async (res) => {
            this.essay = res.data.data;

            console.log(!this.essay.deptName);

            if (!this.essay.deptName) {
              const dept_name = await this.getDeptNameByDeptId(
                this.essay.deptId
              );
              this.$nextTick(() => {
                this.$set(this.essay, "deptName", dept_name);
              });
            }

            this.covertContentImg();
          });
          break;
        case "反腐倡廉":
          getGraft(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
        case "项目资讯":
          getScienceinfoById(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
        case "内网新闻":
          // getImportantpublicityById(parseInt(this.$route.query.id)).then(
          //   (res) => {
          //     this.essay = res.data.data;

          //     this.covertContentImg();
          //   }
          // );
          this.nwnewsBool = true;
          getInNewById(this.$route.query.id).then((res) => {
            this.essay = res.data.data;
            //console.table(this.essay);
            this.covertContentImg();
          });
          break;
        case "合同公示":
          getWorkprogressById(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
        case "规章制度":
          getRulesisminfoById(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
        case "园务公开":
          getReponseopeninfoById(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
        case "办事指南":
          getWorkguideinfoById(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
        case "学术报告":
          getAcademicreportById(parseInt(this.$route.query.id)).then((res) => {
            this.essay = res.data.data;
            this.covertContentImg();
          });
          break;
      }
      //詳細内容

      //解析文章内容提取Image
    },
    covertContentImg() {
      let news_content = document.getElementById("news-content");
      this.essay.createTime = getYearMonthDay(this.essay.createTime);
      this.essay.deptName = localStorage.getItem("deptName");
      //console.log(this.essay.files.length);
      if (this.essay.files != null && this.essay.files.length != 0) {
        if (this.essay.files.indexOf(",") > -1) {
          let files = this.essay.files.split(",");

          for (let i = 0; i < files.length; i++) {
            if (this.validateFileIsDownload(this.$api_url + files[i])) {
              this.fjfiles.push({
                filename: files[i].substring(files[i].lastIndexOf("/") + 1),
                path: this.$api_url + files[i],
              });
            }
          }
        } else {
          this.essay.files = this.$api_url + this.essay.files;
        }

        this.fj_show = true;
      } else {
        this.fj_show = false;
      }
      //责任编辑 essay.createBy

      if (this.essay.createBy != null) {
        getUser(this.essay.createBy).then((res) => {
          if (res.data.code == 500) {
            getUserByEmail(this.essay.createBy).then((res) => {
              this.essay.createBy = res.data.rows[0].name;
            });
          } else {
            if (res.data.data == undefined) {
              getSysUser(this.essay.createBy).then((res) => {
                if (res.data.data != undefined) {
                  this.essay.createBy = res.data.data.nickName;
                }
              });
            } else {
              this.essay.createBy = res.data.data.name;
            }
          }
        });
      }

      setTimeout(() => {
        let divs = news_content.querySelectorAll("div");
        for (let j = 0; j < divs.length; j++) {
          let div = divs[j];
          div.style.textIndent = "0em";
          div.style.fontSize = "16px";
          div.innerHTML = div.innerHTML.replaceAll("&nbsp;", "");
          div.style.textIndent = "2em";
          div.style.textAlign = "left";
        }
        let pp = news_content.querySelectorAll("p");
        for (let j = 0; j < pp.length; j++) {
          let p = pp[j];
          if (p.innerHTML.indexOf("br") > 0 && p.innerText.trim().length == 0) {
            p.remove();
          }
          p.style.textIndent = "0em";
          p.style.fontSize = "16px";
          p.innerHTML = p.innerHTML.replaceAll("&nbsp;", "");
          p.style.textIndent = "2em";
          p.style.textAlign = "left";
          p.style.margin = "0 0 8px 0";
          p.style.lineHeight = "30px";
        }

        let imgs = news_content.querySelectorAll("img");

        for (let i = 0; i < imgs.length; i++) {
          let img = imgs[i];
          let imgsrc = img.getAttribute("src");

          // let imgsrc = img.getAttribute("src").replaceAll('\\"',"");
          // img.setAttribute("src",imgsrc);

          if (imgsrc.indexOf("-api") > -1) {
            img.setAttribute("src", this.$api_url + imgsrc.split("api")[1]);
            img.style.width = "600px";
            img.parentElement.style.textAlign = "center";
            if (img.parentElement.nextElementSibling != null) {
              img.parentElement.nextElementSibling.style.textAlign = "center";
            }
          } else {
            if (img.parentElement.tagName.toLowerCase() == "p") {
              img.parentElement.style.textAlign = "center";
              if (img.parentElement.nextElementSibling != null) {
                img.parentElement.nextElementSibling.style.textAlign = "center";
              }
            } else {
              if (
                img.parentElement.parentElement.tagName.toLowerCase() == "p"
              ) {
                img.parentElement.parentElement.style.textAlign = "center";
                if (
                  img.parentElement.parentElement.nextElementSibling != null
                ) {
                  img.parentElement.parentElement.nextElementSibling.style.textAlign =
                    "center";
                }
              }
            }
          }
        }
        //处理超链接
        let list_a = news_content.querySelectorAll("a");
        if (list_a.length > 0) {
          for (let i = 0; i < list_a.length; i++) {
            let aa = list_a[i];
            aa.style.width = aa.innerText.length * 10 + "px";
            aa.style.textIndent = "0em";
            aa.style.display = "block";
            let new_a = document.createElement("span");
            new_a.innerHTML = aa.getAttribute("href");
            new_a.style.textDecoration = "underline";
            new_a.style.cursor = "pointer";
            new_a.style.color = "#0095ff";
            new_a.style.fontStyle = "italic";
            new_a.onclick = function () {
              window.open(new_a.innerHTML, "_blank");
            };
            if (aa.previousElementSibling != null) {
              aa.previousElementSibling.append(new_a);
              aa.style.display = "none";
            }

            // //console.log(aa.previousElementSibling.innerHTML+aa)
            // let div = document.createElement("div");
            // div.innerHTML =  aa.previousElementSibling.innerHTML+aa;

            // aa.parentElement.append(div);
          }
        }

        //增加浏览量
        this.addViewNum(this.current_title);
      }, 200);

      setTimeout(() => {
        _this.loadingInstance1.close();
      }, 500);
      // let imgs = news_content_p.querySelectorAll('img');
      // console.log(imgs);
    },
    currentChange(cpage) {
      this.current_page = cpage;
      this.list_content_items = [];
      //(cpage-1)*this.total;

      for (
        let i = (this.current_page - 1) * this.pageSize;
        i < this.current_page * this.pageSize;
        i++
      ) {
        if (typeof this.list_data[i] != "undefined") {
          this.list_content_items.push({
            id: i + 1,
            title: this.current_title + "-" + this.list_data[i].title,
            img: this.list_data[i].img,
            content: this.list_data[i].content,
          });
        }
        // console.log(this.list_data[i]);
      }
    },
    clickListItem(e, item) {
      // if (window.location.href.indexOf("newsdetail") > -1) {
      // this.$router
      //   .push({
      //     path: item.url,
      //     query: {
      //       name: item.name,
      //     },
      //   })
      //   .catch((err) => err);
      this.$router.push({
        path: "/news",
        query: {
          name: item.name,
        },
      });
      // } else {
      //   this.current_title = item.name;
      //   this.current_addr = "首页>" + item.name;
      //   this.activeEl(e);
      //   this.initeData(item.name);
      // }
    },
    activeEl(e) {
      let parentEL = e.currentTarget.parentElement;
      let lis = parentEL.querySelectorAll("li");
      for (let i = 0; i < lis.length; i++) {
        let mli = lis[i];
        mli.style.background = "url('" + this.item_bg_image + "') no-repeat";
        mli.style.backgroundPosition = "right center";
        mli.style.backgroundPositionX = "250px";
        mli.style.backgroundSize = "16px 16px";
        mli.style.color = "#000";
      }
      let current_li = e.currentTarget;
      current_li.style.background =
        "url('" + this.item_active_bg_image + "') no-repeat #519f6d";
      current_li.style.backgroundPosition = "right center";
      current_li.style.backgroundPositionX = "250px";
      current_li.style.backgroundSize = "16px 16px";
      current_li.style.color = "#fff";
    },
    selectItem(name) {
      let mlis = document
        .getElementsByClassName("list_news-items")[0]
        .querySelectorAll("li");
      for (let i = 0; i < mlis.length; i++) {
        let current_li = mlis[i];
        this.current_title = name;
        this.current_addr =
          "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
          "<a href='/news?name=" +
          name +
          "' style='cursor:point;text-decoration:none'>" +
          name +
          "</a>";

        current_li.style.background =
          "url('" + this.item_bg_image + "') no-repeat";

        current_li.style.backgroundPosition = "right center";
        current_li.style.backgroundPositionX = "250px";
        current_li.style.backgroundSize = "16px 16px";
        current_li.style.color = "#000";

        if (current_li.innerText == name) {
          this.current_title = name;
          this.current_addr =
            "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
            "<a href='/news?name=" +
            name +
            "' style='cursor:point;text-decoration:none'>" +
            name +
            "</a>";

          current_li.style.background =
            "url('" + this.item_active_bg_image + "') no-repeat #519f6d";

          current_li.style.backgroundPosition = "right center";
          current_li.style.backgroundPositionX = "250px";
          current_li.style.backgroundSize = "16px 16px";
          current_li.style.color = "#fff";
        }
      }
    },
    getRnd(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    clickTo(ss) {},
    encrypt(text) {
      const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
      const IV_LENGTH = 16; // 初始化向量的长度

      // 生成随机的初始化向量（IV）
      const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

      // 使用 AES 加密
      const encrypted = CryptoJS.AES.encrypt(
        text,
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // 返回加密后的字符串和 IV
      return (
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    addViewNum(title) {
      let tableName = null;
      switch (title) {
        case "项目资讯":
          tableName = "xtbg_science_info";
          break;
        case "内网新闻":
          tableName = "xtbg_innews";
          break;
        case "内网公告":
          tableName = "xtbg_notice";
          break;

        case "规章制度":
          tableName = "xtbg_rules_ism";
          break;
        case "园务公开":
          tableName = "xtbg_reponse_open";
          break;
      }

      if (this.essay.viewNum == null) {
        this.essay.viewNum = 1;
      } else {
        this.essay.viewNum = this.essay.viewNum + 1;
      }

      if (tableName != null) {
        let usqlStr =
          "UPDATE " +
          tableName +
          " set view_num = " +
          this.essay.viewNum +
          " WHERE id = " +
          this.$route.query.id;
        let updateSqlStr = {
          query: this.encrypt(usqlStr),
          params: [],
        };
        this.$http
          .post(this.$db_dynamic_url, updateSqlStr, {
            headers: {
              Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
            },
          })
          .then((res) => {
            console.log(res.data.message);
          });
      }
    },

    async getDeptNameByDeptId(DeptId) {
      const sqlStr = "select dept_name from sys_dept where dept_id=" + DeptId;

      const res = await this.$dbquery(this.$http, sqlStr);
      return res[0].dept_name || "";
    },
  },
  mounted() {
    //console.log(this.$route.query);
    // this.current_title = decodeURI(this.$route.query.title);

    // this.essay.content_title = decodeURI(this.$route.query.showTitle);

    this.current_title = decodeURI(localStorage.getItem("title"));
    this.essay.content_title = decodeURI(localStorage.getItem("showTitle"));

    this.selectItem(this.current_title);
    this.initeData(this.current_title);
    // this.current_addr =
    //   this.current_addr +
    //   "><a href=''>" +
    //   decodeURI(this.essay.content_title) +
    //   "</a>";

    goToTop();
  },
};
</script>

<style lang="scss" scoped>
.bny_news-list {
  padding: px 0px 20px 0px;
  width: 1400px;
  height: auto;
  margin: 0px auto;
  /* background: #f2f2f2; */
  .bny_news-list-left {
    width: 298px;
    float: left;
    height: auto;
    border: 1px solid #f2f2f2;
    background-color: #fff;

    .bny_news-list-left-top {
      height: 120px;
      line-height: 120px;
      width: 100%;
      background: url("../../assets/imgs/news/list-top.jpg") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
    }
    .bny_news-list-left-items {
      height: auto;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          height: 70px;
          line-height: 70px;
          text-align: center;
          color: #000;
          font-size: 16px;
          font-weight: 600;
          border-bottom: 1px solid #f2f2f2;
          background: url("../../assets/imgs/news/right.png") no-repeat;
          background-position: right center;
          background-position-x: 250px;
          background-size: 16px 16px;
          &:hover {
            cursor: pointer;

            background: url("../../assets/imgs/news/right1.png") no-repeat
              #519f6d;
            background-position: right center;
            background-position-x: 250px;
            background-size: 16px 16px;
            color: #fff;
          }
        }
      }
    }
  }

  .bny_news-list-right {
    width: 1080px;
    float: left;
    height: auto;
    margin-left: 20px;
    background-color: #fff;

    .bny_news-list-right-top {
      height: 40px;
      line-height: 40px;
      color: #000;
      background: #f3f4f9;
      width: calc(100% - 5px);
      text-align: left;
      padding-left: 5px;
      font-size: 14px;
      span {
        color: #519f6d;
      }
      box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.1);
    }
    .bny_news-list-right-content {
      padding-top: 5px;
      height: auto;
      min-height: 600px;
      padding-bottom: 20px;

      .bny_news-mcontent-title {
        padding-top: 20px;
        width: 100%;
        height: 60px;

        text-align: center;
        font-weight: bold;
        font-size: 22px;
      }
      .bny_news-mcontent-subtitle {
        width: 100%;
        height: 40px;
        line-height: 40px;
        color: #ddd;
        font-size: 12px;
        text-align: center;
      }
      .bny_news-mcontent-content {
        padding: 20px;
        line-height: 32px;

        ::v-deep p {
          text-align: left;
          font-size: 16px !important;
          line-height: 40px;
        }
        ::v-deep p a {
          display: block;
          text-align: center;
        }
      }
    }
  }
  .el-pagination {
    width: 42%;
    margin-top: 30px;
    margin-left: 403px;
    ::v-deep .btn-prev,
    ::v-deep .btn-next {
      border: 1px solid #f2f2f2;
      background-color: transparent;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    ::v-deep .el-icon-arrow-left:before,
    ::v-deep .el-icon-arrow-right:before {
      color: #d5bfa1;
      font-size: 14px;
    }
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #f2f2f2;
    background-color: #fff;
    color: #000;
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 14px;
    margin-right: 15px;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #519f6d;
    color: #fff;
  }
}
::v-deep .el-dialog__body {
  padding: 0px 5px;
}
::v-deep .el-button span {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-button {
  padding: 0px;
}
</style>