/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-29 13:36:15
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-05-30 17:50:34
 * @FilePath: \app_wb\src\api\content\xtbg_science_info.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js'
//规章制度
export function searchListSeekadviseInfo(queryParams) {
    return request('/content/seekadvise/list', {
        method: "get",
       
        params: queryParams
    });
}
export function ListSeekadviseInfo() {
    return request('/content/seekadvise/list', {
        method: "get",
        
        params: {
            pageSize: 100,
            pageNum: 1,

        }
    });
}
export function getSeekadviseById(id) {
    return request('/content/seekadvise/' + id, {
        method: "get",
        

    });
}
export function getSeekreplyinfosByAdviseTitle(title) {
    return request('/content/seekreply/list', {
        method: "get",
        
        params: {
            pageSize: 100,
            pageNum: 1,
            adviseId: title
        }

    });
}

//回复
export function AplyAdvise(data){
    return request('/content/seekreply', {
        method: "post",
       
        data:data
    });
}
//咨询
export function PostSeekAdvise(data){
    return request("/content/seekadvise",{
        method:"post",
        
        data:data
    })
}

