<template>
  <div class="gongjiao_jichuxinxi gongjiao">
    <div
      style="
        margin: 10px 0px;
        height: 100px;
        background-color: #f5f8f7;
        text-align: left;
        display: flex;
        align-items: center;
      "
    >
      <img
        src="../../../assets/imgs/gongwu/zuixingonggao.png"
        style="width: 60px; height: auto; margin-left: 20px"
        alt=""
        srcset=""
      />

      <div style="flex: 1; font-size: 14px; padding: 0px 20px">
        <div style="text-indent: 2em">
          尊敬的用户们， 公务车预约，上线试运行。
          一、内网用户端：第一，内网所有用户可以申请公务车，由车队管理审核指派公务车，其他用户可以搭乘可搭乘的公务车，搭乘需由对应用车人审核同意，即可搭乘。第二，可以自由发布顺风车信息。
          二、车队管理员端（只在PC端管理车辆基础信息，权限管理）。（pc端和小程序端均可审核和指派）审核内网用户公务车申请单，并指派对应车辆和司机（目前，司机和公务车属于一对一）。
          三、司机端（只在小程序端），查看跟自己有关系的公务车订单并可结束行程。
        </div>
        <div class="flex">
          <span style="color: #808080; margin-left: auto"> 2024年9月28日 </span>
        </div>
      </div>
    </div>
    <!-- .el-tabs__item.is-active -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="车辆信息管理" name="first">
        <div>
          <div class="flex" style="margin-bottom: 10px">
            <el-button @click="addNewCar" icon="el-icon-plus" type="success"
              >添加车辆</el-button
            >
            <div style="margin-left: auto; margin-right: 10px">
              <el-input
                type="text"
                v-model="searchLicensePlate"
                placeholder="搜索车牌号"
              ></el-input>
            </div>
            <!-- <div>
                            <el-input type="text" v-model="searchDriverId" placeholder="搜索司机"></el-input>
                        </div> -->
            <div class="flex">
              <el-button
                @click="searchVehicles"
                icon="el-icon-search"
                type="success"
                style="margin-left: 10px"
                >搜索</el-button
              >
              <el-button
                @click="realoadData"
                icon="el-icon-refresh"
                type="text"
                style="margin-left: 10px"
                >刷新</el-button
              >
            </div>
          </div>

          <el-table border height="600px" :data="vehicles" style="width: 100%">
            <el-table-column
              prop="uid"
              label="序号"
              width="60"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="license_plate"
              label="车牌号"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="车辆名称"
              width="180"
            ></el-table-column>
            <el-table-column prop="type" label="车辆类型"></el-table-column>
            <el-table-column prop="seats" label="座位数"></el-table-column>
            <el-table-column
              width="120"
              prop="price"
              label="单价（元/km）"
            ></el-table-column>
            <el-table-column prop="user_name" label="司机"></el-table-column>
            <el-table-column
              prop="admin_name"
              label="车队管理员"
            ></el-table-column>

            <el-table-column prop="status" label="车辆状态">
              <template slot-scope="scope">
                <!-- 车辆状态（0空闲 1已预约 2维护中） -->
                <span v-if="scope.row.status == 0" style="color: #409eff"
                  >空闲</span
                >
                <span v-else-if="scope.row.status == 1" style="color: #008500"
                  >已预约</span
                >
                <span v-else-if="scope.row.status == 2" style="color: #f00"
                  >维护中</span
                >
                <span v-else>状态异常</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="160">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="editVehicle(scope.$index)"
                  icon="el-icon-edit"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="deleteVehicle(scope.row.id)"
                  style="color: #f00"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total,prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page.sync="currentPage"
            @current-change="handleCurrentChange"
            style="margin-top: 20px; text-align: center"
          />
          <!-- 添加车辆 -->
          <el-drawer
            title="车辆信息表单"
            :visible.sync="dialogVisible"
            size="30%"
            :close-on-press-escape="false"
            @close="handleClose"
          >
            <div style="display: flex; flex-wrap: wrap; gap: 10px">
              <el-form
                :model="newVehicle"
                label-width="120px"
                style="flex: 1; min-width: 300px; padding-right: 20px"
              >
                <el-form-item label="车牌号">
                  <el-input v-model="newVehicle.license_plate"></el-input>
                </el-form-item>
                <el-form-item label="车辆名称">
                  <el-input v-model="newVehicle.name"></el-input>
                </el-form-item>
                <el-form-item label="车辆类型">
                  <div
                    style="
                      padding-top: 12px;
                      display: flex;
                      justify-content: flex-start;
                    "
                  >
                    <el-radio-group
                      v-model="newVehicle.type"
                      style="text-align: left"
                    >
                      <el-radio label="轿车">轿车</el-radio>
                      <el-radio label="客车">客车</el-radio>
                      <el-radio label="越野客车">越野客车</el-radio>
                      <el-radio label="商务车">商务车</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="座位数">
                  <el-input v-model.number="newVehicle.seats"></el-input>
                </el-form-item>
                <el-form-item label="单价（元/km）">
                  <el-input v-model.number="newVehicle.price"></el-input>
                </el-form-item>
                <!-- <el-form-item label="车辆颜色">
                                    <el-input v-model="newVehicle.color"></el-input>
                                </el-form-item> -->

                <el-form-item label="司机">
                  <!-- <el-input v-model="newVehicle.user_id"></el-input> -->
                  <el-autocomplete
                    ref="mySjAutocomplete"
                    class="inline-input"
                    v-model="newVehicle.user_id"
                    :fetch-suggestions="querySearchUser"
                    placeholder="请输入查询选择"
                    style="width: 100%"
                    :trigger-on-focus="false"
                    value-key="name"
                    @select="handleSelectUser"
                  >
                    <!-- <template slot-scope="{ item }">
                      <div>{{ item.name }}</div>
                    </template> -->
                  </el-autocomplete>
                </el-form-item>
                <el-form-item label="车队管理员">
                  <el-autocomplete
                    ref="myAdminAutocomplete"
                    class="inline-input"
                    v-model="newVehicle.admin_id"
                    :fetch-suggestions="querySearchUser"
                    placeholder="请输入查询选择"
                    style="width: 100%"
                    :trigger-on-focus="false"
                    value-key="name"
                    @select="handleSelectAdminUser"
                  >
                    <!-- <template slot-scope="{ item }">
                      <div>{{ item.name }}</div>
                    </template> -->
                  </el-autocomplete>
                </el-form-item>
                <el-form-item label="车辆状态">
                  <div
                    style="
                      padding-top: 12px;
                      display: flex;
                      justify-content: flex-start;
                    "
                  >
                    <el-radio-group
                      v-model="newVehicle.status"
                      style="text-align: left"
                    >
                      <el-radio :label="0">空闲</el-radio>
                      <el-radio :label="1">已预约</el-radio>
                      <el-radio :label="2">维护中</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input v-model="newVehicle.remark"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="flex">
              <el-button
                style="margin-left: auto"
                type="success"
                @click="addVehicle"
                v-if="editVisible == false"
                >添加</el-button
              >
              <el-button
                style="margin-left: auto"
                type="success"
                @click="updateVehicle"
                v-if="editVisible == true"
                >更新</el-button
              >
              <el-button
                style="margin-right: 20px"
                type="default"
                @click="dialogVisible = false"
                >取消</el-button
              >
            </div>
          </el-drawer>
        </div>
      </el-tab-pane>
      <el-tab-pane label="公告信息管理" name="second">公告信息管理</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
let tableVihicleName = "xtbg_officecar_vihicleinfo";
export default {
  data() {
    return {
      activeName: "first",
      searchLicensePlate: "", // 用于车牌号的搜索
      searchDriverId: "", // 用于司机ID的搜索

      vehicles: [], // 存储车辆数据的数组
      newVehicle: {
        user_id: null,
        admin_id: null,
        license_plate: "",
        name: "",
        type: "", // 车辆类型
        seats: 0,
        price: 0,
        status: 0, // 车辆状态
        remark: "",
      },

      dialogVisible: false, // 控制对话框的显示
      dialogVisibleForUpdate: false, //更新

      currentPage: 1,
      total: 0,
      pageSize: 8, // 每页显示的数据量

      editVisible: false,
      select_id: 0,
    };
  },
  async mounted() {
    await this.getVechicle();
  },
  computed: {},
  methods: {
    addNewCar() {
      this.resetForm();
      this.dialogVisible = true;
      this.editVisible = false;
    },

    async querySearchUser(queryString, cb) {
      var restaurants = await this.$userList(this.$http);

      // restaurants = restaurants.map(row=>{
      //   return {
      //     user_id: row.id,
      //     name: row.name,
      //   };
      // })

      //console.log(restaurants);


      var results = queryString
        ? restaurants.filter(this.createFilterUser(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilterUser(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    handleSelectUser(user) {
      console.log(user);
      this.newVehicle.user_id = `${user.id}`;
      this.$nextTick(() => {
        this.$refs.mySjAutocomplete.value = user.name;
      });

      //console.log(this.newVehicle);
    },
    handleSelectAdminUser(user) {
      // this.newVehicle.admin_id = user.id;
      this.newVehicle.admin_id = `${user.id}`;

      this.$nextTick(() => {
        this.$refs.myAdminAutocomplete.value = user.name;
      });

      //console.log(this.newVehicle);
    },
    //搜索
    async searchVehicles() {
      //获取车辆信息
      const data = await this.$db_select(
        this.$http,
        tableVihicleName,
        "*",
        "license_plate like '%" +
          this.searchLicensePlate +
          "%' order by id desc limit " +
          this.pageSize +
          " offset " +
          (this.currentPage - 1) * this.pageSize
      );

      this.total = (
        await this.$db_select(
          this.$http,
          tableVihicleName,
          "count(*) as total",
          "license_plate like '%" + this.searchLicensePlate + "%'"
        )
      )[0].total;
      let index = 0;
      this.vehicles = await Promise.all(
        data.map(async (row) => {
          row.uid = ++index;
          if (row.user_id) {
            const uerRes = await this.$db_select(
              this.$http,
              "xtbg_user",
              "name",
              "id=" + row.user_id
            );
            row.user_name = uerRes.length > 0 ? uerRes[0].name : "";
          }
          if (row.admin_id) {
            const adminRes = await this.$db_select(
              this.$http,
              "xtbg_user",
              "name",
              "id=" + row.admin_id
            );
            row.admin_name = adminRes.length > 0 ? adminRes[0].name : "";
          }

          return row;
        })
      );
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getVechicle();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async realoadData() {
      this.currentPage = 1;
      await this.getVechicle();
    },
    async getVechicle() {
      //获取车辆信息
      const data = await this.$db_select(
        this.$http,
        tableVihicleName,
        "*",
        "1=1 order by id desc limit " +
          this.pageSize +
          " offset " +
          (this.currentPage - 1) * this.pageSize
      );

      this.total = (
        await this.$db_select(
          this.$http,
          tableVihicleName,
          "count(*) as total",
          "1=1"
        )
      )[0].total;
      let index = 0;
      this.vehicles = await Promise.all(
        data.map(async (row) => {
          row.uid = ++index;
          if (row.user_id) {
            const uerRes = await this.$db_select(
              this.$http,
              "xtbg_user",
              "name",
              "id=" + row.user_id
            );
            row.user_name = uerRes.length > 0 ? uerRes[0].name : "";
          }
          if (row.admin_id) {
            const adminRes = await this.$db_select(
              this.$http,
              "xtbg_user",
              "name",
              "id=" + row.admin_id
            );
            row.admin_name = adminRes.length > 0 ? adminRes[0].name : "";
          }

          return row;
        })
      );
    },

    validateLicensePlate(licensePlate) {
      // 正则表达式，匹配蓝牌和新能源车牌
      const pattern =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NPQRTUWXY0-9]{4,5}[A-HJ-NPQRTUWXY0-9挂学警港澳]{0,1}$/;
      return pattern.test(licensePlate);
    },

    async addVehicle() {
      // 添加新车辆到数组

      if (!this.validateLicensePlate(this.newVehicle.license_plate.trim())) {
        this.$message.error("请输入正确的车牌号");
        return;
      }
      if (!this.newVehicle.name.trim()) {
        this.$message.error("请输入正确的车辆名称");
        return;
      }
      if (!this.newVehicle.type.trim()) {
        this.$message.error("请输入正确的车辆类型");
        return;
      }
      if (!this.newVehicle.seats) {
        this.$message.error("请输入正确的座位数");
        return;
      }
      if (!this.newVehicle.price) {
        this.$message.error("请输入正确的单价");
        return;
      }

      // this.vehicles.push({ ...this.newVehicle, create_time: new Date() });
      if (!this.newVehicle.user_id) {
        delete this.newVehicle.user_id;
      }
      if (!this.newVehicle.admin_id) {
        delete this.newVehicle.admin_id;
      }
      if (!this.newVehicle.remark) {
        delete this.newVehicle.remark;
      }
      //提交到后台
      //console.log(this.newVehicle);
      const insertRes = await this.$db_insert(
        this.$http,
        tableVihicleName,
        this.newVehicle
      );

      if (insertRes.affectedRows > 0) {
        this.$message.success("添加成功");
        this.dialogVisible = false;
        this.resetForm();
        this.getVechicle();
      }
    },
    async deleteVehicle(id) {
      // 根据索引删除车辆
      //this.vehicles.splice(index, 1);

      // 删除车辆数据 根据ID
      //确认删除对话框
      this.$confirm("确认删除该数据？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const deleteRes = await this.$db_delete(
            this.$http,
            tableVihicleName,
            "id=" + id
          );
          if (deleteRes.affectedRows > 0) {
            this.$message.success("删除成功");
            this.getVechicle();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
            offset: 200,
          });
        });
    },

    editVehicle(index) {
      // 编辑车辆信息，填充表单

      this.dialogVisible = true; // 显示对话框

      this.editVisible = true;

      setTimeout(async () => {
        this.select_id = this.vehicles[index].id;
        for (let key in this.vehicles[index]) {
          if (key in this.newVehicle) {
            this.newVehicle[key] = this.vehicles[index][key];
          }
        }

        // this.newVehicle = { ...this.vehicles[index] };

        console.log(this.newVehicle);
       

        if (this.newVehicle.user_id) {
          //this.newVehicle.user_id = `${this.newVehicle.user_id}`;
          const userRes = await this.$db_select(
            this.$http,
            "xtbg_user",
            "name",
            "id=" + this.newVehicle.user_id
          );


          this.$refs.mySjAutocomplete.value = userRes[0].name;
        }
        if (this.newVehicle.admin_id) {
          //this.newVehicle.admin_id = `${this.newVehicle.admin_id}`;
          const userRes = await this.$db_select(
            this.$http,
            "xtbg_user",
            "name",
            "id=" + this.newVehicle.admin_id
          );

          this.$refs.myAdminAutocomplete.value = userRes[0].name;
        }
      }, 1000);
    },
    async updateVehicle() {
      // 更新车辆信息
      // console.log(this.newVehicle);
      // console.log(this.select_id);
      // if (!this.newVehicle.user_id) {
      //   delete this.newVehicle.user_id;
      // }
      // if (!this.newVehicle.admin_id) {
      //   delete this.newVehicle.admin_id;
      // }
      // if (!this.newVehicle.remark) {
      //   delete this.newVehicle.remark;
      // }
      const updateRes = await this.$db_update(
        this.$http,
        tableVihicleName,
        this.newVehicle,
        "id=" + this.select_id
      );

      if (updateRes.affectedRows > 0) {
        this.$message.success("修改成功");
        this.dialogVisible = false;
        this.getVechicle();
      }

      this.resetForm();
    },

    resetForm() {
      // 重置表单
      this.newVehicle = {
        user_id: null,
        admin_id: null,
        license_plate: "",
        name: "",
        type: "轿车",
        seats: 0,
        price: 0,
        color: "",
        status: 0,
        remark: "",
      };
      this.dialogVisible = false; // 关闭对话框
      this.dialogVisibleForUpdate = false;
    },
  },
};
</script>

<style></style>
