<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-08 11:08:29
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-12 15:32:17
 * @FilePath: \app_wb\src\views\Advise\Detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div>
    <div id="bny_advise" class="bny_advise">
      <div class="bny_advise-top">
        <div>咨询平台</div>
        <div class="bny_advise-top-btn">
          <el-button
            type="primary"
            round
            style="background-color: #519f6d; letter-spacing: 2px"
            @click="back_advise"
            >返回</el-button
          >
        </div>
      </div>
      <div class="bny_advise-showcontent">
        <div class="advise_content">姓名：{{ advise.personal_name }}</div>
        <div class="advise_content">标题：{{ advise.title }}</div>
        <div class="advise_content">内容：{{ advise.content }}</div>

        <div>
          <ul>
            <li>时间：{{ advise.date }}</li>
            <li>审核状态：{{ advise.status }}</li>
            <li @click="centerDialogVisible = true">
              <i class="el-icon-chat-line-square" style="margin-right: 5px"> </i
              >回复
            </li>
          </ul>
        </div>
        <div id="apply-content" class="apply-content"></div>
      </div>
    </div>
    <div style="clear: both"></div>
    <mFooter id="bny_footer"></mFooter>
    <!-- style="position: fixed; bottom: 0px" -->
    <!-- 回复对话框 -->
    <el-dialog
      class="dialogClass"
      :close-on-click-modal="false"
      style="text-align: left; font-size: 14px"
      icon="el-icon-chat-dot-round"
      :visible.sync="centerDialogVisible"
    >
      <div slot="title" class="dialog-header-title" style="font-size: 16px">
        <i class="el-icon-chat-line-round" style="margin-right: 5px"></i
        >{{ dialog_title }}
      </div>
      <div>
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入回复内容"
          v-model="textarea"
        >
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="centerDialogVisible = false"
          icon="el-icon-circle-close"
          style="font-size: 12px"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="applyTo"
          style="font-size: 12px"
          icon="el-icon-circle-check"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import mFooter from "@/components/mFooter.vue";
import { Loading } from "element-ui";
export default {
  components: {
    mFooter,
  },
  data() {
    return {
      dialog_title: "",
      centerDialogVisible: false,
      advise: {
        personal_name: "李一就",
        title: "保鲜手段",
        content: "高端食材需要哪些保鲜手段？",
        date: "2023-04-08",
        status: "已审核",
      },
      textarea: "",
    };
  },
  methods: {
    back_advise(){
      this.$router.push({
        path:"/advise",
      })
    },
    //回复内容
    applyTo() {
      if (this.textarea.length > 0) {
        // let advise_contents = document.getElementsByClassName("advise_content");
        // let advise_content_last = advise_contents[advise_contents.length - 1];

        // let newDiv = document.createElement("div");
        // newDiv.setAttribute("class", "advise_content");
        // newDiv.style.paddingLeft = "20px";

        // let icon_apply = document.createElement("i");
        // icon_apply.setAttribute("class", "el-icon-chat-dot-round");
        // icon_apply.style.marginLeft = "5px";
        // newDiv.append(icon_apply);
        // let span_content = document.createElement("span");
        // span_content.innerText = this.textarea;
        // newDiv.append(span_content);
        // advise_content_last.after(newDiv);

        // //第二次获取
        // let advise_contents2 = document.getElementsByClassName("advise_content");
        // let advise_content_last2 = advise_contents2[advise_contents.length - 1];
        let newDiv = document.createElement("div");
        let icon_apply = document.createElement("i");
        icon_apply.setAttribute("class", "el-icon-chat-dot-round");
        icon_apply.style.marginRight = "5px";
        newDiv.append(icon_apply);
        let span_content = document.createElement("span");
        span_content.innerText = this.textarea;
        newDiv.append(span_content);

        let newDiv2 = document.createElement("div");
        newDiv2.style.color = "#d2d2d2";
        newDiv2.style.textAlign = "right";
        newDiv2.style.width = "100%";

        let span_aswer = document.createElement("span");
        span_aswer.style.float = "left";
        span_aswer.style.marginRight = "20px";

        span_aswer.innerText = "回复人：" + localStorage.getItem("username");
        let span_aswer_time = document.createElement("span");
        span_aswer_time.style.float = "left";
        span_aswer_time.innerText = "回复时间：" + this.getNowDate();

        newDiv2.append(span_aswer);
        newDiv2.append(span_aswer_time);

        document.getElementById("apply-content").append(newDiv);

        document.getElementById("apply-content").append(newDiv2);
        let clearDiv = document.createElement("div");
        clearDiv.style.clear = "both";
        document.getElementById("apply-content").append(clearDiv);

        if (document.getElementById("bny_advise").clientHeight > 600) {
          let bny_footer = document.getElementById("bny_footer");
          bny_footer.removeAttribute("style");
        }

        this.centerDialogVisible = false;
      } else {
        this.$message({
          message: "回复不能为空。",
          type: "warning",
        });
      }
    },
    // 格式化日对象
    getNowDate() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      var weekArr = [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期天",
      ];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds
      );
    },
  },
  mounted() {
    //根据标题 查询对应咨询详细内容
    this.advise.title = this.$route.query.title;
    this.dialog_title = "回复" + this.advise.title;
    if (document.getElementById("bny_advise").clientHeight < 600) {
      let bny_footer = document.getElementById("bny_footer");
      bny_footer.style.position = "fixed";
      bny_footer.style.bottom = "0px";
    }

    //console.log(this.$route.query.title);
  },
};
</script>
  
  <style lang="scss" scoped>
.bny_advise {
  padding: 5px 0px;
  width: 1400px;
  background: #fff;
  height: auto;
  margin: 0 auto;
  overflow-y: scroll;

  .bny_advise-showcontent {
    text-align: left;
    font-size: 14px;
    padding: 20px;
    height: auto;
    div {
      height: 40px;
      line-height: 40px;
      &:nth-child(3) {
        line-height: 0px;
      }
      &:nth-last-child(2) {
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            float: left;
            &:first-child {
              color: #cfdfd4;
              font-style: italic;
            }
            &:nth-child(2) {
              margin-left: 10px;
              color: #cfdfd4;
            }
            &:last-child {
              float: right;
              cursor: pointer;
              &:hover {
                color: #f00;
              }
            }
          }
        }
      }
    }
    .apply-content {
      padding-left: 20px;
      width: 100%;
      height: 40px;
    }
  }
  .bny_advise-top {
    width: calc(100% - 240px);
    height: 100px;
    line-height: 100px;
    background: url("../../assets/imgs/advise/advise-top.png") no-repeat #519f6d;
    background-position-x: 60px;
    background-position-y: 10px;
    background-size: 1280px 80px;
    text-align: left;
    padding: 0 120px;
    font-size: 18px;
    font-weight: bold;
    color: #2e6548;
    div {
      float: left;
    }
    .bny_advise-top-btn {
      float: right;
    }
  }
  .bny_advise-search {
    height: 60px;
    line-height: 60px;
    width: 100%;
  }
  .bny_advise-list {
    height: auto;
    width: calc(100% - 40px);
    padding: 20px;
  }
}
</style>