export function checkEmail(rule, value, callback) {
    const mal = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    // const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    if (rule.required && !value) {
        return callback(new Error('不能为空'))
    }
    if (value) {
        if (!(mal.test(value))) {
            callback(new Error('请输入正确邮箱'))
        } else {
            callback()
        }
    }

}
export function checkPhone(rule, value, callback) {
    if (!value) {
        return callback(new Error('手机号不能为空'));
    } else {
        //验证手机号
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        //验证区号
        const phoneReg = /^\d{3}-\d{8}|\d{4}-\d{7}$/
        console.log(reg.test(value));
        if (reg.test(value) || phoneReg.test(value)) {
            callback();
        } else {
            return callback(new Error('请输入正确的联系电话'));
        }
    }

}
