/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-06-14 11:24:40
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-14 20:37:57
 * @FilePath: \app_wb\src\api\content\db\search.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE[]
 */

import request from '@/utils/request5008.js';

export const tables = ["xtbg_innews", "xtbg_notice", "xtbg_science_info", "xtbg_rules_ism", "xtbg_reponse_open", "xtbg_user"]
export const tables2 = [{ tbname: "xtbg_innews", name: "内网新闻" }, { tbname: "xtbg_notice", name: "内网公告" }, { tbname: "xtbg_science_info", name: "项目资讯" }, { tbname: "xtbg_rules_ism", name: "规章制度" }, { tbname: "xtbg_reponse_open", name: "园务公开" }, { tbname: "xtbg_user", name: "通讯录" }]

export function searchContent(tableName, searchText) {
    return request({
        url: '/dynamic',
        method: "post",
        data: {
            "query": "select * from ?? where content like '%" + searchText + "%'",
            "params": [tableName]
        }
    })
}