<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 10:27:54
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-15 16:27:07
 * @FilePath: \app_wb\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div id="app">
    <Header></Header>
    <div class="bny_main">
      <router-view v-if="isRouterAlive" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

import { jubgeIsOrNot } from "@/utils/local_db.js";
var _this = null;
export default {
  metaInfo: {
    title: "XTBG内网平台",
  },
  name: "app",
  components: {
    Header,
  },
  created() {
    _this = this;
  },
  watch: {
    "$store.state.loginStatus": {
      handler(newV) {
        if (newV) {
          let btn_login_status = document.getElementById("btn_login_status");
          if (btn_login_status.innerText.indexOf("登录") > -1) {
            btn_login_status.innerText = "退出";
          }
        }
      },
      deep: true,
    },
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  mounted() {
    // if (process.env.MODE_ENV == "production") {
    //   console.log = function () {};
    //   console.error = function () {};
    //   console.warn = function () {};
    // }

    document.onkeydown = () => {
      //禁用F12
      if (window.event && window.event.keyCode == 123) {
        return false;
        //禁用ctrl+shift+i,
      } else if (
        window.event.ctrlKey &&
        window.event.shiftKey &&
        window.event.keyCode == 73
      ) {
        return false;
        //屏蔽Shift+F10
      } else if (window.event.shiftKey && window.event.keyCode == 121) {
        return false;
      }
    };
    //屏蔽右键菜单
    document.oncontextmenu = function (event) {
      if (window.event) {
        event = window.event;
      }
      try {
        var the = event.srcElement;
        if (
          !(
            (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
            the.tagName == "TEXTAREA"
          )
        ) {
          return false;
        }
        return true;
      } catch (e) {
        return false;
      }
    };
    if (jubgeIsOrNot() || localStorage.getItem("username") == null) {
      this.$router.push({
        path: "/login",
      });
    }
  },
};
</script>
<style lang="scss">
$mycolor: #519f6d;
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* font-family: "Microsoft YaHei"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  background: url("./assets/index_bj.jpg") no-repeat;
  background-attachment: fixed;
}
.bny_main {
  position: absolute;
  left: 0;
  top: 140px;
  margin-bottom: 200px;
  width: 100%;
  height: auto;
  text-align: center;

  overflow: hidden;
  background: #fff;
}
.el-input-group__append {
  color: #fff !important;
  background-color: #568c6a !important;
  font-weight: bold;
  font-size: 14px;
}
.el-input-group--append .el-input__inner,
.el-input-group__prepend {
  border-color: #568c6a !important;
}
.el-input-group {
  width: 76% !important;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 0.35rem;
  height: 0.35rem;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.375rem #fbfbfb;
  border-radius: 0.625rem;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  box-shadow: inset 0 0 0.375rem #999;
  background-color: #ebeef5;
}
.el-table__body tr:hover {
  background-color: rgba($color: #1f1414, $alpha: 0.3);
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  color: #00742f;
  cursor: pointer;
}

.dialogClass .el-dialog__body {
  padding: 0px 10px;
  padding-top: 5px;
}
.bny_advise-showcontent .el-form-item__label {
  color: #00742f;
  font-size: 14px;
  font-weight: 600;
}

/* 提案系统 */
.main_taxt {
  text-align: left;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-1 {
  flex: 1;
}
.height-40 {
  height: 40px;
}
.line-height-40 {
  line-height: 40px;
}
.line-height-50 {
  line-height: 50px;
}
.height-38 {
  height: 38px;
}
.line-height-38 {
  line-height: 38px;
}
.height-30 {
  height: 30px;
}
.width-80 {
  width: 80px;
}
.width-90 {
  width: 90px;
}
.width-20 {
  width: 20px;
}
.width-100 {
  width: 100px;
}
.width-120 {
  width: 120px;
}
.width-160 {
  width: 160px;
}
.width-180 {
  width: 180px;
}
.width-200 {
  width: 200px;
}
.width-300 {
  width: 300px;
}
.width-400 {
  width: 400px;
}
.width-600 {
  width: 600px;
}
.line-height-30 {
  line-height: 30px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-bottom-5 {
  padding: 5px 0;
}
.margin-top-bottom-5 {
  margin: 5px 0px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.font-weight-600 {
  font-weight: 600;
}
.padding-top-10 {
  padding-top: 10px;
}
.height-80 {
  height: 80px;
}
.min-height-80 {
  min-height: 80px;
}
.height-100 {
  height: 100px;
}
.height-200 {
  height: 200px;
}
.height-300 {
  height: 300px;
}
.line-height-80 {
  line-height: 80px;
}
.height-60 {
  height: 60px;
}
.height-50 {
  height: 50px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.f-22 {
  font-size: 22px;
}
.f-28 {
  font-size: 28px;
}
.margin-left-right-5 {
  margin: 0px 5px;
}
.padding-left-right-5 {
  padding: 0px 5px;
}
.border-all {
  border: 1px solid #ddd;
}
.boder-top-1 {
  border-top: 1px solid #363636;
}
.boder-bottom-1 {
  border-bottom: 1px solid #363636;
}
.boder-left-1 {
  border-left: 1px solid #363636;
}
.boder-right-1 {
  border-right: 1px solid #363636;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-bottom-1 {
  border-bottom: 1px solid #ddd;
}
.main_taxt_table .el-table .el-table__header-wrapper th {
  background: #eff6ef;
}
.main_taxt_table .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  height: 40px;
}
.main_taxt .el-range-editor.is-active,
.el-range-editor.is-active:hover,
.el-select .el-input.is-focus .el-input__inner {
  border-color: #25834b !important;
}

#propose_table {
  .el-radio__inner {
    border-radius: 0; /* 移除圆角效果，使其成为方形 */
  }
  /* 调整选中时的图标为方形 */
  .el-radio__inner::after {
    border-radius: 0; /* 移除圆角效果 */
  }
  .el-radio__label {
    font-size: 16px !important;
  }

  /* 确保el-radio垂直居中显示 */
  .el-radio {
    display: flex;
    align-items: center; /* 通过flex布局使内容垂直居中 */
    font-size: 16px;
  }
  /* 隐藏原始的选中标记 */
  .el-radio__inner::after {
    content: none; /* 隐藏默认的圆点 */
  }

  .el-radio.is-checked .el-radio__label {
    color: #25834b;
  }

  /* 自定义选中时的打勾样式 */
  .el-radio.is-checked .el-radio__inner {
    border-color: #25834b; /* 设置边框颜色，可根据需要调整 */
    background-color: #25834b; /* 设置背景颜色，以便打勾可见 */
  }

  .el-radio.is-checked .el-radio__inner::before {
    content: "✔";
    color: white; /* 打勾的颜色 */
    position: absolute;
    font-size: 14px; /* 调整大小 */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 居中对齐 */
    line-height: 0; /* 调整行高以适应大小 */
  }

  /* 确保el-radio垂直居中显示 */
  .el-radio {
    display: flex;
    align-items: center; /* 通过flex布局使内容垂直居中 */
  }

  .el-input__inner:focus {
    border-color: #25834b !important; /* 使用!important来覆盖默认样式 */
  }

  .el-input__inner {
    border: none !important; /* 移除边框 */
    box-shadow: none !important; /* 移除可能的阴影效果 */
    font-size: 16px;
    .title {
      font-size: 24px;
    }
  }
  /* 隐藏el-input组件的textarea边框 */
  .el-textarea__inner {
    border: none !important; /*  移除边框 */
    box-shadow: none !important; /* 移除可能的阴影效果 */
    font-size: 20px;
  }
}
.el-input.is-disabled .el-input__inner {
  color: #363636 !important;
  background-color: #fff !important;
}
.el-textarea.is-disabled .el-textarea__inner {
  color: #363636 !important;
  background-color: #fff !important;
}
#dynamicTable {
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    height: 40px;
    line-height: 40px;
  }
}
#meettingTable {
  .el-table__body tr:hover {
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    color: #00742f;
    cursor: pointer;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #519f6d;
    color: #fff;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    height: 2.5rem;
    line-height: 40px;
    width: 40px;
  }
  .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    height: 2.5rem;
    line-height: 40px;
  }
}

// #dynamicDatatable {
//   .el-table .el-table__cell {
//     &:nth-child(3) {
//       color: #519f6d;

//     }
//   }
// }

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  height: 40px;
}
.yqsb_mg .el-dialog__body {
  padding-top: 2px;
}
.yqsb_mg .el-dialog__header {
  padding: 0px 10px;
}
.yqsb_mg .el-form-item__content {
  width: 100%;
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none !important;
}
// 危化品
.box-card {
  .el-card__body {
    padding: 0px !important;
  }
}

.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  height: 40px !important;
  line-height: 40px !important;
}
.el-dialog__footer {
  padding-left: 0px !important;
}
.el-dialog__body {
  padding: 10px !important;
}

/* 覆盖 nprogress 的默认样式 */
#nprogress .bar {
  background: #085; /* 你想要的颜色 */
}

#nprogress .peg {
  box-shadow: 0 0 10px #085, 0 0 5px #085; /* 你想要的颜色 */
}

#nprogress .spinner-icon {
  border-top-color: #085; /* 你想要的颜色 */
  border-left-color: #085; /* 你想要的颜色 */
}
#weihuapin_add {
  .el-form-item__error {
    top: 50% !important;
    left: 10px;
  }
  .el-form-item {
    margin-bottom: 5px;
  }
  .el-radio {
    margin-right: 6px;
  }
  .el-checkbox {
    margin-right: 6px;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: #085;
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: #085;
    border-color: #085;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #085;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #085;
    border-color: #085;
  }
  .el-button--primary {
    background: #085;
    border-color: #085;
  }
  .el-form-item__label {
    max-width: 120px;
  }
  .el-dialog__header {
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #ebeef5;
  }
}
.weihuapin_btns {
  .el-button + .el-button,
  .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 5px;
  }
}
.weihuapin_detail {
  .el-card {
    border: none;
  }
}
#list_hazard {
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #519f6d;
    border-color: #519f6d;
  }
}
.myform{
  .el-form-item {
    margin-bottom: 6px;
  }
}
#dialog_shenling {
  .section {
    margin-bottom: 0px;
  }
  .el-form-item {
    margin-bottom: 4px;
  }
  .el-button--primary {
    background: #519f6d;
    border-color: #519f6d;
  }
  .el-dialog__header {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ebeef5;
  }
  .section {
    padding: 0px;
  }
  .el-dialog__footer {
    padding: 0px 10px 10px 0px;
  }
  .el-dialog__body {
    background-color: #f5f7fa !important;
  }
  .el-form-item__error {
    top: 50% !important;
    left: 10px;
  }
}
.el-step__title.is-process {
  font-size: 14px !important; /* Adjust font size */
  color: #519f6d !important; /* Adjust font color */
}
.el-step__head.is-process {
  color: #519f6d;
  border-color: #409eff !important;
}
.el-step__icon-inner {
  color: #303133 !important;
}
.el-step__description {
  width: 300px;
}
#yjzShDialog {
  .el-dialog__header {
    padding: 0px;
  }
  .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 4px 4px 14px 14px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    width: 50%;
  }
  
}
#detail_dialog {
  .el-dialog__header {
    padding: 0px;
  }
}
.el-dialog__headerbtn {
  top: 5px !important;
  right: 5px !important;
  font-size: 18px !important;
  cursor: pointer !important;
}
.el-card__body,
.el-main {
  padding: 5px 20px !important;
}
.gongjiao_jichuxinxi{
  .el-tabs__item.is-active{
    color: #00742f;
  }
  .el-tabs__active-bar{
    background-color: #00742f;
  }
}
.gongjiao{
  .el-button--success{
    background-color:  #fe9123 !important;
    border-color: #fe9123 !important;
  }
  .el-button--default{
    background-color: #fa952f !important;
    color:#333;
  }
  .el-switch.is-checked .el-switch__core{
    border-color: #00742f !important;
    background-color: #00742f !important;

  }
  .el-radio__input.is-checked+.el-radio__label{
    color: #00742f !important;
  }
  .el-radio__input.is-checked .el-radio__inner{
    border-color: #00742f !important;
    background-color: #00742f !important;

  }
  .el-table thead{
    tr th{
      background: #EFF6EF !important;
      color:#808080 !important;
    }
  }
  .el-table{
    z-index: 2;
  }
}

</style>
