<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-19 11:15:50
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-11 11:06:08
 * @FilePath: \app_wb\src\views\proposal\meetting_dahjy.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny__wb-dahuijy">
      <ul>
        <li
          v-for="(item, index) in data_dhjy"
          :key="index"
          @click="showFile(item)"
        >
          <div>{{ index + 1 }}</div>
          <div>{{ item.title }}</div>
          <div>{{ item.create_time }}</div>
        </li>
      </ul>
    </div>
    <!-- 预览窗口 -->
    <el-dialog
      style="text-align: left"
      :title="dhjy_title"
      :visible.sync="dialogVisible"
      width="90%"
    >
      <iframe :src="file_url" width="100%" height="668px"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { getDataFromDb, getYearMonthDay } from "../../utils/common.js";
export default {
  data() {
    return {
      dhjy_title: "",
      file_url: "",
      dialogVisible: false,
      data_dhjy: [],
    };
  },
  async created() {
    

    let result = await this.$db_select(
      this.$http,
      "xtbg_congress_result AS x LEFT JOIN sys_dept AS sd ON x.dept_id = sd.dept_id",
      "x.*, sd.dept_name","1=1 ORDER BY x.create_time DESC"
    );
    result.map((row) => {
      let newRow = {
        ...row,

        create_time: getYearMonthDay(row.create_time),
        file: "https://in.xtbg.ac.cn:8442/prod-api" + row.file,
      };
      this.data_dhjy.push(newRow);
    });
  },
  methods: {
    showFile(item) {
      console.log("文件", item.file);
      if (item.file.indexOf("null") > -1) {
        this.$router.push({
          path: "/proposal_meetting/dahjy_detail",
          query: {
            title: item.title,
            id: item.id,
          },
        });
      }
      if (item.file.length > 0) {
        this.dhjy_title = item.title;
        this.file_url = item.file;
        this.dialogVisible = true;
      } else {
        this.$router.push({
          path: "/proposal_meetting/dahjy_detail",
          query: {
            title: item.title,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bny__wb-dahuijy {
  height: 768px;
  padding: 20px 0px;
  overflow-y: auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      text-align: left;
      padding-left: 10px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #e3e3e3;
      &:nth-child(even) {
        background: #f3f4f9;
      }
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid #008500;
        color: #008500;
        div {
          &:first-child {
            color: #fff;
            background: #008500;
          }
        }
      }
      &:first-child {
        border-top: 1px dotted #ddd;
      }
      div {
        display: inline-block;
        &:last-child {
          float: right;
        }

        &:first-child {
          font-style: italic;
          width: 20px;
          height: 20px;
          line-height: 20px;
          background: #d7d7d7;
          text-align: center;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          color: #101010;
          font-size: 12px;
          &:hover {
            cursor: pointer;
            color: #fff;
            background: #008500;
            font-size: 12px;
          }
        }
      }
    }
  }
}
::v-deep .el-dialog .el-dialog__body {
  padding: 0 5px;
}
</style>
