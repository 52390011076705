import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";

/**
 * 导出Word文档
 * @param {File} templateFile - Word模板文件
 * @param {Object} data - 填充到模板的数据
 * @param {String} outputFileName - 导出的文件名
 */
export async function exportToWordFromStatic(
  templateFilePath,
  data,
  outputFileName
) {
  fetch(templateFilePath)
    .then((response) => {
      if (response.status === 200 || response.status === 0) {
        return response.blob();
      }
      throw new Error("Loading error: " + response.statusText);
    })
    .then((blob) => blob.arrayBuffer())
    .then((arrayBuffer) => {
      const zip = new PizZip(arrayBuffer);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      doc.setData(data);

      try {
        // 渲染文档
        doc.render();
      } catch (error) {
        // 错误处理
        console.error("Template processing error: ", error);
        throw error;
      }

      // 生成文档并保存
      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      saveAs(out, outputFileName);
    })
    .catch((error) => console.error("Error: ", error));
}
