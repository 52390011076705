<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-02-01 16:26:53
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-15 14:49:53
 * @FilePath: \app_wb\src\views\proposal\meetting_taxt.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="main_taxt flex-col padding-top-10">
    <!-- 搜索栏目 -->
    <div
      class="flex"
      style="padding-bottom: 10px; justify-content: space-between"
    >
      <div class="flex" style="max-width: 600px">
        <div class="height-38 line-height-38" style="width: 50px">时间：</div>
        <div
          class="height-38 line-height-38 padding-left-right-5"
          style="border-bottom: 1px solid red"
        >
          {{ select_year }}年提案
        </div>
      </div>

      <div class="flex">
        <div
          v-show="showMain"
          class="flex-1 flex"
          style="justify-content: right"
        >
          <div
            class="height-40 line-height-40"
            style="width: 100px; margin-left: 10px"
          >
            处理部门：
          </div>
          <el-select
            v-model="select_main"
            @change="changeMain"
            placeholder=""
            style="width: 120px; margin-right: 0px; padding-right: 0px"
          >
            <el-option
              v-for="item in option_main"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div></div>

        <div
          class="flex-1 flex"
          style="justify-content: right; margin-left: 0px; padding-left: 0px"
        >
          <div
            class="height-40 line-height-40"
            style="width: 60px; margin-left: 10px"
          >
            类别：
          </div>
          <el-select
            v-model="select_type"
            @change="changeType"
            placeholder="请选择类别"
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="item in option_types"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="flex-1 flex" style="justify-content: right">
          <div
            class="height-40 line-height-40 margin-left-right-5"
            style="width: 60px; margin-left: 10px"
          >
            状态：
          </div>
          <el-select
            v-model="select_status"
            @change="changeStatus"
            placeholder="请选择状态"
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="item in option_status"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button
            icon="el-icon-refresh"
            @click="realodData"
            style="margin-left: 5px; background: #25834b; color: #fff"
            >重载</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表格列表 -->

    <div class="main_taxt_table">
      <dynamic-data-table
        ref="dynamicDatatable"
        :fetchData="fetchData"
        :pageSize="pageSize"
        id="dynamicDatatable"
      >
        <template v-slot:actions="scope">
          <el-button
            @click="handleViewClick('查看', scope.row)"
            type="text"
            size="small"
            >查看</el-button
          >

          <el-button
            v-if="isSupporter(scope.row)"
            @click="handleViewClick('取消附议', scope.row)"
            type="text"
            size="small"
            style="color: #ff0000"
            >取消附议</el-button
          >

          <el-button
            v-else-if="canSupport(scope.row)"
            @click="handleViewClick('附议', scope.row)"
            type="text"
            size="small"
            style="color: #ff0000"
            >附议</el-button
          >

          <el-button
            v-if="canReview(scope.row)"
            @click="handleViewClick('审查', scope.row)"
            type="text"
            size="small"
            style="color: #d60597"
            >审查</el-button
          >

          <el-button
            v-if="carReviewAgain(scope.row)"
            @click="handleViewClick('会审', scope.row)"
            type="text"
            size="small"
            style="color: #d60597"
            >会审</el-button
          >

          <el-button
            v-if="canProcess(scope.row)"
            @click="handleViewClick('处理', scope.row)"
            type="text"
            size="small"
            style="color: #d60597"
            >处理</el-button
          >
          <el-button
            @click="handleViewClick('流程状态', scope.row)"
            type="text"
            size="small"
            style="color: #4e4e4e"
          >
            流程状态
          </el-button>

          <el-button
            @click="handleViewClick('导出', scope.row)"
            type="text"
            size="small"
            style="color: #008500"
            >导出</el-button
          >
        </template>
      </dynamic-data-table>
    </div>
    <!-- 流程状态对话框 -->
    <el-dialog
      title="当前流程状态"
      top="20vh"
      :visible.sync="dialogFlowVisible"
      :close-on-click-modal="false"
    >
      <proposal-status-timeline
        :status-steps="proposalSteps"
        :current-process-type="currentProcessType"
      />

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFlowVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dbquery, getDataTotal } from "./sys/proposeRoleUser.js";
import DynamicDataTable from "../../components/commom/dynamicDataTable.vue";
import {
  getCurrentDate,
  formatDate,
  encryptData,
  objectToSqlSet,
  formatDateForYMD,
  replaceEmptyValuesWithEmptyString,
} from "../../utils/common.js";
import { exportToWordFromStatic } from "../../utils/exportToWord.js";
import ProposalStatusTimeline from "../../components/commom/ProposalStatusTimeline.vue";

const propose_table = "xtbg_congress_propose";

let tempTableTable = [];
let tempAllTableTable = [];
export default {
  components: {
    DynamicDataTable,
    ProposalStatusTimeline,
  },
  data() {
    return {
      dialogFlowVisible: false,
      // 提案的状态步骤数据
      proposalSteps: [
        // {
        //   status: "待提交",
        //   user: "用户A",
        //   timestamp: "2024-03-10 08:00",
        //   color: "grey",
        // },
        // {
        //   status: "待附议",
        //   user: "用户B",
        //   timestamp: "2024-03-11 09:30",
        //   color: "blue",
        // },
        // {
        //   status: "待审查",
        //   user: "用户C",
        //   timestamp: "2024-03-12 10:00",
        //   color: "green",
        // },
        // 根据提案的实际状态，可能会包含更多的步骤
      ],
      // 当前提案的流程类型
      currentProcessType: "normal",
      select_year: "",
      select_type: "",
      select_status: "",
      tableData: [],
      current_year: "",
      select_main: "全部",
      showMain: false,
      option_main: [
        {
          value: "全部",
          label: "全部",
        },
        {
          value: "主办",
          label: "主办",
        },
        {
          value: "协办",
          label: "协办",
        },
      ],
      option_types: [
        {
          value: "单位建设",
          label: "单位建设",
        },
        {
          value: "民主管理",
          label: "民主管理",
        },
        {
          value: "职工福利",
          label: "职工福利",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      option_status: [
        {
          value: "待附议",
          label: "待附议",
        },
        {
          value: "待审查",
          label: "待审查",
        },
        {
          value: "待会审",
          label: "待会审",
        },

        {
          value: "处理中",
          label: "处理中",
        },
        {
          value: "已处理",
          label: "已处理",
        },
        {
          value: "已结案",
          label: "已结案",
        },
      ],
      total: 0,
      pageSize: 10,
      propseID: "",
      formData: {},
      querySqlStr: "",
      querySelectSqlStr: "",
      page: 1,
      templateFile: "../config/xtbgta.docx",

      //附议 角色 职工代表
      bool_zgdb: false,
      //审查
      bool_taz: false,
      //审批
      bool_yld: false,
      //处理
      bool_zbbm: false,
      //当前领导管辖的部门
      manageDeptIds: [],

      myDeptName: "",
      mywhere: "",
      dept_names: [],
    };
  },
  async created() {
    this.select_year = new Date().getFullYear();
    //获取当前用户的角色 来判断可以操作那些
    await this.getUserRoleIdForCongres();
    await this.getDeptNameByUserName();
    await this.judgeDeptByUserName();
  },
  watch: {},
  methods: {
    isSupporter(row) {
      const username = localStorage.getItem("username");
      // console.log("当前用户:", username, row.supporters);
      if (row.supporters) {
        return (
          row.supporters.indexOf(username) > -1 &&
          (row.status === "待附议" || row.status === "待审查")
        );
      } else {
        (row.status === "待附议" || row.status === "待审查") && this.bool_zgdb;
      }
    },
    canSupport(row) {
      return (
        (row.status === "待附议" || row.status === "待审查") && this.bool_zgdb
      );
    },
    canReview(row) {
      return row.status === "待审查" && this.bool_taz;
    },
    carReviewAgain(row) {
      return row.status === "待会审" && this.bool_taz;
    },
    canApprove(row) {
      return (
        row.status === "待审批" &&
        this.bool_yld &&
        this.dept_names.includes(row.suggest_dept)
      );
    },
    canProcess(row) {
      return row.status === "处理中" && this.bool_zbbm;
    },
    async dbquery(sqlStr) {
      return dbquery(this.$http, sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },
    async getDataTrueTotal(where) {
      const sqlStr =
        "select count(*) as total from " + propose_table + " where " + where;

      const res = await this.$dbquery(this.$http, sqlStr);

      if (res[0]) {
        return res[0].total;
      } else {
        return 0;
      }
    },
    async getDetpIdsByUserid() {
      const sqlStr =
        "select dept_id from xtbg_congress_leader_dept where user_id=" +
        localStorage.getItem("userid");
      const res = await this.dbquery(sqlStr);
      return res.map((row) => row.dept_id);
    },

    async getForRowDisplay(row) {
      const res = await this.dbquery(
        "SELECT count(*) as total FROM sys_dept WHERE leader='" +
          localStorage.getItem("username") +
          "' and dept_name='" +
          row.suggest_dept +
          "'"
      );

      if (res[0].total > 0) {
        return true;
      } else {
        return false;
      }
    },

    //获取当前 登录用户是提案系统中的什么角色
    async getUserRoleIdForCongres() {
      const user_id = localStorage.getItem("userid");
      const sqlStr =
        "select role_id from xtbg_congress_role_user where user_id=" + user_id;
      const userRole = await this.dbquery(sqlStr);

      // console.log("当前用户角色：", userRole);

      const currentUserRoles = userRole.map((row) => row.role_id);

      if (
        currentUserRoles.includes(this.$congresConfig.congressRole.roleid_zgdb)
      ) {
        this.bool_zgdb = true;
      }
      if (
        currentUserRoles.includes(this.$congresConfig.congressRole.roleid_taz)
      ) {
        this.bool_taz = true;
      }
      if (
        currentUserRoles.includes(this.$congresConfig.congressRole.roleid_yld)
      ) {
        //判断是否是主办部门的分管领导 当前领导分管部门
        this.manageDeptIds = await this.getDetpIdsByUserid();

        //主办部门 ID
        this.bool_yld = true;
      }
      if (
        currentUserRoles.includes(this.$congresConfig.congressRole.roleid_cbbm)
      ) {
        this.bool_zbbm = true;
      }

      //判断是不是部门负责人

      const deptLeaders = await this.getDeptLeaders();
      // console.log("部门负责人：", deptLeaders);

      if (deptLeaders.includes(localStorage.getItem("username"))) {
        //  this.manageDeptIds = await this.getDetpIdsByUserid();
        //  console.log(this.manageDeptIds);
        //  console.log(this.manageDeptIds.includes(parseInt(localStorage.getItem("deptid"))));
        //  if(this.manageDeptIds.includes(parseInt(localStorage.getItem("deptid")))){
        this.showMain = true;
        //}
        this.bool_zbbm = true;
      }

      //console.log(this.bool_zgdb, this.bool_taz, this.bool_yld, this.bool_zbbm);

      //判断是什么角色 然后确定显示什么内容
    },
    changeMain(newVal) {
      if (newVal == "主办") {
        //suggest_dept
        this.querySelectSqlStr =
          "select * from " +
          propose_table +
          " where suggest_dept='" +
          this.myDeptName +
          "' and status!='待提交'" +
          " order by id desc LIMIT " +
          this.pageSize +
          " OFFSET " +
          (this.page - 1) * this.pageSize;

        //console.log(this.querySelectSqlStr);
      }
      if (newVal == "协办") {
        //suggest_dept_other
        this.querySelectSqlStr =
          "select * from " +
          propose_table +
          " where suggest_dept_other like '%" +
          this.myDeptName +
          "*'  and status!='待提交'" +
          " order by id desc LIMIT " +
          this.pageSize +
          " OFFSET " +
          (this.page - 1) * this.pageSize;
      }
      this.$refs.dynamicDatatable.loadData();
      if (newVal == "全部") {
        this.realodData();
      }

      // this.tableData.filter(row=>{
      //   return  row.suggest_dept==newVal
      // })
    },

    //根据部门负责人名字 获取部门名称
    async getDeptNameByUserName() {
      const currentUserName = localStorage.getItem("username");
      const res = await this.dbquery(
        "SELECT dept_name FROM sys_dept WHERE leader like '%" +
          currentUserName +
          "%'"
      );

      if (res[0]) {
        this.myDeptName = res[0].dept_name;
      }
    },
    async judgeDeptByUserName() {
      const sqlStr =
        "SELECT dept_name FROM sys_dept WHERE dept_id in (SELECT dept_id FROM xtbg_congress_leader_dept WHERE user_id=" +
        localStorage.getItem("userid") +
        ")";
      const deptNames = await this.$dbquery(this.$http, sqlStr);

      this.dept_names = deptNames.map((row) => row.dept_name);
    },

    async getDeptLeaders() {
      const res = await this.dbquery(
        "SELECT leader FROM sys_dept WHERE leader !=''"
      );
      return res.map((row) => row.leader);
    },
    realodData() {
      this.select_type = "";
      this.select_year = "";
      this.select_status = "";
      this.querySelectSqlStr = "";

      this.$refs.dynamicDatatable.loadData();
    },
    changeStatus() {
      this.querySelectSqlStr =
        "select * from " +
        propose_table +
        " where proposal_date like '" +
        this.select_year +
        "%' and type like '%" +
        this.select_type +
        "%' and status like '%" +
        this.select_status +
        "%'  and status!='待提交'" +
        " order by id desc LIMIT " +
        this.pageSize +
        " OFFSET " +
        (this.page - 1) * this.pageSize;
      this.mywhere =
        " proposal_date like '" +
        this.select_year +
        "%' and type like '%" +
        this.select_type +
        "%' and status like '%" +
        this.select_status +
        "%'  and status!='待提交'";
      this.$refs.dynamicDatatable.loadData();
    },
    changeType() {
      this.querySelectSqlStr =
        "select * from " +
        propose_table +
        " where proposal_date like '" +
        this.select_year +
        "%' and type like '%" +
        this.select_type +
        "%' and status like '%" +
        this.select_status +
        "%' and status!='待提交'" +
        " order by id desc LIMIT " +
        this.pageSize +
        " OFFSET " +
        (this.page - 1) * this.pageSize;
      this.mywhere =
        "proposal_date like '" +
        this.select_year +
        "%' and type like '%" +
        this.select_type +
        "%' and status like '%" +
        this.select_status +
        "%' and status!='待提交'";
      this.$refs.dynamicDatatable.loadData();
    },

    async getProposeSupporters(proposeId) {
      const fySql =
        "select supporters from " + propose_table + " where id=" + proposeId;
      const res = await this.dbquery(fySql);
      return res[0].supporters;
    },
    //取消附议
    async updateProposeCancelFy(row) {
      if (row.status == "待附议" || row.status == "待审查") {
        this.propseID = row.id;

        let supportersArr = [];

        // 查询
        supportersArr = row.supporters.split(",");

        const username = localStorage.getItem("username");

        supportersArr = supportersArr.filter((e) => e !== username);
        let status = "";
        if (supportersArr.length > 1) {
          status = "待审查";
        } else {
          status = "待附议";
        }

        let formData = {
          supporters: supportersArr.join(","),
          status: status,
        };

        let myNewFormData = objectToSqlSet(formData);

        // 保存 修改
        const updateProposeSql =
          "update " +
          propose_table +
          " set " +
          myNewFormData +
          " where id=" +
          this.propseID;
        const res = await this.dbquery(updateProposeSql);

        if (res.affectedRows > 0) {
          this.$message({
            showClose: true,
            message: "取消成功",
            type: "success",
            duration: 1000,
            offset: 200,
          });
        } else {
          this.$message({
            showClose: true,
            message: "取消失败",
            type: "fail",
            duration: 1000,
            offset: 200,
          });
        }

        this.$refs.dynamicDatatable.loadData();
      }
    },
    // 附议 操作 函数
    async updateProposeFy(row) {
      this.propseID = row.id;

      let supportersArr = [];

      // 查询
      let supporters = await this.getProposeSupporters(this.propseID);

      if (supporters) {
        if (supporters.indexOf(",")) {
          supportersArr = supportersArr.concat(supporters.split(","));
        } else {
          supportersArr.push(supporters);
        }
      }

      supportersArr.push(localStorage.getItem("username"));

      let formData = {
        supporters: supportersArr.join(","),
      };

      if (supportersArr.length > 1) {
        formData["status"] = "待审查";
        formData["s_time"] = getCurrentDate();
      }

      let myNewFormData = objectToSqlSet(formData);

      // 保存 修改
      const updateProposeSql =
        "update " +
        propose_table +
        " set " +
        myNewFormData +
        " where id=" +
        this.propseID;
      const res = await this.dbquery(updateProposeSql);

      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "附议成功",
          type: "success",
          duration: 1000,
          offset: 200,
        });
      } else {
        this.$message({
          showClose: true,
          message: "附议失败",
          type: "fail",
          duration: 1000,
          offset: 200,
        });
      }

      this.$refs.dynamicDatatable.loadData();
    },

    async updatePropose(row, status, msg) {
      this.propseID = row.id;

      let formData = {
        status: status,
      };
      let myNewFormData = objectToSqlSet(formData);

      // 保存 修改
      const updateProposeSql =
        "update " +
        propose_table +
        " set " +
        myNewFormData +
        " where id=" +
        this.propseID;
      const res = await this.dbquery(updateProposeSql);

      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "提交成功",
          type: "success",
          duration: 1000,
          offset: 200,
        });
      } else {
        this.$message({
          showClose: true,
          message: "提交失败",
          type: "fail",
          duration: 1000,
          offset: 200,
        });
      }

      this.$refs.dynamicDatatable.loadData();
    },
    async getDeptIdByDeptName(deptName) {
      const res = await this.dbquery(
        "select dept_id from sys_dept where dept_name like '%" + deptName + "%'"
      );
      if (res[0]) {
        return res[0].dept_id;
      } else {
        return 0;
      }
    },
    async getUserNameByUserid(usesrid) {
      if (usesrid) {
        const userSql = "select name from xtbg_user where id=" + usesrid;
        const res = await this.$dbquery(this.$http, userSql);
        if (res[0]) {
          return res[0].name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    //表格操作
    async handleViewClick(operate, row) {
      switch (operate) {
        case "查看":
          this.$router.push({
            path: "/proposal_meetting/propose_view",
            query: {
              id: encryptData(row.id + ""),
              from: "now",
            },
          });
          break;
        case "取消附议":
          this.$confirm("确定取消附议?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.updateProposeCancelFy(row);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
                offset: 200,
              });
            });

          break;
        case "附议":
          //附议 规则，自己的提案 不能自己附议 附议如果 有两个人以上就进入 审查阶段 status变为 待审查
          if (localStorage.getItem("userid") == row.proposer_userid) {
            this.$message({
              type: "warning",
              message: "自己不能对自己的提案进行附议",
              duration: 3000,
              offset: 200,
            });
            return;
          }

          if (row.supporters) {
            if (row.supporters.indexOf(localStorage.getItem("username")) > -1) {
              this.$message({
                type: "warning",
                message: "该提案已附议过，不需要重复附议",
                offset: 200,
              });
              return;
            }
          }

          this.$confirm("确定要附议该提案？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.updateProposeFy(row);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
                offset: 200,
              });
            });

          break;
        case "审查":
          //开始审查

          //打开审查页面
          this.$router.push({
            path: "/proposal_meetting/taz",
            query: {
              id: encryptData(row.id + ""),
              from: "now",
            },
          });

          break;
        case "会审":
          //打开会审页面
          this.$router.push({
            path: "/proposal_meetting/tazhs",
            query: {
              id: encryptData(row.id + ""),
              from: "now",
            },
          });

          break;
        case "审批":
          //先判断是否是自己分管的部门
          const zb_deptId = await this.getDeptIdByDeptName(row.suggest_dept);
          if (!this.manageDeptIds.includes(zb_deptId)) {
            this.$message({
              type: "warning",
              message: "无法审批，该部门不是你分管的部门",
              offset: 200,
            });
            return;
          }

          //开始进行审批
          this.$router.push({
            path: "/proposal_meetting/yld",
            query: {
              id: encryptData(row.id + ""),
              from: "now",
            },
          });

          break;
        case "处理":
          if (!(await this.getForRowDisplay(row))) {
            this.$message({
              type: "warning",
              message: "无法处理，请联系主办部门",
              offset: 200,
            });
            return;
          }

          this.$router.push({
            path: "/proposal_meetting/zbbm",
            query: {
              id: encryptData(row.id + ""),
              from: "now",
            },
          });

          break;
        case "导出":
          console.log(row);
          let export_row = row;
          export_row.proposal_date = formatDateForYMD(export_row.proposal_date);
          if (export_row.reply_time) {
            export_row.reply_time = formatDateForYMD(export_row.reply_time);
          }
          if (export_row.t_date) {
            export_row.t_date = formatDateForYMD(export_row.t_date);
          }
          if (export_row.d_head_date) {
            export_row.d_head_date = formatDateForYMD(export_row.d_head_date);
          }
          if (export_row.l_sign_date) {
            export_row.l_sign_date = formatDateForYMD(export_row.l_sign_date);
          }
          if (export_row.p_f_sign_date) {
            export_row.p_f_sign_date = formatDateForYMD(
              export_row.p_f_sign_date
            );
          }
          export_row = replaceEmptyValuesWithEmptyString(export_row);

          //类型 处理
          switch (export_row.type) {
            case "单位建设":
              export_row["type1"] = "☑";
              export_row["type2"] = "☐";
              export_row["type3"] = "☐";
              export_row["type4"] = "☐";
              break;
            case "民主管理":
              export_row["type2"] = "☑";
              export_row["type1"] = "☐";
              export_row["type3"] = "☐";
              export_row["type4"] = "☐";

              break;
            case "职工福利":
              export_row["type3"] = "☑";
              export_row["type1"] = "☐";
              export_row["type2"] = "☐";
              export_row["type4"] = "☐";
              break;
            case "其他":
              export_row["type4"] = "☑";
              export_row["type1"] = "☐";
              export_row["type2"] = "☐";
              export_row["type3"] = "☐";

              break;
            default:
              export_row["type1"] = "☐";
              export_row["type2"] = "☐";
              export_row["type3"] = "☐";
              export_row["type4"] = "☐";
              break;
          }
          // 提案组 审查结果
          switch (export_row.t_result) {
            case "立案":
              export_row["t_result1"] = "✔";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
            case "退回重提":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "✔";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
            case "不予立案":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "✔";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
            case "转意见处理":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "✔";
              export_row["t_result5"] = "";
              break;
            case "其他":
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "✔";
              break;
            default:
              export_row["t_result1"] = "";
              export_row["t_result2"] = "";
              export_row["t_result3"] = "";
              export_row["t_result4"] = "";
              export_row["t_result5"] = "";
              break;
          }
          // 提案人返回结果
          switch (export_row.p_feedback) {
            case "满意":
              export_row["p_fd1"] = "☑";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☐";
              break;
            case "基本满意":
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☑";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☐";
              break;
            case "一般":
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☑";
              export_row["p_fd4"] = "☐";
              break;
            case "不满意":
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☑";

              break;
            default:
              export_row["p_fd1"] = "☐";
              export_row["p_fd2"] = "☐";
              export_row["p_fd3"] = "☐";
              export_row["p_fd4"] = "☐";
              break;
          }
          //导出 wordd 提案
          export_row["propose_name"] = this.$congresConfig.propose_title;
          //console.log(export_row);
          exportToWordFromStatic(
            this.templateFile,
            export_row,
            export_row.title + ".docx"
          );
          break;
        case "流程状态":
          this.dialogFlowVisible = true;
          this.proposalSteps = [];
          if (row.t_result) {
            switch (row.t_result) {
              case "立案":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }

                if (row.l_sign_date) {
                  let status_dsp = {
                    status: "已审批",
                    user: row.l_sign,
                    timestamp: formatDateForYMD(row.l_sign_date),
                  };
                  this.proposalSteps.push(status_dsp);
                }

                if (row.d_head_sign) {
                  let status_ycl = {
                    status: "已处理",
                    user: row.d_head_sign,
                    timestamp: formatDateForYMD(row.d_head_date),
                  };
                  this.proposalSteps.push(status_ycl);
                }

                if (row.p_f_sign) {
                  let status_yja = {
                    status: "已结案",
                    user: row.p_f_sign,
                    timestamp: formatDateForYMD(row.p_f_sign_date),
                  };
                  this.proposalSteps.push(status_yja);
                }

                this.currentProcessType = "normal";
                break;
              case "退回重提":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.t_userid) {
                  let status_dsc = {
                    status: "退回重提",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }

                this.currentProcessType = "resubmit";
                break;
              case "不予立案":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.t_userid) {
                  let status_dsc = {
                    status: "不予立案",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }

                this.currentProcessType = "rejected";
                break;
              case "转意见处理":
                if (row.proposer) {
                  let status_dtj = {
                    status: "提案",
                    user: row.proposer,
                    timestamp: formatDateForYMD(row.proposal_date),
                  };
                  this.proposalSteps.push(status_dtj);
                }

                if (row.supporters) {
                  let status_dfy = {
                    status: "已附议",
                    user: row.supporters,
                    timestamp: formatDateForYMD(row.s_time),
                  };
                  this.proposalSteps.push(status_dfy);
                }

                if (row.t_userid) {
                  let status_dsc = {
                    status: "已审查",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.t_userid) {
                  let status_dsc = {
                    status: "转意见处理",
                    user: await this.getUserNameByUserid(row.t_userid),
                    timestamp: formatDateForYMD(row.t_date),
                  };
                  this.proposalSteps.push(status_dsc);
                }
                if (row.p_f_sign) {
                  let status_yja = {
                    status: "已结案",
                    user: row.p_f_sign,
                    timestamp: formatDateForYMD(row.p_f_sign_date),
                  };
                  this.proposalSteps.push(status_yja);
                }
                this.currentProcessType = "opinion";
                break;
            }
          } else {
            if (row.proposer) {
              let status_dtj = {
                status: "提案",
                user: row.proposer,
                timestamp: formatDateForYMD(row.proposal_date),
              };
              this.proposalSteps.push(status_dtj);
            }

            if (row.supporters) {
              let status_dfy = {
                status: "已附议",
                user: row.supporters,
                timestamp: formatDateForYMD(row.s_time),
              };
              this.proposalSteps.push(status_dfy);
            }
          }

          break;
      }
    },

    async fetchData({ page, pageSize }) {
      this.page = page;

      let myProposeData = [];
      if (this.querySelectSqlStr == "") {
        this.querySqlStr =
          "select * from " +
          propose_table +
          " where status!='待提交'" +
          " order by id desc LIMIT " +
          pageSize +
          " OFFSET " +
          (page - 1) * pageSize;

        this.mywhere = " status!='待提交'";
        myProposeData = await this.dbquery(this.querySqlStr);
      } else {
        if (this.select_status != "") {
          this.querySelectSqlStr =
            "select * from " +
            propose_table +
            " where proposal_date like '" +
            this.select_year +
            "%' and type like '%" +
            this.select_type +
            "%' and status like '%" +
            this.select_status +
            "%'  and status!='待提交'" +
            " order by id desc LIMIT " +
            this.pageSize +
            " OFFSET " +
            (this.page - 1) * this.pageSize;
          this.mywhere =
            " proposal_date like '" +
            this.select_year +
            "%' and type like '%" +
            this.select_type +
            "%' and status like '%" +
            this.select_status +
            "%'  and status!='待提交'";
        }
        if (this.select_type != "") {
          this.querySelectSqlStr =
            "select * from " +
            propose_table +
            " where proposal_date like '" +
            this.select_year +
            "%' and type like '%" +
            this.select_type +
            "%' and status like '%" +
            this.select_status +
            "%' and status!='待提交'" +
            " order by id desc LIMIT " +
            this.pageSize +
            " OFFSET " +
            (this.page - 1) * this.pageSize;
          this.mywhere =
            " proposal_date like '" +
            this.select_year +
            "%' and type like '%" +
            this.select_type +
            "%' and status like '%" +
            this.select_status +
            "%' and status!='待提交'";
        }

        if (this.select_main == "主办") {
          //suggest_dept
          this.querySelectSqlStr =
            "select * from " +
            propose_table +
            " where suggest_dept='" +
            this.myDeptName +
            "' and status!='待提交'" +
            " order by id desc LIMIT " +
            this.pageSize +
            " OFFSET " +
            (this.page - 1) * this.pageSize;
          this.mywhere =
            " suggest_dept='" + this.myDeptName + "' and status!='待提交'";
        }
        if (this.select_main == "协办") {
          //suggest_dept_other
          this.querySelectSqlStr =
            "select * from " +
            propose_table +
            " where suggest_dept_other like '%" +
            this.myDeptName +
            "%'  and status!='待提交'" +
            " order by id desc LIMIT " +
            this.pageSize +
            " OFFSET " +
            (this.page - 1) * this.pageSize;
          this.mywhere =
            " suggest_dept_other like '%" +
            this.myDeptName +
            "%'  and status!='待提交'";
        }

        myProposeData = await this.dbquery(this.querySelectSqlStr);
      }

      const total = await this.getDataTrueTotal(this.mywhere);
      let i = 0;
      const newData = myProposeData.map((row) => {
        i++;
        return {
          ...row,
          uid: i,
          proposal_date: formatDate(row.proposal_date),
        };
      });
      // 这里用你的API请求逻辑替换
      // 模拟API响应格式
      return {
        data: newData, // 从服务器获取的数据
        total: total, // 总条目数
        columns: [
          // { prop: "uid", label: "序号", width: "50" },
          { prop: "num", label: "编号", width: "98" },
          { prop: "type", label: "类型", width: "68" },
          { prop: "title", label: "案名", width: "152"},
          { prop: "proposer", label: "提案人", width: "62" },
          { prop: "proposal_date", label: "提案日期", width: "84" },
          { prop: "supporters", label: "附议人", width: "170" },
          { prop: "t_result", label: "审查结果" },
          { prop: "suggest_dept", label: "主办部门" },
          { prop: "status", label: "状态" },
        ],
      };
    },

    startPropose() {
      this.$router.push({
        path: "/proposal_meetting/propose_table",
      });
    },
    scrollToTop() {
      // 使用window对象的scrollTo方法
      window.scrollTo({
        top: 0, // 目标位置（顶部）
        behavior: "smooth", // 平滑滚动
      });
    },

    async judgeUserHasZbCl() {
      const zbSql =
        "SELECT COUNT('*') as total FROM xtbg_congress_propose WHERE suggest_dept=(SELECT dept_name FROM sys_dept WHERE leader='" +
        localStorage.getItem("username") +
        "') AND status='处理中'";
      const result = await this.$dbquery(this.$http, zbSql);

      if (result[0].total > 0) {
        this.select_main = "主办";
        let _this = this;

        setTimeout(() => {
          _this.changeMain("主办");
        }, 500);
      }
    },
  },
  async mounted() {
    this.scrollToTop();
    await this.judgeUserHasZbCl();
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table .cell {
 
  padding-right: 2px;
  padding-top: 5px;
  padding-bottom: 5px;

 
}

</style>
