<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-08 11:08:29
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-14 09:15:04
 * @FilePath: \app_wb\src\views\Advise\Detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div>
    <div id="bny_advise" class="bny_advise">
      <div class="bny_advise-top">
        <div>咨询平台</div>
        <div class="bny_advise-top-btn">
          <el-button
            type="primary"
            round
            style="background-color: #519f6d; letter-spacing: 2px"
            @click="back_advise"
            >返回</el-button
          >
        </div>
      </div>
      <div class="bny_advise-showcontent">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="咨询标题：">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="公开对象：">
            <el-radio-group v-model="public_object" @input="selectRadio">
              <el-radio-button label="全员"></el-radio-button>
              <el-radio-button label="部门"></el-radio-button>
              <el-radio-button label="个人"></el-radio-button>
            </el-radio-group>
            <el-select
              style="margin: 0 5px; width: 50%"
              v-show="dept_show"
              multiple
              v-model="dept_value"
              clearable
              filterable
              placeholder="请选择咨询部门"
            >
              <el-option
                v-for="item in dept_options"
                :key="item.id"
                :label="item.deptName"
                :value="item.deptName"
              >
              </el-option>
            </el-select>
            <el-select
              style="margin: 0 5px; width: 50%"
              multiple
              v-show="person_show"
              v-model="person_value"
              clearable
              filterable
              placeholder="请选择咨询对象"
            >
              <el-option
                v-for="item in person_options"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="咨询内容：">
            <el-input
              type="textarea"
              :rows="10"
              v-model="form.content"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="onSubmit"
              icon="el-icon-circle-check"
              style="font-size: 14px; background-color: rgb(81, 159, 109)"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="clear: both"></div>
    <mFooter></mFooter>
  </div>
</template>
    
    <script>
import mFooter from "@/components/mFooter.vue";
import { Loading } from "element-ui";
//部门列表
import { ListAllDepts } from "@/api/system/sys_dept.js";
//人员列表
import { ListAllAddrbookInfo } from "@/api/content/xtbg_addr_book.js";

//提交咨询
import { PostSeekAdvise } from "@/api/content/xtbg_seek_advise.js";

export default {
  components: {
    mFooter,
  },
  data() {
    return {
      dept_show: false,
      person_show: false,
      person_value: [],
      person_options: [],
      dept_value: [],
      dept_options: [],
      public_object: "",
      form: {},
    };
  },
  created() {
    this.getDeptList();
    this.getPersonList();
  },
  methods: {
    //获取部门列表
    getDeptList() {
      ListAllDepts().then((res) => {
        this.dept_options = res.data.data;
      });
    },
    //获取人员列表
    getPersonList() {
      ListAllAddrbookInfo().then((res) => {
        this.person_options = res.data.rows;
      });
    },
    onSubmit() {
      if (this.form.title.length == 0) {
        this.$message({
          showClose: true,
          message: "咨询标题不能为空",
          type: "warning",
          offset: 160,
        });
        return false;
      }
      if (this.form.content.length == 0) {
        this.$message({
          showClose: true,
          message: "咨询内容不能为空",
          type: "warning",
          offset: 160,
        });
        return false;
      }
      if (this.public_object.length == 0) {
        this.$message({
          showClose: true,
          message: "公开对象不能为空",
          type: "warning",
          offset: 160,
        });
        return false;
      } else {
        if (this.dept_value.length > 0) {
          this.public_object = this.dept_value.join(",");
        }
        if (this.person_value.length > 0) {
          this.public_object = this.person_value.join(",");
        }

        this.form.publicObject = this.public_object;
      }
      if (this.form.title.length > 0 && this.form.content.length > 0) {
        let loadingInstance1 = Loading.service({ fullscreen: true });
        let _this = this;
        this.form.createBy = localStorage.getItem("username");
        console.log(this.form);
        PostSeekAdvise(this.form).then((res) => {
          if (typeof res != "undefined") {
            if (res.status == 200) {
              this.$message({
                message: "咨询成功",
                type: "success",
                offset: 200,
              });
            } else {
              this.$message({
                message: "咨询失败",
                type: "error",
                offset: 200,
              });
            }
          } else {
            this.$message({
              message: "咨询失败",
              type: "error",
              offset: 200,
            });
          }

          loadingInstance1.close();
        });
      }
    },
    back_advise() {
      this.$router.push({
        path: "/advise",
      });
    },
    // 格式化日对象
    getNowDate() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      var weekArr = [
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
        "星期天",
      ];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds
      );
    },
    selectRadio(value) {
      if (value == "部门") {
        this.dept_show = true;
        this.person_show = false;
      }
      if (value == "个人") {
        this.dept_show = false;
        this.person_show = true;
      }
    },
  },
  mounted() {
    //根据标题 查询对应咨询详细内容
    //console.log(this.$route.query.title);
    document.getElementById("bny_advise").style.height = "calc(100% - 300px)";
  },
};
</script>
    
    <style lang="scss" scoped>
.bny_advise {
  padding: 5px 0px;
  width: 1400px;
  background: #fff;

  margin: 0 auto;
  overflow-y: auto;

  .bny_advise-showcontent {
    text-align: left;
    font-size: 14px;
    padding: 20px;
    height: auto;
    height: auto;
    min-height: 500px;
  }
  .bny_advise-top {
    width: calc(100% - 240px);
    height: 100px;
    line-height: 100px;
    background: url("../../assets/imgs/advise/advise-top.png") no-repeat #519f6d;
    background-position-x: 60px;
    background-position-y: 10px;
    background-size: 1280px 80px;
    text-align: left;
    padding: 0 120px;
    font-size: 18px;
    font-weight: bold;
    color: #2e6548;
    div {
      float: left;
    }
    .bny_advise-top-btn {
      float: right;
    }
  }
  .bny_advise-search {
    height: 60px;
    line-height: 60px;
    width: 100%;
  }
  .bny_advise-list {
    height: auto;
    width: calc(100% - 40px);
    padding: 20px;
  }
}
::v-deep .item .el-form-item__label {
  color: red;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: rgb(81, 159, 109);
}
</style>