<template>
  <div>
    <div class="bny_ldrc-look">
      <div class="bny_meetting-top">
        <div>通讯录</div>
      </div>
      <!-- <div class="bny_ldrc-look-now">{{ now_date }}</div> -->
      <div class="bny_ldrc-look-content">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-input placeholder="请输入名字进行搜索" v-model="searchName">
              <template slot="append">
                <el-button @click="searchByName">搜索</el-button></template
              >
            </el-input>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="1" v-show="false">
            <div style="height: 640px; width: 20px; background-color: #ddd">
              <ul style="margin: 0; padding: 0; list-style: none">
                <li
                  class="li_zm"
                  v-for="item in zms"
                  :key="item"
                  @click="clickZM(item, $event)"
                  style="
                    display: block;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                  "
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="24">
            <el-table
              :data="tableDataNew"
              border
              height="640"
              style="width: 100%"
            >
              <el-table-column prop="name" label="姓名" width="100">
              </el-table-column>
              <el-table-column prop="deptName" label="部门" width="280">
              </el-table-column>
              <el-table-column prop="phone" label="电话号码" width="180">
              </el-table-column>
              <el-table-column prop="email" label="电子邮箱" width="180">
              </el-table-column>
              <el-table-column prop="remark" label="备注"> </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>

    <div style="clear: both"></div>
    <div id="showRC"></div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mFooter from "@/components/mFooter.vue";
import pinyin from "js-pinyin";
import { ListAddrbookInfo } from "@/api/content/xtbg_addr_book.js";
import { getDeptNameById } from "@/api/system/sys_dept.js";
import { Loading } from "element-ui";
import { goToTop } from "@/utils/common";
var _this = null;
export default {
  components: {
    mFooter,
  },
  data() {
    return {
      zms: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      searchName: "",
      tableDataNew: [],
      tableData: [
        {
          name: "Amal",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "阿巴先生",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "阿巴先生",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "白展堂",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "白展堂",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "曹操",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "曹操",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "戴好",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "戴好",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "戴好",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "白展堂",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "白展堂",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "曹操",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "曹操",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "戴好",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "戴好",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
        {
          name: "戴好",
          dept: "行政部门",
          phone: "13544875512",
          email: "22313656@qq.com",
        },
      ],
      queryPramas: {
        pageSize: 2000,
        pageNum: 1,
      },
    };
  },
  created() {
    _this = this;
    this.listBooks();
  },
  watch: {
    searchName(newV) {
      if (newV != null) {
        if (newV.length > 1) {
          //this.searchName = newV;
          this.searchByName();
        } else {
          this.tableDataNew = [];
        }
      }
    },
  },
  methods: {
    listBooks() {
      let loadingInstance = Loading.service({
        text: "正在初始化...",
      });
      ListAddrbookInfo(this.queryPramas).then((res) => {
        _this.tableData = res.data.rows;
        // res.data.rows.map((row) => {
        //   (function (deptId) {
        //     getDeptNameById(deptId).then((rs) => {
        //       row.deptName = rs.data.data.deptName;
        //       _this.tableData.push(row);
        //     });
        //   })(row.deptId);
        // });
        // _this.tableDataNew = _this.tableData;

        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          setTimeout(() => {
            loadingInstance.close();
          }, 100);

          //获取
          //this.searchName = this.$route.query.name;
          this.searchByName();
          goToTop();
        });
      });
    },
    isNumber(str){
      return /^\d+$/.test(str);
    },
    searchByName() {
      if (this.searchName != null) {
        let loadingInstance = Loading.service({
          text: "正在查询...",
        });
        let reg = /^[A-Za-z]+$/;
        this.tableDataNew = [];

        if (reg.test(_this.searchName) && _this.searchName.length >= 2) {
          for (let i = 0; i < this.tableData.length; i++) {
            let row = this.tableData[i];
            let py_name = pinyin.getCamelChars(row.name).toUpperCase();
            if (py_name.indexOf(_this.searchName.toUpperCase()) > -1) {
              _this.tableDataNew.push(row);
            }
          }

          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            setTimeout(() => {
              loadingInstance.close();
            }, 500);
          });
        } else {
          if (this.searchName.length > 0) {
            for (let i = 0; i < this.tableData.length; i++) {
              let row = this.tableData[i];
              if (row.name.indexOf(this.searchName) > -1) {
                this.tableDataNew.push(row);
              }
              this.$nextTick(() => {
                // 以服务的方式调用的 Loading 需要异步关闭
                setTimeout(() => {
                  loadingInstance.close();
                }, 500);
              });
            }
          } else {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              setTimeout(() => {
                loadingInstance.close();
              }, 500);
            });
            this.$message({
              message: "请输入要查询的姓名关键字",
              type: "error",
              offset: 200,
            });
          }
        }

        let myTableData=[];

        this.tableDataNew.map(async row=>{
        
          const nrow =  {
            ...row,
            deptName:this.isNumber(row.deptName)?(await this.$getDeptNameByDeptId(this.$http,row.deptName))[0].dept_name:row.deptName
          };
          myTableData.push(nrow);

        });
        this.tableDataNew = myTableData;
      }
    },
   
    clearLiColor() {
      let li_zms = document.getElementsByClassName("li_zm");
      for (let i = 0; i < li_zms.length; i++) {
        let li = li_zms[i];
        li.style.color = "#000";
      }
    },
    changeLiColor() {
      let li_zms = document.getElementsByClassName("li_zm");
      for (let i = 0; i < li_zms.length; i++) {
        let li = li_zms[i];
        li.style.color = "#f00";
      }
    },
    clickZM(zm, e) {
      let loadingInstance = Loading.service({
        text: "正在查询...",
      });
      this.tableDataNew = [];
      this.clearLiColor();
      let czm_li = e.currentTarget;
      czm_li.style.color = "#f00";

      if (zm == "#") {
        //this.tableDataNew = this.tableData;
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          let row = this.tableData[i];
          let pyname = pinyin.getCamelChars(row.name);
          let first_name = pyname.substring(0, 1);
          if (first_name == zm) {
            _this.tableDataNew.push(row);
          }
        }

       
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          setTimeout(() => {
            loadingInstance.close();
          }, 1000);
        });
      }
    },
  },
  mounted() {
    // this.tableDataNew = this.tableData;

    let name = decodeURIComponent(window.location.href.split("?name=")[1]);

    if (name === "通讯录") {
    } else {
      _this.searchName = name;
    }
    document.onkeydown = function (event) {
      var e = event || window.event || arguments.callee.caller.arguments[0];
      if (e && e.keyCode == 13) {
        _this.searchByName();
      }
    };
  },
};
</script>

<style lang="scss" scoped>
$mheight: auto;
$mtopheight: 68px;
.li_zm {
  &:hover {
    color: #f00;
  }
}
.bny_ldrc-look-content-weeks {
  float: left;
  border: 1px solid #bddda9;
  width: 147.2px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-right: none;
  border-bottom: none;
}
.bny_ldrc-look {
  margin: 0px auto 0px auto;
  width: 1400px;
  height: auto;
  font-size: 14px;
  .bny_meetting-top {
    width: calc(100% - 240px);
    height: 100px;
    line-height: 100px;
    background: url("../assets/imgs/advise/advise-top.png") no-repeat #519f6d;
    background-position-x: 60px;
    background-position-y: 10px;
    background-size: 1280px 80px;
    text-align: left;
    padding: 0 120px;
    font-size: 18px;
    font-weight: 600;
    color: #2e6548;
  }
  .bny_ldrc-look-content {
    height: 700px;
    width: calc(100% - 20px);
    background: #fff;
    padding: 10px;
  }
}
</style>