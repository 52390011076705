<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-04 14:13:13
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-10-17 12:22:33
 * @FilePath: \app_wb\src\components\commom\index_list_calendar.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="bny_index-list">
    <div class="bny_index-list-title">
      <img :src="titlelogo" alt="" />
      <div style="color: #444; border-top: 3px solid rgb(81 159 109)">
        {{ title }}
      </div>
      <div></div>
    </div>
    <div class="bny_index-list-content">
      <div class="calendar_content-calendar">
        <Calendar
          ref="calendar"
          :date="new Date()"
          @click="calendarClick"
          @preMonth="preMonth"
          style="width: 260px; height: 360px"
        >
          <!-- 可使用动态插槽名设置，v-slot:[slotName] -->
          <template v-slot:2021-5-21>
            <div style="position: absolute; bottom: -20px">今天</div>
          </template>
        </Calendar>
      </div>
      <div class="calendar_content">
        <div class="calendar_content-content">
          <el-table :data="rc_data_new" border height="362">
            <el-table-column
              prop="starttime"
              align="center"
              label="开始时间"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="endtime"
              align="center"
              label="结束时间"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              align="center"
              label="会议主题"
              width=""
            >
            </el-table-column>

            <el-table-column prop="room" align="center" label="会议室">
            </el-table-column>
            <el-table-column align="center" label="预定人" width="120">
              <template slot-scope="scope">
                <span
                  style="text-decoration: underline; color: #409eff"
                  @click="gotBook(scope.row.booker)"
                  >{{ scope.row.booker }}</span
                >
              </template>
            </el-table-column>

            <!-- <el-table-column prop="name" label="会议名称">
            </el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import CryptoJS from "crypto-js";
import Calendar from "./Calendar.vue";
import {
  getNowFormatDate,
  geHHMM,
  getFormatDate,
  getNowDateYearMonth,
  getYearMonthDay,
  uniqueJsonArrByField,
} from "../../utils/common.js";

import { meetting_data } from "../../utils/database.js";
import { ListMettingDue } from "@/api/content/xtbg_meetting_due.js";

export default {
  name: "index-list-calendar",
  components: {
    Calendar,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    titlelogo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rc_title: "",
      rc_content: "",
      rc_data_new: [],
      rc_data: [],
      meetting_room: "",
      options: [
        {
          value: "第一会议室",
          label: "第一会议室",
        },
        {
          value: "第二会议室",
          label: "第二会议室",
        },
        {
          value: "第三会议室",
          label: "第三会议室",
        },
        {
          value: "昆明分部101会议室",
          label: "昆明分部101会议室",
        },
        {
          value: "分部219",
          label: "分部219",
        },
      ],
      queryPramas: {
        pageSize: 10000,
        pageNum: 1,
      },
      temp_cells: [],
      temp_cell_bg: "",
      nowDate: "",
    };
  },
  created() {
    this.getListMettingDue();
  },
  watch: {
    "$store.state.chooseYearMonth": {
      handler(newV) {
        //获取当前月份的会议预定情况
        this.nowDate = newV;
        this.getNowMonthMeeting(this.nowDate);
      },
      deep: true,
    },
  },
  methods: {
    gotBook(booker) {
      this.$router.push({
        path: "/books",
        query: {
          name: decodeURIComponent(booker),
        },
      });
    },
    preMonth(event, dateInfo) {
      console.log(dateInfo);
    },
    tableRowClick(row, column, event) {
      console.log(this.title);
    },

    calendarClick(event, dateInfo) {
      let parentEl = event.currentTarget.parentElement;
      let currentEl = event.currentTarget;

      let currentEl_className = currentEl.getAttribute("class");
      let allCell = document.getElementsByClassName(currentEl_className);
      for (let i = 0; i < allCell.length; i++) {
        let cell = allCell[i];
        cell.style.border = "none";
        //cell.style.background ="none";
      }
      // if(this.temp_cells.length>0){
      //   for(let i =0 ; i< this.temp_cells.length;i++){
      //     let temp_cc= this.temp_cells[i];
      //     temp_cc.background = "rgb(81, 159, 109)";
      //   }

      // }
      currentEl.style.border = "3px solid #f00";
      //currentEl.style.background ="red";
      //this.temp_cells.push(currentEl);

      let sDate = getFormatDate(dateInfo.date);
      // console.log(sDate);
      ListMettingDue(this.queryPramas).then((res) => {
        // let test = res.data.rows.filter(rr=>{
        //   console.log(rr.starttime);
        //   return rr.starttime.indexOf(sDate)>-1;
        // })
        // console.log(test);
        this.rc_data = res.data.rows;

        this.rc_title = sDate + "  ";

        //从后台获取对应日期的会议日程
        this.rc_data_new = [];

        for (let i = 0; i < this.rc_data.length; i++) {
          let row = this.rc_data[i];
          if (row.starttime.indexOf(sDate) > -1) {
            let mrow = row;
            mrow.starttime = geHHMM(mrow.starttime);
            mrow.endtime = geHHMM(mrow.endtime);

            this.rc_data_new.push(mrow);
          }
        }
        //排序 按开始时间
        this.rc_data_new = this.rc_data_new.sort((s, n) => {
          return (
            Date.parse(sDate + " " + s.starttime + ":00") -
            Date.parse(sDate + " " + n.starttime + ":00")
          );
        });
        //console.log(this.rc_data_new);

        //渲染当月已安排的
        if (this.nowDate == "") {
          this.getNowMonthMeeting(getNowDateYearMonth(new Date()));
        } else {
          this.getNowMonthMeeting(this.nowDate);
        }
      });
    },
    getListMettingDue() {
      ListMettingDue(this.queryPramas).then((res) => {
        // console.log(res);
        this.rc_data = res.data.rows;
        this.setNowCalendar();
      });
    },
    setNowCalendar() {
      let sDate = getNowFormatDate();
      this.rc_title = sDate + "  ";

      this.rc_data_new = [];

      for (let i = 0; i < this.rc_data.length; i++) {
        let row = this.rc_data[i];

        if (row.starttime.indexOf(sDate) > -1) {
          let mrow = row;
          mrow.starttime = geHHMM(mrow.starttime);
          mrow.endtime = geHHMM(mrow.endtime);

          this.rc_data_new.push(mrow);
        }
      }
      //排序 按开始时间
      this.rc_data_new = this.rc_data_new.sort((s, n) => {
        return (
          Date.parse(sDate + " " + s.starttime + ":00") -
          Date.parse(sDate + " " + n.starttime + ":00")
        );
      });
      // console.log(this.rc_data_new);
    },
    clearCalendarCellBg() {
      let cal_m_day_cells = document.getElementsByClassName("cal_m_day_cell");
      for (let i = 0; i < cal_m_day_cells.length; i++) {
        let cell = cal_m_day_cells[i];
        cell.style.background = "#fff";
        cell.style.color = "#000";
      }
    },
    encrypt(text) {
      const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
      const IV_LENGTH = 16; // 初始化向量的长度

      // 生成随机的初始化向量（IV）
      const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

      // 使用 AES 加密
      const encrypted = CryptoJS.AES.encrypt(
        text,
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // 返回加密后的字符串和 IV
      return (
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    //年月
    getNowMonthMeeting(date) {
      //根据数据数据库日程安排，默认选中当前月份的安排了的日期
      //console.log('开始搞事...')

      let sqlStr =
        "select starttime from xtbg_meetting_due where starttime like '%" +
        date +
        "%'";
      let queryP = {
        query: this.encrypt(sqlStr),
        params: [],
      };
      let date_arr = [];
      //this.clearCalendarCellBg();
      this.$http
        .post(this.$db_dynamic_url, queryP, {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
          },
        })
        .then((res) => {
          res.data.map((row) => {
            let now_date = getYearMonthDay(row.starttime);

            if (date_arr.indexOf(now_date) == -1) {
              date_arr.push(now_date);
            }
          });
          //console.log(date_arr);

          //去遍历日期组件的日期

          let cal_m_day_cells =
            document.getElementsByClassName("cal_m_day_cell");
          for (let i = 0; i < cal_m_day_cells.length; i++) {
            let cell = cal_m_day_cells[i];
            let cell_value = cell.innerHTML.trim();
            // cell.style.background = "#fff";
            // cell.style.color = "#000";
            // if(cell.style.background=="rgb(192, 196, 204)"){
            //   cell.style.background = "";
            // }

            let now_day = new Date().getDate();
            if (cell.style.background == "rgb(81, 159, 109)") {
              cell.style.background = "";
            }

            date_arr.map((item) => {
              let day = item.split("-")[2];

              if (parseInt(day) == parseInt(cell_value)) {
                if (parseInt(now_day) != parseInt(cell_value)) {
                  if (cell.style.background == "") {
                    cell.style.background = "#519f6d"; // "#519f6d";
                    cell.style.color = "#fff";
                  }
                }
              } else {
                //console.log(cell.style.background);
              }
            });
          }
        });
    },
  },
  mounted() {
    this.getNowMonthMeeting(getNowDateYearMonth(new Date()));
  },
};
</script>
  
  <style lang="scss" scoped>
.bny_index-list {
  height: auto;
  width: 1000px;
  //background: url('../../assets/imgs/m001.png') no-repeat;
  background-size: 100% 100%;
  margin: 5px 0px 0px 0px;
  .bny_index-list-title {
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    width: 1000px;
    /* background-color: rgb(81 159 109); */
    background-color: rgb(245, 247, 250);
    border-top: 1px solid rgb(81 159 109);

    img {
      width: 25px;
      height: 25px;
      margin: 12px;
      float: left;
    }
    div {
      color: #fff;
      font-size: 16px;
      text-align: left;
      padding-left: 5px;
      float: left;
      &:last-child {
        float: right;
        margin-right: 15px;
        font-size: 14px;
        cursor: pointer;
        color: #444;
      }
    }
  }
  .bny_index-list-content {
    padding-top: 5px;
    width: 1000px;
    height: 365px;
    font-size: 14px;
    color: rgb(81 159 109);
    background-color: #fff;
    .calendar_content-calendar {
      float: left;
      width: 260px;
    }
    .calendar_content {
      float: left;
      height: 365px;
      width: calc(100% - 266px);

      padding: 0px 0px 0 6px;
      text-align: left;
      font-size: 14px;

      text-indent: 2em;
    }
  }
  .calendar_content-meetting {
    width: calc(100% - 10px);
    height: 60px;
    border-bottom: 1px solid #f2f2f2;
    background: #fff;
    text-align: left;
    padding: 0 5px;
  }
}
</style>
  