<template>
  <div class="gongjiao">

    <div
      style="margin: 10px 0px;height:100px;background-color: #F5F8F7; text-align: left; display: flex; align-items: center;">

      <img src="../../../assets/imgs/gongwu/zuixingonggao.png" style="width: 60px; height: auto; margin-left: 20px;"
        alt="" srcset="">

      <div style="flex:1;font-size: 14px; padding: 0px 20px;">
        <div style="text-indent: 2em; ">
          尊敬的用户们，
          公务车预约，上线试运行。
一、内网用户端：第一，内网所有用户可以申请公务车，由车队管理审核指派公务车，其他用户可以搭乘可搭乘的公务车，搭乘需由对应用车人审核同意，即可搭乘。第二，可以自由发布顺风车信息。
二、车队管理员端（只在PC端管理车辆基础信息，权限管理）。（pc端和小程序端均可审核和指派）审核内网用户公务车申请单，并指派对应车辆和司机（目前，司机和公务车属于一对一）。
三、司机端（只在小程序端），查看跟自己有关系的公务车订单并可结束行程。
        </div>
        <div class="flex">
          <span style="color: #808080; margin-left: auto;">
            2024年9月24日
          </span>
        </div>

      </div>
    </div>
    <div class="flex gongjiao" style="padding: 10px 10px 10px 0">
      <el-button @click="startApplication" type="success"   icon="el-icon-plus">发布顺风</el-button>
      <el-input clearable style="margin-left: auto; margin-right: 10px; max-width: 200px" v-model="searchStartAddress"
        placeholder="搜索起点"></el-input>

      <el-input clearable style="margin-right: 10px; max-width: 200px" v-model="searchEndAddress"
        placeholder="搜索终点"></el-input>

      <el-button @click="searchApplications"  type="text" icon="el-icon-refresh">刷新</el-button>
    
      <el-button @click="showHistory = true"  type="text" style="color:#f9a046"  icon="el-icon-document">发布历史</el-button>
    </div>

    <el-table border height="560px" :data="bookings" style="width: 100%">
      <el-table-column prop="uid" label="序号" width="50" align="center"></el-table-column>
      <el-table-column prop="xingcheng" label="行程" width="140" align="center"></el-table-column>
      <el-table-column prop="carinfo" label="车辆信息"  align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.carinfo.split('-')[0] }}-</span>
          <span style="color: #409EFF ; cursor: pointer;" @click="searchBook(scope.row.carinfo.split('-')[1])">{{
        scope.row.carinfo.split('-')[1] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="start_time" label="出发时间" align="center" width="156"></el-table-column>
      <el-table-column prop="create_time" label="发布时间" align="center" width="156">

      </el-table-column>

      <el-table-column prop="capacity" label="限乘人数" align="center" width="78"></el-table-column>

      <el-table-column prop="price" label="费用(元/人)" width="120"></el-table-column>
      <!-- <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-thumb" v-if="scope.row.status == 1 && scope.row.carpool == '是'"
              @click="editBooking(scope.row)">搭乘</el-button>
          </template>
        </el-table-column> -->
    </el-table>
    <el-pagination background layout="total,prev, pager, next" :total="total" :page-size="pageSize"
      :current-page.sync="currentPage" @current-change="handleCurrentChange"
      style="margin-top: 20px; text-align: center" />
    <!-- 发布顺风 -->
    <el-drawer :title="drawerTitle" :visible.sync="isDrawerVisible" :direction="'rtl'" :show-close="false"
      :wrapperClosable="false" :with-header="false" :size="drawerWidth">
      <div style="padding: 20px; background-color: #F5F5F5">
        <div style="display: flex; padding: 0px 10px 20px 0px;">
          <div style="flex:1;border-bottom: 1px solid #085; text-align: left;padding-bottom: 14px;">
            <span
              style="border-bottom: 6px solid #085; padding-bottom: 10px;margin-left: 15px; font-weight: 600;">发布顺风车信息</span>
          </div>
          <div style="cursor: pointer;" @click="isDrawerVisible = false">
            <i class="el-icon-close" style="font-size: 18px;"></i>
          </div>
        </div>
        <el-form class="myform" label-width="90px" label-position="left">

          <div
            style="padding: 10px; align-items: center; background-color: #fff; min-height: 100px; margin-bottom: 10px;">
            <div class="flex" style="  padding-bottom: 5px; align-items: center">
              <el-input v-model="mFormData.start_address" placeholder="出发地"></el-input>

              <img src="../../../assets/imgs/gongwu/goto.png" alt="" @click="huzhuan"
                style="height: 30px; cursor: pointer;" srcset="">

              <el-input v-model="mFormData.end_address" placeholder="目的地"></el-input>
            </div>
            <div class="flex" style="flex-wrap: wrap;">
              <div
                style="color: #fff; font-size: 12px; cursor: pointer; background-color: #808080; margin: 5px;padding: 5px; border-radius: 5px;"
                v-for="item in changyong" :key="item" @click="chooseAddress(item)">
                {{ item }}
              </div>

            </div>
          </div>

          <el-form-item label="车牌号">
            <el-input v-model="mFormData.license_plate" @input="handleInput" :maxlength="maxlength"
              placeholder="请输入车牌号"></el-input>
          </el-form-item>
          <el-form-item label="限乘人数">
            <div style="display: flex; justify-content: flex-start;">
              <el-input-number v-model.number="mFormData.capacity" :min="1"></el-input-number>
            </div>
          </el-form-item>
          

          <el-form-item label="出发时间">
            <div style="display: flex; justify-content: flex-start; ">
              <el-date-picker v-model="mFormData.start_time" type="datetime" format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="备注">
            <div style="display: flex; justify-content: flex-start;">
              <el-input v-model.number="mFormData.price" :min="1">
                <template slot="append">元/人</template>
              </el-input>
              <!-- <span style="padding-left: 10px;">元/人</span> -->
            </div>
          </el-form-item>




          <el-form-item>
            <div style="display: flex;" class="gongjiao">
              <el-button type="success" style="margin-left: auto;" @click="submitApplication">立即发布</el-button>
            </div>

          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 发布历史记录 -->
    <el-drawer title="发布顺风历史" :visible.sync="showHistory" :with-header="false" :direction="'btt'" :size="'70%'">
      <div class="gongjiao_jichuxinxi" style="padding: 10px 20px 40px 20px;">
        <el-tabs v-model="activeName">
          <el-tab-pane label="发布顺风历史" name="first">
            <div>
              <el-table border height="500" :data="applicationHistory" style="width: 100%">
                <el-table-column prop="uid" label="序号" width="80" align="center"></el-table-column>
                <el-table-column prop="xingcheng" label="行程" align="center"></el-table-column>
                <el-table-column prop="carinfo" label="车辆信息" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.carinfo.split("-")[0] }}-</span>
                    <span style="color: #409eff; cursor: pointer"
                      @click="searchBook(scope.row.carinfo.split('-')[1])">{{ scope.row.carinfo.split("-")[1] }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="start_time" label="开始时间" align="center" width="156"></el-table-column>
                <el-table-column prop="create_time" label="发布时间" align="center" width="100"></el-table-column>
                <el-table-column prop="price" label="费用(元/公里)"></el-table-column>
                <el-table-column prop="capacity" label="限乘人数" align="center" width="78"></el-table-column>


                <el-table-column label="操作" fixed="right" width="100">
                  <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-close"
                      @click="cancelBooking(scope.row)">取消</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination background layout="total,prev, pager, next" :total="total2" :page-size="pageSize2"
                :current-page.sync="currentPage2" @current-change="handleCurrentChange2"
                style="margin-top: 20px; text-align: center" />
            </div>

          </el-tab-pane>
          <!-- <el-tab-pane label="搭乘历史" name="second">搭乘历史</el-tab-pane> -->
        </el-tabs>
      </div>

    </el-drawer>


  </div>
</template>

<script>
let tableBookingApplication = 'xtbg_officecar_hitchhiking';
export default {
  data() {
    return {

      maxlength: 8, // 假设车牌号最大长度为7

      activeName: "first",

      showHistory: false,
      applicationHistory: [],
      currentPage2: 1,
      total2: 0,
      pageSize2: 8,

      changyong: ["昆明--版纳", "版纳--昆明", "曲靖--昆明", "昆明--曲靖", "版纳--普洱", "版纳--玉溪", "版纳--大理", "版纳--楚雄", "版纳--文山", "版纳--红河"],

      isDrawerVisible: false,
      drawerWidth: '25%',
      drawerTitle: '申请用车',
      mFormData: {

        start_address: '',
        end_address: '',
        license_plate: '',
        capacity: 1,
        price: 200,
        start_time: '',



      },

      searchStartAddress: "",
      searchEndAddress: "",
      searchUser: '',
      dialogVisible: false,
      bookings: [],

      vehicles: [],
      currentBooking: {
        vehicle_id: "",
        start_address: "",
        end_address: "",
        start_time: "",
        end_time: "",
        carpool: "",
        capacity: "",
        other_seats: "",
        reason: "",
        status: "",
      },

      currentPage: 1,
      total: 0,
      pageSize: 8, // 每页显示的数据量
    };
  },

  watch: {
    isDrawerVisible(val) {
      if (val) {
        this.mFormData.start_time = this.$FormatDateToYMDHMS(new Date(), true);

        this.getVihicleinos();
      }
    },
    showHistory(val) {
      if (val) {
        this.getApplicationHistorys();
      }
    },
    searchStartAddress(val) {
      if (val) {
        this.searchApplications();

      } else {
        this.getBookings();
      }
    },
    searchEndAddress(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    },
    searchUser(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    }
  },



  methods: {

    handleInput(value) {
      // 限制输入长度和内容
      const regex = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]A-Z0-9]{5}$/;
      if (!regex.test(value)) {
        this.licensePlate = value.slice(0, -1); // 去掉最后一个字符
      }
      // 可以根据需要进一步限制每个位置的字符，例如省份简称、字母、数字等
    },

    async searchApplications() {
      //SELECT * FROM xtbg_officecar_application WHERE start_address like '%昆明%' AND end_address LIKE '%%' AND create_by in (SELECT id FROM xtbg_user WHERE name like '%刘%') AND status=1
      this.currentPage = 1;
      this.bookings = [];
      let _tableName = tableBookingApplication + ` a`;
      let _sfd = `a.*,CONCAT(license_plate,'-',(SELECT name FROM xtbg_user WHERE id=a.create_by)) as carinfo,CONCAT(start_address,'-',end_address) as xingcheng`;
      let _where = `start_address like '%${this.searchStartAddress}%' 
              AND end_address LIKE '%${this.searchEndAddress}%' 
              ORDER BY id DESC LIMIT ${this.pageSize} offset ` + (this.currentPage - 1) * this.pageSize;

      //console.log("select "+_sfd+" from "+ _tableName+" where "+_where);
      const dataRes = await this.$db_select(this.$http, _tableName, _sfd, _where);

      const data = dataRes.length > 0 ? dataRes : [];
      let index = 0;
      this.bookings = data.map((item) => {
        item.uid = ++index;
        item.cstatus = "待发车";
        item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
        return item;
      });
      const totalRes = await this.$db_select(this.$http, tableBookingApplication, "count(*) as total", _where.split("ORDER BY")[0]);
      this.total = totalRes.length > 0 ? totalRes[0].total : 0;



    },
    async getVihicleinos() {
      const data = await this.$db_select(this.$http, "xtbg_officecar_vihicleinfo v", "id,seats,license_plate,type,IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'待定') as user_name", "status=0")
      this.vehicles = data;

    },
    chooseAddress(item) {
      this.mFormData.start_address = item.split("--")[0];
      this.mFormData.end_address = item.split("--")[1];


    },
    huzhuan() {
      let temp = this.mFormData.start_address;
      this.mFormData.start_address = this.mFormData.end_address
      this.mFormData.end_address = temp;


    },

    openDrawer() {
      this.isDrawerVisible = true;
    },
    async submitApplication() {
      if (!this.mFormData.start_address.trim()) {
        this.$message({
          type: "error",
          message: "请填写出发地!",
        });
        return false;
      }
      if (!this.mFormData.end_address.trim()) {
        this.$message({
          type: "error",
          message: "请填目的地!",
        });
        return false;
      }

      if (!this.mFormData.license_plate.trim()) {
        this.$message({
          type: "error",
          message: "请输入车牌号!",
        });
        return false;
      }
      if (this.mFormData.capacity == 0) {
        this.$message({
          type: "error",
          message: "请填写限乘车人数!",
        });
        return false;
      }
      if (this.mFormData.price==0) {
        this.$message({
          type: "error",
          message: "请填写乘车单价!",
        });
        return false;
      }

      // 提交申请逻辑
      this.mFormData.create_by = localStorage.getItem("userid");
      this.mFormData.create_time = this.$FormatDateToYMDHMS(new Date(), true);

      this.mFormData.start_time = this.$FormatDateToYMDHMS(this.mFormData.start_time, true);

      console.log('提交申请', this.mFormData);

      const insertRes = await this.$db_insert(this.$http, tableBookingApplication, this.mFormData);
      console.log(insertRes);
      if (insertRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "发布成功!",
        });
        this.getBookings();


      } else {
        this.$message({
          type: "error",
          message: "发布失败!",
        });
      }
      this.isDrawerVisible = false;


    },
    saveDraft() {
      // 保存草稿逻辑
      console.log('保存草稿', this.formData);
      this.isDrawerVisible = false;
    },

    isSelfApplication() {
      return this.currentBooking.create_by == localStorage.getItem("userid");
    },
    searchBookings() {
      // 搜索逻辑

    },
    editBooking(booking) {
      this.currentBooking = { ...booking };
      this.dialogVisible = true;
    },
    startApplication() {
      this.isDrawerVisible = true;

    },
    lookApplications() {

    },
    //获取 公务车信息 审核通过的
    async getBookings() {
      // 获取公务车信息
      // 假设数据为 bookings

      const data = await this.$db_select(
        this.$http,
        tableBookingApplication + " a ",
        `a.*,CONCAT(license_plate,'-',(SELECT name FROM xtbg_user WHERE id=a.create_by)) as carinfo,CONCAT(start_address,'-',end_address) as xingcheng`,
        "1=1 ORDER BY create_time DESC limit " +
        this.pageSize +
        " offset " +
        (this.currentPage - 1) * this.pageSize
      );



      this.total = (
        await this.$db_select(
          this.$http,
          tableBookingApplication,
          "count(*) as total",
          "1=1"
        )
      )[0].total;
      let index = 0;
      this.bookings = data.map((item) => {
        item.uid = ++index;

        item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
        item.create_time = this.$FormatDateToYMDHMS(item.create_time, true);

        return item;
      });

    },
    // 翻页
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getBookings();
    },
    handleCurrentChange2(page) {
      this.currentPage2 = page;
      this.getApplicationHistorys();
    },
    async getApplicationHistorys() {
      // 获取公务车信息
      // 假设数据为 bookings
      const data = await this.$db_select(
        this.$http,
        tableBookingApplication + " a ",
        `a.*,CONCAT(license_plate,'-',(SELECT name FROM xtbg_user WHERE id=a.create_by)) as carinfo,CONCAT(start_address,end_address) as xingcheng`,
        "create_by=" + localStorage.getItem("userid") + " order by id desc limit " +
        this.pageSize2 +
        " offset " +
        (this.currentPage2 - 1) * this.pageSize2
      );

      console.log("记录", data);




      let index = 0;
      this.applicationHistory = data.map((item) => {
        item.uid = ++index;
        item.create_time = this.$FormatDateToYMDHMS(item.create_time, true);
        item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
        return item;
      });
      this.total2 = (
        await this.$db_select(
          this.$http,
          tableBookingApplication,
          "count(*) as total",
          "create_by=" + localStorage.getItem("userid")
        )
      )[0].total;
      console.log(this.total2);


    },
    async cancelBooking(row) {
      const deleteRes = await this.$db_delete(this.$http, tableBookingApplication, "id=" + row.id);
      if (deleteRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "取消成功!",
        });
        this.getApplicationHistorys();
      }
    },




    searchBook(username) {
      this.$router.push({
        path: '/books',
        query: {
          name: username,
        }
      })

    },


  },
  mounted() {
    this.getBookings();


  }
};
</script>

<style scoped>
/* 添加样式 */
</style>