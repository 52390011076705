<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-29 16:06:03
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-30 08:25:29
 * @FilePath: \app_wb\src\components\commom\index_list_item_kzss.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index_list-kz">
    <div class="index_list-kz-title">学术搜索</div>
    <div class="index_list-kz-items">
      <ul id="kzss_list">
        <li v-html="item" @click="clickKzssItem(item,$event)" v-for="(item,index) in list_items" :key="index">
           
        </li>
      </ul>
    </div>
    <div class="index_list-kz-search">
      <el-input placeholder="请输入内容" v-model="input2">
        <template slot="append">Search</template>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input2:'',
      list_items: [
        "在线<br/>期刊",
        "电子<br/>图书",
        "馆藏<br/>图书",
        "机构<br/>知识库",
        "百度<br/>学术",
      ],
    };
  },
  methods:{
    clickKzssItem(item,ev){
        let kzss_list = document.getElementById("kzss_list");
        let lis = kzss_list.querySelectorAll('li');
        for(let i = 0; i<lis.length;i++){
            lis[i].style.background = "#fff";
            lis[i].style.border = "none";
        }
        let li = ev.currentTarget;
        li.style.background = "#f2f2f2";
        li.style.border= "1px solid #ddd";
        //开始搜索
        item = item.replace("<br/>","");
        console.log(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.index_list-kz {
  width: 395px;
  height: 320px;
  background: url("../../assets/imgs/list-items/kzss.png") #fff no-repeat;
  background-size: 100% 100%;

  .index_list-kz-title {
    color: #568c6a;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    height: 20px;
    padding-top: 70px;
  }
  .index_list-kz-items {
    padding: 40px;
    text-align: center;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 60px;
        height: 40px;
        padding: 10px 0px;
        
        
        float: left;
        font-size: 14px;
        color: #568c6a;
        font-weight: bold;
        cursor: pointer;
        &:active{
            background: #f2f2f2;
        }
      }
    }
  }
  .index_list-kz-search{
    padding-top: 40px;
  }
}
</style>