import CryptoJS from "crypto-js";
/**
 * 获取当前的日期
 */
export function getNowDateYYYYMMDDHHMMSS() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var strHour = date.getHours();
  strHour = strHour < 10 ? "0" + strHour : strHour;
  var strMinute = date.getMinutes();
  strMinute = strMinute < 10 ? "0" + strMinute : strMinute;
  var strSecond = date.getSeconds();
  strSecond = strSecond < 10 ? "0" + strSecond : strSecond;
  var currentdate =
    year +
    seperator1 +
    month +
    seperator1 +
    strDate +
    " " +
    strHour +
    ":" +
    strMinute +
    ":" +
    strSecond;
  return currentdate;
}
/**
 * 获取当前的日期
 */
export function getNowFormatDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}
/**
 * 根据日期返回对应的格式 2024-04-25
 */
export function getFormatDate(date) {
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}
/**
 * 返回年月 202-06
 */
export function getFormaYearMonth(date) {
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month;
  return currentdate;
}
/**
 * 根据日期返回 是星期几
 */
export function weekDay(time) {
  const weekNum = new Date(time).getDay();
  const week = {
    0: "星期天",
    1: "星期一",
    2: "星期二",
    3: "星期三",
    4: "星期四",
    5: "星期五",
    6: "星期六",
  };
  return week[weekNum] ? week[weekNum] : "日期错误";
}
/**
 * 转换对应的格式
 */
export function getWeekName(week) {
  if (week == "周一") {
    return "星期一";
  }
  if (week == "周二") {
    return "星期二";
  }
  if (week == "周三") {
    return "星期三";
  }
  if (week == "周四") {
    return "星期四";
  }
  if (week == "周五") {
    return "星期五";
  }
  if (week == "周六") {
    return "星期六";
  }

  if (week == "周日") {
    return "星期天";
  }
}
/**
 * 根据星期几返回对应的索引值
 */
export function getIndexFromWeek(week) {
  if (week == "星期一") {
    return 0;
  }
  if (week == "星期二") {
    return 1;
  }
  if (week == "星期三") {
    return 2;
  }
  if (week == "星期四") {
    return 3;
  }
  if (week == "星期五") {
    return 4;
  }
  if (week == "星期六") {
    return 5;
  }

  if (week == "星期天") {
    return 6;
  }
}
/**
 * 返回所有 星期
 */
export function getWeekDays() {
  const weeks = [
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
    "星期天",
  ];
  return weeks;
}
/**
 * 返回某个月有几天
 */
export function getMonthDays(target) {
  let year = target.split("-")[0];
  let month = target.split("-")[1];
  let date = new Date(year, month, 0); // 这里的month，比我们日常说的几月份小1，10则表示11月份
  return date.getDate();
}
/**
 * 返回两个数组直接重复的 重新组成数组返回
 */
export function repeatElement1(a, b) {
  let arr = [];
  for (let i = 0; i < a.length; i++) {
    if (b.indexOf(a[i]) !== -1 && arr.includes(a[i]) == false) {
      arr.push(a[i]);
    }
  }
  return arr;
}

export function mouseOverShowText(text, e) {
  setTimeout(() => {
    let div = document.createElement("div");
    div.style.display = "block";
    div.style.backgroundColor = "rgba(0,0,0,0.5)";
    div.style.zIndex = "999";
    div.style.width = "auto";
    div.style.height = "auto";
    div.style.color = "#fff";
    div.innerHTML = text;
    div.style.position = "absolute";
    div.style.left = e.pageX - 0 + "px";
    div.style.top = e.pageY - 160 + "px";
    div.style.textAlign = "left";
    div.style.fontSize = "14px";
    div.style.padding = "6px";
    div.style.borderRadius = "5px";
  }, 200);
}
export const isMobile = () => {
  let userAgentInfo = navigator.userAgent;
  let Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let getArr = Agents.filter((i) => userAgentInfo.includes(i));
  return getArr.length ? true : false;
};

export function removeHtml(item) {
  return item
    .replace(/<[^>]+>/g, "")
    .replaceAll("&nbsp;", "")
    .replaceAll(" ", ""); // 去掉所有的html标记
}
export function getNowDateYearMonthHourMS(db_date) {
  let date = new Date(db_date);

  let year = date.getFullYear();
  let month = date.getMonth();
  month = month + 1;
  month = month > 12 ? 1 : month;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  let hour = date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  let strip = "-";
  return (
    year +
    strip +
    month +
    strip +
    day +
    " " +
    hour +
    ":" +
    minutes +
    ":" +
    second
  );
}
//获取日期的年月
export function getNowDateYearMonth(db_date) {
  let date = new Date(db_date);

  let year = date.getFullYear();
  let month = date.getMonth();
  month = month + 1;
  month = month > 12 ? 1 : month;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  let hour = date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  let strip = "-";
  return year + strip + month;
}
//获取日期的 年月 下一个月
export function getNextDateYearMonth(db_date) {
  let date = new Date(db_date);

  let year = date.getFullYear();
  let month = date.getMonth();
  month = month + 2;
  month = month > 12 ? 1 : month;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  let hour = date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  let strip = "-";
  return year + strip + month;
}
// 获取日期的年月日
export function getYearMonthDay(db_date) {
  const date = new Date(db_date);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  const separator = "-";
  return `${year}${separator}${month}${separator}${day}`;
}
export function formatDateTime(db_date) {
  const date = new Date(db_date);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const separator = "-";

  return `${year}${separator}${month}${separator}${day} ${hours}:${minutes}:${seconds}`;
}
// 获取日期的小时和分钟 秒
export function geHHMMSS(db_date) {
  let date = new Date(db_date);

  let hour = date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  let strip = ":";
  return hour + strip + minutes + strip + second;
}
// 获取日期的小时和分钟
export function geHHMM(db_date) {
  let date = new Date(db_date);

  let hour = date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  let strip = ":";
  return hour + strip + minutes;
}
export function regManyJsonStrToJsonArr(jsonStrs) {
  let resStr = jsonStrs.replaceAll("[", "").replaceAll(",]", "");

  let resSts = resStr.split("},");

  let arr = [];

  for (let i = 0; i < resSts.length; i++) {
    let jsonStr = "";
    if (i == resSts.length - 1) {
      jsonStr = resSts[i];
    } else {
      jsonStr = resSts[i] + "}";
    }
    let text = jsonStr.replace(/[\r|\n|\t]/g, "");

    try {
      arr.push(JSON.parse(text));
    } catch (err) {
      console.log(err);
    }
  }
  return arr;
}

export function sortJsonArr(x, y) {
  return x.orderNum - y.orderNum;
}

export function goToTop() {
  var height = document.documentElement.scrollTop || document.body.scrollTop;
  var t = setInterval(() => {
    height -= 50;
    if (height > 0) {
      window.scrollTo(0, height);
    } else {
      window.scrollTo(0, 0);
      window.clearInterval(t);
    }
  }, 10);
}

/*
 * json数组去重
 * @param: {Array} jsonArr 去重之前的数组
 * @param  {String} field  需要去重的字段值
 * @return {Array}        去重之后的数组
 */
export function uniqueJsonArrByField(jsonArr, field) {
  // 数组长度小于2 或 没有指定去重字段 或 不是json格式数据
  if (jsonArr.length < 2 || !field || typeof jsonArr[0] !== "object")
    return jsonArr;
  const uniqueArr = jsonArr.reduce(
    (all, next) =>
      all.some((item) => item[field] == next[field]) ? all : [...all, next],
    []
  );
  return uniqueArr;
}

export function sortBy(filed, rev, primer) {
  rev = rev ? -1 : 1;
  return function (a, b) {
    a = a[filed];
    b = b[filed];
    if (typeof primer != "undefined") {
      a = primer(a);
      b = primer(b);
    }
    if (a < b) {
      return rev * -1;
    }
    if (a > b) {
      return rev * 1;
    }
    return 1;
  };
}
/**
 * JSON归类 根据特定条件，将同类数据归类到一起
 * @param arr
 * @returns {[]}
 * @constructor
 */
export function JsonClassification(arr, keyname) {
  // 存放最终归类好的数据
  const resultData = [];
  // 存放已经遍历过的元素
  const temp = {};
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const itemObj = item;
    // 判断当前key是否在已经遍历过的元素对象里
    if (!temp[item[keyname]]) {
      resultData.push({
        name: item[keyname],
        children: [itemObj],
      });
      // 将当前key放进已经遍历过的元素对象中
      temp[item[keyname]] = item;
    } else {
      // 遍历归类好的数据，将当前遍历到的数据放进与之对应的分类中
      for (let j = 0; j < resultData.length; j++) {
        let resultItem = resultData[j];
        if (resultItem[keyname] === item[keyname]) {
          resultItem.children.push(itemObj);
          break;
        }
      }
    }
  }
  return resultData;
}

export function dateCompare(a, b) {
  let flag = false;
  var arr = a.split("-");

  var starttime = new Date(arr[0], arr[1], arr[2]);
  var starttimes = starttime.getTime();
  var arrs = b.split("-");
  var endTime = new Date(arrs[0], arrs[1], arrs[2]);
  var endTimes = endTime.getTime();
  // 进行日期比较
  if (endTimes > starttimes) {
    flag = true;
    return flag;
  } else {
    flag = false;
    return flag;
  }
}
export function dateCompare2(a, b) {
  let flag = false;
  var arr = a.split("-");
  var starttime = new Date(arr[0], arr[1], arr[2]);
  var starttimes = starttime.getTime();
  var arrs = b.split("-");
  var endTime = new Date(arrs[0], arrs[1], arrs[2]);
  var endTimes = endTime.getTime();
  // 进行日期比较
  if (endTimes >= starttimes) {
    flag = true;
    return flag;
  } else {
    flag = false;
    return flag;
  }
}
function getYearMonthDay2(db_date) {
  let date = new Date(db_date);

  let year = date.getFullYear();
  let month = date.getMonth();
  month = month + 1;
  month = month > 12 ? 1 : month;
  month = month < 10 ? "0" + month : month;
  let day = date.getDate();
  day = day < 10 ? "0" + day : day;
  let hour = date.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let second = date.getSeconds();
  second = second < 10 ? "0" + second : second;
  let strip = "-";
  return year + strip + month + strip + day;
}
export function getalldays(start, end) {
  let arr = [];
  var startTime = new Date(start).getTime();
  var endTime = new Date(end).getTime();
  for (var k = startTime; k <= endTime; ) {
    arr.push(getYearMonthDay2(new Date(parseInt(k))));
    k = k + 24 * 60 * 60 * 1000;
  }
  return arr;
}
export function transMD(date) {
  let mdates = date.split("-");
  return mdates[1] + "月" + mdates[2] + "日";
}
//加密算法
function encrypt(text) {
  const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
  const IV_LENGTH = 16; // 初始化向量的长度

  // 生成随机的初始化向量（IV）
  const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

  // 使用 AES 加密
  const encrypted = CryptoJS.AES.encrypt(
    text,
    CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  // 返回加密后的字符串和 IV
  return (
    iv.toString(CryptoJS.enc.Hex) +
    ":" +
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );
}

/**
 * https://in.xtbg.ac.cn:8441/dynamic
 */
// const db_dynamic_url = "/hd";
let db_dynamic_url = "https://in.xtbg.ac.cn:8574/dynamic";
export function getDataFromDb(sqlStr) {
  return new Promise((resolve, reject) => {
    const data = {
      query: encrypt(sqlStr),
      params: [],
    };

    fetch(db_dynamic_url, {
      method: "POST",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",

        "Content-Type": "application/json",
      },

      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
/**
 * yyyyMMdd加上三位序号的字符串
 */
export function generateDateSequence(sequenceNumber) {
  // 获取当前日期
  const now = new Date();
  // 格式化日期为 yyyyMMdd
  const dateString =
    now.getFullYear().toString() +
    (now.getMonth() + 1).toString().padStart(2, "0") +
    now.getDate().toString().padStart(2, "0");
  // 将序号转换为三位字符串，不足部分用0补齐
  const sequenceString = sequenceNumber.toString().padStart(3, "0");
  // 拼接日期字符串和序号
  return dateString + sequenceString;
}

//获取ID字段最大的值
export async function getMaxId(db_dynamic_url) {
  const maxIDRes = await getDataFromDb(
    db_dynamic_url,
    "select max(id) as maxid from xtbg_congress_case"
  );

  return maxIDRes[0].maxid == null ? 1 : maxIDRes[0].maxid;
}
export function getCurrentDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份是从0开始的
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // 月份是从0开始的
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function extractNonEmptyValues(formObject) {
  // 使用Object.keys()遍历对象的所有键，然后使用reduce()累积非空值
  return Object.keys(formObject).reduce((accumulator, currentKey) => {
    // 检查当前键的值是否不为空
    if (
      formObject[currentKey] !== null &&
      formObject[currentKey] !== undefined &&
      formObject[currentKey] !== ""
    ) {
      // 如果不为空，则将此键值对添加到累加器对象中
      accumulator[currentKey] = formObject[currentKey];
    }
    // 返回累加器对象
    return accumulator;
  }, {}); // 初始值是一个空对象
}
export function addQuotesToObjectProperties(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    // 检查属性值是否为字符串
    acc[key] = typeof value === "string" ? `'${value}'` : value;
    return acc;
  }, {});
}
export function formatDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } else {
    return "";
  }
}
//加密
export function encryptData(data) {
  const secretKey = "xtbg";
  return CryptoJS.AES.encrypt(data, secretKey).toString();
}
//解密
export function decryptData(encryptedData) {
  const secretKey = "xtbg";
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
//删除对象中的属性为空的属性
export function removeEmptyProperties(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}
//表单对象（或任何对象）并想将其转换成一个SQL UPDATE 语句的一部分，即属性名和属性值的配对
export function objectToSqlSet(obj) {
  // 将对象的每个属性转换为"key='value'"的字符串形式
  const keyValuePairs = Object.keys(obj).map((key) => {
    let value = obj[key];

    // 如果值是字符串，将其包裹在单引号中
    // 注意：为了防止SQL注入，确保对值进行适当的清理/转义
    if (typeof value === "string") {
      value = `'${value.replace(/'/g, "''")}'`; // 简单的转义单引号
    }

    return `${key}=${value}`;
  });

  // 将所有"key='value'"形式的字符串用逗号连接起来
  return keyValuePairs.join(", ");
}
//2023-09-09 转换2023年09月09日
export function formatDateForYMD(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}年${month}月${day}日`;
  } else {
    return "";
  }
}
// onst formObject = {
//   name: "John Doe",
//   age: null,
//   email: undefined,
//   address: "", // 已经是空字符串
//   city: "New York"
// };
// 转换为 { name: 'John Doe', age: '', email: '', address: '', city: 'New York' }
export function replaceEmptyValuesWithEmptyString(formObject) {
  return Object.keys(formObject).reduce((acc, key) => {
    // 检查属性值是否为空，包括 null、undefined 或空字符串
    acc[key] =
      formObject[key] === null ||
      formObject[key] === undefined ||
      formObject[key] === ""
        ? ""
        : formObject[key];
    return acc;
  }, {});
}
export function formatDateToYmdhs(date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes(),
    second = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (second.length < 2) second = "0" + second;

  return [year, month, day].join("-") + " " + [hour, minute, second].join(":");
}

export function isFutureDateTime(dateTimeStr) {
  // 将输入的日期时间字符串转换为日期对象
  const inputDate = new Date(dateTimeStr);

  // 获取当前日期和时间
  const now = new Date();

  // 比较输入的日期和时间是否小于当前的日期和时间
  if (inputDate > now) {
    // 如果输入的日期和时间在当前日期和时间之后，返回 true
    return true;
  } else {
    // 如果输入的日期和时间是当前或之前的时间，返回 false
    return false;
  }
}
