<template>
  <div id="propose_table" class="flex-col f-15">
    <!-- 标题 -->
    <div class="padding-bottom-20 padding-top-20 f-22 font-weight-600">
      中国科学院西双版纳热带植物园<br />{{this.$congresConfig.propose_title}}职工代表大会提案表
    </div>
    <!-- 类别 -->
    <div class="flex padding-top-bottom-5">
      <div class="height-40 line-height-40 width-80">类别：</div>
      <div class="flex-1 flex align-items-center height-40 line-height-40">
        <el-radio class="flex-1" v-model="formData.type" label="单位建设"
          >单位建设</el-radio
        >
        <el-radio class="flex-1" v-model="formData.type" label="民主管理"
          >民主管理</el-radio
        >
        <el-radio class="flex-1" v-model="formData.type" label="职工福利"
          >职工福利</el-radio
        >
        <div class="flex align-items-center width-600">
          <el-radio v-model="formData.type" label="其他">其他</el-radio>
          <div class="flex-1 height-40 line-height-40">
            <el-input
              v-model="formData.type_review"
              placeholder="请输其他类型"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- 编号 填写日期-->
    <div
      class="flex margin-top-bottom-5"
      style="justify-content: space-between"
    >
      <div class="flex">
        <div class="height-40 line-height-40 width-80">编号：</div>
        <div>
          <el-input v-model="formData.num" placeholder="20240301001"></el-input>
        </div>
      </div>
      <div class="flex">
        <div class="height-40 line-height-40 width-80">填写日期：</div>
        <div class="height-40 line-height-40">
          <el-date-picker
            v-model="formData.proposal_date"
            type="date"
            placeholder="选择日期"
            format="yyyy年MM月dd日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <!-- 提案人 联系电话 -->
    <div class="flex">
        <!-- 提案人 -->
        <div class="flex-1 flex boder-right-1 boder-top-1 boder-left-1 boder-bottom-1 height-50 align-items-center">
            <div class="width-80 height-50 line-height-50 boder-right-1 ">提案人</div>
            <div class="flex-1">
                <el-input v-model="formData.proposer" placeholder="提案人"></el-input>
            </div>
        </div>
        <!-- 联系电话 -->
        <div class="flex-1 flex boder-right-1 boder-top-1 boder-bottom-1 height-50 align-items-center">
            <div class="width-80 height-50 line-height-50 boder-right-1">联系电话</div>
            <div class="flex-1">
                <el-input id="mobileInput" v-model="formData.p_phone" placeholder="联系电话"></el-input>
            </div>

        </div>

    </div>
    <!-- 附议人 -->
    <div class="flex-1 flex boder-left-1 boder-right-1 boder-bottom-1 height-50 align-items-center">
        <div class="width-80 height-50 line-height-50 boder-right-1">附议人</div>
        <div class="flex-1">
            <el-input v-model="formData.supporters" placeholder="附议人,至少两人或两人以上"></el-input>
        </div>

    </div>
    <!-- 提案内容 -->
    <div class="flex">
        <div class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center" style="height: 683px;padding: 0px 30px;">
            提<br/>案<br/>内<br/>容
        </div>
        <div class="flex-1 flex-col">
            <!-- 案名 -->
            <div class="flex align-items-center">
                <div class="height-80 line-height-80 boder-right-1 boder-bottom-1" style="padding: 0px 30px;">案名</div>
                <div class="flex-1  height-80 line-height-80 boder-bottom-1 boder-right-1">
                    <el-input v-model="formData.title"  style="font-size: 18px;" placeholder="提案的题目，用简洁的文字说明提案要求解决什么问题，案名要和提案内容一致，比如“关于推进\解决\设立XXXX的建议”或“建议推进\解决\设立XXXX的提案”。一定要“一事一案”。"></el-input>
                </div>
            </div>
            <!-- 案由 -->
            <div class="flex align-items-center">
                <div class="height-300 boder-bottom-1 boder-right-1 flex" style="align-items: center;padding: 0 30px;">案由</div>
                <div class="flex-1 height-300  boder-bottom-1  boder-right-1" style="position: relative;">
                    <el-input id="input_reason" type="textarea" resize="none" :rows="9" v-model="formData.reason" placeholder="提案的理由，是解决问题的重要依据，要开门见山，可进行现状分析，介绍背景或趋势、相关政策文件以及存在的问题等，在充分进行调研的基础上，以事实和数据为依据进行说明。"></el-input>
                   <Zoom :textid="'input_reason'"></Zoom>
                </div>
            </div>
            <!-- 具体意见建议 -->
            <div class="flex align-items-center">
                <div class="boder-bottom-1 boder-right-1 flex" style="align-items: center;height: 300px; padding: 0 23px 0 22px">具体意<br/>见建议</div>
                <div class="flex-1  boder-bottom-1  boder-right-1" style="height: 300px; position: relative;">
                    <el-input id="input_suggest" type="textarea"  resize="none" :rows="9" v-model="formData.suggest" placeholder="该部分是提案的核心内容，也是提案能否被采纳的关键，必须针对案由反映的问题，提出解决问题的主张和办法，提出的建议要力求具体可行，实事求是，具有则针对性、可操作性。"></el-input>
                    <Zoom :textid="'input_suggest'"></Zoom>
                </div>
            </div>
        </div>
      

        

    </div>
    <div class="flex-1 flex boder-left-1 boder-right-1 boder-bottom-1 height-50 align-items-center">
        <div class="width-80 height-50 line-height-50 boder-right-1">备注</div>
        <div class="flex-1">
            <el-input v-model="formData.remark" placeholder="备注"></el-input>
        </div>
    </div>

   

    <div class="flex padding-top-10">
        <div class="flex-1"></div>
        <div class="flex">
            <el-button @click="saveFormData" style="margin-left: 5px; background: #25834b; color: #fff">保存</el-button>
            <el-button @click="back">返回</el-button>

        </div>
    </div>

  </div>
</template>
<script>
    import { dbquery, getDataTotal } from "../sys/proposeRoleUser.js";
    import { Message } from 'element-ui';
    import Zoom from '../tools/zoom.vue'
import {generateDateSequence,getMaxId,getCurrentDate,getCurrentDateTime} from '../../../utils/common.js'
var that = null;
export default {
    components:{
        Zoom
    },
  data() {
    return {
      zb_deptOptions: [],
      xb_deptOptions: [],

      //职工代表
      is_zgdb:false,
      
      //提案组
      is_taz:false,
      //分管领导
      is_yld:false,
      //主办部门
      is_zbdept:false,
      


      formData: {
        type: "", // 类别
        type_review: "", // 其他类型
        num: "", // 编号
        proposal_date: "", // 提案日期
        title: "", // 案名
        reason: "", // 案由
        suggest: "", // 建议
        remark: "", // 备注
        proposer_userid: "", // 提案人用户ID
        proposer: "", // 提案人
        p_phone: "", // 电话
        supporters_userids: "", // 附议人用户IDS
        supporters: "", // 附议人
        s_time: "", // 附议时间
        t_userid: "", // 提案组操作人员ID
        t_result: "", // 提案组审核结果
        t_review: "", // 提案组说明选择原因
        suggest_dept: "", // 建议承办部门
        reply_time: "", // 承办部门答复时间
        t_date: "", // 提案组审核时间
        l_userid: "", // 分管领导用户ID
        l_opinion: "", // 分管领导意见
        l_sign: "", // 分管领导签名
        l_sign_date: "", // 签名意见日期
        d_response: "", // 承办部门处理意见
        d_head_userid: "", // 承办部门负责人用户ID
        d_head_sign: "", // 承办部门负责人签名
        d_head_date: "", // 签名日期
        p_feedback: "", // 提案人反馈等级
        p_f_detail: "", // 反馈详情
        p_f_sign: "", // 反馈签名
        p_f_sign_date: "", // 反馈签名日期
      },
    };
  },
  async created(){
    that = this;
    let maxId = await getMaxId(this.$db_dynamic_url);
    //编号生成
    this.formData.num = generateDateSequence(maxId);
    //填写日期
    this.formData.proposal_date = getCurrentDate();
    //附议时间
    this.formData.s_time = getCurrentDate();
    //承办部门答复时间
    this.formData.reply_time = getCurrentDate();
    //提案组审核时间
    this.formData.t_date = getCurrentDate();
    //园领导签名日期
    this.formData.l_sign_date = getCurrentDate()
    //承办部门负责人签字日期
    this.formData.d_head_date = getCurrentDate();
    //提案人反馈日期
    this.formData.p_f_sign_date = getCurrentDate();
    //主办部门
    //协办部门


    //获取用户信息
    //提案人
    this.formData.proposer = localStorage.getItem("username");

    //附议人
    this.formData.supporters = localStorage.getItem("username");
    
  },
  methods:{
    async dbquery(sqlStr) {
      return dbquery(this.$http,  sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },
    validateMobileNumber() {
      var regex = /^1[3-9]\d{9}$/;
      if (regex.test(this.formData.tel)) {
        console.log("Valid mobile number.");
      } else {
        console.log("Invalid mobile number.");
        //alert("请输入正确的手机号码");
        this.$message({
          showClose: true,
          message: '请输入正确的手机号码',
          type: 'error',
          offset:200
        });
      }
    },
    isValidInput(){
        //判断手机号
       
       
    },
    saveFormData(){
        if(this.formData.type==''){
            this.$message({
                showClose: true,
                message: '请选择类别',
                type: 'error',
                offset:200
            });
            return false;
        }
        if(this.formData.proposer==''){
            this.$message({
                showClose: true,
                message: '请填写提案人',
                type: 'error',
                offset:200
            });
            return false;
        }
        if(this.formData.resolutioner==''){
            this.$message({
                showClose: true,
                message: '请填写附议人',
                type: 'error',
                offset:200
            });
            return false;
        }
        if(this.formData.case==''){
            this.$message({
                showClose: true,
                message: '请填写案名',
                type: 'error',
                offset:200
            });
            return false;
        }
        if(this.formData.cause==''){
            this.$message({
                showClose: true,
                message: '请填写案由',
                type: 'error',
                offset:200
            });
            return false;
        }
        if(this.formData.suggestion==''){
            this.$message({
                showClose: true,
                message: '请填写具体建议',
                type: 'error',
                offset:200
            });
            return false;
        }
    },
    back(){
        this.$router.push({
            path:"/proposal_meetting/taxt_my"
        })
    },
    //根据userid 判断他是属于什么角色
    async getUserRoleIdForCongres(){
        const user_id = localStorage.getItem("userid");
        const sqlStr = "select role_id from xtbg_congress_role_user where user_id="+user_id;
        const userRole = await this.dbquery(sqlStr);

        const currentUserRoles = userRole.map(row=>row.role_id);
        //判断是什么角色 然后确定显示什么内容
    }
  },
  async mounted() {
  
    document.getElementById('mobileInput').addEventListener('blur', function() {
        that.validateMobileNumber();
    });

    // console.log(this.$congresConfig.congressRole);

    await this.getUserRoleIdForCongres()


    // for(let i=0;i<localStorage.length;i++){
    //     const key = localStorage.key(i)
    //     const value = localStorage.getItem(key);
    //     console.log(key+"=>",value);
    // }
    
        
  },
  beforeDestroy() {
    let inputElement = document.getElementById('mobileInput');
    if (inputElement) {
      // 请确保在组件销毁前移除事件监听器，防止内存泄漏
      inputElement.removeEventListener('blur', this.handleInputBlur);
    }
  },
};
</script>

