<template>
  <div class="index_list-apps" style="position: relative">
    <div class="index_list-apps-emails">
      综合服务平台
      <!-- <ul>
        <li>所长邮箱</li>
        <li>纪检邮箱</li>
      </ul> -->
    </div>
    <div class="index_list-apps-content">
      <ul>
        <li
          v-for="(item, index) in list_items"
          :key="index"
          @click="clickItem(item)"
        >
          <img :src="item.thumbnail" alt="" />
          <div>{{ item.name }}</div>
        </li>
      </ul>
      <div style="clear: both"></div>
      <el-row style="margin-bottom: 30px">
        <el-button type="text" @click="moreApps">更多...</el-button>
      </el-row>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script>
import { ListPlatformService } from "@/api/content/xtbg_platform_service.js";
export default {
  data() {
    return {
      list_items: [],
      queryParam: {
        pageSize: 20,
        pageNum: 1,
        isindex: "是",
      },
      wjhidden: true,
    };
  },
  created() {
    this.getListPlatformService();
  },
  watch: {
    "$store.state.loginwj": {
      handler(newV) {
        console.log(newV);
        this.getListPlatformService();
      },
      deep: true,
    },
  },
  methods: {
    moreApps() {
      this.$router.push({
        path: "/news",
        query: {
          name: "综合服务",
        },
      });
    },
    clickItem(item) {
      //console.log(item);
      if ((item.name == "常用下载")) {
        this.$store.commit("updateSelectedTitle", "内网新闻");

        localStorage.setItem("title", encodeURI("内网新闻"));
        localStorage.setItem("showTitle", encodeURI("各种常用表格等资料下载"));
        this.$router.push({
          path: "/newsdetail",
          query: {
            id: 11824,
          },
        });
      } else {
        if (item.url.indexOf("http") > -1) {
          window.open(item.url);
        } else if (item.url == "/" || item.url.length == 0) {
          this.$message({
            message: "正在努力开发中，请稍后...",
            type: "warning",
            offset: 200,
          });
        } else {
          this.$router.push({
            path: item.url,
            query: {
              name: item.name,
            },
          });
        }
      }
    },
    getListPlatformService() {
      ListPlatformService(this.queryParam).then((res) => {
        let rows = res.data.rows;
        rows = rows.sort(this.up);
        this.list_items = [];
        rows.map((row) => {
          row.thumbnail = this.$api_url + row.thumbnail;
          this.list_items.push(row);
        });
        if (!this.wjhidden) {
          this.list_items = this.list_items.filter((row) => {
            return row.name.indexOf("职代会") == -1;
          });
        } else {
          this.list_items = this.list_items;
        }
      });
    },
    up(x, y) {
      return x.orderNum - y.orderNum;
    },
  },
  mounted() {
    let roleName = localStorage.getItem("roleName");
    //判断权限，如果是外籍 用户 则隐藏有些模块
    if (roleName != null) {
      if (roleName.indexOf("外籍") > -1) {
        this.wjhidden = false;
      } else {
        this.wjhidden = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.index_list-apps {
  width: 385px;
  height: auto;
  padding-left: 10px;
  margin-left: 0px;

  /* background: url("../../assets/imgs/list-items/apps.jpg") no-repeat; */
  background-size: 385px 100%;
  background-position-x: 10px;
  /* //box-shadow:  inset 0 -5px #d7d7d7,inset 0 5px #d7d7d7,inset -5px 0 #d7d7d7,inset 5px 0 #d7d7d7; */
  margin-bottom: 5px;

  .index_list-apps-emails {
    height: 60px;
    line-height: 62px;
    width: 160px;
    background: rgb(245, 247, 250);
    position: absolute;
    left: calc(50% - 75px);
    top: -30px;
    /* border: 1px solid rgb(81 159 109); */
    /* box-shadow: 5px 5px 5px #ddd; */
    border-radius: 5px;

    background: url('../../assets/imgs/apptitlebg.png');
    background-size:100% 100%;
    color:#3A7355;

    /* color: #519f6d; */
    font-size: 17px;
    font-weight: 600;

    /* ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        height: 100px;
        line-height: 90px;
        width: 196px;
        text-align: center;
        float: left;
        font-size: 16px;
        font-weight: normal;
        &:hover{
            cursor: pointer;
        }
      
      }
    } */
  }
  .index_list-apps-content {
    margin-top: 30px;
    border: 1px solid #ebeef5;
    /* border-top: none; */
    height: auto;
    padding-top: 30px;
    /* background: rgb(245, 247, 250); */
    background: linear-gradient(0deg, #F2F7F2 77%, #D1EAD1 100%);
    ul {
      padding: 0 20px;
      margin: 0;
      list-style: none;
      li {
        height: 104px;
        width: 70px;
        padding: 0px 20px;
        padding-top: 30px;
        text-align: center;
        float: left;
        font-size: 14px;
        font-weight: normal;
        &:hover {
          cursor: pointer;
        }
        img {
          height: 50px;
          width: 50px;
        }
        div {
          width: auto;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>