<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-19 11:17:20
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-12 17:21:54
 * @FilePath: \app_wb\src\views\proposal\meettint_dhjy.vue
 * @contentription: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny_advise-top">
      <div>职工代表建言</div>
    </div>
    <div class="bny__wb-dahuijy">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
       
        <el-form-item label="建言内容" prop="content" >
          <el-input type="textarea" :rows="10"  v-model="ruleForm.content"></el-input>
        </el-form-item>
        <el-form-item style="float:left;">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即建言</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {checkEmail,checkPhone} from '../../utils/formvalidage.js'
export default {
  data(){
    return{
      ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          content: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请输入邮箱地址',validate:checkEmail, trigger: 'blur' },
 
          ],
          phone: [
            { required: true, message: '请输入邮箱地址',validate:checkPhone, trigger: 'blur' },
          ],
         
         
          content: [
            { required: true, message: '请填写建言内容', trigger: 'blur' }
          ]
        }
      }
  },
  methods: {

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>

<style lang="scss" scoped>
.bny_advise-top {
  width: calc(100% - 240px);
  height: 100px;
  line-height: 100px;
  background: url("../../assets/imgs/advise/advise-top.png") no-repeat #519f6d;
  background-position-x: 60px;
  background-position-y: 10px;
  background-size: 950px 80px;
  text-align: left;
  padding: 0 120px;
  font-size: 18px;
  font-weight: bold;
  color: #2e6548;
}
.bny__wb-dahuijy {
  height: auto;
  padding: 10px 20px;
  overflow-y: auto;
}
</style>