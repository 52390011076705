<template>
  <div id="propose_table" class="flex-col f-15">
    <!-- 标题 -->
    <div class="padding-bottom-20 padding-top-20 f-22 font-weight-600">
      中国科学院西双版纳热带植物园<br />{{
        this.$congresConfig.propose_title
      }}职工代表大会提案表
    </div>
    <!-- 类别 -->
    <div class="flex padding-top-bottom-5">
      <div class="height-40 line-height-40 width-80">类别：</div>
      <div class="flex-1 flex align-items-center height-40 line-height-40">
        <el-radio
          class="flex-1"
          :disabled="isDisabled"
          v-model="formData.type"
          label="单位建设"
          >单位建设</el-radio
        >
        <el-radio
          class="flex-1"
          :disabled="isDisabled"
          v-model="formData.type"
          label="民主管理"
          >民主管理</el-radio
        >
        <el-radio
          class="flex-1"
          :disabled="isDisabled"
          v-model="formData.type"
          label="职工福利"
          >职工福利</el-radio
        >
        <div class="flex align-items-center width-600">
          <el-radio label="其他" :disabled="isDisabled">其他</el-radio>
          <div class="flex-1 height-40 line-height-40">
            <el-input
              :disabled="isDisabled"
              v-model="formData.type_review"
              placeholder="请输其他类型"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- 编号 填写日期-->
    <div
      class="flex margin-top-bottom-5"
      style="justify-content: space-between"
    >
      <div class="flex">
        <div class="height-40 line-height-40 width-80">编号：</div>
        <div>
          <el-input
            :disabled="isDisabled"
            v-model="formData.num"
            placeholder="20240301001"
          ></el-input>
        </div>
      </div>
      <div class="flex">
        <div class="height-40 line-height-40 width-80">填写日期：</div>
        <div class="height-40 line-height-40">
          <el-date-picker
            v-model="formData.proposal_date"
            type="date"
            :disabled="isDisabled"
            placeholder="选择日期"
            format="yyyy年MM月dd日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <!-- 提案人 联系电话 -->
    <div class="flex">
      <!-- 提案人 -->
      <div
        class="flex-1 flex boder-right-1 boder-top-1 boder-left-1 boder-bottom-1 height-50 align-items-center"
      >
        <div class="width-80 height-50 line-height-50 boder-right-1">
          提案人
        </div>
        <div class="flex-1">
          <el-input
            :disabled="isDisabled"
            v-model="formData.proposer"
            placeholder="提案人"
          ></el-input>
        </div>
      </div>
      <!-- 联系电话 -->
      <div
        class="flex-1 flex boder-right-1 boder-top-1 boder-bottom-1 height-50 align-items-center"
      >
        <div class="width-80 height-50 line-height-50 boder-right-1">
          联系电话
        </div>
        <div class="flex-1">
          <el-input
            :disabled="isDisabled"
            id="mobileInput"
            v-model="formData.p_phone"
            placeholder="联系电话"
          ></el-input>
        </div>
      </div>
    </div>

    <!-- 提案内容 -->
    <div class="flex">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 683px; padding: 0px 30px"
      >
        提<br />案<br />内<br />容
      </div>
      <div class="flex-1 flex-col">
        <!-- 案名 -->
        <div class="flex align-items-center">
          <div
            class="height-80 line-height-80 boder-right-1 boder-bottom-1"
            style="padding: 0px 30px"
          >
            案名
          </div>
          <div
            class="flex-1 height-80 line-height-80 boder-bottom-1 boder-right-1"
          >
            <el-input
              v-model="formData.title"
              style="font-size: 18px"
              :disabled="isDisabled"
              placeholder="提案的题目，用简洁的文字说明提案要求解决什么问题，案名要和提案内容一致，比如“关于推进\解决\设立XXXX的建议”或“建议推进\解决\设立XXXX的提案”。一定要“一事一案”。"
            ></el-input>
          </div>
        </div>
        <!-- 案由 -->
        <div class="flex align-items-center">
          <div
            class="height-300 boder-bottom-1 boder-right-1 flex"
            style="align-items: center; padding: 0 30px"
          >
            案由
          </div>
          <div
            class="flex-1 height-300 boder-bottom-1 boder-right-1"
            style="position: relative"
          >
            <el-input
              id="input_reason"
              type="textarea"
              resize="none"
              :rows="9"
              :disabled="isDisabled"
              v-model="formData.reason"
              placeholder="提案的理由，是解决问题的重要依据，要开门见山，可进行现状分析，介绍背景或趋势、相关政策文件以及存在的问题等，在充分进行调研的基础上，以事实和数据为依据进行说明。"
            ></el-input>
            <Zoom :textid="'input_reason'"></Zoom>
          </div>
        </div>
        <!-- 具体意见建议 -->
        <div class="flex align-items-center">
          <div
            class="boder-bottom-1 boder-right-1 flex"
            style="align-items: center; height: 301px; padding: 0 23px 0 22px"
          >
            具体意<br />见建议
          </div>
          <div
            class="flex-1 boder-bottom-1 boder-right-1"
            style="height: 301px; position: relative"
          >
            <el-input
              id="input_suggest"
              type="textarea"
              :disabled="isDisabled"
              resize="none"
              :rows="9"
              v-model="formData.suggest"
              placeholder="该部分是提案的核心内容，也是提案能否被采纳的关键，必须针对案由反映的问题，提出解决问题的主张和办法，提出的建议要力求具体可行，实事求是，具有则针对性、可操作性。"
            ></el-input>
            <Zoom :textid="'input_suggest'"></Zoom>
          </div>
        </div>
      </div>
      <!-- 提案组审核意见 -->
    </div>

    <div
      class="flex-1 flex boder-left-1 boder-right-1 boder-bottom-1 height-50 align-items-center"
    >
      <div class="width-80 height-50 line-height-50 boder-right-1">备注</div>
      <div class="flex-1">
        <el-input v-model="formData.remark" placeholder="备注"></el-input>
      </div>
    </div>

    <div class="flex padding-top-10">
      <div class="flex-1"></div>
      <div class="flex">
        <el-button
          @click="saveFormData"
          style="margin-left: 5px; background: #25834b; color: #fff"
          >保存</el-button
        >
        <el-button @click="back">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { dbquery, getDataTotal } from "../sys/proposeRoleUser.js";
import { Message } from "element-ui";
import Zoom from "../tools/zoom.vue";

import {
  generateDateSequence,
  getMaxId,
  getCurrentDate,
  getCurrentDateTime,
  removeEmptyProperties,
  extractNonEmptyValues,
  addQuotesToObjectProperties,
  decryptData,
  objectToSqlSet,
  formatDate,
} from "../../../utils/common.js";
var that = null;

const propose_table = "xtbg_congress_propose";
let current_status = "";
export default {
  components: {
    Zoom,
  },
  data() {
    return {
      isDisabled: false, // 控制所有组件的不可编辑状态
      zb_deptOptions: [],
      xb_deptOptions: [],

      //职工代表
      is_zgdb: false,

      //提案组
      is_taz: false,
      //分管领导
      is_yld: false,
      //主办部门
      is_zbdept: false,

      formData: {
        type: "", // 类别
        type_review: "", // 其他类型
        num: "", // 编号
        proposal_date: "", // 提案日期
        title: "", // 案名
        reason: "", // 案由
        suggest: "", // 建议
        remark: "", // 备注
        proposer_userid: "", // 提案人用户ID
        proposer: "", // 提案人
        p_phone: "", // 电话
        supporters_userids: "", // 附议人用户IDS
        supporters: "", // 附议人
        s_time: "", // 附议时间
        t_userid: "", // 提案组操作人员ID
        t_result: "", // 提案组审核结果
        t_review: "", // 提案组说明选择原因
        suggest_dept: "", // 建议承办部门
        reply_time: "", // 承办部门答复时间
        t_date: "", // 提案组审核时间
        l_userid: "", // 分管领导用户ID
        l_opinion: "", // 分管领导意见
        l_sign: "", // 分管领导签名
        l_sign_date: "", // 签名意见日期
        d_response: "", // 承办部门处理意见
        d_head_userid: "", // 承办部门负责人用户ID
        d_head_sign: "", // 承办部门负责人签名
        d_head_date: "", // 签名日期
        p_feedback: "", // 提案人反馈等级
        p_f_detail: "", // 反馈详情
        p_f_sign: "", // 反馈签名
        p_f_sign_date: "", // 反馈签名日期
      },
      propseID: "",
    };
  },
  async created() {
    that = this;
  },
  methods: {
    // 提交数据
    async dbquery(sqlStr) {
      return dbquery(this.$http,  sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },

    async getMaxNum() {},

    // 保存到当前临时表
    async saveFormData() {
      //开始提交
      //如果为空 就踢出去
      this.formData.t_date = formatDate(this.formData.t_date);

      this.formData.s_time = formatDate(this.formData.s_time);
      this.formData.proposal_date = formatDate(this.formData.proposal_date);

      if (this.$route.query.status == "退回重提") {
        this.formData["status"] = "待审查";

        this.formData = extractNonEmptyValues(this.formData);
        this.formData["t_result"] = "";
      } else {
        this.formData = extractNonEmptyValues(this.formData);
      }

      // console.log(this.formData);
      // return;
      delete this.formData.id;

      //表单对象（或任何对象）并想将其转换成一个SQL UPDATE 语句的一部分，即属性名和属性值的配对
      let myNewFormData = objectToSqlSet(this.formData);

      // 保存 修改

      const updateProposeSql =
        "update " +
        propose_table +
        " set " +
        myNewFormData +
        " where id=" +
        this.propseID;

      //console.log("修改：", updateProposeSql);
      const res = await this.dbquery(updateProposeSql);
      //console.log(res);
      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "修改成功",
          type: "success",
          duration: 1000,
          offset: 200,
        });
      } else {
        this.$message({
          showClose: true,
          message: "修改失败",
          type: "fail",
          duration: 1000,
          offset: 200,
        });
      }
      this.back();
    },
    back() {
      this.$router.push({
        path: "/proposal_meetting/taxt_my",
      });
    },
    async getDataById(id) {
      const selectSql = "select * from " + propose_table + " where id=" + id;
      const res = await this.dbquery(selectSql);
      this.formData = res[0];
      // this.formData = removeEmptyProperties(this.formData);
    },
  },
  async mounted() {
    this.propseID = decryptData(this.$route.query.id);
    // current_status == this.$route.query.status;
    // console.log(this.$route.query.status);

    await this.getDataById(this.propseID);
  },
  beforeDestroy() {},
};
</script>
