<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-19 11:15:50
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-01 15:22:46
 * @FilePath: \app_wb\src\views\proposal\meetting_dahjy.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny__wb-dahuijy">
      <ul>
        <li
          v-for="(item, index) in data_dhjy"
          :key="index"
          @click="showFile(item)"
        >
          <div>{{ index + 1 }}</div>
          <div>{{ item.title }}</div>
          <div>{{ item.create_time }}</div>
        </li>
      </ul>
    </div>
    <!-- 预览窗口 -->
    <el-dialog
      style="text-align: left"
      :title="dhjy_title"
      :visible.sync="dialogVisible"
      width="90%"
    >
      <iframe :src="file_url" width="100%" height="668px"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { getDataFromDb, getYearMonthDay } from "../../utils/common.js";
export default {
  data() {
    return {
      dhjy_title: "",
      file_url: "",
      dialogVisible: false,
      data_dhjy: [],
    };
  },
  async created() {
    //读取大会情况列表
    // let sqlStr = "select * from xtbg_congress_list order by create_time desc";
    // let sqlStr_deptNae = "SELECT dept_name FROM sys_dept WHERE dept_id = ";
    // const result = await getDataFromDb(this.$db_dynamic_url, sqlStr);

    // console.log(result);

    // result.map(async (row) => {
    //   let dept_name = (
    //     await getDataFromDb(this.$db_dynamic_url, sqlStr_deptNae + row.dept_id)
    //   )[0].dept_name;

    //   let newRow = {
    //     ...row,
    //     dept_name: dept_name,
    //     create_time: getYearMonthDay(row.create_time),
    //   };
    //   this.data_dhjy.push(newRow);
    // });
    // const res_data = this.data_dhjy.sort((a, b) => {
    //   return b.create_time.localeCompare(a.create_time);
    // });



    this.data_dhjy = await this.$db_select(
      this.$http,
      "xtbg_congress_list AS x JOIN sys_dept AS s ON x.dept_id = s.dept_id",
      "x.*, s.dept_name","1=1 ORDER BY x.create_time DESC"
    );

    this.data_dhjy.map((item) => {
      item.create_time = getYearMonthDay(item.create_time);
    });

  

    
  },
  methods: {
    showFile(item) {
      if (item.file) {
        this.dhjy_title = item.title;
        this.file_url = item.file;
        this.dialogVisible = true;
      } else {
        this.$router.push({
          path: "/proposal_meetting/chmd_detail",
          query: {
            title: item.title,
            id: item.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bny__wb-dahuijy {
  height: 768px;
  padding: 20px 0px;
  overflow-y: auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      text-align: left;
      padding-left: 10px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #e3e3e3;
      &:nth-child(even) {
        background: #f3f4f9;
      }
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid #008500;
        color: #008500;
        div {
          &:first-child {
            color: #fff;
            background: rgb(81, 159, 109);
          }
        }
      }
      &:first-child {
        border-top: 1px dotted #ddd;
      }
      div {
        display: inline-block;
        &:last-child {
          float: right;
        }

        &:first-child {
          font-style: italic;
          width: 20px;
          height: 20px;
          line-height: 20px;
          background: #d7d7d7;
          text-align: center;
          margin-right: 10px;
          border-radius: 10px;
          margin-top: 10px;
          color: #101010;
          font-size: 12px;
          &:hover {
            cursor: pointer;
            color: #fff;
            background: rgb(81, 159, 109);
            font-size: 12px;
          }
        }
      }
    }
  }
}
::v-deep .el-dialog .el-dialog__body {
  padding: 0 5px;
}
</style>
