<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-19 11:15:02
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-01 12:18:59
 * @FilePath: \app_wb\src\views\proposal\meetting_dhqk.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="padding: 20px; height: auto">
    <!-- 文章标题 -->
    <div class="bny_news-mcontent-title">{{ data_dhqk_detail.title }}</div>
    <!-- 发布时间，浏览量，来源 -->
    <div class="bny_news-mcontent-subtitle">
      发布时间：{{ data_dhqk_detail.create_time }}&nbsp;&nbsp;&nbsp;&nbsp; 浏览量：{{
        data_dhqk_detail.view_num
      }}&nbsp;&nbsp;&nbsp;&nbsp;
      来源：{{ data_dhqk_detail.dept_name }}
    </div>
    <!-- 文章内容 -->
    <div
      id="bny_news-mcontent-content"
      class="bny_news-mcontent-content"
      v-html="data_dhqk_detail.content"
    ></div>
  </div>
</template>

<script>
import { getDataFromDb, getYearMonthDay } from "../../utils/common.js";
export default {
  data() {
    return {
      title_img: require("../../assets/imgs/zdh/title.jpg"),
      data_dhqk_detail: {
        title: "版纳植物园召开六届二次职工代表大会",
        subtitle: "发布时间：2023-04-05  浏览量：100  来源：信息中心",
        date: "2023-04-03",
        title_img: require("../../assets/imgs/zdh/title.jpg"),
        content: "",
      },
    };
  },
  methods: {
    async setViewNum(id) {
      //先读取原来的
      let sqlStr = "SELECT * FROM xtbg_congress_workers WHERE id=" + id;
      
      let view_num = (await this.$db_select(this.$http, "xtbg_congress_workers","*","id=" + id))[0].view_num;
      if (view_num == null) {
        view_num = 0;
      }

      //去修改原来的 view_num +1
      view_num = view_num + 1;
      let updateStr =
        "update xtbg_congress_workers set view_num=" +
        view_num +
        " where id=" +
        id;

      await this.$db_update(this.$http,"xtbg_congress_workers",{view_num:view_num},"id="+id)
     
     
    },

    async showContent(id) {
      //读取数据
      let sqlStr = "SELECT * FROM xtbg_congress_workers WHERE id=" + id;
     

      let result = (await this.$db_select(this.$http,"xtbg_congress_workers","*","id=" + id))[0];

      let sqlStr_deptNae = "SELECT dept_name FROM sys_dept WHERE dept_id = ";

      let dept_name = (

        await this.$db_select(this.$http,"sys_dept","dept_name","dept_id = " + result.dept_id)

        
      )[0].dept_name;

      this.data_dhqk_detail = {
        ...result,
        dept_name: dept_name,
        create_time: getYearMonthDay(result.create_time),
      };

      let bny_newsmcontentcontent = document.getElementById(
        "bny_news-mcontent-content"
      );
      this.$nextTick(() => {
        if (bny_newsmcontentcontent) {
          bny_newsmcontentcontent.querySelectorAll("p").forEach((p) => {
            let a = p.querySelector("a");
            let img = p.querySelector("img");
           
            if (img) {
            img.style.height="300px";
              img.src= "https://in.xtbg.ac.cn:8442/prod-api/"+img.src.split("prod-api")[1];
              p.style.textAlign = "center";
              
              p.nextElementSibling.style.textAlign = "center";
            }
            // else if (p.querySelector("img")) {
            //   p.style.textAlign="center";
            //   p.nextElementSibling.style.textAlign="center";
            // }else{
            //   p.style.textAlign="left";
            // }
          });
        }
      });
    },
  },

  mounted() {
    this.data_dhqk_detail.title = this.$route.query.title;
    this.showContent(this.$route.query.id);
    this.setViewNum(this.$route.query.id);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.center-text {
  text-align: center !important;
}
.bny_news-mcontent-title {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  font-size: 22px;
  text-align: center;

}
.bny_news-mcontent-subtitle {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #7a7a7a;
  font-size: 12px;
  text-align: center;
}
.bny_news-mcontent-content {
  padding: 20px;
  text-indent: 2em;
  text-align: left;
  font-size: 16px;
  line-height: 30px;
}
</style>
