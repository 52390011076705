/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-29 12:58:03
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-08-23 19:35:35
 * @FilePath: \app_wb\src\api\content\xtbg_notice.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js'
export function ListNotice(queryParam){
    return request('/content/notice/list2',{
        method: "get",
        
        params:queryParam
    });
}
export function getNoticeById(id){
    return request('/content/notice/'+id,{
        method: "get",
       
      
    });
}