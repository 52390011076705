<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <el-row>
        <div class="index_list-ss">
          <div class="index_list-ss-title">站内搜索</div>

          <div class="index_list-ss-search" style="width: 100%">
            <el-input
              placeholder="请输入内容"
              id="seach_input"
              v-model="searchName"
              style="width: 100%"
              @keyup.enter.native="searchWb"
            >
              <template slot="append">
                <el-button type="button" @click="searchWb">搜索</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </el-row>
      <el-row style="min-height: 600px">
        <el-row
          style="
            background: #eee;
            height: 40px;
            line-height: 40px;
            margin-bottom: 20px;
          "
        >
          <!-- <el-col :span="16">
            <el-row>
              <el-col :span="12" style="text-align: right">排序方式: </el-col>
              <el-col class="px_fbsj" :span="2" style="text-align: center"
                >发布时间</el-col
              >
              <el-col class="px_xgrd" :span="10" style="text-align: left"
                >相关热度</el-col
              >
            </el-row> 
          </el-col> -->
          <el-col :span="24">
            共搜索到
            <span style="color: red"
              >{{ books.length > 0 ? books.length : 0 }} </span
            >个相关人员, 共搜索到
            <span style="color: red">{{ count }} </span>篇相关记录
          </el-col>
        </el-row>
        <el-alert
          style="margin-bottom: 10px"
          v-show="alertError"
          title="请输入两个关键字以上进行搜索"
          type="error"
        >
        </el-alert>
        <!-- 通讯录搜索结果 -->
        <el-row
          class="div-result-search"
          v-for="row in books"
          :key="row.id"
          style="
            border: 1px solid #e3e3e3;
            border-bottom: 4px solid #eaeaea;
            padding: 15px 35px;
          "
        >
          <!-- 标题 -->
          <el-row style="text-align: left" class="div-result-search-title">
            <el-col :span="18">
              {{ row.uid == undefined ? "" : row.uid }}.
              <span style="font-size: 16px; font-weight: 600">{{
                row.name
              }}</span>
            </el-col>
            <el-col :span="6" style="font-size: 14px; text-align: right">
              [通讯录]
            </el-col>
          </el-row>
          <!-- 内容 -->
          <el-row style="text-align: left">
            <div
              style="
                text-indent: 0em;
                text-indent: 2em;
                font-size: 15px;
                color: #999;

                line-height: 25px;
                overflow: hidden;
              "
            >
              部门：{{ row.dept_name }}，邮箱：{{ row.email }}，电话：{{
                row.phone
              }}
            </div>
          </el-row>
        </el-row>
        <!-- 文章搜索结果 -->
        <el-row
          class="div-result-search"
          v-for="row in tableDataNew"
          :key="row.id"
          style="
            border: 1px solid #e3e3e3;
            border-bottom: 4px solid #eaeaea;
            padding: 15px 35px;
          "
          @click.native="showDetail(row)"
        >
          <!-- 标题 -->
          <el-row style="text-align: left" class="div-result-search-title">
            <el-col :span="18">
              <div
                style="font-size: 16px; font-weight: 600"
                @click="showDetail(row)"
                v-html="row.uid + '.' + row.title"
              ></div>
            </el-col>
            <el-col :span="6" style="font-size: 14px; text-align: right">
              [{{ row.table_name }}]
            </el-col>
          </el-row>
          <!-- 内容 -->
          <el-row style="text-align: left">
            <div
              style="
                text-indent: 0em;
                text-indent: 2em;
                font-size: 15px;
                color: #999;
                line-height: 25px;
                overflow: hidden;
              "
              v-html="row.content"
            ></div>
          </el-row>
          <!-- 发布时间 -->
          <el-row
            style="
              text-align: left;
              height: 40px;
              line-height: 40px;
              font-size: 15px;
            "
          >
            发布时间：{{ row.create_time == undefined ? "" : row.create_time }}
          </el-row>
        </el-row>

        <el-pagination
          :hide-on-single-page="true"
          background
          layout="prev, pager, next"
          :total="count"
          :page-size="10"
          @current-change="currentChange"
        >
        </el-pagination>
      </el-row>
    </div>
    <mFooter></mFooter>
  </div>
</template>

<script>
import mFooter from "@/components/mFooter.vue";
import SearchBox from "@/components/commom/index_list_item_ss2.vue";
import { Loading } from "element-ui";
import pinyin from "js-pinyin";
import { ListAddrbookInfo } from "@/api/content/xtbg_addr_book.js";
import { getDeptNameById } from "@/api/system/sys_dept.js";
import { tables, tables2 } from "@/api/content/db/search.js";
import { getYearMonthDay, goToTop } from "@/utils/common";
import CryptoJS from "crypto-js";

var _this = null;
export default {
  components: {
    SearchBox,
    mFooter,
  },
  data() {
    return {
      alertError: false,
      activeName: "first",
      count: 0,
      searchName: "",
      tableData: [],
      tableDataNew: [],
      Loading: false,
      books: [],
      queryPramas: {
        pageSize: 2000,
        pageNum: 1,
      },
    };
  },
  created() {
    _this = this;
    // this.listBooks();
  },
  watch: {
    searchName(newV) {
      this.searchName = newV;
      if (newV.length >= 2) {
        this.searchWb();
      } else {
        this.tableData = [];
        this.tableDataNew = [];
        this.books = [];
        this.count = 0;
      }
    },
  },

  methods: {
    encrypt(text) {
      const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
      const IV_LENGTH = 16; // 初始化向量的长度

      // 生成随机的初始化向量（IV）
      const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

      // 使用 AES 加密
      const encrypted = CryptoJS.AES.encrypt(
        text,
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // 返回加密后的字符串和 IV
      return (
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    showDetail(item) {
      let dept_id = item.dept_id == undefined ? "" : item.dept_id;

      if (dept_id != "") {
        //查询部门名称
        getDeptNameById(parseInt(dept_id)).then((res) => {
          this.$store.commit("updateSelectedTitle", item.table_name);

          localStorage.setItem("title", encodeURI(item.table_name));
          localStorage.setItem("showTitle", encodeURI(item.title));
          localStorage.setItem("deptName", item.deptName);
          if (item.deptName == undefined) {
            localStorage.setItem("deptName", item.dept);
          }

          let routeData = this.$router.resolve({
            path: "/newsdetail",
            query: {
              id: item.id,
            },
          });
          window.open(routeData.href, "_blank");
        });
      } else {
        if (item.dept != undefined) {
          this.$store.commit("updateSelectedTitle", item.table_name);

          localStorage.setItem("title", encodeURI(item.table_name));
          localStorage.setItem("showTitle", encodeURI(item.title));
          localStorage.setItem("deptName", item.deptName);
          if (item.deptName == undefined) {
            localStorage.setItem("deptName", item.dept);
          }

          let routeData = this.$router.resolve({
            path: "/newsdetail",
            query: {
              id: item.id,
            },
          });
          window.open(routeData.href, "_blank");
        }
      }
    },
    currentChange(cpage) {
      this.tableDataNew = [];
      let start_i = 10 * (cpage - 1);
      let end_i = 10 * cpage;
      for (let i = start_i; i < end_i; i++) {
        if (this.tableData[i] != null) {
          this.tableDataNew.push(this.tableData[i]);
        }
      }
    },
    searchWb() {
      let loadingInstance = Loading.service({
        text: "正在努力查询中...",
      });
      _this.count = 0;
      let uid = 1;
      let tuid = 1;
      _this.tableData = [];
      _this.tableDataNew = [];
      _this.books = [];
      if (_this.searchName.length < 2) {
        this.alertError = true;
        loadingInstance.close();
        this.count = 0;
        uid = 1;
        tuid = 1;

        return false;
      }

      this.alertError = false;
      for (let i = 0; i < tables2.length; i++) {
        let table_name = tables2[i].tbname;
        let tname = tables2[i].name;
        //开始查询
        (function (tableName, tname) {
          let queryStr = {};
          if (tableName == "xtbg_user") {
            queryStr = {
              query: _this.encrypt(
                "select * from xtbg_user where name like '%" +
                  _this.searchName +
                  "%'"
              ),
              params: [],
            };
          } else {
            queryStr = {
              query: _this.encrypt(
                "select * from " +
                  tableName +
                  " where title like '%" +
                  _this.searchName +
                  "%' or content like '%" +
                  _this.searchName +
                  "%' order by create_time desc"
              ),
              params: [],
            };
          }

          _this.$http
            .post(_this.$db_dynamic_url, queryStr, {
              headers: {
                Authorization:
                  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
              },
            })
            .then((res) => {
              if (tableName != "xtbg_user") {
                _this.count = _this.count + res.data.length;
              }

              res.data.map((row) => {
                if (tableName == "xtbg_user") {
                  row.uid = tuid;
                  _this.books.push(row);
                  tuid++;
                } else {
                  row.content = row.content.substring(0, 200);
                  //标红 内容或标题
                  row.title = row.title.replaceAll(
                    _this.searchName,
                    "<span style='color:#f00'>" + _this.searchName + "</span>"
                  );
                  row.content = row.content.replaceAll(
                    _this.searchName,
                    "<span style='color:#f00'>" + _this.searchName + "</span>"
                  );

                  row.uid = uid;
                  row.table_name = tname;
                  row.create_time = getYearMonthDay(row.create_time);
                  _this.tableData.push(row);
                  uid++;
                  if (uid == _this.count) {
                    _this.initeData();
                  }
                }
              });

              loadingInstance.close();
            });
        })(table_name, tname);
      }
    },
    listBooks() {
      let loadingInstance = Loading.service({
        text: "正在初始化...",
      });
      let _this = this;
      ListAddrbookInfo(this.queryPramas).then((res) => {
        _this.tableData = [];
        res.data.rows.map((row) => {
          (function (deptId) {
            _this.$getDeptNameByDeptId(_this.$http, deptId).then((rs) => {
              row.deptName = rs[0].dept_name;
              _this.tableData.push(row);
            });
          })(row.deptId);
        });
        // _this.tableDataNew = _this.tableData;

        //console.log(this.tableData);

        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          setTimeout(() => {
            loadingInstance.close();
          }, 500);
        });
      });
    },
    searchByName() {},
    initeData() {
      // this.tableDataNew = this.tableData.slice(0,10);
      this.tableDataNew = [];
      for (let i = 0; i < 10; i++) {
        this.tableDataNew.push(this.tableData[i]);
      }
    },
  },

  mounted() {
    console.log(this.$route.query);
    this.searchName = this.$route.query.searchTxt;
    document.getElementById("seach_input").focus();
    goToTop();
  },
};
</script>

<style lang="scss" scoped>
.index_list-ss {
  width: 1400px;
  height: 180px;
  background: #fff;
  margin: 0 auto;
  margin-top: 5px;

  .index_list-ss-title {
    color: #568c6a;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    height: 20px;
    padding-top: 40px;
  }
  .index_list-ss-items {
    padding: 40px;
    text-align: center;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 60px;
        height: 40px;
        padding: 10px 0px;

        float: left;
        font-size: 14px;
        color: #568c6a;
        font-weight: bold;
        cursor: pointer;
        &:active {
          background: #f2f2f2;
        }
      }
    }
  }
  .index_list-ss-search {
    padding-top: 20px;
  }
}
::v-deep .el-tabs__nav-scroll {
  width: 20%;
  margin: 0 auto;
}
.px_fbsj {
  &:hover {
    color: #519f6d;
  }
}
.px_xgrd {
  &:hover {
    color: #519f6d;
  }
}
.div-result-search {
  margin-bottom: 20px;

  cursor: pointer;
  &:first-child {
    color: #519f6d !important;
  }
}
.div-result-search:hover {
  border-bottom: 8px solid #eee !important;
  border-right: 4px solid #eee !important;
}

.div-result-search-title {
  margin-bottom: 5px !important;
}
.div-result-search-title:hover {
  color: #519f6d !important;
}
.el-pagination {
  width: 42%;
  margin-top: 30px;
  margin-left: 403px;
  ::v-deep .btn-prev,
  ::v-deep .btn-next {
    border: 1px solid #f2f2f2;
    background-color: transparent;
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  ::v-deep .el-icon-arrow-left:before,
  ::v-deep .el-icon-arrow-right:before {
    color: #d5bfa1;
    font-size: 14px;
  }
}

::v-deep .el-pagination.is-background .el-pager li {
  border: 1px solid #f2f2f2;
  background-color: #fff;
  color: #000;
  width: 40px;
  height: 40px;
  line-height: 37px;
  font-size: 14px;
  margin-right: 15px;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #519f6d;
  color: #fff;
}
</style>