<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-19 11:15:02
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-02-01 09:30:27
 * @FilePath: \app_wb\src\views\proposal\meetting_dhqk.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="height: 600px; overflow-y: auto; padding-top: 20px">
    <!-- <vue-timeline-update
      v-for="(item, index) in data_dhqk"
      :key="index"
      :date="new Date(item.date)"
      :title="item.title"
      description=""
      :thumbnail="item.title_img"
      :category="item.date"
      icon="code"
      color="blue"
      @click.native="showContent(item.title)"
    /> -->
    <ul class="dhqk_list">
      <li
        v-for="(row, index) in listData"
        :key="'dhqk_' + index"
        @click="showContent(row)"
      >
        <!-- 序号 -->
        <div>{{ index + 1 }}</div>
        <!-- 标题 -->
        <div>{{ row.title }}</div>
        <!-- 发布时间 -->
        <div>{{ row.create_time }}</div>
        <!-- 发文部门 -->
        <!-- <div>{{ row.dept_name }}</div> -->
      </li>
    </ul>
  </div>
</template>

<script>
import { getDataFromDb, getYearMonthDay } from "../../utils/common.js";
export default {
  data() {
    return {
      listData: [],
      title_img: require("../../assets/imgs/zdh/title.jpg"),
      data_dhqk: [
        {
          title: "版纳植物园召开六届三次职工代表大会",
          date: "2023-04-20",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
        {
          title: "版纳植物园召开六届二次职工代表大会",
          date: "2023-04-15",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
        {
          title: "版纳植物园召开六届一次职工代表大会",
          date: "2023-04-02",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
        {
          title: "版纳植物园六届一次职工代表大会致青年的倡议书",
          date: "2023-03-31",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
        {
          title: "版纳植物园召开五届四次职工代表大会 ",
          date: "2018-04-16",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
        {
          title: "版纳植物园召开五届三次职工代表大会 ",
          date: "2018-04-07",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
        {
          title: "版纳植物园召开五届二次职工代表大会 ",
          date: "2016-04-19",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
        {
          title: "版纳植物园召开五届一次职工代表大会 ",
          date: "2015-12-21",
          title_img: require("../../assets/imgs/zdh/title.jpg"),
        },
      ],
    };
  },
  async created() {
    //读取大会情况列表
    let sqlStr =
      "select * from xtbg_congress_workers order by create_time desc";
    let sqlStr_deptNae = "SELECT dept_name FROM sys_dept WHERE dept_id = ";
    //const result = await getDataFromDb(this.$db_dynamic_url, sqlStr);

    const result = await this.$db_select(
      this.$http,
      "xtbg_congress_workers",
      "*",
      "1=1 order by create_time desc"
    );

    result.map(async (row) => {
      let dept_name = (
        await await this.$db_select(
          this.$http,
          "sys_dept",
          "dept_name",
          "dept_id = " + row.dept_id
        )
      )[0].dept_name;

      let newRow = {
        ...row,
        dept_name: dept_name,
        create_time: getYearMonthDay(row.create_time),
      };
      this.listData.push(newRow);
    });

    this.listData = await this.$db_select(
      this.$http,
      "xtbg_congress_workers AS cw LEFT JOIN sys_dept AS sd ON cw.dept_id = sd.dept_id",
      "cw.*, sd.dept_name","1=1 ORDER BY cw.create_time DESC"
    );
    this.listData.map((item) => {
      item.create_time = getYearMonthDay(item.create_time);
    });
  },
  methods: {
    showContent(row) {
      this.$router.push({
        path: "/proposal_meetting/dhqk_detail",
        query: {
          id: row.id,
          title: row.title,
        },
      });
    },
  },
  mounted() {
    var weeksago = document.getElementsByClassName(
      "gb-vue-timeline-update__ago"
    );

    for (let i = 0; i < weeksago.length; i++) {
      let wa = weeksago[i];

      let was = wa.innerHTML.split(" ");
      var lstring = "";
      if (was[1].indexOf("year") > -1) {
        lstring = "年 以前";
      }
      if (was[1].indexOf("month") > -1) {
        lstring = "月 以前";
      }
      if (was[1].indexOf("week") > -1) {
        lstring = "周 以前";
      }
      if (was[1].indexOf("day") > -1) {
        lstring = "天 以前";
      }
      if (was[1].indexOf("hour") > -1) {
        lstring = "小时 以前";
      }
      if (was[1].indexOf("minute") > -1) {
        lstring = "分 以前";
      }
      if (was[1].indexOf("second") > -1) {
        lstring = "秒 以前";
      }

      wa.innerHTML = was[0] + " " + lstring;
    }
    //7 years ago
  },
};
</script>

<style lang="scss" scoped>
.dhqk_list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-align: left;
  li {
    font-size: 15px;

    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    padding-left: 10px;
    &:nth-child(even) {
      background: #f3f4f9;
    }
    &:hover {
      cursor: pointer;
      border-bottom: 1px solid #008500;
      color: #008500;
      div {
        &:first-child {
          color: #fff;
          background: #008500;
        }
      }
    }
    div {
      &:first-child {
        font-style: italic;
        width: 20px;
        height: 20px;
        line-height: 20px;
        background: #d7d7d7;
        text-align: center;
        margin-right: 10px;
        border-radius: 10px;
        margin-top: 15px;
        color: #101010;
        font-size: 12px;
        &:hover {
          cursor: pointer;
          color: #fff;
          background: #008500;
          font-size: 12px;
        }
      }
      &:nth-child(2) {
        flex: 1;
        &:hover {
          font-size: 16px;
        }
      }
      /* &:nth-child(3) {
        width: 200px;
        font-size: 14px;
      } */
      &:last-child {
        width: 100px;
      }
    }
  }
}

::v-deep
  .gb-vue-timeline-update
  .gb-vue-timeline-update__right
  .gb-vue-timeline-update__information
  .gb-vue-timeline-update__title {
  color: #000;
}
::v-deep
  .gb-vue-timeline-update
  .gb-vue-timeline-update__right
  .gb-vue-timeline-update__thumbnail {
  width: 400px;
  height: 300px;
  object-fit: cover;
}
::v-deep
  .gb-vue-timeline-update
  .gb-vue-timeline-update__left
  .gb-vue-timeline-update__ago {
  color: #000;
}
::v-deep .gb-base-number--dark.gb-base-number--blue {
  color: #bedeb5;
}
::v-deep .gb-vue-timeline-update .gb-base-badge--dark .gb-base-badge--blue {
}
.gb-base-badge__focuser {
  background: #519f6d;
}
::v-deep .gb-base-badge--dark.gb-base-badge--blue .gb-base-badge__focuser {
  background: #519f6d !important;
}
/* #303133 */
</style>
