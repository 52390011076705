<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-06-02 10:49:11
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-07 16:24:42
 * @FilePath: \app_wb\src\components\commom\index_list_item_ss2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="index_list-ss">
    <div class="index_list-ss-title">站内搜索</div>
    
    <div class="index_list-ss-search">
      <el-input placeholder="请输入内容" v-model="input2">
        <template slot="append">
          <el-button type="button" @click="searchWb">搜索</el-button>
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input2:'',
      
    };
  },
  methods:{
    searchWb(){
      this.$message({
          message: '正在努力开发中，请稍后...',
          type: 'warning',
          offset:200,
        });
    },
    clickKzssItem(item,ev){
        let znss_list = document.getElementById("znss_list");
        let lis = znss_list.querySelectorAll('li');
        for(let i = 0; i<lis.length;i++){
            lis[i].style.background = "#fff";
            lis[i].style.border = "none";
        }
        let li = ev.currentTarget;
        li.style.background = "#f2f2f2";
        li.style.border= "1px solid #ddd";
        //开始搜索
        item = item.replace("<br/>","");
        console.log(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.index_list-ss {
  width: 1400px;
  height: 180px;
  background:#fff;
  margin: 0 auto;
  margin-top: 5px;

  .index_list-ss-title {
    color: rgb(81 159 109);
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    height: 20px;
    padding-top: 40px;
  }
  .index_list-ss-items {
    padding: 40px;
    text-align: center;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 60px;
        height: 40px;
        padding: 10px 0px;
        
        
        float: left;
        font-size: 14px;
        color: #rgb(81 159 109);
        font-weight: bold;
        cursor: pointer;
        &:active{
            background: #f2f2f2;
        }
      }
    }
  }
  .index_list-ss-search{
    padding-top: 20px;
  }
}
</style>