<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-08-16 19:13:55
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-10-25 20:25:39
 * @FilePath: \app_wb\src\views\xyyp\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="height:100vh;">
    <iframe :src="xy_url" width="100%" height="100%" frameBorder="0px"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xy_url: "https://in.xtbg.ac.cn:8877",
    };
  },
};
</script>

<style>
</style>