import request from '@/utils/request.js'

export function ListInNews(queryParam) {
    return request('/content/xtbginnews/list', {
        method: "get",
        params: queryParam,
        
    });
}
export function getInNewById(id) {
    return request('/content/xtbginnews/' + id, {
        method: "get",
        

    });
}