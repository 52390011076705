import { render, staticRenderFns } from "./roleManager.vue?vue&type=template&id=09456984&scoped=true"
import script from "./roleManager.vue?vue&type=script&lang=js"
export * from "./roleManager.vue?vue&type=script&lang=js"
import style0 from "./roleManager.vue?vue&type=style&index=0&id=09456984&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09456984",
  null
  
)

export default component.exports