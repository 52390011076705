<template>
  <div class="bny_index-list">
    <div class="bny_index-list-title">
      <img :src="titlelogo" alt="" />
      <div style="height: 33px; border-bottom: 3px solid rgb(81 159 109)">
        {{ title }}
      </div>
      <div @click="moreTrip">更多>></div>
    </div>
    <div class="bny_index-list-content">
      <!-- <el-row v-for="row in tableData" :key="'index-'+row.id" style="height: 60px;padding-top: 18px; border-bottom: 1px dashed #ddd; color:#444;">
        <el-col :span="3">{{ row.leader }}</el-col>
        <el-col :span="6">{{ row.date }}</el-col>
        <el-col :span="15" style="text-align: left;"><div v-html="row.ncontent"></div></el-col>
      </el-row> -->
      <el-table
        :data="tableData"
        stripe
        :show-header="false"
        style="width: 395px; background: transparent; font-size: 14px"
      >
        <el-table-column prop="leader" label="领导" width="70px">
        </el-table-column>
        <el-table-column label="date" width="100px">
          <template slot-scope="scope">
            <div>
              {{ scope.row.date == "08:30-18:00" ? "全天" : scope.row.date }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ncontent" label="nconten" width="">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { ListLeaderTrip } from "@/api/content/xtbg_leader_trip";
import CryptoJS from "crypto-js";

import {
  dateCompare2,
  removeHtml,
  geHHMM,
  getYearMonthDay,
  sortBy,
  getNowDateYearMonth,
  getNowDateYearMonthHourMS,
  uniqueJsonArrByField,
} from "@/utils/common.js";
import { ListLeaders } from "@/api/system/sys_dict_data.js";
var _this = null;
export default {
  name: "index-line-item",
  props: {
    title: {
      type: String,
      default: "",
    },
    titlelogo: {
      type: String,
      default: "",
    },
    TableColumns: {
      type: Array,
      default: () => [
        {
          id: 1,
          label: "领导",
          pname: "leader",
          width: "70px",
        },
        {
          id: 2,
          label: "日程内容",
          pname: "content",
          width: "",
        },
        {
          id: 2,
          label: "位置",
          pname: "location",
          width: "",
        },
        {
          id: 3,
          label: "时间",
          pname: "date",
          width: "100px",
        },
      ],
    },
  },
  data() {
    return {
      queryPramas: {
        pageSize: 100,
        pageNum: 1,
      },
      tableData: [],
      leaders: [],
    };
  },
  created() {
    _this = this;
    //this.getListLeaderTrip();
    this.listLeaders();
  },
  methods: {
    listLeaders() {
      ListLeaders().then((res) => {
        this.leaders = [];

        let rows = res.data.rows.sort((s, n) => {
          return s.dictSort - n.dictSort;
        });

        rows.map((row) => {
          this.leaders.push(row.dictValue);
        });
        this.getLeaderTripList();
      });
    },
    encrypt(text) {
      const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
      const IV_LENGTH = 16; // 初始化向量的长度

      // 生成随机的初始化向量（IV）
      const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

      // 使用 AES 加密
      const encrypted = CryptoJS.AES.encrypt(
        text,
        CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // 返回加密后的字符串和 IV
      return (
        iv.toString(CryptoJS.enc.Hex) +
        ":" +
        encrypted.ciphertext.toString(CryptoJS.enc.Hex)
      );
    },
    getLeaderTripList() {
      let nowDate = getYearMonthDay(new Date());
      let queryStr =
        "SELECT * FROM xtbg_leader_trip WHERE mdate='" +
        nowDate +
        "' OR ('" +
        nowDate +
        "'>=mdate AND '" +
        nowDate +
        "'<=edate)";
      let queryParam = {
        query: this.encrypt(queryStr),
        params: [],
      };
      this.$http
        .post(this.$db_dynamic_url, queryParam, {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
          },
        })
        .then((res) => {
          this.tableData = [];
          res.data.map((row) => {
            row.date = geHHMM(row.start_time) + "-" + geHHMM(row.end_time);
            row.startTime = getNowDateYearMonthHourMS(row.start_time);
            row.endTime = getNowDateYearMonthHourMS(row.end_time);

            row.ncontent = row.location + "，" + row.content;
            _this.tableData.push(row);
          });

          _this.tableData = _this.tableData.sort((star, next) => {
            return (
              _this.leaders.indexOf(star.leader) -
              _this.leaders.indexOf(next.leader)
            );
          });
        });
    },

    moreTrip() {
      this.$router.push({
        path: "/ldrc_look",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bny_index-list {
  height: auto;
  width: 385px;
  padding-left: 10px;
  //background: url('../../assets/imgs/m001.png') no-repeat;
  background-size: 100% 100%;
  margin: 5px 0px 5px 0px;
  .bny_index-list-title {
    font-weight: bold;
    height: 30px;
    line-height: 30px;

    width: 385px;
    // background-color: rgb(81 159 109);

    img {
      width: 25px;
      height: 25px;
      margin: 4px 12px;
      float: left;
    }
    div {
      font-size: 16px;
      text-align: left;
      padding-left: 5px;
      color: #444;
      float: left;
      &:first-child {
        border-bottom: 2px solid rgb(81 159 109);
      }
      &:last-child {
        float: right;
        margin-right: 15px;
        cursor: pointer;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .bny_index-list-content {
    width: 385px;
    height: auto;
    font-size: 14px;
    color: rgb(81 159 109);
    overflow: hidden;
    overflow: hidden;
    border-top: 1px solid rgb(81 159 109);
    background: #fafafa;
  }
}
</style>
