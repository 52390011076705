
<template>
  <div>
    <div class="bny_advise">
      <div class="bny_advise-top">
        <div>咨询平台</div>
        <div class="bny_advise-top-btn">
          <el-button
            type="primary"
            round
            style="background-color: #519f6d; letter-spacing: 2px"
            @click="start_advise"
            >咨询</el-button
          >
        </div>
      </div>
      <div class="bny_advise-search">
        <el-input
          placeholder="请输入咨询标题"
          v-model="search_title"
          class="input-with-select"
          id="input_searchtitle"
        >
          <template slot="prepend">咨询标题</template>
          <el-button
            type="primary"
            slot="append"
            style="background-color: #519f6d"
            icon="el-icon-search"
            @click="btn_searchdata"
          ></el-button>
        </el-input>
      </div>
      <div
        class="bny_advise-list"
        style="font-size: 14px; border: 1px solid #ddd"
      >
        <div v-for="row in list_advise" :key="row.id" style="padding: 10px 0px">
          <el-row
            style="
              border-bottom: 1px dotted #ddd;
              background: #ebeef5;
              padding: 8px 0px;
              height: 120px;
              overflow-y: auto;
            "
          >
            <el-col :span="4" style="text-align: center; padding-top: 18px">
              <el-image :src="woman" fit="cover"></el-image>
              <el-row
                style="font-size: 12px; height: 20px; line-height: 20px"
                >{{ row.createBy }}</el-row
              >
            </el-col>
            <el-col
              :span="16"
              style="
                text-align: left;
                text-indent: 0em;
                height: 100px;
                padding-top: 18px;
                overflow-y: auto;
              "
            >
              <el-row>
                <span style="font-weight: 600">
                  <i
                    class="el-icon-chat-line-round"
                    style="margin-right: 5px"
                  ></i
                  >咨询标题：</span
                >
                {{ row.title }}
              </el-row>
              <el-row style="padding: 10px 0; line-height: 20px">
                <span style="padding-left: 20px"> 咨询内容：</span
                >{{ row.content }}
              </el-row>
            </el-col>
            <el-col :span="4">
              <el-row style="text-align: right; padding-right: 40px">
                <el-button
                  type="text"
                  style="font-size: 14px"
                  @click="replyTo(row.title)"
                  >回复</el-button
                >
              </el-row>
              <el-row style="text-align: right; padding-right: 5px">{{
                row.createTime
              }}</el-row>
            </el-col>
          </el-row>

          <el-row
            v-for="item in row.children"
            :key="item.id"
            style="
              padding: 2px;
              height: 120px;
              border-bottom: 1px dotted #ddd;
              overflow-y: auto;
            "
          >
            <el-col :span="2" style="height: 10px"> </el-col>
            <el-col :span="4" style="padding-top: 20px">
              <el-image :src="woman" fit="cover"></el-image>
              <el-row
                style="font-size: 12px; height: 20px; line-height: 20px"
                >{{ item.createBy }}</el-row
              >
            </el-col>
            <el-col
              :span="18"
              style="text-align: left; text-indent: 0em; line-height: 22px;padding-top: 8px;"
            >
              <el-row>
                <span style="font-weight: 600"
                  ><i
                    class="el-icon-chat-dot-square"
                    style="margin-right: 5px"
                  ></i
                  >回复内容：</span
                ><br />{{ item.content }}
              </el-row>

              <el-row>
                <el-col :span="20" style="height: 10px"> </el-col>
                <el-col :span="4" style="height: 10px">
                  {{ item.createTime }}
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <mFooter></mFooter>
    <!-- 回复对话框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      top="20vh"
      style="text-align: left"
    >
      <span slot="title"
        ><i class="el-icon-chat-line-round" style="margin-right: 5px"></i
        >回复</span
      >
      <div>
        <el-form>
          <el-form-item label="回复内容">
            <el-input
              type="textarea"
              :rows="8"
              v-model="form_reply.content"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="replyAdvise">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mFooter from "@/components/mFooter.vue";
import { Loading } from "element-ui";
import { getNowDateYYYYMMDDHHMMSS, removeHtml } from "@/utils/common.js";

import {
  ListSeekadviseInfo,
  getSeekreplyinfosByAdviseTitle,
  searchListSeekadviseInfo,
  AplyAdvise,
} from "@/api/content/xtbg_seek_advise.js";
import { fromByteArray } from "ipaddr.js";
var _this = null;
export default {
  components: {
    mFooter,
  },
  data() {
    return {
      queryParams: {
        pageSize: 100,
        pageNum: 1,
        title: "",
      },
      form_reply: {},
      dialogVisible: false,
      woman: require("@/assets/imgs/advise/woman.png"),
      search_title: "",
      current_page: 1,
      total: 0,
      pageSize: 20,
      TableColumns: [
        {
          id: 1,
          label: "标题",
          pname: "name",
          width: "1000px",
        },
        {
          id: 2,
          label: "回复时间",
          pname: "date",
          width: "200px",
        },
        {
          id: 3,
          label: "回复人员",
          pname: "answer",
          width: "160px",
        },
      ],
      tableDataNew: [],

      tableData: [
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城咨询...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
        {
          date: "2023-02-02",
          name: "版纳植物园组织参加生命爱在春城...",
          dept: "党委办公室",
        },
      ],
      list_advise: [],
      adviseTitle: "",
    };
  },
  created() {
    _this = this;
    this.getAdviseList();
  },
  methods: {
    getAdviseList() {
      var loadingInstance1 = Loading.service({
        fullscreen: true,
        text: "正在初始化...",
      });
      this.list_advise = [];
      ListSeekadviseInfo().then((res) => {
        res.data.rows.map((row) => {
          (function (title) {
            getSeekreplyinfosByAdviseTitle(title).then((res) => {
              row.children = [];
              res.data.rows.map((row1) => {
                row1.content = removeHtml(row1.content);
                row.children.push(row1);
              });

              row.content = removeHtml(row.content);
              _this.list_advise.push(row);
            });
          })(row.title);
          setTimeout(() => {
          loadingInstance1.close();
        }, 500);
        });
      });
    },
    //回复
    replyTo(title) {
      this.adviseTitle = title;
      this.dialogVisible = true;
    },
    replyAdvise() {
      this.form_reply.adviseId = this.adviseTitle;
      this.form_reply.createBy = localStorage.getItem("username");
      this.form_reply.createTime = getNowDateYYYYMMDDHHMMSS();

      AplyAdvise(this.form_reply).then((res) => {
        this.dialogVisible = false;
        this.getAdviseList();
        if (typeof res != "undefined") {
          if (res.status == 200) {
            this.$message({
              message: "回复成功",
              type: "success",
              offset: 200,
            });
          } else {
            this.$message({
              message: "回复失败",
              type: "error",
              offset: 200,
            });
          }
        } else {
          this.$message({
            message: "回复失败",
            type: "error",
            offset: 200,
          });
        }
      });
    },
    start_advise() {
      this.$router.push({
        path: "/advise_add",
      });
    },
    tableRowClick(row, column, event) {
      this.$router.push({
        path: "/advise_detail",
        query: {
          title: row.name,
        },
      });
    },
    currentChange(page) {
      this.current_page = page;
      this.initTable();
    },
    initTable() {
      this.tableDataNew = [];
      this.total = this.tableData.length;
      for (
        let i = (this.current_page - 1) * this.pageSize;
        i <= this.current_page * this.pageSize;
        i++
      ) {
        this.tableDataNew.push(this.tableData[i]);
      }
    },
    btn_searchdata() {
      var loadingInstance1 = Loading.service({
        fullscreen: true,
        text: "正在查询",
      });
      this.queryParams.title = this.search_title;
      searchListSeekadviseInfo(this.queryParams).then((res) => {
        this.list_advise = [];
        res.data.rows.map((row) => {
          (function (title) {
            getSeekreplyinfosByAdviseTitle(title).then((res) => {
              row.children = [];
              res.data.rows.map((row1) => {
                row1.content = removeHtml(row1.content);
                row.children.push(row1);
              });

              row.content = removeHtml(row.content);
              _this.list_advise.push(row);
            });
          })(row.title);
        });
        setTimeout(() => {
          loadingInstance1.close();
        }, 500);
      });
    },
    searchData() {
      this.current_page = 1;
      var loadingInstance1 = Loading.service({
        fullscreen: true,
        text: "正在查询",
      });
      this.tableDataNew = [];
      for (let i = 0; i < this.tableData.length; i++) {
        let row = this.tableData[i];
        if (row.name.indexOf(this.search_title) > -1) {
          this.tableDataNew.push(row);
        }
      }
      this.total = this.tableDataNew.length;
      setTimeout(() => {
        loadingInstance1.close();
      }, 500);
    },
  },
  mounted() {
    //初始化表格数据

    let input_searchtitle = document.getElementById("input_searchtitle");
    let _this = this;
    input_searchtitle.addEventListener("keyup", function (event) {
      event.preventDefault();
      if (event.keyCode === 13) {
        _this.btn_searchdata();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.bny_advise {
  padding: 5px 0px;
  width: 1400px;
  background: #fff;
  height: auto;
  margin: 0 auto;
  .bny_advise-top {
    width: calc(100% - 240px);
    height: 100px;
    line-height: 100px;
    background: url("../../assets/imgs/advise/advise-top.png") no-repeat #519f6d;
    background-position-x: 60px;
    background-position-y: 10px;
    background-size: 1280px 80px;
    text-align: left;
    padding: 0 120px;
    font-size: 18px;
    font-weight: bold;
    color: #2e6548;
    div {
      float: left;
    }
    .bny_advise-top-btn {
      float: right;
    }
  }
  .bny_advise-search {
    height: 60px;
    line-height: 60px;
    width: 100%;
    margin: 40px 0;
  }
  .bny_advise-list {
    height: auto;
    width: calc(100% - 40px);
    padding: 20px;
  }
}
.el-pagination {
  width: 42%;
  margin-top: 30px;
  margin-left: 403px;
  ::v-deep .btn-prev,
  ::v-deep .btn-next {
    border: 1px solid #f2f2f2;
    background-color: transparent;
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  ::v-deep .el-icon-arrow-left:before,
  ::v-deep .el-icon-arrow-right:before {
    color: #d5bfa1;
    font-size: 14px;
  }
}

::v-deep .el-pagination.is-background .el-pager li {
  border: 1px solid #f2f2f2;
  background-color: #fff;
  color: #000;
  width: 40px;
  height: 40px;
  line-height: 37px;
  font-size: 14px;
  margin-right: 15px;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #519f6d;
  color: #fff;
}
</style>