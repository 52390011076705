<template>
  <div class="bny_index-list">
    <div class="bny_index-list-title">
      <img :src="titlelogo" alt="" />
      <div style="color: #fff;border-top: 3px solid rgb(81 159 109);">{{ title }}</div>
      <div @click="moreClick">更多>></div>
    </div>
    <div class="bny_index-list-content">
      <el-table
        :data="tableData"
        stripe
        style="width: 1000px; background: transparent"
        @row-click="tableRowClick"
        :show-header="false"
      >
        <el-table-column
          v-for="(item, index) in TableColumns"
          :key="index"
          :prop="item.pname"
          :label="item.label"
          :width="item.width"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "index-line-item",
  props: {
    title: {
      type: String,
      default: "",
    },
    titlelogo: {
      type: String,
      default: "",
    },
    TableColumns: {
      type: Array,
      default: [],
      required: true,
    },
    tableData: {
      type: Array,
      default: [],
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    tableRowClick(row, column, event) {
      // console.log(this.title);
      // console.log(row);
      localStorage.setItem("title", encodeURI(this.title));
      localStorage.setItem("showTitle", encodeURI(row.title));
      localStorage.setItem("deptName", row.deptName);
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: row.id,
        },
      });
    },
    moreClick() {
      if (this.title.indexOf("咨询平台") == -1) {
        this.$router.push({
          path: "/news",
          query: {
            name: this.title,
          },
        });
      } else {
        this.$router.push({
          path: "/advise",
          query: {
            name: this.title,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bny_index-list {
  height: auto;
  width: 1000px;
  /* //background: url('../../assets/imgs/m001.png') no-repeat; */
  background-size: 100% 100%;
  margin: 5px 0px 0px 0px;
  .bny_index-list-title {
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    width: 1000px;
    /* background-color: rgb(81 159 109); */
    background-color:#42825D;
    /* background-color: #f7f7f7; */
    /* background: rgb(245, 247, 250); */
    /* border-top: 1px solid rgb(81 159 109); */
   
    img {
      width: 25px;
      height: 25px;
      margin: 12px;
      float: left;
    }
    div {
      color: #fff;
      font-size: 16px;
      text-align: left;
      padding-left: 5px;
      float: left;
      &:last-child {
        float: right;
        margin-right: 15px;
        font-size: 14px;
        cursor: pointer;
        color: #fff;
        font-weight: normal;
      }
    }
  }
  .bny_index-list-content {
    width: 1000px;
    height: auto;
    font-size: 14px;
    color: #42825D;
  }
}
</style>
