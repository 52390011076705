export function meetting_data() {
    let meettingData = [
        {
            meetting_startdate: "2023-04-17 08:30",
            meetting_enddate: "2023-04-17 09:30",
            meetting_room: "昆明分部101会议室",
            meetting_booker: "赵霞",
            meetting_name: "创新药物研究组组会",
        },
        {
            meetting_startdate: "2023-04-17 14:00",
            meetting_enddate: "2023-04-17 17:30",
            meetting_room: "第二会议室",
            meetting_booker: "郁文彬",
            meetting_name: "生物多样性研究组学生开题答辩",
        },
        {
            meetting_startdate: "2023-04-17 15:30",
            meetting_enddate: "2023-04-17 17:30",
            meetting_room: "第三会议室",
            meetting_booker: "殷寿华",
            meetting_name: "勐腊县合作任务商谈会议",
        },
        {
            meetting_startdate: "2023-04-17 15:30",
            meetting_enddate: "2023-04-17 17:30",
            meetting_room: "分部219",
            meetting_booker: "李扬",
            meetting_name: "植物矿质营养组组会",
        },
        {
            meetting_startdate: "2023-04-17 09:30",
            meetting_enddate: "2023-04-17 10:30",
            meetting_room: "第三会议室",
            meetting_booker: "庄玉",
            meetting_name: "与深圳技术大学座谈",
        },
        {
            meetting_startdate: "2023-04-17 18:00",
            meetting_enddate: "2023-04-17 22:00",
            meetting_room: "第一会议室",
            meetting_booker: "条保处",
            meetting_name: "报告厅与分部101会议室测试",
        },
        {
            meetting_startdate: "2023-04-11 08:30",
            meetting_enddate: "2023-04-11 09:30",
            meetting_room: "昆明分部101会议室",
            meetting_booker: "郁文彬",
            meetting_name: "创新药物研究组组会",
        },
        {
            meetting_startdate: "2023-04-11 14:00",
            meetting_enddate: "2023-04-11 17:30",
            meetting_room: "第二会议室",
            meetting_booker: "赵霞",
            meetting_name: "生物多样性研究组学生开题答辩",
        },
        {
            meetting_startdate: "2023-04-11 15:30",
            meetting_enddate: "2023-04-11 17:30",
            meetting_room: "第三会议室",
            meetting_booker: "殷寿华",
            meetting_name: "勐腊县合作任务商谈会议",
        },
        {
            meetting_startdate: "2023-04-11 15:30",
            meetting_enddate: "2023-04-11 17:30",
            meetting_room: "分部219",
            meetting_booker: "李扬",
            meetting_name: "植物矿质营养组组会",
        },
        {
            meetting_startdate: "2023-04-11 09:30",
            meetting_enddate: "2023-04-11 10:30",
            meetting_room: "第三会议室",
            meetting_booker: "庄玉",
            meetting_name: "与深圳技术大学座谈",
        },
        {
            meetting_startdate: "2023-04-11 18:00",
            meetting_enddate: "2023-04-11 22:00",
            meetting_room: "第一会议室",
            meetting_booker: "条保处",
            meetting_name: "报告厅与分部101会议室测试",
        }
    ];
    return meettingData;
}
export function meetting_room() {
    return [
        {
            value: "第一会议室",
            label: "第一会议室",
        },
        {
            value: "第二会议室",
            label: "第二会议室",
        },
        {
            value: "第三会议室",
            label: "第三会议室",
        },
        {
            value: "昆明分部101会议室",
            label: "昆明分部101会议室",
        },
        {
            value: "分部219",
            label: "分部219",
        },
    ];
}
export function meetting_date_minute(){
    return [
        {
            time:"07:50",
            index:0,
        },
        {
            time:"08:00",
            index:0,
        },
        {
            time:"08:10",
            index:1,
        },
        {
            time:"08:20",
            index:2,
        },
        {
            time:"08:30",
            index:3,
        },
        {
            time:"08:40",
            index:4,
        },
        {
            time:"08:50",
            index:5,
        },
        {
            time:"09:00",
            index:6,
        },
        {
            time:"09:10",
            index:7,
        },
        {
            time:"09:20",
            index:8,
        },
        {
            time:"09:30",
            index:9,
        },
        {
            time:"09:40",
            index:10,
        },
        {
            time:"09:50",
            index:11,
        },
        {
            time:"10:00",
            index:12,
        },
        {
            time:"10:10",
            index:13,
        },
        {
            time:"10:20",
            index:14,
        },
        {
            time:"10:30",
            index:15,
        },
        {
            time:"10:40",
            index:16,
        },
        {
            time:"10:50",
            index:17,
        },
        {
            time:"11:00",
            index:18,
        },
        {
            time:"11:10",
            index:19,
        },
        {
            time:"11:20",
            index:20,
        },
        {
            time:"11:30",
            index:21,
        },
        {
            time:"11:40",
            index:22,
        },
        {
            time:"11:50",
            index:23,
        },
        {
            time:"12:00",
            index:24,
        },
        {
            time:"12:10",
            index:25,
        },
        {
            time:"12:20",
            index:26,
        },
        {
            time:"12:30",
            index:27,
        },
        {
            time:"12:40",
            index:28,
        },
        {
            time:"12:50",
            index:29,
        },
        {
            time:"13:00",
            index:30,
        },
        {
            time:"13:10",
            index:31,
        },
        {
            time:"13:20",
            index:32,
        },
        {
            time:"13:30",
            index:33,
        },
        {
            time:"13:40",
            index:34,
        },
        {
            time:"13:50",
            index:35,
        },
        {
            time:"14:00",
            index:36,
        },
        {
            time:"14:10",
            index:37,
        },
        {
            time:"14:20",
            index:38,
        },
        {
            time:"14:30",
            index:39,
        },
        {
            time:"14:40",
            index:40,
        },
        {
            time:"14:50",
            index:41,
        },
        {
            time:"15:00",
            index:42,
        },
        {
            time:"15:10",
            index:43,
        },
        {
            time:"15:20",
            index:44,
        },
        {
            time:"15:30",
            index:45,
        },
        {
            time:"15:40",
            index:46,
        },
        {
            time:"15:50",
            index:47,
        },
        {
            time:"16:00",
            index:48,
        },
        {
            time:"16:10",
            index:49,
        },
        {
            time:"16:20",
            index:50,
        },
        {
            time:"16:30",
            index:51,
        },
        {
            time:"15:40",
            index:52,
        },
        {
            time:"16:50",
            index:53,
        },
        {
            time:"17:00",
            index:54,
        },
        {
            time:"17:10",
            index:55,
        },
        {
            time:"17:20",
            index:56,
        },
        {
            time:"17:30",
            index:57,
        },
        {
            time:"17:40",
            index:58,
        },
        {
            time:"17:50",
            index:59,
        },
        {
            time:"18:00",
            index:60,
        },
        {
            time:"18:10",
            index:61,
        },
        {
            time:"18:20",
            index:62,
        },
        {
            time:"18:30",
            index:63,
        },
        {
            time:"18:40",
            index:64,
        },
        {
            time:"18:50",
            index:65,
        },
        {
            time:"19:00",
            index:66,
        },
        {
            time:"19:10",
            index:67,
        },
        {
            time:"19:20",
            index:68,
        },
        {
            time:"19:30",
            index:69,
        },
        {
            time:"19:40",
            index:70,
        },
        {
            time:"19:50",
            index:71,
        },
        {
            time:"20:00",
            index:72,
        },
        {
            time:"20:10",
            index:73,
        },
        {
            time:"20:20",
            index:74,
        },
        {
            time:"20:30",
            index:75,
        },
        {
            time:"20:40",
            index:76,
        },
        {
            time:"20:50",
            index:77,
        },
        {
            time:"21:00",
            index:78,
        },
        {
            time:"21:10",
            index:79,
        },
        {
            time:"21:20",
            index:80,
        },
        {
            time:"21:30",
            index:81,
        },
        {
            time:"21:40",
            index:82,
        },
        {
            time:"21:50",
            index:83,
        },
        {
            time:"22:00",
            index:84,
        },
        {
            time:"22:10",
            index:85,
        },
        {
            time:"22:20",
            index:86,
        },
        {
            time:"22:30",
            index:87,
        },
        {
            time:"22:40",
            index:88,
        },
        {
            time:"22:50",
            index:89,
        },
        {
            time:"23:00",
            index:90,
        },
       
       
        
    ];
}