<template>
  <div class="index_list_item_tabs" style="position: relative">
    <div
      @click="moreList"
      style="
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 15px;
        height: 20px;
        line-height: 20px;
        width: 80px;
        z-index: 990;
        color: #444;
        font-size: 14px;
        font-weight: normal;

      "
    >
      更多>>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first" v-if="wjhidden">
        <div slot="label" class="tab-label1" id="tab-label1">
          {{ tabOne }}
        </div>
        <div class="index_list_item_tabs-content">
          <el-table
            :data="tableData"
            stripe
            @row-click="tableRowClick1"
            style="width: 1000px; background: transparent"
            :show-header="false"
          >
            <el-table-column
              v-for="(item, index) in TableColumns"
              :key="index"
              :prop="item.pname"
              :label="item.label"
              :width="item.width"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second">
        <div slot="label" class="tab-label2" id="tab-label2">
          {{ tabTwo }}
        </div>
        <div class="index_list_item_tabs-content">
          <el-table
            :data="tableData2"
            stripe
            @row-click="tableRowClick"
            style="width: 1000px; background: transparent"
            :show-header="false"
          >
            <el-table-column
              v-for="(item, index) in TableColumns"
              :key="index"
              :prop="item.pname"
              :label="item.label"
              :width="item.width"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//内网新闻
import { ListInNews } from "@/api/content/xtbg_innews.js";
import { getDeptNameById } from "@/api/content/xtbg_common.js";
//官网动态
import { ListNews, getNewNews } from "@/api/content/xtbg_news.js";
import { getYearMonthDay, regManyJsonStrToJsonArr } from "@/utils/common.js";

var _this = null;
export default {
  name: "index_list_item_tabs",
  data() {
    return {
      activeName: "first",
      title: "内网新闻",
      queryPramas: {
        pageSize: 8,
        pageNum: 1,
      },
      tabOne: "内网新闻",
      tabTwo: "官网动态",
      TableColumns: [
        {
          id: 1,
          label: "标题",
          pname: "title",
          width: "",
        },
        {
          id: 1,
          label: "发文部门",
          pname: "deptName",
          width: "180px",
        },
        {
          id: 2,
          label: "发文日期",
          pname: "createTime",
          width: "100px",
        },
        // {
        //   id: 3,
        //   label: "发文部门",
        //   pname: "deptName",
        //   width: "200px",
        // },
      ],

      tableData: [],
      tableData2: [],

      wjhidden: true,
    };
  },
  created() {
    _this = this;
    this.getListImportantpublicity();
    this.getListNews();
  },
  methods: {
    moreList() {
      this.$router.push({
        path: "/news",
        query: {
          name: this.title,
        },
      });
    },
    tableRowClick1(row) {
      localStorage.setItem("title", encodeURI(this.title));
      localStorage.setItem("showTitle", encodeURI(row.title));
      localStorage.setItem("deptName", row.deptName);
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: row.id,
        },
      });
    },
    tableRowClick(row, column, event) {
      window.open(row.link);
    },
    handleClick(tab, event) {
      if (tab.name == "first") {
        this.title = this.tabOne;
        document.getElementById("tab-label1").style.borderTop = "3px solid rgb(81 159 109)";
        document.getElementById("tab-label2").style.borderTop = "none";
      } else {
        this.title = this.tabTwo;
        document.getElementById("tab-label1").style.borderTop = "none";
        document.getElementById("tab-label2").style.borderTop = "3px solid rgb(81 159 109)";
      }


    },
    //根据部门ID获取部门名称
    getDeptNameById(id) {
      return new Promise((resolve) => {
        this.$getDeptNameByDeptId(this.$http,id).then((res) => {
          resolve(res[0].dept_name);
        });
      });
    },

    //内网新闻
    getListImportantpublicity() {
      ListInNews(this.queryPramas).then(res=>{
        this.tableData = [];
        res.data.rows.map(row=>{
          row.deptName = row.dept;

          row.createTime = getYearMonthDay(row.createTime);

          this.tableData.push(row);

        })
      })


     // ListImportantpublicity(this.queryPramas).then((res) => {
      //   this.tableData = [];

      //   let rows = res.data.rows;

      //   rows.map((row) => {
      //     (function (deptId) {
      //       if (deptId != null) {
      //         _this.$parent.getDeptNameById(deptId).then((deptName) => {
      //           row.deptName = deptName;

      //           row.createTime = getYearMonthDay(row.createTime);

      //           _this.$nextTick(function () {
      //             if (row.createTime.indexOf(" ") > -1) {
      //               row.createTime = getYearMonthDay(row.createTime);
      //               _this.tableData.push(row);
      //             } else {
      //               _this.tableData.push(row);
      //             }
      //           });
      //         });
      //       } else {
      //         _this.tableData.push(row);
      //       }
      //     })(row.deptId);
      //   });
      // });
    },
    //官网动态
    getListNews() {
      // ListNews(this.queryPramas).then((res) => {
      //   this.tableData2 = [];

      //   let rows = res.data.rows;
      //   _this.tableData2 = [];
      //   rows.map(row=>{
      //     row.createTime = getYearMonthDay(row.createTime);
      //     _this.tableData2.push(row);
      //   })

      // });

      //最新的官网动态

      getNewNews().then((result) => {
        this.tableData2 = [];
        regManyJsonStrToJsonArr(result)
          .filter((item, index) => {
            return index < 10;
          })
          .map((row) => {
            let newRow = {
              createTime: row.date,
              deptName: row.dept,
              title: row.title,
              link: row.link,
            };
            this.tableData2.push(newRow);
          });
          this.tableData2 = this.tableData2.slice(0,8);
      });
    },
  },
  mounted() {
    let roleName = localStorage.getItem("roleName");
    //判断权限，如果是外籍 用户 则隐藏有些模块
    if (roleName != null) {
      if (roleName.indexOf("外籍") > -1) {
        this.wjhidden = false;
        this.activeName = "second";
      } else {
        this.wjhidden = true;
        this.activeName = "first";
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.index_list_item_tabs {
  padding-top: 5px;
  /* background: rgb(81 159 109) !important; */
  .tab-label1 {
    background: url("../../assets/imgs/list-items/nexw.png") no-repeat;
    background-size: 25px 25px;
    background-position: left center;
    background-position-x: 10px;
    padding-left: 40px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    color: #444;
    font-size: 16px;
    border-top: 3px solid rgb(81 159 109);
  }
  .tab-label2 {
    background: url("../../assets/imgs/list-items/gwdt.png") no-repeat;
    background-size: 25px 25px;
    background-position: left center;
    padding-left: 30px;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    color: #444;
    font-size: 16px;
    /* border-top: 3px solid rgb(81 159 109); */
  }
  .index_list_item_tabs-content {
    width: 1000px;
    height: auto;
    font-size: 14px;
    color: rgb(81 159 109);
    font-size: 14px;
  }
  ::v-deep .el-tabs__nav-scroll {
    height: 50px;
    line-height: 50px;
    /* background: rgb(81 159 109); */
    /* background: #f7f7f7; */
    background: rgb(245, 247, 250);
    border-top: 1px solid rgb(81 159 109);
  }
  ::v-deep .el-tabs__header {
    margin: 0px;
  }
}
</style>