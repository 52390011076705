export default {
  // 提案系统前端权限控制
  congressRole: {
    // 职工代表
    roleid_zgdb: 1,
    // 提案组
    roleid_taz: 2,
    // 承办部门
    roleid_cbbm: 3,
    // 园领导
    roleid_yld: 4,
    // 权限管理员
    roleid_gly: 5,
   
  },
  
  propose_title:"六届三次"
};
