<template>
  <div class="gongjiao">
    <div
      style="
        margin: 10px 0px;
        height: 100px;
        background-color: #f5f8f7;
        text-align: left;
        display: flex;
        align-items: center;
      "
    >
      <img
        src="../../../assets/imgs/gongwu/zuixingonggao.png"
        style="width: 60px; height: auto; margin-left: 20px"
        alt=""
        srcset=""
      />

      <div style="flex: 1; font-size: 14px; padding: 0px 20px">
        <div style="text-indent: 2em">
          尊敬的用户们， 公务车预约，上线试运行。
          一、内网用户端：第一，内网所有用户可以申请公务车，由车队管理审核指派公务车，其他用户可以搭乘可搭乘的公务车，搭乘需由对应用车人审核同意，即可搭乘。第二，可以自由发布顺风车信息。
          二、车队管理员端（只在PC端管理车辆基础信息，权限管理）。（pc端和小程序端均可审核和指派）审核内网用户公务车申请单，并指派对应车辆和司机（目前，司机和公务车属于一对一）。
          三、司机端（只在小程序端），查看跟自己有关系的公务车订单并可结束行程。
        </div>
        <div class="flex">
          <span style="color: #808080; margin-left: auto"> 2024年9月24日 </span>
        </div>
      </div>
    </div>
    <div class="flex" style="padding: 10px">
      <!-- <el-input
        style="margin-left: auto; margin-right: 10px; max-width: 200px"
        v-model="searchStartAddress"
        placeholder="搜索起点"
      ></el-input>

      <el-input
        style="margin-right: 10px; max-width: 200px"
        v-model="searchEndAddress"
        placeholder="搜索终点"
      ></el-input> -->
      <!-- <el-input
        style="margin-right: 10px; max-width: 200px"
        v-model="searchUser"
        placeholder="搜索用车人"
      ></el-input> -->
      <el-button
      type="text"
        style="margin-left: auto"
        @click="refreshDC"
        icon="el-icon-refresh"
        >刷新</el-button
      >
    </div>

    <el-table border height="500" :data="logDcHistory" style="width: 100%">
      <el-table-column
        prop="uid"
        label="序号"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="xingcheng"
        label="行程"
        align="center"
      ></el-table-column>
      <el-table-column prop="carinfo" label="车辆信息" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.carinfo.split("-")[0] }}-</span>
          <span
            style="color: #409eff; cursor: pointer"
            @click="searchBook(scope.row.carinfo.split('-')[1])"
            >{{ scope.row.carinfo.split("-")[1] }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="start_time"
        label="开始时间"
        align="center"
        width="156"
      >
        <template slot-scope="scope">
          <span>{{ $FormatDateToYMDHMS(scope.row.start_time, true) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="user_name" label="搭乘人" align="center" width="156">
                  <template slot-scope="scope">
                    <span style="color: #409eff; cursor: pointer" @click="searchBook(scope.row.user_name)">{{
        scope.row.user_name }}</span>
                  </template>
                </el-table-column>

      <el-table-column
        prop="capacity"
        label="搭乘人数"
        align="center"
        width="78"
      ></el-table-column>
      <!-- <el-table-column prop="carpool" label="可搭乘" align="center" width="66">
                  <template slot-scope="scope">
                    <span v-if="scope.row.carpool == '是'" style="color: #085">是</span>
                    <span v-if="scope.row.carpool != '是'" style="color: #f00">否</span>
                  </template> 
                </el-table-column>-->
      <el-table-column prop="status" label="状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0" style="color: #f00">待审核</span>
          <span v-if="scope.row.status == 1" style="color: #085">同意</span>
          <span
            v-if="scope.row.status == 2"
            style="color: #f00; cursor: pointer"
            @click="agreeDc(scope.row)"
            >驳回</span
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-check"
            style="color: #519F6D;"
            v-if="scope.row.status == 0"
            @click="agreeDc(scope.row)"
            >同意</el-button
          >
          <el-button
            type="text"
            icon="el-icon-close"
            v-if="scope.row.status == 0"
            style="color: #f00;"
            @click="cancelDc(scope.row)"
            >驳回</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total,prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
      style="margin-top: 20px; text-align: center"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchStartAddress: "",
      searchEndAddress: "",
      searchUser: "",
      dialogVisible: false,
      bookings: [],
      currentBooking: {
        vehicle_id: "",
        start_address: "",
        end_address: "",
        start_time: "",
        end_time: "",
        carpool: "",
        capacity: "",
        other_seats: "",
        reason: "",
        status: "",
      },
      selected_row: {},

      logDcHistory: [],

      currentPage: 1,
      total: 0,
      pageSize: 8, // 每页显示的数据量
    };
  },
  methods: {
    async getUserName(create_by){
      return await this.$db_select(this.$http,"xtbg_user","name","id="+create_by);

    },
    searchBookings() {
      // 搜索逻辑
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getDcInfo();
    },
    editBooking(booking) {
      this.currentBooking = { ...booking };
      this.dialogVisible = true;
    },
    async getDcInfo() {
      //
      let _tableName = "xtbg_officecar_log a";
      let _sfd = `a.*,CONCAT(a.start_address,'-',a.end_address) as xingcheng,
                (SELECT CONCAT(v.license_plate,'(',v.type,')-',
                IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'待定')) FROM xtbg_officecar_vihicleinfo v WHERE v.id=a.vehicle_id) as carinfo,
                (SELECT name FROM xtbg_user WHERE id=a.create_by) as user_name`;
      let _where =
        `admin_userid is NULL AND EXISTS ( SELECT 1 FROM xtbg_officecar_application 
      WHERE status=1 AND id=a.application_id AND create_by=${localStorage.getItem(
        "userid"
      )})  ORDER BY id desc LIMIT ` +
        this.pageSize +
        ` OFFSET ` +
        (this.currentPage - 1) * this.pageSize;
      const data = await this.$db_select(this.$http, _tableName, _sfd, _where);
      const mdata = data.length > 0 ? data : [];
      let index = 0;
      this.logDcHistory = mdata.map((row) => {
        row.uid = ++index;
        return row;
      });

      const totalRes = await this.$db_select(
        this.$http,
        _tableName,
        "count(*) as total",
        _where.split("ORDER BY")[0]
      );

      this.total = totalRes.length > 0 ? totalRes[0].total : 0;
    },
    refreshDC() {
      this.currentPage = 1;
      this.getDcInfo();
    },
    async agreeDc(row) {
      this.selected_row = row;

      const phoneRes = await this.$GetUserPhone(
        this.$http,
        this.selected_row.create_by
      );
      this.$confirm("确定同意搭乘, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(async () => {
          const bcRes = await this.$db_update(
            this.$http,
            "xtbg_officecar_log",
            { status: 1 },
            "id=" + row.id
          );
          if (bcRes.affectedRows > 0) {
            if (phoneRes.length > 0) {
              this.$SendMSG(phoneRes[0].phone, "2275966");
            }
            this.getDcInfo();
            this.$message({
              type: "success",
              message: "同意搭乘!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async cancelDc(row) {
      this.selected_row = row;
      const phoneRes = await this.$GetUserPhone(
        this.$http,
        this.selected_row.create_by
      );
      this.$confirm("确定驳回搭乘, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const bcRes = await this.$db_update(
            this.$http,
            "xtbg_officecar_log",
            { status: 2, capacity: 0 },
            "id=" + row.id
          );
          if (bcRes.affectedRows > 0) {
            if (phoneRes.length > 0) {
              this.$SendMSG(phoneRes[0].phone, "2275966");
            }
            this.getDcInfo();

            this.$message({
              type: "success",
              message: "驳回成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消驳回",
          });
        });
    },
    searchBook(username) {
      this.$router.push({
        path: "/books",
        query: {
          name: username,
        },
      });
    },
  },
  mounted() {
    this.getDcInfo();
  },
};
</script>

<style scoped>
/* 添加样式 */
</style>
