<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-09 15:17:44
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-13 09:30:47
 * @FilePath: \app_wb\src\components\commom\dynamicDataTable.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="dynamicTable">
    <el-table :data="tableData" style="width: 100%" max-height="640">
      <el-table-column
        v-for="column in columns"
        :key="column.prop"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
        :sortable="column.prop === 'status'"
        @sort-change="handleSortChange"
      >
        <template v-if="column.prop === 'status'" #default="scope">
          <span :style="{ color: getStatusColor(scope.row.status) }">{{
            scope.row.status
          }}</span>
        </template>
      </el-table-column>
      <!-- 操作列 -->
      <el-table-column fixed="right" label="操作" width="200">
        <template #default="scope">
          <slot name="actions" :row="scope.row"></slot>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      layout="total,prev, pager, next"
      background
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    fetchData: {
      type: Function,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      currentPage: 1,
      total: 0,
      tableData: [],
      columns: [],
    };
  },
  watch: {
    // 当currentPage变化时自动获取新的数据
    currentPage: {
      immediate: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    // 点击表头排序时触发
    handleSortChange({ prop, order }) {
      // 根据排序的列和顺序重新获取数据
      this.loadData({ sortProp: prop, sortOrder: order });
    },
    async loadData({ sortProp, sortOrder } = {}) {
      try {
        const { data, total, columns } = await this.fetchData({
          page: this.currentPage,
          pageSize: this.pageSize,
          sortProp, // 排序属性
          sortOrder, // 排序顺序
        });
        this.tableData = data;
        this.total = total;
        this.columns = columns;
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    },
    // async loadData() {
    //   try {
    //     const { data, total, columns } = await this.fetchData({
    //       page: this.currentPage,
    //       pageSize: this.pageSize,
    //     });
    //     this.tableData = data;
    //     this.total = total;
    //     this.columns = columns;
    //   } catch (error) {
    //     console.error("Failed to fetch data:", error);
    //   }
    // },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
    },

    getStatusColor(status) {
      switch (status) {
        case "已处理":
          return "#67C23A";
        case "已结案":
          return "#008500";
        case "待附议":
          return "#409EFF";
        case "待提交":
          return "#E6A23C";
        case "处理中":
          return "#F56C6C";
        case "待审查":
          return "#F56C6C";
        case "待会审":
          return "#F56C6C";
        case "待审批":
          return "#F56C6C";
        case "不予立案":
          return "#909399";
        // 添加更多状态和颜色的映射
        default:
          return "black"; // 默认颜色
      }
    },
  },
};
</script>

