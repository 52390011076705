/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-29 13:36:15
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-05-29 13:36:26
 * @FilePath: \app_wb\src\api\content\xtbg_science_info.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js'
//学术报告
export function ListAcademicreport(queryParam){
    return request('/content/academicreport/list',{
        method: "get",
       
        params:queryParam
    });
}
export function getAcademicreportById(id){
    return request('/content/academicreport/'+id,{
        method: "get",
       
      
    });
}