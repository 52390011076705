<template>
  <div class="gongjiao">
    <div
      style="
        margin: 10px 0px;
        height: 100px;
        background-color: #f5f8f7;
        text-align: left;
        display: flex;
        align-items: center;
      "
    >
      <img
        src="../../../assets/imgs/gongwu/zuixingonggao.png"
        style="width: 60px; height: auto; margin-left: 20px"
        alt=""
        srcset=""
      />

      <div style="flex: 1; font-size: 14px; padding: 0px 20px">
        <div style="text-indent: 2em">
          尊敬的用户们， 公务车预约，上线试运行。
          一、内网用户端：第一，内网所有用户可以申请公务车，由车队管理审核指派公务车，其他用户可以搭乘可搭乘的公务车，搭乘需由对应用车人审核同意，即可搭乘。第二，可以自由发布顺风车信息。
          二、车队管理员端（只在PC端管理车辆基础信息，权限管理）。（pc端和小程序端均可审核和指派）审核内网用户公务车申请单，并指派对应车辆和司机（目前，司机和公务车属于一对一）。
          三、司机端（只在小程序端），查看跟自己有关系的公务车订单并可结束行程。
        </div>
        <div class="flex">
          <span style="color: #808080; margin-left: auto"> 2024年9月24日 </span>
        </div>
      </div>
    </div>
    <div class="flex gongjiao" style="padding: 10px 10px 10px 0px">
      <el-button @click="startApplication" type="success" icon="el-icon-plus"
        >申请用车</el-button
      >
      <el-input
        clearable
        style="margin-left: auto; margin-right: 10px; max-width: 200px"
        v-model="searchStartAddress"
        placeholder="搜索起点"
      ></el-input>

      <el-input
        clearable
        style="margin-right: 10px; max-width: 200px"
        v-model="searchEndAddress"
        placeholder="搜索终点"
      ></el-input>
      <el-input
        clearable
        style="margin-right: 10px; max-width: 200px"
        v-model="searchUser"
        placeholder="搜索用车人"
      ></el-input>
      <el-button @click="searchApplications" type="text" icon="el-icon-refresh"
        >刷新</el-button
      >
      
      <el-button @click="showHistory = true" type="text" style="color:#f9a046" icon="el-icon-document"
        >申请/搭乘历史</el-button
      >
    </div>

    <el-table border height="560px" :data="bookings" style="width: 100%">
      <el-table-column
        prop="uid"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="xingcheng"
        label="行程"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="carinfo"
        label="车辆信息"
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.carinfo.split("-")[0] }}-</span>
          <span
            style="color: #409eff; cursor: pointer"
            @click="searchBook(scope.row.carinfo.split('-')[1])"
            >{{ scope.row.carinfo.split("-")[1] }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="start_time"
        label="开始时间"
        align="center"
        width="156"
      ></el-table-column>
      <el-table-column prop="user_name" label="用车人" align="center">
        <template slot-scope="scope">
          <span
            style="color: #409eff; cursor: pointer"
            @click="searchBook(scope.row.user_name)"
            >{{ scope.row.user_name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="reason"
        label="用车事由"
        width="140"
      ></el-table-column>
      <el-table-column
        prop="capacity"
        label="乘车人数"
        align="center"
        width="78"
      ></el-table-column>
      <el-table-column
        prop="oseats"
        label="剩余座位"
        align="center"
        width="78"
      ></el-table-column>
      <el-table-column prop="carpool" label="可搭乘" align="center" width="66">
        <template slot-scope="scope">
          <span v-if="scope.row.oseats <= 0" style="color: #f00">否</span>
          <div v-else>
            <span v-if="scope.row.carpool == '是'" style="color: #085">是</span>
            <span v-if="scope.row.carpool != '是'" style="color: #f00">否</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="cstatus"
        label="状态"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="110">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-thumb"
            style="color: #519F6D;"
            v-if="
              scope.row.status == 1 &&
              scope.row.carpool == '是' &&
              scope.row.oseats > 0&& isMySeleft(scope.row.create_by)
            "
            @click="editBooking(scope.row)"
            >搭乘</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total,prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handleCurrentChange"
      style="margin-top: 20px; text-align: center"
    />
    <!-- 申请用车 -->
    <el-drawer
      :title="drawerTitle"
      :visible.sync="isDrawerVisible"
      :direction="'rtl'"
      :show-close="false"
      :wrapperClosable="false"
      :with-header="false"
      :size="drawerWidth"
    >
      <div style="padding: 20px; background-color: #f5f5f5">
        <div style="display: flex; padding: 0px 10px 20px 0px">
          <div
            style="
              flex: 1;
              border-bottom: 1px solid #085;
              text-align: left;
              padding-bottom: 14px;
            "
          >
            <span
              style="
                border-bottom: 6px solid #085;
                padding-bottom: 10px;
                margin-left: 15px;
                font-weight: 600;
              "
              >申请用车</span
            >
          </div>
          <div style="cursor: pointer" @click="isDrawerVisible = false">
            <i class="el-icon-close" style="font-size: 18px"></i>
          </div>
        </div>
        <el-form class="myform" label-width="90px" label-position="left">
          <div
            style="
              padding: 10px;
              align-items: center;
              background-color: #fff;
              min-height: 100px;
              margin-bottom: 10px;
            "
          >
            <div class="flex" style="padding-bottom: 5px; align-items: center">
              <el-input
                v-model="mFormData.start_address"
                placeholder="出发地"
              ></el-input>

              <img
                src="../../../assets/imgs/gongwu/goto.png"
                alt=""
                @click="huzhuan"
                style="height: 30px; cursor: pointer"
                srcset=""
              />

              <el-input
                v-model="mFormData.end_address"
                placeholder="目的地"
              ></el-input>
            </div>
            <div class="flex" style="flex-wrap: wrap">
              <div
                style="
                  color: #fff;
                  font-size: 12px;
                  cursor: pointer;
                  background-color: #808080;
                  margin: 5px;
                  padding: 5px;
                  border-radius: 5px;
                "
                v-for="item in changyong"
                :key="item"
                @click="chooseAddress(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>

          <el-form-item label="选择车辆">
            <div
              class="gongjiao"
              style="
                display: flex;
                flex-direction: column;
                text-align: left;
                padding: 10px;
                background-color: #fff;
                height: 150px;
                overflow-y: auto;
                overflow-x: auto;
              "
            >
              <el-radio-group v-model="mFormData.vehicle_id">
                <el-radio
                  style="flex: 1; margin-bottom: 10px"
                  :label="item.id"
                  v-for="(item, i) in vehicles"
                  :key="'ve_' + i"
                  >{{ i + 1 }}-{{ item.license_plate }}（{{ item.type }}|{{
                    item.seats
                  }}座-{{item.price}}元/公里）-<span
                    style="color: #409eff; cursor: pointer"
                    @click="searchBook(item.user_name)"
                    >{{ item.user_name || "应急车" }}</span
                  ></el-radio
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="用车类型">
            <div
              style="
                display: flex;
                justify-content: flex-start;
                padding-top: 15px;
              "
              class="gongjiao"
            >
              <el-radio v-model="nFormData.use_type" label="内部用车"
                >内部用车</el-radio
              >
              <el-radio v-model="nFormData.use_type" label="外部用车"
                >外部用车</el-radio
              >
            </div>
          </el-form-item>
          <el-form-item label="乘车人数">
            <div style="display: flex; justify-content: flex-start">
              <el-input-number
                v-model="mFormData.capacity"
                :min="1"
              ></el-input-number>
              <span style="color:#f00;line-height:1.2;text-align: left;padding-left: 5px;">注：乘车人数不能超过所选车辆的除司机的总乘坐座位。</span>
            </div>
          </el-form-item>
          <el-form-item label="用车事由">
            <el-input
              type="textarea"
              v-model="mFormData.reason"
              placeholder="请输入用车事由"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="出发时间">
            <div style="display: flex; justify-content: flex-start">
              <el-date-picker
                v-model="mFormData.start_time"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              ></el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="是否可搭乘">
            <div
              class="gongjiao"
              style="
                display: flex;
                justify-content: flex-start;
                padding-top: 10px;
              "
            >
              <el-switch
                v-model="mFormData.carpool"
                :active-value="'是'"
                :inactive-value="'否'"
              >
              </el-switch>
            </div>
          </el-form-item>
          <el-form-item>
            <div style="display: flex" class="gongjiao">
              <el-button
                type="success"
                style="margin-left: auto"
                @click="submitApplication"
                >立即申请</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 搭乘 -->
    <el-drawer
      :title="drawerTitle"
      :visible.sync="isDrawerDcVisible"
      :direction="'rtl'"
      :show-close="false"
      :wrapperClosable="false"
      :with-header="false"
      :size="drawerWidth"
    >
      <div style="padding: 20px">
        <div style="display: flex; padding: 0px 10px 20px 0px">
          <div
            style="
              flex: 1;
              border-bottom: 1px solid #085;
              text-align: left;
              padding-bottom: 14px;
            "
          >
            <span
              style="
                border-bottom: 6px solid #085;
                padding-bottom: 10px;
                margin-left: 15px;
                font-weight: 600;
              "
              >搭乘信息</span
            >
          </div>
          <div style="cursor: pointer" @click="isDrawerDcVisible = false">
            <i class="el-icon-close" style="font-size: 18px"></i>
          </div>
        </div>
        <el-form class="myform" label-width="90px" label-position="left">
          <div
            style="
              padding: 10px;
              align-items: center;
              background-color: #fff;
              min-height: 50px;
              margin-bottom: 10px;
            "
          >
            <div class="flex" style="padding-bottom: 5px; align-items: center">
              <el-input
                v-model="nFormData.start_address"
                placeholder="出发地"
                readonly
              ></el-input>

              <img
                src="../../../assets/imgs/gongwu/goto.png"
                alt=""
                @click="huzhuan"
                style="height: 30px; cursor: pointer"
                srcset=""
              />

              <el-input
                v-model="nFormData.end_address"
                placeholder="目的地"
                readonly
              ></el-input>
            </div>
          </div>

          <el-form-item label="选择车辆">
            <el-input v-model="nFormData.carinfo" readonly></el-input>
          </el-form-item>
          <el-form-item label="搭乘人数">
            <div style="display: flex; justify-content: flex-start">
              <el-input-number
                v-model="nFormData.capacity"
                :min="1"
              ></el-input-number
              ><span
                >最多可搭乘<span style="color: #f00; font-weight: 600">{{
                  total_capacity
                }}</span
                >人</span
              >
            </div>
          </el-form-item>

          <el-form-item label="出发时间">
            <div style="display: flex; justify-content: flex-start">
              <el-date-picker
                v-model="nFormData.start_time"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                readonly
                placeholder="选择日期时间"
              ></el-date-picker>
            </div>
          </el-form-item>

          <el-form-item>
            <div style="display: flex" class="gongjiao">
              <el-button
                type="success"
                style="margin-left: auto"
                @click="submitDacheng"
                >立即搭乘</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 申请历史记录 -->
    <el-drawer
      title="申请历史"
      :visible.sync="showHistory"
      :with-header="false"
      :direction="'btt'"
      :size="'70%'"
    >
      <div class="gongjiao_jichuxinxi" style="padding: 10px 20px 40px 20px">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="公务车用车历史" name="first">
            <div>
              <el-table
                border
                height="500"
                :data="applicationHistory"
                style="width: 100%"
              >
                <el-table-column
                  prop="uid"
                  label="序号"
                  width="80"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="xingcheng"
                  label="行程"
                  align="center"
                ></el-table-column>
                <el-table-column prop="carinfo" label="车辆信息" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.carinfo.split("-")[0] }}-</span>
                    <span
                      style="color: #409eff; cursor: pointer"
                      @click="searchBook(scope.row.carinfo.split('-')[1])"
                      >{{ scope.row.carinfo.split("-")[1] }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="start_time"
                  label="开始时间"
                  align="center"
                  width="156"
                ></el-table-column>
                <el-table-column
                  prop="user_name"
                  label="用车人"
                  align="center"
                  width="156"
                >
                  <template slot-scope="scope">
                    <span
                      style="color: #409eff; cursor: pointer"
                      @click="searchBook(scope.row.user_name)"
                      >{{ scope.row.user_name }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="reason"
                  label="用车事由"
                ></el-table-column>
                <el-table-column
                  prop="capacity"
                  label="乘车人数"
                  align="center"
                  width="78"
                ></el-table-column>
                <!-- <el-table-column prop="carpool" label="可搭乘" align="center" width="66">
                  <template slot-scope="scope">
                    <span v-if="scope.row.carpool == '是'" style="color: #085">是</span>
                    <span v-if="scope.row.carpool != '是'" style="color: #f00">否</span>
                  </template>
                </el-table-column> -->
                <el-table-column
                  prop="cstatus"
                  label="状态"
                  align="center"
                  width="100"
                ></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      icon="el-icon-close"
                      v-if="scope.row.status == 0"
                      @click="cancelBooking(scope.row)"
                      >取消</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                background
                layout="total,prev, pager, next"
                :total="total2"
                :page-size="pageSize2"
                :current-page.sync="currentPage2"
                @current-change="handleCurrentChange2"
                style="margin-top: 20px; text-align: center"
              />
            </div>
          </el-tab-pane>
          <el-tab-pane label="搭乘历史" name="second">
            <div>
              <el-table
                border
                height="500"
                :data="logDcHistory"
                style="width: 100%"
              >
                <el-table-column
                  prop="uid"
                  label="序号"
                  width="80"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="xingcheng"
                  label="行程"
                  align="center"
                ></el-table-column>
                <el-table-column prop="carinfo" label="车辆信息" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.carinfo.split("-")[0] }}-</span>
                    <span
                      style="color: #409eff; cursor: pointer"
                      @click="searchBook(scope.row.carinfo.split('-')[1])"
                      >{{ scope.row.carinfo.split("-")[1] }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="start_time"
                  label="开始时间"
                  align="center"
                  width="156"
                ></el-table-column>
                <el-table-column
                  prop="user_name"
                  label="用车人"
                  align="center"
                  width="156"
                >
                  <template slot-scope="scope">
                    <span
                      style="color: #409eff; cursor: pointer"
                      @click="searchBook(scope.row.user_name)"
                      >{{ scope.row.user_name }}</span
                    >
                  </template>
                </el-table-column>

                <el-table-column
                  prop="capacity"
                  label="搭乘人数"
                  align="center"
                  width="78"
                ></el-table-column>
                <!-- <el-table-column prop="carpool" label="可搭乘" align="center" width="66">
                  <template slot-scope="scope">
                    <span v-if="scope.row.carpool == '是'" style="color: #085">是</span>
                    <span v-if="scope.row.carpool != '是'" style="color: #f00">否</span>
                  </template> 
                </el-table-column>-->
                <el-table-column
                  prop="cstatus"
                  label="状态"
                  align="center"
                  width="100"
                ></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      icon="el-icon-close"
                      v-if="scope.row.status == 0"
                      @click="cancelBooking(scope.row)"
                      >取消</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                background
                layout="total,prev, pager, next"
                :total="total3"
                :page-size="pageSize3"
                :current-page.sync="currentPage3"
                @current-change="handleCurrentChange3"
                style="margin-top: 20px; text-align: center"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script>
let tableBookingApplication = "xtbg_officecar_application";
export default {
  data() {
    return {
      activeName: "first",

      isDrawerDcVisible: false,
      total_capacity: 0,
      selected_row: {},
      logDcHistory: [], //搭乘历史
      currentPage3: 1,
      total3: 0,
      pageSize3: 8,

      showHistory: false,
      applicationHistory: [], //申请历史
      currentPage2: 1,
      total2: 0,
      pageSize2: 8,

      changyong: [
        "园部--嘎洒机场",
        "园部--高铁站",
        "分部--昆明站",
        "分部--昆明南站",
        "分部--长水机场"
      ],

      isDrawerVisible: false,
      drawerWidth: "28%",
      drawerTitle: "申请用车",
      mFormData: {
        start_address: "",
        end_address: "",
        capacity: 1,
        carpool: "是",
        reason: "",
        start_time: "",
        status: 0,
      },
      nFormData: {
        start_address: "",
        end_address: "",
        capacity: 1,
        carpool: "是",
        reason: "",
        start_time: "",
        status: 0,
        use_type: "内部用车",
      },
      searchStartAddress: "",
      searchEndAddress: "",
      searchUser: "",
      dialogVisible: false,
      bookings: [],

      vehicles: [],
      currentBooking: {
        vehicle_id: "",
        start_address: "",
        end_address: "",
        start_time: "",
        end_time: "",
        carpool: "",
        capacity: "",
        other_seats: "",
        reason: "",
        status: "",
      },

      currentPage: 1,
      total: 0,
      pageSize: 8, // 每页显示的数据量
    };
  },

  watch: {
    "mFormData.capacity":{
      handler: async function(val){
        //SELECT seats-1 as seats FROM xtbg_officecar_vihicleinfo WHERE id=
      

      },
      deep:true,

    },
    "nFormData.capacity": {
      handler: function (val) {
        if (val > this.total_capacity) {
          this.$message({
            message: "搭乘人数超出了，最多可搭乘人数" + this.total_capacity,
            type: "error",
            duration: 2000,
          });
        }
      },
      deep: true,
    },
    
    isDrawerVisible(val) {
      if (val) {
        this.mFormData.start_time = this.$FormatDateToYMDHMS(new Date(), true);

        this.getVihicleinos();
      }
    },
    showHistory(val) {
      if (val) {
        this.getApplicationHistorys();
      }
    },
    searchStartAddress(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    },
    searchEndAddress(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    },
    searchUser(val) {
      if (val) {
        this.searchApplications();
      } else {
        this.getBookings();
      }
    },
  },

  methods: {

    isMySeleft(create_by){
      return localStorage.getItem("userid")!=create_by;
    },
    handleTabClick() {
      if (this.activeName == "first") {
        this.getApplicationHistorys();
      } else {
        this.getLogDcHistory();
      }
    },
    async searchApplications() {
      //SELECT * FROM xtbg_officecar_application WHERE start_address like '%昆明%' AND end_address LIKE '%%' AND create_by in (SELECT id FROM xtbg_user WHERE name like '%刘%') AND status=1
      this.currentPage = 1;
      this.bookings = [];
      let _tableName = tableBookingApplication + ` a`;
      let _sfd = `a.*,CONCAT(a.start_address,'-',a.end_address) as xingcheng,
        (SELECT CONCAT(v.license_plate,'(',v.type,')',
        IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'待定')) FROM xtbg_officecar_vihicleinfo v WHERE v.id=a.vehicle_id) as carinfo,
        (SELECT name FROM xtbg_user WHERE id=a.create_by) as user_name,
        (SELECT (seats-1)-(IFNULL((SELECT capacity FROM xtbg_officecar_application WHERE id=a.id),0))-(IFNULL((SELECT SUM(capacity) FROM xtbg_officecar_log WHERE application_id=a.id),0)) as seats FROM xtbg_officecar_vihicleinfo WHERE id=a.vehicle_id) as oseats`;
      let _where =
        `start_address like '%${this.searchStartAddress}%' 
            AND end_address LIKE '%${this.searchEndAddress}%' 
            AND create_by in (SELECT id FROM xtbg_user WHERE name like '%${this.searchUser}%') 
            AND status=1 AND a.vehicle_id is not NULL ORDER BY id DESC LIMIT ${this.pageSize} offset ` +
        (this.currentPage - 1) * this.pageSize;

      //console.log("select "+_sfd+" from "+ _tableName+" where "+_where);
      const dataRes = await this.$db_select(
        this.$http,
        _tableName,
        _sfd,
        _where
      );

      const data = dataRes.length > 0 ? dataRes : [];
      let index = 0;
      this.bookings = data.map((item) => {
        item.uid = ++index;
        item.cstatus = "待发车";
        item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
        return item;
      });
      const totalRes = await this.$db_select(
        this.$http,
        tableBookingApplication,
        "count(*) as total",
        _where.split("ORDER BY")[0]
      );
      this.total = totalRes.length > 0 ? totalRes[0].total : 0;
    },
    async getVihicleinos() {
      const data = await this.$db_select(
        this.$http,
        "xtbg_officecar_vihicleinfo v",
        "id,seats,license_plate,type,price,IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'应急车') as user_name",
        "status=0"
      );
      this.vehicles = data;
    },
    chooseAddress(item) {
      this.mFormData.start_address = item.split("--")[0];
      this.mFormData.end_address = item.split("--")[1];
    },
    //始发地和目的地 互换
    huzhuan() {
      let temp = this.mFormData.start_address;
      this.mFormData.start_address = this.mFormData.end_address;
      this.mFormData.end_address = temp;
    },
    //打开申请用车
    openDrawer() {
      this.isDrawerVisible = true;
    },
    //提交 申请用车
    async submitApplication() {
      if (!this.mFormData.start_address.trim()) {
        this.$message({
          type: "error",
          message: "请填写出发地!",
        });
        return false;
      }
      if (!this.mFormData.end_address.trim()) {
        this.$message({
          type: "error",
          message: "请填目的地!",
        });
        return false;
      }

      if (!this.mFormData.vehicle_id) {
        this.$message({
          type: "error",
          message: "请选择默认申请用车信息!",
        });
        return false;
      }
      if (this.mFormData.capacity == 0) {
        this.$message({
          type: "error",
          message: "请填写乘车人数!",
        });
        return false;
      }
      if (!this.mFormData.reason.trim()) {
        this.$message({
          type: "error",
          message: "请填写用车事由!",
        });
        return false;
      }

      // 提交申请逻辑
      this.mFormData.create_by = localStorage.getItem("userid");
      this.mFormData.create_time = this.$FormatDateToYMDHMS(new Date(), true);
      this.mFormData.start_time = this.$FormatDateToYMDHMS(
        this.mFormData.start_time,
        true
      );
      //console.log('提交申请', this.mFormData);

      const insertRes = await this.$db_insert(
        this.$http,
        tableBookingApplication,
        this.mFormData
      );
      if (insertRes.affectedRows > 0) {
        const phonesRes = await this.$GetCdGlyUserPhones(this.$http);
        console.log(phonesRes)

        if (phonesRes.length > 0) {
          Promise.all(
            phonesRes.map(async (row) => {
              await this.$SendMSG(row.phone, "2275753");
            })
          );
        }

        this.$message({
          type: "success",
          message: "申请成功!",
        });
      } else {
        this.$message({
          type: "error",
          message: "申请失败!",
        });
      }
      this.isDrawerVisible = false;
    },
    saveDraft() {
      // 保存草稿逻辑
      console.log("保存草稿", this.formData);
      this.isDrawerVisible = false;
    },
    //判断是否是自己的申请用车记录
    isSelfApplication() {
      return this.currentBooking.create_by == localStorage.getItem("userid");
    },
    searchBookings() {
      // 搜索逻辑
    },
    //搭乘
    async editBooking(row) {
      //获取次车辆的座位数vehicle_id

    
      this.selected_row = row;


      const vehicleCapacity = await this.$db_select(
        this.$http,
        "xtbg_officecar_vihicleinfo",
        "(seats-1)-(IFNULL((SELECT capacity FROM xtbg_officecar_application WHERE id=" +
          this.selected_row.id +
          "),0))-(IFNULL((SELECT SUM(capacity) FROM xtbg_officecar_log WHERE application_id=" +
          this.selected_row.id +
          "),0)) as seats",
        "id=" + this.selected_row.vehicle_id
      );

      this.total_capacity =
        vehicleCapacity.length > 0 ? vehicleCapacity[0].seats : 0;

      //插入搭乘表
      this.nFormData.vehicle_id = this.selected_row.vehicle_id;
      this.nFormData.application_id = this.selected_row.id;
      this.nFormData.start_address = this.selected_row.start_address;
      this.nFormData.end_address = this.selected_row.end_address;
      this.nFormData.carinfo = this.selected_row.carinfo;

      this.nFormData.start_time = this.$FormatDateToYMDHMS(
        this.selected_row.start_time,
        true
      );
      this.nFormData.create_time = this.$FormatDateToYMDHMS(new Date(), true);
      this.nFormData.create_by = localStorage.getItem("userid");
      this.nFormData.status = 0;

      this.nFormData.capacity = row.oseats;
      this.isDrawerDcVisible = true;
    },
    //开始搭乘
    async submitDacheng() {
      if (this.nFormData.capacity > this.total_capacity) {
        this.$message({
          type: "error",
          message: "搭乘人数超出公务车可乘座位数!",
        });
        return false;
      }

      console.log("搭乘...");

      //搭乘之前 再次判断一次剩余座位
      const vehicleCapacity = await this.$db_select(
        this.$http,
        "xtbg_officecar_vihicleinfo",
        "(seats-1)-(IFNULL((SELECT capacity FROM xtbg_officecar_application WHERE id=" +
          this.selected_row.id +
          "),0))-(IFNULL((SELECT SUM(capacity) FROM xtbg_officecar_log WHERE application_id=" +
          this.selected_row.id +
          "),0)) as seats",
        "id=" + this.selected_row.vehicle_id
      );
     
      const total_seats = vehicleCapacity[0].seats;
      //获取当前的总乘人

      if (this.nFormData.capacity > total_seats) {
        this.total_capacity = total_seats;
        this.$message({
          type: "error",
          message: "搭乘人数超出公务车可乘座位数!",
        });

        return false;
      }

      delete this.nFormData.carinfo;

      
      //return false;
      delete this.nFormData.use_type;

      const insertDc = await this.$db_insert(
        this.$http,
        "xtbg_officecar_log",
        this.nFormData
      );

      const phoneRes = await this.$db_select(this.$http,"xtbg_user","phone","id="+this.selected_row.create_by);

      if (insertDc.affectedRows > 0) {
        if (phoneRes.length > 0) {
          await this.$SendMSG(phoneRes[0].phone, '2275847');
        }

        this.$message({
          type: "success",
          message: "搭乘成功，等待用车人审核",
        });
        this.isDrawerDcVisible = false;
        this.getBookings();
      }
    },
    //打开申请用车 对话框
    startApplication() {
      this.isDrawerVisible = true;
    },
    lookApplications() {},
    //获取 公务车信息 审核通过的
    async getBookings() {
      // 获取公务车信息
      // 假设数据为 bookings
      const data = await this.$db_select(
        this.$http,
        tableBookingApplication + " a ",
        `a.*,CONCAT(a.start_address,'-',a.end_address) as xingcheng,
                (SELECT CONCAT(v.license_plate,'(',v.type,')-',IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'应急车')) FROM xtbg_officecar_vihicleinfo v WHERE v.id=a.vehicle_id) as carinfo, 
                (SELECT name FROM xtbg_user WHERE id=a.create_by) as user_name,
                (SELECT (seats-1)-(IFNULL((SELECT capacity FROM xtbg_officecar_application WHERE id=a.id),0))-(IFNULL((SELECT SUM(capacity) FROM xtbg_officecar_log WHERE application_id=a.id),0)) as seats FROM xtbg_officecar_vihicleinfo WHERE id=a.vehicle_id) as oseats`,
        "a.status=1 and a.vehicle_id is not NULL order by id desc limit " +
          this.pageSize +
          " offset " +
          (this.currentPage - 1) * this.pageSize
      );

      this.total = (
        await this.$db_select(
          this.$http,
          tableBookingApplication,
          "count(*) as total",
          "status=1"
        )
      )[0].total;
      let index = 0;
      this.$nextTick(() => {
        this.bookings = data.map((item) => {
          item.uid = ++index;
          item.cstatus = "待发车";
          item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
          return item;
        });
        //console.log(this.bookings);
      });
    },
    // 翻页
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getBookings();
    },
    //申请历史翻页
    handleCurrentChange2(page) {
      this.currentPage2 = page;
      this.getApplicationHistorys();
    },
    //搭乘历史翻页
    handleCurrentChange3(page) {
      this.currentPage3 = page;
      this.getApplicationHistorys();
    },
    //获取申请记录
    async getApplicationHistorys() {
      // 获取公务车信息
      // 假设数据为 bookings
      const data = await this.$db_select(
        this.$http,
        tableBookingApplication + " a ",
        `a.*,CONCAT(a.start_address,'-',a.end_address) as xingcheng,
                (SELECT CONCAT(v.license_plate,'(',v.type,')-',IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'应急车')) FROM xtbg_officecar_vihicleinfo v WHERE v.id=a.vehicle_id) as carinfo, 
                (SELECT name FROM xtbg_user WHERE id=a.create_by) as user_name`,
        "create_by=" +
          localStorage.getItem("userid") +
          " order by id desc limit " +
          this.pageSize2 +
          " offset " +
          (this.currentPage2 - 1) * this.pageSize2
      );

      //console.log("记录", data);

      let index = 0;
      this.applicationHistory = data.map((item) => {
        item.uid = ++index;

        if (item.status == 0) {
          item.cstatus = "待审核";
        }
        if (item.status == 1) {
          item.cstatus = "待发车";
        }
        if (item.status == 2) {
          item.cstatus = "驳回";
        }
        if (item.status == 3) {
          item.cstatus = "已结束";
        }
        if (item.status == 4) {
          item.cstatus = "已评价";
        }

        item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
        return item;
      });
      this.total2 = (
        await this.$db_select(
          this.$http,
          tableBookingApplication,
          "count(*) as total",
          "create_by=" + localStorage.getItem("userid")
        )
      )[0].total;
      //console.log(this.total2);
    },
    //获取搭乘历史
    async getLogDcHistory() {
      //xtbg_officecar_log
      const data = await this.$db_select(
        this.$http,
        "xtbg_officecar_log a ",
        `a.*,CONCAT(a.start_address,'-',a.end_address) as xingcheng,
                (SELECT CONCAT(v.license_plate,'(',v.type,')-',IFNULL((SELECT name FROM xtbg_user WHERE id=v.user_id),'应急车')) FROM xtbg_officecar_vihicleinfo v WHERE v.id=a.vehicle_id) as carinfo,
                (SELECT name FROM xtbg_user WHERE id=(SELECT create_by FROM xtbg_officecar_application WHERE id=a.application_id)) as user_name`,
        "create_by=" +
          localStorage.getItem("userid") +
          " order by id desc limit " +
          this.pageSize3 +
          " offset " +
          (this.currentPage3 - 1) * this.pageSize3
      );

      let index = 0;
      this.logDcHistory = data.map((item) => {
        item.uid = ++index;

        if (item.status == 0) {
          item.cstatus = "待审核";
        }
        if (item.status == 1) {
          item.cstatus = "待发车";
        }
        if (item.status == 2) {
          item.cstatus = "驳回";
        }
        if (item.status == 3) {
          item.cstatus = "已结束";
        }
        if (item.status == 4) {
          item.cstatus = "已评价";
        }

        item.start_time = this.$FormatDateToYMDHMS(item.start_time, true);
        return item;
      });
      this.total3 = (
        await this.$db_select(
          this.$http,
          "xtbg_officecar_log",
          "count(*) as total",
          "create_by=" + localStorage.getItem("userid")
        )
      )[0].total;
      console.log(this.total3);
    },
    async cancelBooking(row) {
      const deleteRes = await this.$db_delete(
        this.$http,
        tableBookingApplication,
        "id=" + row.id
      );
      if (deleteRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "取消成功!",
        });
        this.getApplicationHistorys();
      }
    },

    searchBook(username) {
      this.$router.push({
        path: "/books",
        query: {
          name: username,
        },
      });
    },
  },
  mounted() {
    this.getBookings();
  },
};
</script>

<style scoped>
/* 添加样式 */
</style>
