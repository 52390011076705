import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    select_title:null,
    current_site_addr:null,
    loginwj:true,
    loginStatus:false,
    chooseYearMonth:'',
    
  },
  getters: {
  },
  mutations: {
    updateSelectedTitle(state,value){
      state.select_title = value
    },
    updateCurrentSiteAddr(state,value){
      state.current_site_addr = value
    },
    setWjLogin(state,value){
      state.loginwj= value;
    },
    setLoginStatus(state,value){
      state.loginStatus= value;
    },
    setChooseYearMonth(state,value){
      state.chooseYearMonth = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
