/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-30 14:49:20
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-28 17:30:57
 * @FilePath: \app_wb\src\api\system\sys_dict_data.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js'
//获取会议室列表
export function ListMeettinRooms(queryParam){
    return request('/system/dict/data/list',{
        method: "get",
        params:{
            pageSize:50,
            pageNum:1,
            dictType:"xtbg_meetting_rooms"
        }
      
    });
}
//视频会议室
export function ListMeettinVideoRooms(queryParam){
    return request('/system/dict/data/list',{
        method: "get",
        params:{
            pageSize:50,
            pageNum:1,
            dictType:"xtbg_room_videotypes"
        }
      
    });
}
//获取领导列表
export function ListLeaders(queryParam){
    return request('/system/dict/data/list',{
        method: "get",
        params:{
            pageSize:10,
            pageNum:1,
            dictType:"content_leader_list"
        }
      
    });
}