<template>
  <div class="container">
    <div class="header" style="width: 60%">
      <el-autocomplete
        v-model="searchQuery"
        :fetch-suggestions="querySearch"
        placeholder="危废名称"
        prefix-icon="el-icon-search"
        @select="handleSelect"
        :trigger-on-focus="false"
        class="search-input"
      />
      
        <button class="cart-button">
          <img
            src="../../assets/imgs/weihuapin/出库车图标.png"
            class="button-icon"
            alt="出库车图标"
          />
          <el-badge :value="17" style="margin-top: 0px;margin-right:10px;"><span style="height: 30px;padding-right: 7px;">出库车</span></el-badge>
          
        </button>

      <button class="export-button">
        <img
          src="../../assets/imgs/weihuapin/出库图标.png"
          class="button-icon"
          alt="出库图标"
        />
        出库
      </button>
    </div>
    <div class="items">
      <div class="item" v-for="item in filteredItems" :key="item.id">
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/危废名称图标.png"
            class="icon"
            alt="危废名称图标"
          /> -->
          <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;padding-right: 2px;
                "
                >危废名称</span
              >
         <span style="width: calc(100% - 85px);">
          {{ item.name }}
         </span>
        </p>
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/主要危害成分图标.png"
            class="icon"
            alt="主要危害成分图标"
          /> -->
          <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;padding-right: 2px;
                "
                > 危害成分</span>
         
          <span style="width: calc(100% - 85px);">
            {{ item.ingredient }}
         </span>
        </p>
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/产生研究组图标.png"
            class="icon"
            alt="产生研究组图标"
          /> -->
          <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;padding-right: 2px;
                "
                > 产生来源</span>
                <span style="width: calc(100% - 85px);">
                  {{ item.researchGroup }}
                </span>
        
        </p>
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/产生重量图标.png"
            class="icon"
            alt="产生重量图标"
          /> -->
          <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;padding-right: 2px;
                "
                > 产生重量</span>
         <span style="width: calc(100% - 85px);"> {{ item.weight }}</span>
        </p>
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/产生时间图标.png"
            class="icon"
            alt="产生时间图标"
          /> -->
          <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;padding-right: 2px;
                "
                > 产生时间 </span>
         <span style="width: calc(100% - 85px);">
          {{ item.productionDate }}
         </span>
        </p>
        <button class="export-item-button">
          <!-- <img
            src="../../assets/imgs/weihuapin/出库图标.png"
            class="icon"
            alt="出库图标"
          /> -->
          出库
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchQuery: "",
      filteredItems: [],
      items: [
        {
          id: 1,
          name: "麻药物, 药品麻药物, 药品9麻药物, ",
          ingredient: "磷酸盐缓冲液磷酸盐",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 2,
          name: "麻药物, 药品2",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 3,
          name: "麻药物, 药品3",
          ingredient: "磷酸盐缓冲液磷酸盐缓冲液磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 4,
          name: "麻药物, 药品4",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 5,
          name: "麻药物, 药品5",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 6,
          name: "麻药物, 药品6",
          ingredient: "磷酸盐磷酸盐缓冲液缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 7,
          name: "麻药物, 药品7",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 8,
          name: "麻药物, 药品8",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 9,
          name: "麻药物, 药品9",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 10,
          name: "麻药物, 药品10",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 11,
          name: "麻药物, 药品11",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 12,
          name: "麻药物, 药品12",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 13,
          name: "麻药物, 药品13",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 14,
          name: "麻药物, 药品14",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 15,
          name: "麻药物, 药品15",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
        {
          id: 16,
          name: "麻药物, 药品16",
          ingredient: "磷酸盐缓冲液",
          researchGroup: "协同进化组",
          weight: "500g",
          productionDate: "2024-06-01",
        },
      ],
    };
  },
  created() {
    this.filteredItems = this.items;
  },
  watch: {
    searchQuery(newQuery) {
      if (newQuery.length == 0) {
        this.filteredItems = this.items;
        return;
      }
      this.searchItems();
    },
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.items.map((item) => {
        return {
          value: item.name,
        };
      });
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      this.searchQuery = item.value;
      this.searchItems();
    },
    searchItems() {
      // const query = this.searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 14px;
  background:#f8f8f8;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  flex-grow: 1;
}

.search-bar {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
}
.cart-button {
  margin-left: 10px;
}
.cart-button,
.export-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #fafafa;
  color: #008500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cart-button:hover,
.export-button:hover {
  background-color: #e7f1eb;
  
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.item {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  background: #fff;

  box-shadow: 2px 2px 6px 4px rgba(0, 0, 0, 0.1);
 
  width: calc(25% - 8px); /* 根据需要调整这个值 */
  box-sizing: border-box;
  cursor: pointer;
  &:hover{
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  }

}

.item p {
  padding: 0px !important;
  margin: 0 !important;
  min-height: 30px;
  text-align: left;
  display: flex;

  &:first-child{
    background: #ffffff;
    height: 40px;
    margin-bottom: 10px !important;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:nth-child(n+2){
    height: 30px;
  }
  padding-left: 10px !important;



  display: flex;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
 
}
.button-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.export-item-button {
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #fafafa;
  color: rgb(34, 34, 34);
  border: none;
  border-radius: 4px;
  max-width: 80px;
  cursor: pointer;
  margin: 0px 5px 5px auto;
}

.export-item-button:hover {
  background-color: #e7f1eb;
 
}
</style>

