import { render, staticRenderFns } from "./yjzhexiao.vue?vue&type=template&id=52344efb&scoped=true"
import script from "./yjzhexiao.vue?vue&type=script&lang=js"
export * from "./yjzhexiao.vue?vue&type=script&lang=js"
import style0 from "./yjzhexiao.vue?vue&type=style&index=0&id=52344efb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52344efb",
  null
  
)

export default component.exports