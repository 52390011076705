/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-05-31 15:13:50
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-07-11 06:32:11
 * @FilePath: \app_wb\src\api\content\xtbg_user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js'
export function ListUser(username,password){
    return request('/content/user/list',{
        method: "get",
        
        params:{
            pageSize:1,
            pageNum:1,
            email:username,
            password:password
        }
    });
}
export function ListUser2(params){
    return request('/content/user/list',{
        method: "get",
        
        params:params
    });
}
export function UpdateUser(data){
    return request('/content/user',{
        method: "put",
        
        data:data
    });
}
export function getUser(id) {
    return request({
      url: '/content/user/' + id,
      
      method: 'get'
    })
  }
  
  export function getUserByEmail(email) {
    return request('/content/user/list',{
        method: "get",
        params:{
            pageSize:1,
            pageNum:1,
            email:email,
            
        }
    });
  }