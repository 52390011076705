<template>
  <div id="propose_table" class="flex-col f-15">
    <!-- 标题 -->
    <div class="padding-bottom-20 padding-top-20 f-22 font-weight-600">
      中国科学院西双版纳热带植物园<br />{{
        this.$congresConfig.propose_title
      }}职工代表大会提案表
    </div>
    <!-- 类别 -->
    <div class="flex padding-top-bottom-5">
      <div class="height-40 line-height-40 width-80">类别：</div>
      <div class="flex-1 flex align-items-center height-40 line-height-40">
        <el-radio class="flex-1" v-model="formData.type" label="单位建设"
          >单位建设</el-radio
        >
        <el-radio class="flex-1" v-model="formData.type" label="民主管理"
          >民主管理</el-radio
        >
        <el-radio class="flex-1" v-model="formData.type" label="职工福利"
          >职工福利</el-radio
        >
        <div class="flex align-items-center width-600">
          <el-radio v-model="formData.type" label="其他">其他</el-radio>
          <div class="flex-1 height-40 line-height-40">
            <el-input
              v-model="formData.type_review"
              placeholder="请输其他类型"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- 编号 填写日期-->
    <div
      class="flex margin-top-bottom-5"
      style="justify-content: space-between"
    >
      <div class="flex">
        <div class="height-40 line-height-40 width-80">编号：</div>
        <div>
          <el-input v-model="formData.num" placeholder="20240301001"></el-input>
        </div>
      </div>
      <div class="flex">
        <div class="height-40 line-height-40 width-80">填写日期：</div>
        <div class="height-40 line-height-40">
          <el-date-picker
            v-model="formData.proposal_date"
            type="date"
            placeholder="选择日期"
            format="yyyy年MM月dd日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <!-- 提案人 联系电话 -->
    <div class="flex">
      <!-- 提案人 -->
      <div
        class="flex-1 flex boder-right-1 boder-top-1 boder-left-1 boder-bottom-1 height-50 align-items-center"
      >
        <div class="width-80 height-50 line-height-50 boder-right-1">
          提案人
        </div>
        <div class="flex-1">
          <el-input v-model="formData.proposer" placeholder="提案人"></el-input>
        </div>
      </div>
      <!-- 联系电话 -->
      <div
        class="flex-1 flex boder-right-1 boder-top-1 boder-bottom-1 height-50 align-items-center"
      >
        <div class="width-80 height-50 line-height-50 boder-right-1">
          联系电话
        </div>
        <div class="flex-1">
          <el-input
            id="mobileInput"
            v-model="formData.p_phone"
            placeholder="联系电话"
          ></el-input>
        </div>
      </div>
    </div>
    <!-- 附议人 -->
    <div
      class="flex-1 flex boder-left-1 boder-right-1 boder-bottom-1 height-50 align-items-center"
    >
      <div class="width-80 height-50 line-height-50 boder-right-1">附议人</div>
      <div class="flex-1">
        <el-input
          v-model="formData.supporters"
          placeholder="附议人,至少两人或两人以上"
        ></el-input>
      </div>
    </div>
    <!-- 提案内容 -->
    <div class="flex">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 683px; padding: 0px 30px"
      >
        提<br />案<br />内<br />容
      </div>
      <div class="flex-1 flex-col">
        <!-- 案名 -->
        <div class="flex align-items-center">
          <div
            class="height-80 line-height-80 boder-right-1 boder-bottom-1"
            style="padding: 0px 30px"
          >
            案名
          </div>
          <div
            class="flex-1 height-80 line-height-80 boder-bottom-1 boder-right-1"
          >
            <el-input
              v-model="formData.title"
              style="font-size: 18px"
              placeholder="提案的题目，用简洁的文字说明提案要求解决什么问题，案名要和提案内容一致，比如“关于推进\解决\设立XXXX的建议”或“建议推进\解决\设立XXXX的提案”。一定要“一事一案”。"
            ></el-input>
          </div>
        </div>
        <!-- 案由 -->
        <div class="flex align-items-center">
          <div
            class="height-300 boder-bottom-1 boder-right-1 flex"
            style="align-items: center; padding: 0 30px"
          >
            案由
          </div>
          <div
            class="flex-1 height-300 boder-bottom-1 boder-right-1"
            style="position: relative"
          >
            <el-input
              id="input_reason"
              type="textarea"
              resize="none"
              :rows="9"
              v-model="formData.reason"
              placeholder="提案的理由，是解决问题的重要依据，要开门见山，可进行现状分析，介绍背景或趋势、相关政策文件以及存在的问题等，在充分进行调研的基础上，以事实和数据为依据进行说明。"
            ></el-input>
            <Zoom :textid="'input_reason'"></Zoom>
          </div>
        </div>
        <!-- 具体意见建议 -->
        <div class="flex align-items-center">
          <div
            class="boder-bottom-1 boder-right-1 flex"
            style="align-items: center; height: 300px; padding: 0 23px 0 22px"
          >
            具体意<br />见建议
          </div>
          <div
            class="flex-1 boder-bottom-1 boder-right-1"
            style="height: 300px; position: relative"
          >
            <el-input
              id="input_suggest"
              type="textarea"
              resize="none"
              :rows="9"
              v-model="formData.suggest"
              placeholder="该部分是提案的核心内容，也是提案能否被采纳的关键，必须针对案由反映的问题，提出解决问题的主张和办法，提出的建议要力求具体可行，实事求是，具有则针对性、可操作性。"
            ></el-input>
            <Zoom :textid="'input_suggest'"></Zoom>
          </div>
        </div>
      </div>
      <!-- 提案组审核意见 -->
    </div>
    <div class="flex" v-show="true">
      <div
        class="width-20 boder-left-1 boder-right-1 boder-bottom-1 flex align-items-center"
        style="height: 343px; padding: 0px 30px"
      >
        提<br />案<br />组<br />审<br />核<br />意<br />见
      </div>
      <div
        class="flex-1 flex-col width-20 boder-right-1 boder-bottom-1"
        style="height: 343px"
      >
        <!-- 提案状态 -->
        <div class="height-40 flex boder-bottom-1">
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio v-model="formData.t_result" label="立案">立案</el-radio>
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio v-model="formData.t_result" label="退回重提"
              >退回重提</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio v-model="formData.t_result" label="不予立案"
              >不予立案</el-radio
            >
          </div>
          <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio v-model="formData.t_result" label="转意见处理"
              >转意见处理</el-radio
            >
          </div>
          <!-- <div
            class="flex-1 boder-right-1 height-40 flex align-items-center justify-content-center"
          >
            <el-radio v-model="formData.t_result" label="其他">其他</el-radio>
          </div> -->
        </div>
        <div class="boder-bottom-1">
          <el-input
            type="textarea"
            resize="none"
            :rows="2"
            v-model="formData.t_review"
            placeholder="请说明以上选择原因"
          ></el-input>
        </div>
        <div class="flex" style="padding: 5px 0 0 5px; flex-wrap: wrap">
          <div
            class="height-40 line-height-40"
            style="font-size: 18px; text-indent: 2em"
          >
            建议由
          </div>
          <div>
            <el-select
              v-model="formData.suggest_dept"
              filterable
              clearable
              placeholder="请选择部门"
              style="min-width: 200px"
            >
              <el-option
                v-for="item in zb_deptOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="height-40 line-height-40" style="font-size: 18px">
            主办，
          </div>
          <div>
            <el-select
              v-model="formData.suggest_dept_other"
              filterable
              clearable
              placeholder="请选择部门"
              :multiple="true"
            >
              <el-option
                v-for="item in xb_deptOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="height-40 line-height-40" style="font-size: 18px">
            协办，并于
          </div>
          <div>
            <el-date-picker
              v-model="formData.reply_time"
              type="date"
              placeholder="选择日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="height-40 line-height-40" style="font-size: 18px">
            时间前作出书面答复。
          </div>
        </div>
        <div class="flex" style="justify-content: space-between">
          <div></div>
          <div>
            <el-date-picker
              v-model="formData.t_date"
              type="date"
              placeholder="选择日期"
              format="yyyy年MM月dd日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>

    <div
      class="flex-1 flex boder-left-1 boder-right-1 boder-bottom-1 height-50 align-items-center"
    >
      <div class="width-80 height-50 line-height-50 boder-right-1">备注</div>
      <div class="flex-1">
        <el-input v-model="formData.remark" placeholder="备注"></el-input>
      </div>
    </div>

    <div class="flex padding-top-10">
      <div class="flex-1"></div>
      <div class="flex">
        <el-button
          @click="saveFormData"
          style="margin-left: 5px; background: #25834b; color: #fff"
          >提交</el-button
        >
        <el-button @click="back">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { dbquery, getDataTotal } from "../sys/proposeRoleUser.js";
import { Message } from "element-ui";
import Zoom from "../tools/zoom.vue";
import {
  generateDateSequence,
  getMaxId,
  getCurrentDate,
  formatDate,
  getCurrentDateTime,
  extractNonEmptyValues,
  addQuotesToObjectProperties,
  decryptData,
  objectToSqlSet,
} from "../../../utils/common.js";
var that = null;
export default {
  components: {
    Zoom,
  },
  data() {
    return {
      zb_deptOptions: [],
      xb_deptOptions: [],

      //职工代表
      is_zgdb: false,

      //提案组
      is_taz: false,
      //分管领导
      is_yld: false,
      //主办部门
      is_zbdept: false,

      formData: {},
      propseID: "",
      from: "",
    };
  },
  async created() {
    that = this;

    //承办部门答复时间

    this.getZbDepts();
    this.formData["t_userid"] = localStorage.getItem("userid");
  },
  methods: {
    async dbquery(sqlStr) {
      return dbquery(this.$http,  sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },
    async getZbDepts() {
      const sqlStr =
        "select dept_id from xtbg_congress_role_user where role_id=" +
        this.$congresConfig.congressRole.roleid_cbbm;
      const deptData = await this.dbquery(sqlStr);
      const dettIds = deptData.map((row) => row.dept_id);

      const sqlStr_dept =
        "select dept_id,dept_name from sys_dept where dept_id in (" +
        dettIds +
        ")";
      const zbDeptData = await this.dbquery(sqlStr_dept);
      this.xb_deptOptions = this.zb_deptOptions = zbDeptData.map((row) => ({
        label: row.dept_name,
        value: row.dept_name,
      }));
    },
    validateMobileNumber() {
      var regex = /^1[3-9]\d{9}$/;
      if (regex.test(this.formData.tel)) {
        console.log("Valid mobile number.");
      } else {
        console.log("Invalid mobile number.");
        //alert("请输入正确的手机号码");
        this.$message({
          showClose: true,
          message: "请输入正确的手机号码",
          type: "error",
          offset: 200,
        });
      }
    },
    isValidInput() {
      //判断手机号
    },
    async updatePropose(formData, msg) {
      let myNewFormData = objectToSqlSet(formData);

      // 保存 修改
      const updateProposeSql =
        "update xtbg_congress_propose set " +
        myNewFormData +
        " where id=" +
        this.propseID;
      const res = await this.dbquery(updateProposeSql);

      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: msg + "成功",
          type: "success",
          duration: 1000,
          offset: 200,
        });
      } else {
        this.$message({
          showClose: true,
          message: msg + "失败",
          type: "fail",
          duration: 1000,
          offset: 200,
        });
      }

      this.back();
    },
    async saveFormData() {
      if (this.formData.t_result == null) {
        this.$message({
          showClose: true,
          message: "请勾选审查结果",
          type: "error",
          offset: 200,
        });
        return false;
      }
      if (
        this.formData.t_result == "立案" &&
        this.formData.suggest_dept == null
      ) {
        this.$message({
          showClose: true,
          message: "请选择主办部门",
          type: "error",
          offset: 200,
        });
        return false;
      }

      // if (this.formData.reply_time == null) {
      //   this.$message({
      //     showClose: true,
      //     message: "请选择答复时间",
      //     type: "error",
      //     offset: 200,
      //   });
      //   return false;
      // }

      let mystatus = "";

      switch (this.formData.t_result) {
        case "立案":
          mystatus = "已审批";
          this.formData.l_opinion = "同意立案";
          break;
        case "退回重提":
          mystatus = "退回重提";

          break;
        case "不予立案":
          mystatus = "不予立案";
          this.formData.l_opinion = "同意不予立案";
          break;
        case "转意见处理":
          mystatus = "转意见处理";
          this.formData.l_opinion = "同意转意见处理";
          break;
      }
      if (this.formData.suggest_dept_other) {
        console.log(this.formData.suggest_dept_other);
        this.formData.suggest_dept_other =
          this.formData.suggest_dept_other.join(",");
      }

      let formData = {
        type: this.formData.type,
        status: "处理中",
        t_userid: localStorage.getItem("userid"),
        t_result: this.formData.t_result,
        t_date: getCurrentDate(),
        t_review: this.formData.t_review,
        suggest_dept: this.formData.suggest_dept,
        reply_time:
          this.formData.reply_time == null
            ? this.formData.reply_time
            : formatDate(this.formData.reply_time),
        suggest_dept_other: this.formData.suggest_dept_other,
        l_userid: this.formData.l_userid,
        l_opinion: this.formData.l_opinion,
        l_sign: this.formData.l_sign,
        l_sign_date: getCurrentDate(),
        remark: this.formData.remark,
      };

      // console.log(formData);

      await this.updatePropose(formData, "会审");

      //开始提交
    },
    back() {
      this.$router.push({
        path: "/proposal_meetting/taxt_now",
      });
    },
    //根据userid 判断他是属于什么角色
    async getUserRoleIdForCongres() {
      const sqlStr =
        "select role_id from xtbg_congress_role_user where user_id=" + user_id;
      const userRole = await this.dbquery(sqlStr);

      const currentUserRoles = userRole.map((row) => row.role_id);
      //判断是什么角色 然后确定显示什么内容
    },

    async getDataById(rowId) {
      const selectSql = "select * from xtbg_congress_propose where id=" + rowId;
      const res = await this.$dbquery(this.$http, selectSql);
      this.formData = res[0];
      // console.log(this.formData);

      //  //把领导审批数据初始化
      this.$set(this.formData, "t_date", getCurrentDate());

      this.$set(this.formData, "l_sign_date", getCurrentDate());

      this.$set(this.formData, "l_opinion", "同意立案");

      //console.log(this.formData);
      // this.$set(
      //   this.formData,
      //   "suggest_dept_other",
      //   this.suggest_dept_other == null
      //     ? ""
      //     : this.formData.suggest_dept_other.indexOf(",") > -1
      //     ? this.formData.suggest_dept_other.split(",")
      //     : []
      // );
      if (this.formData.suggest_dept_other.indexOf(",") > -1) {
        this.formData.suggest_dept_other =
          this.formData.suggest_dept_other.split(",");
      } else {
        this.formData.suggest_dept_other =
          this.formData.suggest_dept_other.split(",");
      }

      const { name, id } = await this.getLeaderBySelectMainDept(
        this.formData.num
      );

      this.$set(this.formData, "l_userid", id);
      this.$set(this.formData, "l_sign", name);

      //
    },
    //根据选中的主办部门获取对应的分管领导
    async getLeaderBySelectMainDept(pNum) {
      const leaderSql =
        "SELECT `name`,id FROM xtbg_user WHERE id=(SELECT user_id FROM xtbg_congress_leader_dept WHERE dept_id =(SELECT dept_id FROM sys_dept WHERE dept_name = (SELECT suggest_dept FROM xtbg_congress_propose WHERE num='" +
        pNum +
        "')))";
      const resLeader = await this.$dbquery(this.$http, leaderSql);
      if (resLeader.length > 0) {
        return resLeader[0];
      } else {
        return "";
      }
    },
    scrollToTop() {
      // 使用window对象的scrollTo方法
      window.scrollTo({
        top: 0, // 目标位置（顶部）
        behavior: "smooth", // 平滑滚动
      });
    },
  },
  async mounted() {
    this.propseID = decryptData(this.$route.query.id);
    this.from = this.$route.query.from;
    await this.getDataById(this.propseID);
    this.scrollToTop();
  },
  beforeDestroy() {
    let inputElement = document.getElementById("mobileInput");
    if (inputElement) {
      // 请确保在组件销毁前移除事件监听器，防止内存泄漏
      inputElement.removeEventListener("blur", this.handleInputBlur);
    }
  },
};
</script>
