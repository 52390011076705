<template>
  <div class="bny_footer">
    <div class="bny_footer-left">
      <div class="bny_footer-left-logo">
        <img :src="foot_logo" alt="" />
        <div>
          <el-select
            v-model="yqvalue"
            placeholder="友情链接"
            @change="GotoLink"
          >
            <el-option
              v-for="item in yqoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div style="clear: both"></div>
      <div class="bny_footer-phone">
        电话：{{ copyright.copyrightTel }}，旅游咨询：{{
          copyright.copyrightTripTel
        }}
      </div>
      <div class="bny_footer-location">{{ copyright.copyrightLocation }}</div>
      <div class="bny_footer-youbian">
        邮政编码：{{ copyright.copyrightPostalcode }}
      </div>
      <div class="bny_footer-copywrite">{{ copyright.copyrightInfo }}</div>
    </div>
    <div class="bny_footer-right">
      <ul>
        <li v-for="(item, index) in footer_ercodes" :key="index">
          <img :src="item.url" alt="" />
          <div>{{ item.title }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
import { ListWebsiteLinks } from "@/api/content/xtbg_website_links.js";
import { ListWebsiteQrCode } from "@/api/content/xtbg_website_qrcode.js";
import { ListWebsiteInfo } from "@/api/content/xtbg_website_info.js";
export default {
  name: "bny-footer",
  data() {
    return {
      copyright: {
        copyrightTel: "",
        copyrightTripTel: "",
        copyrightLocation: "",
        copyrightPostalcode: "",
        copyrightInfo: "",
      },
      foot_logo: "",

      yqoptions: [],
      yqvalue: "",

      footer_ercodes: [],
      queryPramas: {
        pageSize: 10,
        pageNum: 1,
      },
    };
  },
  created() {
    this.getYqLinks();
    this.getQrCode();
    this.getWebsiteInfo();
  },
  methods: {
    GotoLink() {
      this.yqoptions.map((row) => {
        if (row.value == this.yqvalue) {
          window.open(row.url);
        }
      });
    },
    getWebsiteInfo() {
      this.footer_ercodes = [];
      ListWebsiteInfo(this.queryPramas).then((res) => {
        this.copyright = res.data.rows[0];
        this.foot_logo = this.$api_url + res.data.rows[0].logoThree;
      });
    },
    getQrCode() {
      this.footer_ercodes = [];
      ListWebsiteQrCode(this.queryPramas).then((res) => {
        let rows = res.data.rows;
        let nrows = rows.filter((row, index) => {
          return index > rows.length - 5;
        });
        nrows.map((row) => {
          row.url = this.$api_url + row.url;

          this.footer_ercodes.push(row);
        });
      });
    },
    getYqLinks() {
      this.yqoptions = [];
      this.queryPramas.location = "网站底部";
      ListWebsiteLinks(this.queryPramas).then((res) => {
        res.data.rows.map((row) => {
          this.yqoptions.push({
            value: row.name,
            label: row.name,
            url: row.url,
          });
        });
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.bny_footer {
  text-align: left;
  height: 200px;
  width: calc(100% - 40px);
  padding: 0px 20px;
  margin-top: 20px;
  background: url("../assets/imgs/footer.png") no-repeat;
  background-size: cover;

  .bny_footer-left {
    float: left;
    width: calc(100vw - 580px);
    height: 180px;
    padding: 10px 0px;
    .bny_footer-left-logo {
      img {
        float: left;
        width: 200px;
        height: 50px;
      }
      div {
        float: left;
        padding-top: 3px;
        margin-left: 10px;
      }
    }
    .bny_footer-phone {
      background: url("../assets/imgs/phone_light.png") no-repeat;
      background-size: 20px 20px;
      background-position-x: 5px;
      background-position-y: 5px;
      height: 30px;
      line-height: 30px;
      padding-left: 40px;
      color: #fff;
      margin-top: 10px;
      font-size: 14px;
    }
    .bny_footer-location {
      background: url("../assets/imgs/location.png") no-repeat;
      background-size: 20px 20px;
      background-position-x: 5px;
      background-position-y: 5px;
      height: 30px;
      line-height: 30px;
      padding-left: 40px;
      color: #fff;
      font-size: 14px;
    }
    .bny_footer-youbian {
      background: url("../assets/imgs/layers.png") no-repeat;
      background-size: 20px 20px;
      background-position-x: 5px;
      background-position-y: 5px;
      height: 30px;
      line-height: 30px;
      padding-left: 40px;
      color: #fff;
      font-size: 14px;
    }
    .bny_footer-copywrite {
      font-size: 14px;
      color: #f2f2f2;
      padding-left: 5px;
    }
  }
  .bny_footer-right {
    float: left;
    width: 500px;
    height: 200px;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        float: left;
        width: 100px;
        height: 140px;
        margin: 30px 10px;
        img {
          height: 100px;
          width: 100px;
        }
        div {
          height: 40px;
          text-align: center;
          font-size: 16px;
          color: #f2f2f2;
        }
      }
    }
  }
}
</style>