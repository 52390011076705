<template>
  <div>
    <!-- 搜索部分 -->
    <div class="PastSsession-Search">
      <el-autocomplete
        v-model="searchinput"
        :fetch-suggestions="querySearch"
        placeholder="请输入大会名称"
        :trigger-on-focus="false"
        clearable
      >
        <el-button slot="append" icon="el-icon-search" @click="filterData"
          >筛选</el-button
        >
      </el-autocomplete>
    </div>
    <!-- 信息部分 -->
    <div class="PastSsession-message" style="padding-top: 20px">
      <data-Statistics
        :dataObject="item"
        v-for="(item, index) in filteredData"
        :key="index"
      ></data-Statistics>
    </div>
  </div>
</template>
<script>
import dataStatistics from "./taml/dataStatistics.vue";
export default {
  name: "PastWorksession",
  components: {
    dataStatistics,
  },
  data() {
    return {
      searchinput: "",
      originalData: [
        {
          id: 1,
          title: "六届二次职工代表大会",//几届大会
          time: "2023年3月27日",//时间
          allproposal: 22,//总接收的提案
          Implement: 11,//落实或正在落实的提案
          filenumber: 19,//立案数量
          Implementratio: "",//落实比例
          UnitConstruction: 6,//单位建设
          DemocracyControl: 3,//民主管理
          EmployeeBenefits: 7,//职工福利
          Miscellaneous: 3,//其他方面
          file: "https://in.xtbg.ac.cn:3103/62.pdf",
        },
        {
          id: 2,
          title: "六届一次职工代表大会",
          time: "2022年3月31日",
          allproposal: 34,
          Implement: 26,
          filenumber: 34,
          Implementratio: "",
          UnitConstruction: 10,
          DemocracyControl: 14,
          EmployeeBenefits: 7,
          Miscellaneous: 3,
          file: "https://in.xtbg.ac.cn:3103/61.pdf",
        },
        {
          id: 3,
          title: "五届五次职工代表大会",
          time: "2019年3月27日",
          allproposal: 24,
          Implement: 17,
          filenumber: 24,
          Implementratio: "71%",
          UnitConstruction: 0,
          DemocracyControl: 0,
          EmployeeBenefits: 0,
          Miscellaneous: 0,
          file: "",
        },
      ],
      filteredData: [], // 保存筛选后的数据
    };
  },

  created() {
    this.originalData = this.originalData.map((row) => {
      return {
        id: row.id,
        title: row.title,
        time: row.time,
        allproposal: row.allproposal,
        Implement: row.Implement,
        filenumber: row.filenumber,
        Implementratio: this.luoShiBie(row),
        UnitConstruction: row.UnitConstruction,
        DemocracyControl: row.DemocracyControl,
        EmployeeBenefits: row.EmployeeBenefits,
        Miscellaneous: row.Miscellaneous,
        file: row.file,
      };
    });
  },
  methods: {
    luoShiBie(obj) {
      var percentage = Math.round((obj.Implement / obj.allproposal) * 100);
      return percentage + "%";
    },
    // 输入框搜索内容
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value
          .toLowerCase()
          .includes(queryString.toLowerCase());
      };
    },
    loadAll() {
      // 建议列表

      return this.originalData.map((item) => ({
        value: item.title,
      }));
    },
    filterData() {
      // 筛选数据方法
      const selectedValue = this.searchinput.trim();
      if (selectedValue !== "") {
        this.filteredData = this.originalData.filter(
          (data) => data.title === selectedValue
        );
      } else {
        this.filteredData = [...this.originalData];
      }
    },
  },
  mounted() {
    this.restaurants = this.loadAll();
    this.filteredData = [...this.originalData]; // 初始化时显示全部数据
  },
};
</script>

<style lang="less" scoped>
.PastSsession-Search {
  display: flex;
  margin-left: 15px;

  ::v-deep .el-input {
    &-group {
      border: 1px solid #25834b;
      border-radius: 4px;
      height: 30px;
      width: 286.19px;

      &__append,
      .el-input-group__prepend {
        background: #25834b;
        font-size: 12px;
        color: white;
        border: 0;
        border-radius: 0;
        padding: 0 20px;
      }
    }

    &__inner {
      height: 30px;
      width: 286.19px;

      &:focus {
        border-color: transparent;
      }
    }

    &__suffix {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.PastSsession-message {
  width: 1080px;
  display: flex;
  flex-wrap: wrap;
}
</style>
