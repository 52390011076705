<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 14:00:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-11 16:45:15
 * @FilePath: \app_wb\src\views\News.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny_news-list">
      <div class="bny_news-list-left">
        <div class="bny_news-list-left-top" style="font-size: 22px">职代会</div>
        <div class="bny_news-list-left-items">
          <ul class="list_news-items" id="list_zdh">
            <li
              v-for="(item, index) in list_items"
              :key="index"
              @click="clickListItem($event, item.name, item.url)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bny_news-list-right">
        <div class="bny_news-list-right-top">
          您当前的位置：<span>{{ current_addr }}</span>
          <div style="float: right" v-show="false">
            <ul>
              <li>
                发文部门：<el-autocomplete
                  class="inline-input"
                  v-model="dept"
                  :fetch-suggestions="querySearchBm"
                  placeholder="请输入内容"
                  @select="handleSelectBm"
                  style="width: 50%"
                ></el-autocomplete>
              </li>
              <li>
                时间：<el-date-picker
                  v-model="choosedate"
                  type="date"
                  placeholder="选择日期时间"
                  align="right"
                  style="width: 54%"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </li>
              <li>
                标题：<el-input
                  placeholder="请输入关键字搜索"
                  v-model="serachtext"
                  style="width: 70%"
                >
                  <template slot="append">搜索</template>
                </el-input>
              </li>
            </ul>
          </div>
        </div>
        <div class="bny_news-list-right-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
    <mFooter></mFooter>
  </div>
</template>

<script>

import mFooter from "../../components/mFooter.vue";
import { dbquery, getDataTotal } from "./sys/proposeRoleUser.js";

export default {
  inject: ["reload"],
  components: {
    mFooter,
  },

  data() {
    return {
      total: 0,
      pageSize: 20,
      current_title: "",
      current_page: 1,
      current_addr: "",
      item_bg_image: require("../../assets/imgs/news/right.png"),
      item_active_bg_image: require("../../assets/imgs/news/right1.png"),

      list_items: [
        {
          name: "大会情况",
          url: "/proposal_meetting/dhqk",
        },
        {
          name: "大会决议",
          url: "/proposal_meetting/dahjy",
        },
        {
          name: "历届职代会",
          url: "/proposal_meetting/taml",
        },
        {
          name: "职代会名单",
          url: "/proposal_meetting/chmd",
        },
        {
          name: "常用下载",
          url: "/proposal_meetting/cyxz",
        },
        {
          name: "提案系统",
          url: "#",
        },
      ],
      list_content_items: [],
      list_data: [],
      dept: "",
      bm_list: [
        {
          value: "部门名称",
        },
        {
          value: "部门名称",
        },
        {
          value: "部门名称",
        },
      ],
      choosedate: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      serachtext: "",
    };
  },
  computed: {},

  watch: {
    $route: function (to, from) {
      // console.log(this.$route.query.name.indexOf("提案"));
      var _this = this;
      setTimeout(() => {
        if (to.query) {
          if (typeof to.query.title != "undefined") {
            _this.current_title = localStorage.getItem("current_title");
            _this.current_addr =
              "首页" +
              ">" +
              localStorage.getItem("current_title") +
              ">" +
              to.query.title;
          }
        }
        if (to.path.indexOf("taz") > -1) {
          _this.current_addr = "首页>提案系统>当前提案>审查提案";
        }
        if (to.path.indexOf("taxt_now") > -1) {
          _this.current_addr = "首页>提案系统>当前提案";
        }
        if (to.path.indexOf("propose_table") > -1) {
          _this.current_addr = "首页>提案系统>我的提案>开始提案";
        }
        if (to.path.indexOf("propose_update") > -1) {
          _this.current_addr = "首页>提案系统>我的提案>修改提案";
        }
        if (to.path.indexOf("propose_view") > -1) {
          _this.current_addr = "首页>提案系统>查看提案";
        }

        if (to.path.indexOf("propose_table") > -1) {
          _this.current_addr = "首页>提案系统>我的提案>开始提案";
        }
        if (to.path.indexOf("propose_fyr") > -1) {
          _this.current_addr = "首页>提案系统>提案附议";
        }

        if (to.path.indexOf("propose_taz") > -1) {
          _this.current_addr = "首页>提案系统>提案组审查";
        }
        if (to.path.indexOf("propose_fgld") > -1) {
          _this.current_addr = "首页>提案系统>园领导审批";
        }
        if (to.path.indexOf("propose_zgbm") > -1) {
          _this.current_addr = "首页>提案系统>主办部门处理";
        }
        if (to.path.indexOf("propose_fkyj") > -1) {
          _this.current_addr = "首页>提案系统>提案反馈";
        }
        if (to.path.indexOf("taxt_my") > -1) {
          _this.current_addr = "首页>提案系统>我的提案";
        }
        if (to.path.indexOf("zbbm") > -1) {
          _this.current_addr = "首页>提案系统>当前提案>处理提案";
        }
        if (to.path.indexOf("yld") > -1) {
          _this.current_addr = "首页>提案系统>当前提案>领导审批";
        }
        if (to.path.lastIndexOf("fk") > -1) {
          _this.current_addr = "首页>提案系统>当前提案>提案反馈";
        }
      }, 200);

      this.$nextTick(function () {
        try {
          this.selectItem(this.$route.query.name);
          this.initeData();
        } catch (err) {}
      });
    },
  },
  methods: {
    async dbquery(sqlStr) {
      return dbquery(this.$http,  sqlStr);
    },
    async getDataTotal(tName) {
      return getDataTotal(this.$http, this.$db_dynamic_url, tName);
    },
    querySearchBm(queryString, cb) {
      var restaurants = this.bm_list;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelectBm() {},
    initeData() {
      this.list_data = [];
      this.list_content_items = [];
      for (
        let i = (this.current_page - 1) * this.pageSize;
        i < this.current_page * this.pageSize;
        i++
      ) {
        this.list_content_items.push({
          id: i + 1,
          title: this.current_title + "-" + this.list_data[i].title + (i + 1),

          content: this.list_data[i].content,
          date: this.list_data[i].date,
          dept: this.list_data[i].dept,
        });
      }
      this.total = this.list_data.length;
    },
    currentChange(cpage) {
      this.current_page = cpage;
      this.list_content_items = [];
      //(cpage-1)*this.total;

      for (
        let i = (this.current_page - 1) * this.pageSize;
        i < this.current_page * this.pageSize;
        i++
      ) {
        if (typeof this.list_data[i] != "undefined") {
          this.list_content_items.push({
            id: i + 1,
            title: this.current_title + "-" + this.list_data[i].title,
            img: this.list_data[i].img,
            content: this.list_data[i].content,
          });
        }
        // console.log(this.list_data[i]);
      }
    },
    clickListItem(e, name, url) {
      var _this = this;
      var $event = e;

      setTimeout(() => {
        localStorage.setItem("current_title", name);
        _this.activeEl($event);
        _this.current_addr = "首页>" + name;
        _this.current_title = name;
      }, 50);
      if (name != "提案系统") {
        this.$nextTick(function () {
          _this.$router
            .push({
              path: url,
            })
            .catch((e) => {});
        });
      }

      //this.selectItem(item.name);

      // this.initeData();
    },
    activeEl(e) {
      let parentEL = e.target.parentElement;

      let lis = parentEL.querySelectorAll("li");
      for (let i = 0; i < lis.length; i++) {
        let mli = lis[i];
        mli.style.background = "url('" + this.item_bg_image + "') no-repeat";
        mli.style.backgroundPosition = "right center";
        mli.style.backgroundPositionX = "250px";
        mli.style.backgroundSize = "16px 16px";
        mli.style.color = "#000";
      }

      let current_li = e.target;
      current_li.style.background =
        "url('" + this.item_active_bg_image + "') no-repeat #519f6d";
      current_li.style.backgroundPosition = "right center";
      current_li.style.backgroundPositionX = "250px";
      current_li.style.backgroundSize = "16px 16px";
      current_li.style.color = "#fff";

      // this.current_addr = "首页>" + e.currentTarget.innerText;
    },
    selectItem(name) {
      let mlis = document
        .getElementsByClassName("list_news-items")[0]
        .querySelectorAll("li");
      for (let i = 0; i < mlis.length; i++) {
        let current_li = mlis[i];
        this.current_title = name;
        this.current_addr = "首页>" + name;

        current_li.style.background =
          "url('" + this.item_bg_image + "') no-repeat";

        current_li.style.backgroundPosition = "right center";
        current_li.style.backgroundPositionX = "250px";
        current_li.style.backgroundSize = "16px 16px";
        current_li.style.color = "#000";

        if (current_li.innerText == name) {
          this.current_title = name;
          this.current_addr = "首页>" + name;

          current_li.style.background =
            "url('" +
            this.item_active_bg_image +
            "') no-repeat rgb(81, 159, 109)";

          current_li.style.backgroundPosition = "right center";
          current_li.style.backgroundPositionX = "250px";
          current_li.style.backgroundSize = "16px 16px";
          current_li.style.color = "#fff";
        }
      }
    },
    getRnd(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    showDetail(title) {
      //打开详细内容页面
      //console.log(title);

      this.$store.commit("updateSelectedTitle", this.current_title);

      this.$router.push({
        path: "/newsdetail",
        query: {
          title: this.current_title,
          showTitle: title,
        },
      });
    },
    createChildDiv(text, url) {
      let that = this;
      let div_child = document.createElement("div");
      div_child.style.cssText =
        "color: #000; height: 50px; line-height: 50px; text-align: right; border-bottom: 1px solid #ddd; padding-right: 80px; cursor: pointer;";
      div_child.innerText = text;

      div_child.addEventListener("click", function (event) {
        //console.log(event.target.innerText);
        that.$nextTick(() => {
          setTimeout(() => {
            that.current_addr = "首页>提案系统>" + event.target.innerText;
          }, 200);
        });

        div_child.parentNode.childNodes.forEach((div) => {
          div.style.background = "#fff";
          div.style.color = "#000";
        });

        that.$nextTick(() => {
          div_child.style.background = "#25834B";
          div_child.style.color = "#ffffff";
        });

        that.$router.push({
          path: url,
        });
        event.stopPropagation();
      });

      // div_child.addEventListener("mouseover", function () {
      //   this.style.color = "#008500";
      // });

      // div_child.addEventListener("mouseout", function () {
      //   this.style.color = "#000";
      // });

      return div_child;
    },
    //根据userid 判断他是属于什么角色
    async getUserRoleIdForCongres() {
      const user_id = localStorage.getItem("userid");
      
      const userRole = await this.$db_select(this.$http,"xtbg_congress_role_user","role_id","user_id=" + user_id)

      const currentUserRoles = userRole.map((row) => row.role_id);
      return currentUserRoles;
      //判断是什么角色 然后确定显示什么内容
    },
    async getDeptLeaders() {
      const res = await this.dbquery(
        "SELECT leader FROM sys_dept WHERE leader !=''"
      );
      return res.map((row) => row.leader);
    },
  },

  async mounted() {
    // console.log(this.$route);
    //window.location.reload();
    var _this = this;
    if (this.$route.path.indexOf("dhqk") > -1) {
      setTimeout(() => {
        _this.selectItem("大会情况");
        _this.current_addr = "首页>大会情况";
      }, 200);
    }
    _this.$nextTick(function () {
      _this.$router
        .push({
          path: "/proposal_meetting/dhqk",
        })
        .catch((e) => {});
    });

    let that = this;

    // 提案系统
    let list_zdh = document.getElementById("list_zdh");
    if (list_zdh != null) {
      let li_zdhs = list_zdh.querySelectorAll("li");

      if (li_zdhs) {
        try {
          let last_li = li_zdhs[li_zdhs.length - 1];
          let li_div = document.createElement("div");
          li_div.className = "bny_news-list-left-items-li-div";
          last_li.appendChild(li_div);

          for (let i = 0; i < li_zdhs.length - 1; i++) {
            li_zdhs[i].onclick = function () {
              li_div.innerHTML = "";
            };
          }
          //console.log(that.$congresConfig.congressRole.roleid_gly);
          //当前用户的角色 组
          let curentRoles = await that.getUserRoleIdForCongres();

          last_li.addEventListener("click", async () => {
            li_div.innerHTML = "";
            if (
              //职工代表
              curentRoles.includes(
                that.$congresConfig.congressRole.roleid_zgdb
              ) ||
              //提案组
              curentRoles.includes(
                that.$congresConfig.congressRole.roleid_taz
              ) ||
              //园领导
              curentRoles.includes(
                that.$congresConfig.congressRole.roleid_yld
              ) ||
              //主办部门 负责人
              curentRoles.includes(
                that.$congresConfig.congressRole.roleid_cbbm
              ) ||
              (await this.getDeptLeaders()).includes(
                localStorage.getItem("username")
              )
            ) {
              //职工代表 查看 附议 导出
              // 提案组 查看 审查 导出
              // 分管(园)领导 查看 审批 导出
              //  主办部门负责人  查看 处理

              li_div.appendChild(
                that.createChildDiv("当前提案", "/proposal_meetting/taxt_now")
              );
            } else {
              li_div.appendChild(
                that.createChildDiv("当前提案", "/proposal_meetting/taxt_now")
              );

              li_div.appendChild(
                that.createChildDiv(
                  "历史提案",
                  "/proposal_meetting/taxt_history"
                )
              );
            }

            if (
              curentRoles.includes(that.$congresConfig.congressRole.roleid_zgdb)
            ) {
              //  // 提案人 查看 修改  提交  反馈  删除导出
              li_div.appendChild(
                that.createChildDiv("我的提案", "/proposal_meetting/taxt_my")
              );
            }

            if (
              curentRoles.includes(that.$congresConfig.congressRole.roleid_gly)
            ) {
              li_div.appendChild(
                that.createChildDiv(
                  "提案权限管理",
                  "/proposal_meetting/taxt_sys"
                )
              );
            }
          });
        } catch (error) {}
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.bny_news-list {
  padding: 0px 0px 20px 0px;
  width: 1400px;
  min-height: 898px;
  margin: 0px auto;
  /* background: #f2f2f2; */
  .bny_news-list-left {
    width: 298px;
    float: left;
    height: auto;
    background-color: #fff;
    border: 1px solid #f2f2f2;

    .bny_news-list-left-top {
      height: 120px;
      line-height: 120px;
      width: 100%;
      background: url("../../assets/imgs/news/list-top.jpg") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
    }
    .bny_news-list-left-items {
      height: auto;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          height: 70px;
          line-height: 70px;
          text-align: center;
          color: #000;
          font-size: 16px;
          font-weight: 600;
          border-bottom: 1px solid #f2f2f2;
          background: url("../../assets/imgs/news/right.png") no-repeat;
          background-position: right center;
          background-position-x: 250px;
          background-size: 16px 16px;
          &:hover {
            cursor: pointer;

            background: url("../../assets/imgs/news/right1.png") no-repeat
              #008500;
            background-position: right center;
            background-position-x: 250px;
            background-size: 16px 16px;
            color: #fff;
          }
        }
      }
    }
  }
  .bny_news-list-left-items-li-div {
    height: 70px;
    line-height: 70px;
    text-align: right;
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }

  .bny_news-list-right {
    width: 1080px;
    float: left;
    height: auto;
    margin-left: 20px;
    background-color: #fff;

    .bny_news-list-right-top {
      height: 40px;
      line-height: 40px;
      color: #000;
      background: #f3f4f9;
      width: calc(100% - 5px);
      text-align: left;
      padding-left: 5px;
      font-size: 14px;
      span {
        color: #519f6d;
      }
      box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.1);
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
        li {
          float: left;
          margin: 0px 10px;
          width: 250px;
        }
      }
    }
    .bny_news-list-right-content {
      padding-top: 5px;
      height: auto;
      padding-bottom: 20px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          height: 180px;
          width: 100%;
          box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
          margin: 5px 0px;
          &:hover {
            box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha: 0.1);
            margin: 5px 0px;
            cursor: pointer;
          }
          .bny_news-list-right-content-left {
            float: left;
            height: 150px;
            width: 150px;
            padding: 15px;
            img {
              height: 148px;
              width: 148px;
              border: 1px solid #f2f2f2;
              box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
              &:hover {
                box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha: 0.1);
              }
            }
          }
          .bny_news-list-right-content-right {
            padding: 30px 10px;
            float: left;
            height: 120px;
            width: 870px;
            font-size: 14px;
            div:first-child {
              text-align: left;
              height: 40px;
              line-height: 40px;
              font-weight: bold;
            }
            div:nth-child(2) {
              text-align: left;
            }
            div:last-child {
              text-align: left;
              color: #948b8b;
              padding: 5px 0 0 0;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .el-pagination {
    width: 42%;
    margin-top: 30px;
    margin-left: 403px;
    ::v-deep .btn-prev,
    ::v-deep .btn-next {
      border: 1px solid #f2f2f2;
      background-color: transparent;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    ::v-deep .el-icon-arrow-left:before,
    ::v-deep .el-icon-arrow-right:before {
      color: #d5bfa1;
      font-size: 14px;
    }
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #f2f2f2;
    background-color: #fff;
    color: #000;
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 14px;
    margin-right: 15px;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #519f6d;
    color: #fff;
  }
}
</style>
