import Vue from "vue";
import { tables } from "./../../../api/content/db/search";
import CryptoJS from "crypto-js";
import axios from "axios";
function extractTableAndParams(sql) {
  // 去掉多余的空白字符，并将其转为小写以便处理
  const normalizedSql = sql.replace(/\s+/g, " ").trim().toLowerCase();

  let tableName = "";
  let params = {};

  // 提取表名
  if (normalizedSql.startsWith("select")) {
    tableName = normalizedSql.match(/from\s+(\w+)/)?.[1] || "";
    const whereMatch = normalizedSql.match(/where\s+(.*)/);
    if (whereMatch) {
      params = extractParams(whereMatch[1]);
    }
  } else if (normalizedSql.startsWith("update")) {
    tableName = normalizedSql.match(/update\s+(\w+)/)?.[1] || "";
    const setMatch = normalizedSql.match(/set\s+(.*)/);
    if (setMatch) {
      params = extractParams(setMatch[1]);
    }
    const whereMatch = normalizedSql.match(/where\s+(.*)/);
    if (whereMatch) {
      params = { ...params, ...extractParams(whereMatch[1]) };
    }
  } else if (normalizedSql.startsWith("delete")) {
    tableName = normalizedSql.match(/from\s+(\w+)/)?.[1] || "";
    const whereMatch = normalizedSql.match(/where\s+(.*)/);
    if (whereMatch) {
      params = extractParams(whereMatch[1]);
    }
  } else if (normalizedSql.startsWith("insert")) {
    tableName = normalizedSql.match(/insert\s+into\s+(\w+)/)?.[1] || "";
    const columnsMatch = normalizedSql.match(/\(([^)]+)\)\s+values/);
    const valuesMatch = normalizedSql.match(/values\s+\(([^)]+)\)/);
    if (columnsMatch && valuesMatch) {
      const columns = columnsMatch[1].split(",").map((col) => col.trim());
      const values = valuesMatch[1]
        .split(",")
        .map((val) => val.trim().replace(/['"]/g, ""));
      params = columns.reduce((acc, col, index) => {
        acc[col] = values[index];
        return acc;
      }, {});
    }
  }

  return { tableName, params };
}

// 提取参数的方法
function extractParams(conditionString) {
  const conditions = conditionString.split(/and|or/); // 通过 'AND' 和 'OR' 拆分条件
  const paramObj = {};
  conditions.forEach((cond) => {
    const [key, value] = cond.split("=").map((str) => str.trim());
    if (key && value) {
      paramObj[key] = value.replace(/['"]/g, ""); // 移除引号
    }
  });
  return paramObj;
}

function transformQuery(query) {
  // 确保 query 是一个字符串
  if (typeof query !== "string") {
    throw new Error("The query should be a string.");
  }

  const params = [];

  // 处理 SELECT、DELETE、UPDATE 语句中的表名
  const tableNameMatch =
    query.match(/from\s+([^\s]+)/i) ||
    query.match(/update\s+([^\s]+)/i) ||
    query.match(/into\s+([^\s]+)/i);
  if (tableNameMatch) {
    const tableName = tableNameMatch[1];
    params.push(tableName);
    query = query.replace(new RegExp(tableName, "i"), "??");
  }

  // 处理 INSERT 语句的表名
  const insertTableMatch = query.match(/insert\s+into\s+([^\s]+)/i);
  if (insertTableMatch) {
    const tableName = insertTableMatch[1];
    params.push(tableName);
    query = query.replace(new RegExp(tableName, "i"), "??");
  }

  // 处理 INSERT 语句中的列和值
  if (/insert\s+into/i.test(query)) {
    const valuesMatch = query.match(/\(([^)]+)\)\s+values\s+\(([^)]+)\)/i);
    if (valuesMatch) {
      const columns = valuesMatch[1].split(",").map((col) => col.trim());
      const values = valuesMatch[2]
        .split(",")
        .map((val) => val.trim().replace(/['"]/g, ""));

      // 将所有值替换为占位符 ? 并添加到 params 中
      values.forEach((val) => {
        params.push(isNaN(val) ? val : Number(val));
        query = query.replace(new RegExp(`['"]?${val}['"]?`, "i"), "?");
      });
    }
  } else {
    // 处理 SELECT、UPDATE、DELETE 语句中的条件参数
    // 保留1=1
    query = query.replace(/(?!1=1)['"]([^'"]+)['"]/g, (match, p1) => {
      params.push(p1);
      return "?";
    });
    query = query.replace(/(?!1=1)(\b\d+\b)/g, (match, p1) => {
      params.push(Number(p1)); // 将数字参数转换为 Number 类型
      return "?";
    });
  }

  return {
    query,
    params,
  };
}

//加密算法
function encrypt(text) {
  const ENCRYPTION_KEY = "12345678901234567890123456789012"; // 必须是32字节
  const IV_LENGTH = 16; // 初始化向量的长度

  // 生成随机的初始化向量（IV）
  const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);

  // 使用 AES 加密
  const encrypted = CryptoJS.AES.encrypt(
    text,
    CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  // 返回加密后的字符串和 IV
  return (
    iv.toString(CryptoJS.enc.Hex) +
    ":" +
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );
}

export function dbquery(myhttp, sqlStr, headers) {
  const input = {
    query: encrypt(sqlStr),
    params: [],
  };
  // console.log(sqlStr);
  let db_dynamic_url = "https://in.xtbg.ac.cn:8574/dynamic";
  // let _input='';
  // if(sqlStr.startsWith("https://")){
  //   return false;

  // }else{
  //    _input = transformQuery(sqlStr);
  // }

  return new Promise((resolve, reject) => {
    // select
    // let sqlQuery = {
    //   query: sqlStr,
    //   params: [tableName],
    // };

    myhttp
      .post(db_dynamic_url, input, {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZpeGVkLXVzZXIifQ.sD4ljYTYbWhIlkXBJML2Nm4q5yJXgPL6rRNR7ZQzNZI",
        },
      })
      .then((result) => {
      
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function dbquery2(myhttp, sqlStr) {
  // const input = {
  //   query: sqlStr,
  //   params: [],
  // };
  // console.log(sqlStr);
  let db_dynamic_url = "/hd";
  let _input = "";
  if (sqlStr.startsWith("https://")) {
    return false;
  } else {
    _input = transformQuery(sqlStr);
  }

  return new Promise((resolve, reject) => {
    // select
    // let sqlQuery = {
    //   query: sqlStr,
    //   params: [tableName],
    // };

    myhttp
      .post(db_dynamic_url, _input)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export async function getDataTotal(myhttp, db_dynamic_url, tableName) {
  db_dynamic_url = "/hd";
  const sqlStr = `SELECT COUNT(*) as total from ${tableName}`;
  return (await dbquery(myhttp, db_dynamic_url, sqlStr))[0]["total"];
}
