<template>
  
    <DetailPage
      :imageSrc="deviceInfo.photo_urls"
      :title="deviceInfo.name"
      :model="deviceInfo.model"
      :englishName="deviceInfo.english_name"
      :purchaseDate="deviceInfo.purchase_date"
      :id="deviceInfo.num"
      :manufacturer="deviceInfo.maker"
      :functions="deviceInfo.purpose"
      :department="deviceInfo.dept"
      :location="deviceInfo.location"
      :manager="deviceInfo.administrator"
      :contact="deviceInfo.contact"
      :pdfLink="deviceInfo.attachment_urls"
    />

</template>
  
  <script>
import DetailPage from "./components/DetailPage.vue";

export default {
  name: "App",
  components: {
    DetailPage,
  },
  data() {
    return {
      deviceInfo: {},
    };
  },
  methods: {
    async showDevice(id) {
      const sqlStr =
        `SELECT 
                    i.*, 
                    p.photo_urls, 
                    a.attachment_urls
                FROM xtbg_device_instruments i
                LEFT JOIN (
                    SELECT 
                        instrument_num, 
                        GROUP_CONCAT(DISTINCT photo_url) AS photo_urls
                    FROM xtbg_device_instrument_photos
                    GROUP BY instrument_num
                ) p ON p.instrument_num = i.num
                LEFT JOIN (
                    SELECT 
                        instrument_num, 
                        GROUP_CONCAT(DISTINCT attachment_url) AS attachment_urls
                    FROM xtbg_device_instrument_attachments
                    GROUP BY instrument_num
                ) a ON a.instrument_num = i.num 
                WHERE i.id=` + id;
      this.deviceInfo = (await this.$dbquery(this.$http, sqlStr))[0];
      //console.log(this.deviceInfo);
    },
  },
  mounted() {
    this.showDevice(this.$route.query.id);
  },
};
</script>
  
  <style scoped>
#device_detail {
  display: flex;
  height: 100vh;
  font-size: 14px;
  text-align: left;
  width: 100%;
}
</style>
  