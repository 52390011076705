<template>
  <div class="gongjiao">
    <div style="
        margin: 10px 0px;
        height: 100px;
        background-color: #f5f8f7;
        text-align: left;
        display: flex;
        align-items: center;
      ">
      <img src="../../../assets/imgs/gongwu/zuixingonggao.png" style="width: 60px; height: auto; margin-left: 20px"
        alt="" srcset="" />

      <div style="flex: 1; font-size: 14px; padding: 0px 20px">
        <div style="text-indent: 2em">
          尊敬的用户们，
          公务车预约，上线试运行。
一、内网用户端：第一，内网所有用户可以申请公务车，由车队管理审核指派公务车，其他用户可以搭乘可搭乘的公务车，搭乘需由对应用车人审核同意，即可搭乘。第二，可以自由发布顺风车信息。
二、车队管理员端（只在PC端管理车辆基础信息，权限管理）。（pc端和小程序端均可审核和指派）审核内网用户公务车申请单，并指派对应车辆和司机（目前，司机和公务车属于一对一）。
三、司机端（只在小程序端），查看跟自己有关系的公务车订单并可结束行程。
        </div>
        <div class="flex">
          <span style="color: #808080; margin-left: auto"> 2024年9月24日 </span>
        </div>
      </div>
    </div>
    <el-table border height="500" :data="currentTableData" style="width: 100%">
      <el-table-column prop="id" label="序号" width="180"></el-table-column>
      <el-table-column prop="role_name" label="角色"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            style="color: #409eff"
            type="text"
            icon="el-icon-user"
            size="mini"
            @click="handleEditUser(scope.row)"
            >用 户</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handlePageChange"
      style="margin-top: 20px; text-align: center"
    />

    <list_users :visible.sync="selectUserVisible"></list_users>
  </div>
</template>

<script>

import list_users from "./list_users.vue";
export default {
  components: {
    list_users,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      selectUserVisible: false,
      userAllTableData: [],
    };
  },
  computed: {
    currentTableData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.tableData.slice(start, end);
    },
    selectUserIds() {
      return this.userAllTableData.map((item) => item.id);
    },
  },
  async created() {
    const data = await this.$dbquery(
      this.$http,
      `SELECT * FROM xtbg_officecar_role`
    );
    this.tableData = data;
    this.total = data.length; // 设置数据总数
  },
  methods: {
    

    handlePageChange(page) {
      this.currentPage = page;
    },
    handleEditUser(){
      this.selectUserVisible = true;
      
    },
    closeUser(){
      this.selectUserVisible = false;
      
    }
   
  },
};
</script>

<style scoped>
.el-table {
  width: 100%;
}
</style>
