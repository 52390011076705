var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_taxt flex-col padding-top-10"},[_c('div',{staticClass:"flex flex-space-between",staticStyle:{"padding-bottom":"10px"}},[_c('div',{staticClass:"flex"},[_c('el-button',{staticStyle:{"margin-left":"5px","background":"#25834b","color":"#fff"},attrs:{"icon":"el-icon-plus"},on:{"click":_vm.startPropose}},[_vm._v("提案")])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"height-38 line-height-38 margin-left-right-5"},[_vm._v("年份")]),_c('div',[_c('el-select',{attrs:{"placeholder":"请选年份","clearable":""},on:{"change":_vm.changeYear},model:{value:(_vm.select_year),callback:function ($$v) {_vm.select_year=$$v},expression:"select_year"}},_vm._l((_vm.option_years),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"height-40 line-height-40 margin-left-right-5"},[_vm._v("类别")]),_c('div',[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择类别"},on:{"change":_vm.changeType},model:{value:(_vm.select_type),callback:function ($$v) {_vm.select_type=$$v},expression:"select_type"}},_vm._l((_vm.option_types),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',[_c('el-button',{staticStyle:{"margin-left":"5px","background":"#25834b","color":"#fff"},attrs:{"icon":"el-icon-refresh"},on:{"click":_vm.realodData}},[_vm._v("重载")])],1)])]),_c('div',{staticClass:"main_taxt_table"},[_c('dynamic-data-table',{ref:"dynamicDatatable",attrs:{"fetchData":_vm.fetchData,"pageSize":_vm.pageSize},scopedSlots:_vm._u([{key:"actions",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleViewClick('查看', scope.row)}}},[_vm._v("查看")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(
            scope.row.status == '待提交' ||
            scope.row.status == '待附议' ||
            scope.row.status == '待审查' ||
            scope.row.status == '退回重提'
          ),expression:"\n            scope.row.status == '待提交' ||\n            scope.row.status == '待附议' ||\n            scope.row.status == '待审查' ||\n            scope.row.status == '退回重提'\n          "}],staticStyle:{"color":"#ff0000"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleViewClick('修改', scope.row)}}},[_vm._v("修改")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(scope.row.status == '待提交'),expression:"scope.row.status == '待提交'"}],staticStyle:{"color":"#008500"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleViewClick('提交', scope.row)}}},[_vm._v("提交")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(
            scope.row.status == '已处理' || scope.row.status == '转意见处理'
          ),expression:"\n            scope.row.status == '已处理' || scope.row.status == '转意见处理'\n          "}],staticStyle:{"color":"#008500"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleViewClick('反馈', scope.row)}}},[_vm._v("反馈")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(scope.row.status == '待提交'),expression:"scope.row.status == '待提交'"}],staticStyle:{"color":"#f39d1b"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleViewClick('删除', scope.row)}}},[_vm._v("删除")]),_c('el-button',{staticStyle:{"color":"#4e4e4e"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleViewClick('流程状态', scope.row)}}},[_vm._v(" 流程状态 ")]),_c('el-button',{staticStyle:{"color":"#008500"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleViewClick('导出', scope.row)}}},[_vm._v("导出")])]}}])})],1),_c('el-dialog',{attrs:{"title":"当前流程状态","top":"20vh","visible":_vm.dialogFlowVisible,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogFlowVisible=$event}}},[_c('proposal-status-timeline',{attrs:{"status-steps":_vm.proposalSteps,"current-process-type":_vm.currentProcessType}}),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogFlowVisible = false}}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }