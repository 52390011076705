/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 10:27:54
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-09 17:10:09
 * @FilePath: \app_wb\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import Home from "../views/Home.vue";
import News from "../views/news/News.vue";
import NewsDetail from "../views/news/NewsDetail.vue";
import Books from "../views/Books.vue";
import Guidlines from "../views/Guidlines.vue";
import Notice from "../views/Notice.vue";
import Office from "../views/Office.vue";
import Rules from "../views/Rules.vue";

import Advise from "../views/Advise/Advise.vue";
import AdviseDetail from "../views/Advise/Detail.vue";
import AdviseAdd from "../views/Advise/add.vue";
import MeettingAdd from "../views/meetting/add.vue";
import LdrcLook from "../views/ldrc/look.vue";

// 提案
import ProposalMeetting from "../views/proposal/proposal_meetting.vue";
//大会情况
import MeetingDhqk from "../views/proposal/meetting_dhqk.vue";
//大会详情
import MeettingDhqkDetail from "../views/proposal/meetting_dhqk_detail.vue";
//大会决议
import MeetingDahjy from "../views/proposal/meetting_dahjy.vue";
import MeetingDahjyDetail from "../views/proposal/meetting_dahjy_detail.vue";
//提案目录
import MeetingTaml from "../views/proposal/meetting_taml.vue";
//参会名单
import MeetingChmd from "../views/proposal/meetting_chmd.vue";
//参会名单详情
import MeetingChmdDetail from "../views/proposal/meetting_chmd_detail.vue";
//大会建言
import MeetingDhjy from "../views/proposal/meetting_dhjy.vue";
//当前提案
import MeetingTaxtNow from "../views/proposal/meetting_taxt_now.vue";
//历史提案
import MeetingTaxtHistory from "../views/proposal/meetting_taxt_history.vue";
//我的提案
import MeetingTaxtMy from "../views/proposal/meetting_taxt_my.vue";

//提案表
import ProposeTable from "../views/proposal/my/propose.vue";
import ProposeTableView from "../views/proposal/my/propose_view.vue";
import ProposeTableUpdate from "../views/proposal/my/propose_update.vue";
//提案附议
import ProposeFy from "../views/proposal/my//propose_fyr.vue";
//提案组
import ProposeTaz from "../views/proposal/my//propose_taz.vue";
import ProposeTazSh from "../views/proposal/my/propose_taz_hs.vue";
//分管领导
import ProposeFgld from "../views/proposal/my//propose_fgld.vue";
//主办部门
import ProposeZbbm from "../views/proposal/my//propose_zgbm.vue";
//提案人反馈
import ProposeYjfk from "../views/proposal/my//propose_fkyj.vue";
// 提案系统角色管理
import ProposalRoleManager from "@/views/proposal/sys/roleManager.vue";
//常用下载
import MeetingCyxz from "../views/proposal/meetting_cyxz.vue";
//搜索
import SearchBox from "@/views/search/search.vue";

import { jubgeIsOrNot } from "@/utils/local_db.js";
//个人中心
import Personal from "@/views/personal/index.vue";

//西园云
import xyyp from "@/views/xyyp/index.vue";

//科学发展规划
import fzgh from "@/views/fzgh/fzgh.vue";
import fzghDetail from "@/views/fzgh/fzghDetail.vue";

// 仪器展示
import device_index from "@/views/device/index.vue";
import device_show from "@/views/device/show.vue";
import device_manage from "@/views/device/manage.vue";
import device_rolemanage from "@/views/device/rolemanage.vue";
import device_detail from "@/views/device/detail.vue";
//危化品
import hazard_index from "@/views/weihuapin/index.vue";
import hazard_show from "@/views/weihuapin/show.vue";
import hazard_manage from "@/views/weihuapin/weifeipin.vue";
import hazard_rolemanage from "@/views/weihuapin/rolemanage.vue";

import hazard_studygroup from "@/views/weihuapin/studygroup.vue";
import basicData from "@/views/weihuapin/basicdata.vue";
import whpDetail from '@/views/weihuapin/components/weihupin/detail.vue'
import whpDetailyjz from '@/views/weihuapin/components/weihupin/detailyjz.vue'


//公务车
import gw_index from "@/views/gongjiao/index.vue";
import gw_quanxian from '@/views/gongjiao/quanxian/rolemanage.vue'; // 权限管理
import gw_jichuxinxi from '@/views/gongjiao/jichuxinxi/index.vue';//基础信息
import gw_shenpin from '@/views/gongjiao/shenpi/index.vue' //审批
import gw_gongwu from '@/views/gongjiao/gongwu/index.vue' //公务用车
import gw_shunfeng from '@/views/gongjiao/shunfeng/index.vue' //顺风信息

import gw_dcshenhe from '@/views/gongjiao/shenpi/dc_index.vue' // 搭乘审核


// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, resolve, reject) {
//   if (resolve || reject) return originalPush.call(this, location, resolve, reject)
//   return originalPush.call(this, location).catch((e) => { })
// }

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/newsdetail",
    name: "NewsDetail",
    component: NewsDetail,
  },
  ,
  {
    path: "/fzgh",
    name: "fzgh",
    component: fzgh,
  },
  {
    path: "/fzghDetail",
    name: "fzghDetail",
    component: fzghDetail,
  },
  {
    path: "/books",
    name: "Books",
    component: Books,
  },
  {
    path: "/guidlines",
    name: "Guidlines",
    component: Guidlines,
  },
  {
    path: "/notice",
    name: "Notice",
    component: Notice,
  },
  {
    path: "/office",
    name: "Office",
    component: Office,
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
  },
  {
    path: "/advise",
    name: "advise",
    component: Advise,
  },
  {
    name: "AdviseDetail",
    path: "/advise_detail",
    component: AdviseDetail,
  },
  {
    name: "AdviseAdd",
    path: "/advise_add",
    component: AdviseAdd,
  },
  {
    path: "/meetting_add",
    name: "MeettingAdd",
    component: MeettingAdd,
  },
  {
    path: "/ldrc_look",
    name: "LdrcLook",
    component: LdrcLook,
  },
  {
    path: "/search",
    name: "Search",
    component: SearchBox,
  },
  {
    path: "/personal",
    name: "Personal",
    component: Personal,
  },
  {
    path: "/proposal_meetting",
    name: "ProposalMeetting",
    component: ProposalMeetting,
    children: [
      {
        path: "/proposal_meetting/dhqk",
        component: MeetingDhqk,
      },
      {
        path: "/proposal_meetting/dhqk_detail",
        component: MeettingDhqkDetail,
      },
      {
        path: "/proposal_meetting/cyxz",
        component: MeetingCyxz,
      },
      {
        path: "/proposal_meetting/dahjy",
        component: MeetingDahjy,
      },
      {
        path: "/proposal_meetting/dahjy_detail",
        component: MeetingDahjyDetail,
      },
      {
        path: "/proposal_meetting/taml",
        component: MeetingTaml,
      },
      {
        path: "/proposal_meetting/chmd",
        component: MeetingChmd,
      },
      {
        path: "/proposal_meetting/chmd_detail",
        component: MeetingChmdDetail,
      },
      {
        path: "/proposal_meetting/taxt_now",
        component: MeetingTaxtNow,
      },
      {
        path: "/proposal_meetting/taxt_history",
        component: MeetingTaxtHistory,
      },
      {
        path: "/proposal_meetting/taxt_my",
        component: MeetingTaxtMy,
      },
      {
        path: "/proposal_meetting/taxt_sys",
        component: ProposalRoleManager,
      },
      // 提案
      {
        path: "/proposal_meetting/propose_table",
        component: ProposeTable,
      },
      //查看提案
      {
        path: "/proposal_meetting/propose_view",
        component: ProposeTableView,
      },

      //修改提案
      {
        path: "/proposal_meetting/propose_update",
        component: ProposeTableUpdate,
      },
      // 附议
      {
        path: "/proposal_meetting/fy",
        component: ProposeFy,
      },
      // 提案组初审
      {
        path: "/proposal_meetting/taz",
        component: ProposeTaz,
      },
      //提案组会审
      {
        path: "/proposal_meetting/tazhs",
        component: ProposeTazSh,
      },

      // 园领导
      {
        path: "/proposal_meetting/yld",
        component: ProposeFgld,
      },
      // 主办部门
      {
        path: "/proposal_meetting/zbbm",
        component: ProposeZbbm,
      },
      // 提案人反馈
      {
        path: "/proposal_meetting/fk",
        component: ProposeYjfk,
      },
    ],
  },
  //危化品
  {
    path: "/weihuapin/index",
    name: "weihuapin",
    component: hazard_index,
    children: [
      {
        name: "hazard_show",
        path: "/weihuapin/show",
        component: hazard_show,
      },
      {
        path: "/weihuapin/weifeipin",
        component: hazard_manage,
      },
      {
        path: "/weihuapin/rolemanage",
        component: hazard_rolemanage,
      },
     
      
      {
        path: "/weihuapin/studygroup",
        component: hazard_studygroup,
      },
      {
        path: "/weihuapin/basic",
        component: basicData,
      },
      {
        path: "/weihuapin/detail",
        component: whpDetail,
      },
      {
        path: "/weihuapin/yjzdetail",
        component: whpDetailyjz,
      },
    ],
  },
  // 设备展示
  {
    path: "/device",
    name: "device",
    component: device_index,
    children: [
      {
        name: "device_show",
        path: "/device/show",
        component: device_show,
      },
      {
        path: "/device/manage",
        component: device_manage,
      },
      {
        path: "/device/rolemanage",
        component: device_rolemanage,
      },
      {
        path: "/device/detail",
        component: device_detail,
      },
    ],
  },
  //公务车预约
  {
    path:"/gongjiao/index",
    name:"gongjiao",
    component: gw_index,
    children:[
      {
        path:"/gongjiao/quanxian",
        component: gw_quanxian,
      },{
        path:"/gongjiao/jichuxinxi",
        component: gw_jichuxinxi,
      },{
        path:"/gongjiao/shenpi",
        component: gw_shenpin,
      },{
        path:"/gongjiao/gongwu",
        component: gw_gongwu,
      },{
        path:"/gongjiao/shunfeng",
        component: gw_shunfeng,
      },{
        path:"/gongjiao/dcshenpi",
        component: gw_dcshenhe,
        

      }
     
    ]
   
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/xyyp",
    name: "xyyp",
    component: xyyp,
  },
];

const isMobile = () => {
  let userAgentInfo = navigator.userAgent;
  let Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let getArr = Agents.filter((i) => userAgentInfo.includes(i));
  return getArr.length ? true : false;
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (isMobile()) {
    window.location.href = "https://in.xtbg.ac.cn:8866/";
  } else {

    

    if (to.name == "login") {
      next();
    } else {
      if (jubgeIsOrNot()) {
        next({
          path: "/login",
        });
      } else {
        NProgress.start();
        next();
      }
    }
  }
});
router.afterEach(() => {
  NProgress.done();
});
// router.beforeEach((to, from, next) => {
//   if (to.name == 'login') {

//     next()	//放行

//   } else {
//     if (localStorage.getItem("username") == null) {

//       next({
//         path: '/login'
//       })

//     } else {
//       next();
//     }
//   }

// })

export default router;
