<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-04-19 11:16:55
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-04-19 17:32:36
 * @FilePath: \app_wb\src\views\proposal\meettint_chmd.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div style="height: 600px">
    <div
      style="
        height: 40px;
        line-height: 40px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        padding: 5px 0px;
        border-bottom: 1px solid #f2f2f2;
      "
    >
      <el-input type="text" style="width: 300px">
        <template slot="prepend">文件名：</template>
      </el-input>
      <el-button
        icon="el-icon-search"
        @click="searchData"
        style="margin-left: 5px; background: #25834b; color: #fff"
        >搜索</el-button
      >
    </div>
    <div>
      <!-- <div class="flex-1 flex-item">
        <div>{{  }}</div>
        <div>{{ title }}</div>
      </div> -->
      <div
        class="flex-1 flex-item"
        v-for="(item, index) in files"
        :key="'file_' + index"
      >
        <img src="@/assets/imgs/zdh/pdf1.png" @click="yunLandFile(item.files)" alt="" srcset="" />
        <div class="cyxz_com">
          <a @click="openFile(item.files)" target="_blank"><img src="@/assets/imgs/zdh/download.png"/>{{ item.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let Base64 = require("js-base64").Base64;
export default {
  data() {
    return {
      files: [],
    };
  },
  created() {
    this.readCommonfiles();
  },
  methods: {
    searchData() {},
    async readCommonfiles() {
      const sqlStr = "select title,files from xtbg_common_dowload";
      const res = await this.$dbquery(this.$http, sqlStr);

    

      this.files = res;
    },
    yunLandFile(file) {
      if (file) {
        file = this.$api_url+file;
        // window.open(file)


        window.open(
          "http://42.193.22.219:8012/onlinePreview?url=" +
            encodeURIComponent(Base64.encode(file))
        );
      } else {
        this.$message({
          message: "无法预览",
          type: "warning",
          offset: 400,
        });
      }
    },

    openFile(file) {
      if (file) {
        file = this.$api_url+file;
        window.open(file)


        // window.open(
        //   "http://42.193.22.219:8012/onlinePreview?url=" +
        //     encodeURIComponent(Base64.encode(file))
        // );
      } else {
        this.$message({
          message: "无法下载",
          type: "warning",
          offset: 400,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-item {
  cursor: pointer;
 
  width: 120px;
  height: 120px;
  float: left;
  margin: 1px;
  padding: 10px 5px 2px 5px;
  img {
    height: 80px;
    width: 80;
  }
  .cyxz_com {
    display: flex;
    justify-content: center;
    a {
      font-size: 15px;
      text-decoration: none;
      color: #313131;
      padding: 0px 10px;
      img{
        width: 14px;
        height: 14px;
        margin-right: 5px;

      }
      
    }
  }
}
::v-deep .el-input-group__prepend {
  border-color: #f2f2f2 !important;
}
</style>