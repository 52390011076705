import request from "@/utils/request";

// 查询科技创新规划列表
export function listFzgh(query) {
  return request({
    url: "/content/fzgh/list",
    method: "get",
   
    params: query,
  });
}

// 查询科技创新规划详细
export function getFzgh(id) {
  return request({
    url: "/content/fzgh/" + id,
   
    method: "get",
  });
}

// 新增科技创新规划
export function addFzgh(data) {
  return request({
    url: "/content/fzgh",
    method: "post",
    
    data: data,
  });
}

// 修改科技创新规划
export function updateFzgh(data) {
  return request({
    url: "/content/fzgh",
    
    method: "put",
    data: data,
  });
}

// 删除科技创新规划
export function delFzgh(id) {
  return request({
    url: "/content/fzgh/" + id,
    
    method: "delete",
  });
}
