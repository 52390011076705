<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 14:00:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-10-18 20:59:06
 * @FilePath: \app_wb\src\views\News.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="bny_news-list">
      <div class="bny_news-list-left">
        <div class="bny_news-list-left-top">科技创新规划</div>
        <div class="bny_news-list-left-items">
          <ul class="list_news-items" id="left_lists">
            <li
              v-for="(item, index) in list_items"
              :key="index + 'fzgh'"
              @click="clickListItem($event, item.name)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bny_news-list-right">
        <div class="bny_news-list-right-top">
          您当前的位置：<span v-html="current_addr"></span>
          <div style="float: right; padding-right: 10px">
            <!-- <el-button
              type="text"
              @click="showSeachBox"
              v-show="showSearchButton"
              >查询搜索</el-button
            > -->
          </div>
          <!-- <div style="float: right" v-show="false">
            <ul>
              <li>
                发文部门：<el-autocomplete
                  class="inline-input"
                  v-model="dept"
                  :fetch-suggestions="querySearchBm"
                  placeholder="请输入内容"
                  @select="handleSelectBm"
                  style="width: 70%"
                ></el-autocomplete>
              </li>
              <li>
                时间：<el-date-picker
                  v-model="choosedate"
                  type="date"
                  placeholder="选择日期时间"
                  align="right"
                  style="width: 54%"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </li>
              <li>
                标题：<el-input
                  placeholder="请输入关键字搜索"
                  v-model="serachtext"
                  style="width: 70%"
                >
                  <template slot="append">
                    <el-button type="button" @click="btn_search"
                      >搜索</el-button
                    >
                  </template>
                </el-input>
              </li>
            </ul>
          </div> -->
        </div>

        <div class="bny_news-list-right-content">
          <fzgh_item
           v-show="fzgh_data_one_show"
          
            title="科研进展"
            :title_logo="logos.kxfz"
            :list_data="fzgh_data_one"
          ></fzgh_item>
          <fzgh_item
          v-show="fzgh_data_two_show"
            title="合作与交流"
            :title_logo="logos.xsjl"
            :list_data="fzgh_data_two"
          ></fzgh_item>
          <fzgh_item
          v-show="fzgh_data_three_show"
            title="成果及奖励"
            :title_logo="logos.xmcg"
            :list_data="fzgh_data_three"
          ></fzgh_item>
          <fzgh_item
          v-show="fzgh_data_four_show"
            title="项目争取及人才交流"
            :title_logo="logos.rcpy"
            :list_data="fzgh_data_four"
          ></fzgh_item>
          <fzgh_item
          v-show="fzgh_data_five_show"
            title="规划介绍"
            :title_logo="logos.xmjs"
            :list_data="fzgh_data_five"
          ></fzgh_item>
        </div>
      </div>
    </div>

    <!-- 查询搜索 -->
    <el-drawer
      title="查询搜索文章"
      :visible.sync="drawer"
      :with-header="false"
      :size="400"
      :modal="false"
    >
      <!-- 标题、内容、作者、部门、年份 -->
      <div style="padding: 150px 20px 0px 20px; text-align: left">
        <el-form ref="form" :model="searchForm" label-width="60px">
          <el-form-item label="标题">
            <el-input clearable v-model="searchForm.s_title"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input clearable v-model="searchForm.s_content"></el-input>
          </el-form-item>

          <el-form-item label="作者" v-show="s_author_show">
            <el-input clearable v-model="searchForm.s_author"></el-input>
          </el-form-item>
          <el-form-item label="部门">
            <el-input clearable v-model="searchForm.s_dept"></el-input>
          </el-form-item>
          <el-form-item label="年份">
            <el-select
              filterable
              clearable
              v-model="searchForm.s_year"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in s_years"
                :key="'year_' + index"
                :value="item"
                :label="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="start_seach"
              style="background: #519f6d"
              >查询</el-button
            >
            <el-button @click="drawer = false">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <div style="clear: both"></div>

    <mFooter></mFooter>
  </div>
</template>

<script>
import { Loading, TabPane } from "element-ui";
import mFooter from "@/components/mFooter.vue";

import fzgh_item from "./fzgh_item.vue";
import axios from "axios";
import { listFzgh } from "../../api/content/fzgh.js";

import {
  removeHtml,
  regManyJsonStrToJsonArr,
  sortJsonArr,
  goToTop,
  getYearMonthDay,
  uniqueJsonArrByField,
} from "@/utils/common.js";

var _this = this;
export default {
  inject: ["reload"],
  components: {
    mFooter,
    fzgh_item,
  },
  data() {
    return {
      logos: {
        kxfz: require("../../assets/imgs/fzgh/kxfz.png"),
        rcpy: require("../../assets/imgs/fzgh/rcpy.png"),
        xmcg: require("../../assets/imgs/fzgh/xmcg.png"),
        xmjs: require("../../assets/imgs/fzgh/xmjs.png"),
        xsjl: require("../../assets/imgs/fzgh/xsjl.png"),
      },

      fzgh_data_one: [],
      fzgh_data_two: [],
      fzgh_data_three: [],
      fzgh_data_four: [],
      fzgh_data_five: [],
      fzgh_data_one_show: true,
      fzgh_data_two_show: true,
      fzgh_data_three_show: true,
      fzgh_data_four_show: true,
      fzgh_data_five_show: true,
      showSearchButton: true,
      drawer: false,
      searchForm: {
        s_title: "",
        s_content: "",
        s_author: "",
        s_dept: "",
        s_year: "",
      },

      showOrFalse: true,
      showContentOrFalse: false,
      showWlNTrurOrFalse: false, //内网新闻、官网动态
      showWlTrurOrFalse: false, //项目资讯、内网公告、园务公开、规章制度

      plat_types: [],
      // fits: ["fill", "fill", "fill", "fill", "fill"],
      // url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",

      total: 0,
      pageSize: 10,
      current_title: "官网动态",
      current_page: 1,
      current_addr: "",
      item_bg_image: require("@/assets/imgs/news/right.png"),
      item_active_bg_image: require("@/assets/imgs/news/right1.png"),

      list_items: [
        {
          name: "“十四五”规划",
          url: "",
        },

        {
          name: "历届规划",
          url: "",
        },
        {
          name: "“十三五”规划",
          url: "",
        },
      ],
      list_content_items: [],
      list_data: [],
      dept: "",
      bm_list: [],
      choosedate: "",
      gwdt_data: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      serachtext: "",
      queryPramas: {
        pageSize: 10,
        pageNum: 1,
      },
      loadingInstance1: null,
      wjhidden: true,

      searchByWhere: false,
      searchResultList: [],
      s_years: [],
      s_author_show: false,
    };
  },
  created() {
    //模拟数据
    _this = this;

    this.setSyears();
    this.listFzghData();
  },

  watch: {
    "$store.state.loginwj": {
      handler(newV) {
        this.wjhidden = newV;
      },
      deep: true,
    },
  },
  methods: {
    listFzghData() {
      listFzgh({
        pageSize: 100,
        pageNum: 1,
      }).then((res) => {
        let rows = Object.freeze(res.data.rows);
        console.log(rows);


        _this.fzgh_data_one = rows.filter((row) => {
          return row.tinytype.indexOf("科研进展") > -1;
        });
        _this.fzgh_data_two = rows.filter((row) => {
          return row.tinytype.indexOf("合作与交流") > -1;
        });

        console.log(_this.fzgh_data_two);
        _this.fzgh_data_three = rows.filter((row) => {
          return row.tinytype.indexOf("成果及奖励") > -1;
        });
        _this.fzgh_data_four= rows.filter((row) => {
          return row.tinytype.indexOf("项目争取及人才交流") > -1;
        });
        _this.fzgh_data_five = rows.filter((row) => {
          return row.tinytype.indexOf("规划介绍") > -1;
        });

        if(_this.fzgh_data_one.length>0){
          _this.fzgh_data_one_show=true;
        }else{
          _this.fzgh_data_one_show=false;
        }
        if(_this.fzgh_data_two.length>0){
          _this.fzgh_data_two_show=true;
        }else{
          _this.fzgh_data_two_show=false;
        }
        if(_this.fzgh_data_three.length>0){
          _this.fzgh_data_three_show=true;
        }else{
          _this.fzgh_data_three_show=false;
        }
        if(_this.fzgh_data_four.length>0){
          _this.fzgh_data_one_show=true;
        }else{
          _this.fzgh_data_four_show=false;
        }
        if(_this.fzgh_data_five.length>0){
          _this.fzgh_data_five_show=true;
        }else{
          _this.fzgh_data_five_show=false;
        }
        
      });
    },
    setSyears() {
      let n_year = new Date().getFullYear();
      for (let i = n_year; i > n_year - 11; i--) {
        this.s_years.push(i);
      }
    },
    showSeachBox() {
      this.queryPramas = {
        pageSize: 10,
        pageNum: 1,
      };

      this.drawer = true;
    },
    searchBYSelfCodition(queryCondition) {
      return new Promise(function (resolve, reject) {
        _this.$http
          .post(_this.$db_dynamic_url, queryCondition)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    searchByCondition(tableName, whereCondition) {
      let conditionTotal =
        "select COUNT(*) as total from " +
        tableName +
        " WHERE " +
        whereCondition;
      let queryTotal = {
        query: conditionTotal,
        params: [],
      };

      let conditionSql =
        "select * from " +
        tableName +
        " WHERE " +
        whereCondition +
        " ORDER BY create_time desc LIMIT " +
        (this.queryPramas.pageNum - 1) * this.queryPramas.pageSize +
        "," +
        this.queryPramas.pageNum * this.queryPramas.pageSize +
        "";

      //console.log(conditionSql);
      let queryStr = {
        query: conditionSql,
        params: [],
      };
      return new Promise((resolve, reject) => {
        Promise.all([
          this.searchBYSelfCodition(queryTotal),
          this.searchBYSelfCodition(queryStr),
        ])
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    start_seach_bycondition(table_name) {
      let whereCondition =
        "title like '%" +
        this.searchForm.s_title +
        "%' and content like '%" +
        this.searchForm.s_content +
        "%' and create_by like '%" +
        this.searchForm.s_author +
        "%' and create_time like '%" +
        this.searchForm.s_year +
        "%'";

      this.searchByCondition(table_name, whereCondition).then((res) => {
        let rows = [];
        res[1].map((row) => {
          let new_row = {
            id: row.id,
            title: row.title,
            deptId: row.dept_id,
            createTime: getYearMonthDay(row.create_time),
          };
          rows.push(new_row);
        });
        let new_res = {
          data: {
            total: res[0][0].total,
            rows: rows,
          },
        };

        _this.converData(new_res);
      });
    },
    start_seach() {
      this.searchByWhere = true;
      //开始查询
      this.showContentOrFalse = false;
      this.showWlTrurOrFalse = false;
      this.showWlNTrurOrFalse = false;
      this.showOrFalse = true;
      this.loadingInstance1 = Loading.service({
        fullscreen: true,
        text: "正在查询...",
      });

      let currentName = this.$route.query.name;

      //console.log(currentName);
      switch (currentName) {
        case "项目资讯":
          this.showWlTrurOrFalse = true;
          this.s_author_show = false;
          //this.queryPramas.title = this.searchForm.s_title;

          this.start_seach_bycondition("xtbg_science_info");

          break;
        case "内网新闻":
          this.showWlNTrurOrFalse = true;
          //this.start_seach_bycondition("xtbg_innews");
          break;
        case "内网公告":
          this.s_author_show = false;
          this.showWlTrurOrFalse = true;
          //this.start_seach_bycondition("xtbg_notice");
          break;
        case "官网动态":
          break;
        case "园务公开":
          this.showWlTrurOrFalse = true;
          this.s_author_show = false;
          //this.start_seach_bycondition("xtbg_reponse_open");
          break;
        case "规章制度":
          this.showWlTrurOrFalse = true;
          this.s_author_show = false;
          //this.start_seach_bycondition("xtbg_rules_ism");
          break;
      }
      setTimeout(() => {
        _this.loadingInstance1.close();
        this.drawer = false;
      }, 500);
    },
    //部门列表
    getListDepts() {
      this.bm_list = [];
      ListDepts().then((res) => {
        res.data.data.map((row) => {
          this.bm_list.push({
            value: row.deptName,
          });
        });
      });
    },
    btn_search() {
      this.$message({
        message: "正在努力开发中，请稍后...",
        type: "warning",
        offset: 200,
      });
    },
    querySearchBm(queryString, cb) {
      var restaurants = this.bm_list;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },

    handleSelectBm() {},
    ListPlatServices(typename) {
      let queryParams = {
        pageSize: 100,
        pageNum: 1,
        typename: typename,
      };
      ListPlatformService(queryParams).then((res) => {});
    },
    //排序
    up(x, y) {
      return x.orderNum - y.orderNum;
    },
    goTo(url) {
      if (url == "/") {
        this.$message({
          message: "正在开发中...",
          type: "info",
          offset: 200,
          center: true,
        });
        return false;
      }
      if (url.indexOf("http") > -1) {
        window.open(url);
      } else {
        this.$router.push({
          path: url,
        });
      }
    },
    initeData(navName) {
      this.showContentOrFalse = false;
      this.showWlTrurOrFalse = false;
      this.showWlNTrurOrFalse = false;
      this.showOrFalse = true;

      this.list_data = [];
      this.plat_types = [];
      let showLoading = false;
      this.current_title = navName;
      localStorage.setItem("current_title",navName);

      switch (navName) {
        case "“十四五”规划":
          this.loadingInstance1 = Loading.service({
            fullscreen: true,

            text: "正在加载...",
          });
          showLoading = true;
          this.showOrFalseSsw(false);
          break;
        case "历届规划":
          this.showOrFalseSsw(true);
          showLoading = false;
          console.log(9898997);
          break;
        case "“十三五”规划":
          this.loadingInstance1 = Loading.service({
            fullscreen: true,
            text: "正在加载...",
          });
          showLoading = true;
          break;
      }

      setTimeout(() => {
        if (showLoading) {
          _this.loadingInstance1.close();
        }
      }, 500);
    },
    dictSort(x, y) {
      return x.dictSort - y.dictSort;
    },
    converData(res) {
      let rows = res.data.rows;
      this.total = res.data.total;

      if (this.total > 10) {
        this.showOrFalse = true;
      } else {
        this.showOrFalse = false;
      }
      this.list_data = [];
      rows.map((row) => {
        if (row.content != null) {
          row.content = removeHtml(row.content);
          row.content =
            row.content.length > 100
              ? row.content.substring(0, 100) + "..."
              : row.content;
        }

        if (row.thumbnail != null) {
          if (row.thumbnail.indexOf("http") == -1) {
            row.thumbnail = _this.$api_url + row.thumbnail;
          }
        }

        row.createTime = getYearMonthDay(row.createTime);

        if (row.deptId != undefined) {
          (function (deptId) {
            this.$getDeptNameByDeptId(this.$http,deptId).then((res) => {
              row.deptName = res[0].dept_name;;

              if (!_this.isInJson(_this.list_data, row.title)) {
                _this.list_data.push(row);
              }
            });
          })(row.deptId);
        } else {
          if (row.dept != undefined || row.dept != "undefined") {
            row.deptName = row.dept;
          }
          if (!_this.isInJson(_this.list_data, row.title)) {
            _this.list_data.push(row);
          }
          //this.list_data.push(row);
          this.list_data = uniqueJsonArrByField(this.list_data, "title");
        }
      });
      setTimeout(() => {
        let el_image_errors =
          document.getElementsByClassName("el-image__error");

        for (let i = 0; i < el_image_errors.length; i++) {
          el_image_errors[i].innerHTML =
            '<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>';
        }
        //_this.list_data = uniqueJsonArrByField(_this.list_data, "title");
        //console.log(this.list_data);
        _this.list_data = this.reduceArr(_this.list_data);
        _this.list_data = _this.list_data.sort((s, n) => {
          return (
            new Date(n.createTime).getTime() - new Date(s.createTime).getTime()
          );
        });
      }, 500);
    },
    isInJson(jsonArr, title) {
      jsonArr.map((item) => {
        if (JSON.stringify(item).indexOf(title) > -1) {
          return true;
        }
      });
      return false;
    },
    reduceArr(arr) {
      let hash = {};
      arr = arr.reduce(function (item, next) {
        hash[next.title] ? "" : (hash[next.title] = true && item.push(next));
        return item;
      }, []);
      return arr;
    },
    initList() {
      this.list_content_items = [];
      for (
        let i = (this.current_page - 1) * this.pageSize;
        i < this.current_page * this.pageSize;
        i++
      ) {
        this.list_content_items.push({
          id: i + 1,
          title: this.current_title + "-" + this.list_data[i].title + (i + 1),
          img: this.list_data[i].img,
          content: this.list_data[i].content,
          date: this.list_data[i].date,
          dept: this.list_data[i].dept,
        });
      }

      this.total = this.list_data.length;
      if (this.total > 10) {
        this.showOrFalse = true;
      } else {
        this.showOrFalse = false;
      }
    },
    currentChange(cpage) {
      // this.current_page = cpage;
      // this.list_content_items = [];

      if (this.gwdt_data.length > 0) {
        let startIndex = (cpage - 1) * this.pageSize;
        let endIndex = cpage * this.pageSize;

        this.list_data = this.gwdt_data.slice(startIndex, endIndex);
      } else {
        //console.log(this.queryPramas.pageNum);
        this.queryPramas.pageNum = cpage;
        if (this.searchByWhere) {
          this.start_seach();
        } else {
          this.initeData(this.current_title);
        }
      }
      goToTop();
    },
    clickListItem(e, name) {
      this.queryPramas = {
        pageNum: 1,
        pageSize: 10,
      };
      this.current_title = name;

      //修改路由
      this.$router.push({
        path: "/fzgh",
        query: {
          name: name,
        },
      });

      this.current_addr =
        "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
        "<a href='/news?name=" +
        name +
        "' style='cursor:point;text-decoration:none'>" +
        name +
        "</a>";
      this.activeEl(e);
      this.initeData(name);
    },
    activeEl(e) {
      let parentEL = e.currentTarget.parentElement;
      let lis = parentEL.querySelectorAll("li");
      for (let i = 0; i < lis.length; i++) {
        let mli = lis[i];
        mli.style.background = "url('" + this.item_bg_image + "') no-repeat";
        mli.style.backgroundPosition = "right center";
        mli.style.backgroundPositionX = "250px";
        mli.style.backgroundSize = "16px 16px";
        mli.style.color = "#000";
      }
      let current_li = e.currentTarget;
      current_li.style.background =
        "url('" + this.item_active_bg_image + "') no-repeat #519f6d";
      current_li.style.backgroundPosition = "right center";
      current_li.style.backgroundPositionX = "250px";
      current_li.style.backgroundSize = "16px 16px";
      current_li.style.color = "#fff";
      this.clickLastLi();
    },
    selectItem(name) {
      let mlis = document
        .getElementsByClassName("list_news-items")[0]
        .querySelectorAll("li");
      for (let i = 0; i < mlis.length; i++) {
        let current_li = mlis[i];
        this.current_title = name;
        this.current_addr =
          "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
          "<a href='/news?name=" +
          name +
          "' style='cursor:point;text-decoration:none'>" +
          name +
          "</a>";

        current_li.style.background =
          "url('" + this.item_bg_image + "') no-repeat";

        current_li.style.backgroundPosition = "right center";
        current_li.style.backgroundPositionX = "250px";
        current_li.style.backgroundSize = "16px 16px";
        current_li.style.color = "#000";

        if (current_li.innerText == name) {
          this.current_title = name;
          this.current_addr =
            "<a href='/' style='cursor:point;text-decoration:none'>首页</a>>" +
            "<a href='/news?name=" +
            name +
            "' style='cursor:point;text-decoration:none'>" +
            name +
            "</a>";

          current_li.style.background =
            "url('" + this.item_active_bg_image + "') no-repeat #519f6d";

          current_li.style.backgroundPosition = "right center";
          current_li.style.backgroundPositionX = "250px";
          current_li.style.backgroundSize = "16px 16px";
          current_li.style.color = "#fff";
        }
      }
    },
    getRnd(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    showDetail(item) {
      this.$store.commit("updateSelectedTitle", this.current_title);

      localStorage.setItem("title", encodeURI(this.current_title));
      localStorage.setItem("showTitle", encodeURI(item.title));
      localStorage.setItem("deptName", item.deptName);
      if (item.link != "undefined" && this.current_title == "官网动态") {
        window.open(item.link);
      } else {
        this.$router.push({
          path: "/newsdetail",
          query: {
            id: item.id,
          },
        });
      }
    },
    showOrFalseSsw(bool) {
      let left_lists = document
        .getElementById("left_lists")
        .querySelectorAll("li");
      let mlast_li = left_lists[left_lists.length - 1];
      mlast_li.style.textAlign = "right";
      mlast_li.style.paddingRight = "70px";
      mlast_li.style.height = "50px";
      mlast_li.style.lineHeight = "50px";

      if (bool) {
        mlast_li.style.display = "block";
      } else {
        mlast_li.style.display = "none";
      }
    },
    clickLastLi(bool) {
      let left_lists = document
        .getElementById("left_lists")
        .querySelectorAll("li");
      let mlast_li = left_lists[left_lists.length - 1];
      mlast_li.style.textAlign = "right";
      mlast_li.style.paddingRight = "70px";
      mlast_li.style.height = "50px";
      mlast_li.style.lineHeight = "50px";

      mlast_li.style.background =
        "url('" + this.item_active_bg_image + "') no-repeat";
      mlast_li.style.backgroundPosition = "right center";
      mlast_li.style.backgroundPositionX = "250px";
      mlast_li.style.backgroundSize = "16px 16px";
      mlast_li.style.color = "rgb(81, 159, 109)";

      let mlast_li_pre = left_lists[left_lists.length - 2];
      mlast_li_pre.style.background =
        "url('" + this.item_active_bg_image + "') no-repeat #519f6d";
      mlast_li_pre.style.backgroundPosition = "right center";
      mlast_li_pre.style.backgroundPositionX = "250px";
      mlast_li_pre.style.backgroundSize = "16px 16px";
      mlast_li_pre.style.color = "#fff";
    },
  },

  mounted() {
    //console.log(this.$route.query.name);
    //window.location.reload();

    this.selectItem(this.$route.query.name);
    this.initeData(this.$route.query.name);

    //修改坐标菜单层级
    this.showOrFalseSsw(false);
    //this.goToTop();
  },
};
</script>

<style lang="scss" scoped>
.bny_news-list {
  padding: 0px 0px 200px 0px;
  width: 1400px;
  height: auto;

  margin: 0px auto;
  /* background: #f2f2f2; */
  .bny_news-list-left {
    width: 298px;
    float: left;
    height: auto;
    background-color: #fff;
    border: 1px solid #f2f2f2;

    .bny_news-list-left-top {
      height: 120px;
      line-height: 120px;
      width: 100%;
      background: url("@/assets/imgs/news/list-top.jpg") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
    }
    .bny_news-list-left-items {
      height: auto;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          display: block;
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 16px;
          color: #4c4c4c;
          font-weight: bold;
          border-bottom: 1px solid #f2f2f2;
          background: url("@/assets/imgs/news/right.png") no-repeat;
          background-position: right center;
          background-position-x: 250px;
          background-size: 16px 16px;
          &:hover {
            cursor: pointer;
            background: url("@/assets/imgs/news/right1.png") no-repeat #519f6d;
            background-position: right center;
            background-position-x: 250px;
            background-size: 16px 16px;
            color: #fff;
          }
        }
      }
    }
  }

  .bny_news-list-right {
    width: 1080px;
    float: left;
    height: auto;
    margin-left: 20px;
    background-color: #fff;

    .bny_news-list-right-top {
      height: 60px;
      line-height: 60px;
      color: #000;
      background: #f3f4f9;
      width: calc(100% - 5px);
      text-align: left;
      padding-left: 5px;
      font-size: 14px;
      span {
        color: #519f6d;
      }
      box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.1);
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
        li {
          float: left;
          margin: 0px;
          width: 250px;
        }
      }
    }
    .bny_news-list-searchbar {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #ddd;
      .el-input-group__append,
      .el-input-group__prepend {
        padding: 0 8px !important;
      }
    }
    .bny_news-list-right-content {
      padding-top: 5px;
      height: auto;
      padding-bottom: 20px;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          .xmzx-list-item {
            height: 62px;
            line-height: 62px;
            border-bottom: 1px dashed #e8e8e8;

            &:hover {
              color: #519f6d;
              border-bottom: 1px dotted #519f6d;
              cursor: pointer;
              font-weight: 600;
              padding-left: 23px;
              transition: all 0.5s;
            }
            padding-left: 20px;
          }
          .nwxw-list-item {
            height: 180px;
            width: 100%;
            //box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
            margin: 18px 0px;
            border: 1px solid #e8e8e8;
            border-bottom: 4px solid #eaeaea;

            &:hover {
              //box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha: 0.1);
              margin: 18px 0px;
              cursor: pointer;
              border-bottom: 4px solid #047632;
            }
            .bny_news-list-right-content-left {
              float: left;
              height: 150px;
              width: 280px;
              padding: 15px;
              img {
                height: 148px;
                width: 148px;
                border: 1px solid #f2f2f2;
                box-shadow: 1px 1px 4px 3px rgba($color: #000000, $alpha: 0.1);
                &:hover {
                  box-shadow: -2px -2px 4px 3px rgba($color: #000000, $alpha:
                        0.1);
                }
              }
            }
            .bny_news-list-right-content-right {
              padding: 2px 10px;
              float: left;
              height: 120px;
              width: 740px;
              font-size: 14px;
              div:first-child {
                text-align: left;
                height: 40px;
                line-height: 40px;
                font-weight: bold;
                color: #4c4c4c;
                &:hover {
                  color: #047632;
                }
              }

              div:nth-child(2) {
                text-align: left;
                color: #999999;
              }
              div:last-child {
                text-align: left;
                color: #948b8b;
                padding: 5px 0 0 0;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .el-pagination {
    width: 42%;
    margin-top: 30px;
    margin-left: 403px;
    ::v-deep .btn-prev,
    ::v-deep .btn-next {
      border: 1px solid #f2f2f2;
      background-color: transparent;
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
    ::v-deep .el-icon-arrow-left:before,
    ::v-deep .el-icon-arrow-right:before {
      color: #d5bfa1;
      font-size: 14px;
    }
  }

  ::v-deep .el-pagination.is-background .el-pager li {
    border: 1px solid #f2f2f2;
    background-color: #fff;
    color: #000;
    width: 40px;
    height: 40px;
    line-height: 37px;
    font-size: 14px;
    margin-right: 15px;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #519f6d;
    color: #fff;
  }
}
.zhfw-list-items {
  .block {
    float: left;
    text-align: center;
    &:hover {
      box-shadow: 5px 5px 5px #f2f2f2;

      color: #519f6d;
    }
    img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>