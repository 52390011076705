<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 13:37:44
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-06-09 13:43:10
 * @FilePath: \app_wb\src\components\Index\Carousel.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 13:37:44
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-03-28 17:44:09
 * @FilePath: \app_wb\src\components\Index\Carousel .vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="block" style="width: 1000px">
    <el-carousel
      :interval="5000"
      height="400px"
      style="text-align: center; position: relative"
    >
      <el-carousel-item v-for="(item, index) in banner_data" :key="index">
        <!-- <h3 class="small">{{ item }}</h3> -->
        <img @click="clickBanner(item.url)" :src="item.file" alt="" />
        <div class="bny__news-title">
          <el-row
            style="
              font-size: 30px;
              font-weight: 600;
              height: 30px;
              line-height: 30px;
              text-align: center;
              margin-bottom: 120px;
            "
            >{{ item.title }}</el-row
          >
          <el-row
            style="
              text-align: center;
              font-size: 18px;
              height: 20px;
              line-height: 20px;
              padding: 20px 0 10px 0;
            "
            v-if="item.enddate"
            >时间：{{ item.startdate }}至{{ item.enddate }}</el-row
          >
          <el-row
            style="
              text-align: center;
              font-size: 18px;
              height: 20px;
              line-height: 20px;
              padding: 20px 0;
              margin-bottom: 40px;
            "
            v-if="item.location"
            >地点：{{ item.location }}</el-row
          >
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { ListTrends } from "@/api/content/xtbg_trends.js";
export default {
  name: "banner",
  data() {
    return {
      banner_data: [],
      title: "官网动态",
      queryPramas: {
        pageSize: 10,
        pageNum: 1,
      },
    };
  },
  created() {
    this.getListTrends();
  },
  methods: {
    async getListTrends() {
      this.loading = true;

      let where = "status='是' order by id desc";

      let teandsData = await this.$db_select(
        this.$http,
        "xtbg_trends",
        "*",
        where
      );
      teandsData = teandsData.map((item) => this.$ToSnakeUpper(item));

      teandsData.map((row) => {
        if (row.startdate) {
          row.startdate = this.$FormatDateToYMDHMS(row.startdate, false);
          row.enddate = this.$FormatDateToYMDHMS(row.enddate, false);
        }

        row.file = this.$api_url + row.file;
        this.banner_data.push(row);
      });

      // ListTrends(this.queryPramas).then(res=>{
      //   this.banner_data = [];
      //   let rows  = res.data.rows;
      //   rows.map(row=>{
      //     row.file = this.$api_url + row.file;
      //     this.banner_data.push(row);

      //   })

      // });
    },
    clickBanner(url) {
      this.$router.push({
        path: "/news",
        query: {
          name: this.title,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bny__news-title {
  position: absolute;
  left: calc(50% - 250px);
  bottom: 30px;
  z-index: 999;
  color: #fff;
  font-size: 15px;
  width: 500px;
  padding: 0px 10px;
  height: auto;

  /* background: rgba(0,0,0,0.5); */
}
</style>