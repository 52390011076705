import request from '@/utils/request'

// 查询反腐倡廉列表
export function listGraft(query) {
  return request({
    url: '/content/graft/list',
    method: 'get',
    params: query
  })
}

// 查询反腐倡廉详细
export function getGraft(id) {
  return request({
    url: '/content/graft/' + id,
    method: 'get'
  })
}

// 新增反腐倡廉
export function addGraft(data) {
  return request({
    url: '/content/graft',
    method: 'post',
    data: data
  })
}

// 修改反腐倡廉
export function updateGraft(data) {
  return request({
    url: '/content/graft',
    method: 'put',
    data: data
  })
}

// 删除反腐倡廉
export function delGraft(id) {
  return request({
    url: '/content/graft/' + id,
    method: 'delete'
  })
}
