
<template>
  <div class="index_list-ss">
    <div class="index_list-ss-title">站内搜索</div>

    <div class="index_list-ss-search">
      <el-input placeholder="请输入内容" style="border-radius: 0px !important;" v-model="searchTxt" @keyup.enter.native="searchWb">
        <template slot="append">
          <el-button style="background: linear-gradient(237deg, #047632 7%, #047632 59%);box-shadow: inset 0px 4px 10px 0px #A6B75B,inset 0px 4px 10px 0px #0E8313;" type="button" @click="searchWb">搜索</el-button>
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input2: "",
      searchTxt: "",
    };
  },
  // watch: {
  //   searchTxt(newV) {
  //     let roleName = localStorage.getItem("roleName");
  //     this.searchTxt = newV;
  //     if (roleName != null) {
  //       if (roleName.indexOf("外籍") == -1) {
  //         this.$router
  //           .push({
  //             path: "/search",
  //             query: {
  //               searchTxt: newV,
  //             },
  //           })
  //           .catch((err) => {});
  //       }
  //       // console.log(this.$store.state.);
  //     }
  //   },
  // },
  methods: {
    searchWb() {
      
      let roleName = localStorage.getItem("roleName");
      if (roleName != null) {
        if (roleName.indexOf("外籍") == -1) {
          this.$router
            .push({
              path: "/search",
              query: {
                searchTxt: this.searchTxt,
              },
            })
            .catch((err) => {});
        } else {
          this.$message({
            message: "您没有权限搜索...",
            type: "warning",
            offset: 200,
          });
        }
        // console.log(this.$store.state.);
      }
    },
    clickKzssItem(item, ev) {
      let znss_list = document.getElementById("znss_list");
      let lis = znss_list.querySelectorAll("li");
      for (let i = 0; i < lis.length; i++) {
        lis[i].style.background = "#fff";
        lis[i].style.border = "none";
      }
      let li = ev.currentTarget;
      li.style.background = "#f2f2f2";
      li.style.border = "1px solid #ddd";
      //开始搜索
      item = item.replace("<br/>", "");
      console.log(item);
    },
  },
  mounted() {
   
  },
};
</script>

<style lang="scss" scoped>
.index_list-ss {
  width: 385px;
  padding-left: 10px;
  height: 180px;
  background: url("../../assets/imgs/list-items/ss_bg.png") #fff no-repeat;
  background-size: 385px 100%;
  background-position-x: 10px;
  margin-top: 5px;

  .index_list-ss-title {
    color: rgb(81 159 109);
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    height: 20px;
    padding-top: 40px;
    color: #047632;
  }
  .index_list-ss-items {
    padding: 40px;
    text-align: center;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 60px;
        height: 40px;
        padding: 10px 0px;

        float: left;
        font-size: 14px;
        color: rgb(81 159 109);
        font-weight: bold;
        cursor: pointer;
        &:active {
          background: #f2f2f2;
        }
      }
    }
  }
  .index_list-ss-search {
    padding-top: 20px;
  }
}
</style>