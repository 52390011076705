<template>
  <div >{{ show_time }}</div>
</template>

<script>
import {
  getNowDateYYYYMMDDHHMMSS,
  weekDay,
  getFormatDate,
  getNowFormatDate,
} from "../../utils/common.js";
export default {
  data() {
    return {
      show_time: "",
      timer_interval: null,
    };
  },
  methods: {},
  destroyed() {
    window.clearInterval(this.timer_interval);
  },
  mounted() {
    var _this = this;
    _this.timer_interval = setInterval(function () {
      _this.show_time = getNowDateYYYYMMDDHHMMSS();
      let cdate = getNowFormatDate();
      _this.show_time=_this.show_time+" "+ weekDay(new Date(cdate));
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
</style>