<template>
  <div>
    <el-table :data="currentTableData" style="width: 100%">
      <el-table-column prop="id" label="序号" width="180"></el-table-column>
      <el-table-column prop="role_name" label="角色"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            style="color: #409eff"
            type="text"
            icon="el-icon-user"
            size="mini"
            @click="handleEditUser(scope.row)"
            >用 户</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="currentPage"
      @current-change="handlePageChange"
      style="margin-top: 20px; text-align: center"
    />

    <list_users :visible.sync="selectUserVisible"></list_users>
  </div>
</template>

<script>
import { from } from "rxjs";
import list_users from "./components/list_users.vue";
export default {
  components: {
    list_users,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      selectUserVisible: false,
      userAllTableData: [],
    };
  },
  computed: {
    currentTableData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.tableData.slice(start, end);
    },
    selectUserIds() {
      return this.userAllTableData.map((item) => item.id);
    },
  },
  async created() {
    const data = await this.$dbquery(
      this.$http,
      `SELECT * FROM xtbg_device_roles`
    );
    this.tableData = data;
    this.total = data.length; // 设置数据总数
  },
  methods: {
    

    handlePageChange(page) {
      this.currentPage = page;
    },
    handleEditUser(){
      this.selectUserVisible = true;
      
    },
    closeUser(){
      this.selectUserVisible = false;
      
    }
   
  },
};
</script>

<style scoped>
.el-table {
  width: 100%;
}
</style>
