import request from '@/utils/request.js'
//获取部门列表
export function ListDepts(){
    return request('/system/dept/list',{
        method: "get",
       
    });
}
export function ListAllDepts(){
    return request('/system/dept/list',{
        method: "get",
      
        params:{
            pageSize:2000,
            pageNum:1
        }
    });
}

//根据id获取部门名称
export function getDeptNameById(id){
    return request('/system/dept/'+id,{
        method: "get",
       
       
    });
}