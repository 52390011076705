<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-10-10 20:20:20
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-10-12 14:17:07
 * @FilePath: \app_wb\src\components\commom\index_list_item_fzgh.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="index_list-ss">
    <div class="index_list-ss2" @click="navTo">科技创新规划</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input2: "",
      searchTxt: "",
    };
  },

  methods: {
    navTo() {
      this.$router.push({
        path: "/fzgh",
        query: {
          name: "“十四五”规划",
        },
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.index_list-ss {
  width: 385px;
  padding-left: 10px;
  height: 180px;
  background: url("../../assets/imgs/fzgh/kxfzi.png") no-repeat;
  background-size: 100%;
  background-position-x: 10px;
  backdrop-filter: blur(105px); /* 调整5px以改变模糊程度 */
  -webkit-backdrop-filter: blur(105px); /* 为了兼容Safari浏览器，添加前缀 */


  margin-top: 5px;
  position: relative;
}
.index_list-ss2 {
  position: absolute;
  left: 10px;
  top: 0;
  width: 375px;
  padding-left: 10px;
  height: 180px;
  /* background: rgba($color: #519f6d, $alpha: 1); */
  background-size: 385px 100%;
  background-position-x: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 2px;
  color: rgb(255, 255, 255);
  cursor: pointer;

  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* 阴影的位置和颜色 */
}
</style>