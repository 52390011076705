import Vue from 'vue'
import { tables } from './../../../api/content/db/search';

export function dbquery(myhttp,db_dynamic_url,sqlStr){
    return new Promise((resolve,reject)=>{
        let sqlQuery= {
            "query":sqlStr,
            "params":[]
        }
        myhttp.post(db_dynamic_url,sqlQuery).then(result=>{
            resolve(result.data)
        }).catch(error=>{
            reject(error)
        })

    })
}
export async function  getDataTotal(myhttp,db_dynamic_url,tableName) {
    const sqlStr = `SELECT COUNT(*) as total from ${tableName}`;
    return  (await dbquery(myhttp,db_dynamic_url,sqlStr))[0]["total"]
    
}